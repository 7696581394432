import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, history, loadDefaultFilter } from '../../utils';
import { columns } from '../../constants/tablesColumns';
import MaterialTable from 'material-table';
import { materialTableProps, userLookups, ResetButton } from '../../utils';
import { userService, organisationUserService } from '../../services';
import { notification, Popconfirm } from 'antd';
import Icon from '@material-ui/core/Icon';

class UserListPage extends Component {
    constructor(props) {
        super(props);
        const tableKey = 'user_list';
        this.state = {
            _tableKey: tableKey,
            _title: 'Centraide - Liste des utilisateurs',
            possibleColumns: (columns.getKeys('users') || []).map((c) => {
                var completeCol = {
                    ...columns['users']()[c],
                    field: c.toLowerCase(),
                };
                loadDefaultFilter(tableKey, completeCol);
                return { ...completeCol };
            }),
            user_types: {},
            association: [],
            org_user_filter: null,
            update_fitler: false,
        };

        this.tableRef = React.createRef();
        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        organisationUserService
            .get()
            .then((result) => {
                this.setState({ association: result });
                this.refreshTable();
            })
            .catch((e) => {
                this.setState(() => {
                    throw new Error(e);
                });
            });

        userLookups(this);
    }

    handlePagination(query) {
        var _ = this;

        query = JSON.parse(JSON.stringify(query));
        query.filters = query.filters.filter((el) => {
            if (el.column.field === 'organization_name') {
                el.column.field = 'has_organization_users';
                return el;
            } else {
                return true;
            }
        });

        return new Promise(function (resolve, reject) {
            userService
                .listPaginate(
                    query.page + 1,
                    query.pageSize,
                    query.filters,
                    query.orderBy,
                    query.orderDirection
                )
                .then((result) => {
                    result.data.map((el) => {
                        el.created_at = el.created_at
                            ? new Date(el.created_at)
                            : null;
                        el.updated_at = el.updated_at
                            ? new Date(el.updated_at)
                            : null;
                        const organization = _.state.association.filter(
                            (a) => a.user_id === el.id
                        );
                        el.organization_name =
                            organization.length > 0
                                ? organization[0].organization.name
                                : '';
                        return el;
                    });

                    resolve({
                        data: result.data,
                        page:
                            result.total_pages > result.page
                                ? result.page - 1
                                : result.total_pages === 0
                                ? 0
                                : result.total_pages - 1,
                        totalCount: result.total,
                    });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    handleDisable(user) {
        userService
            .disable(user.id)
            .then(() => {
                notification.open({
                    message: 'Utilisateur désactivé',
                    description:
                        "L'utilisateur " +
                        user.email +
                        ' a été désactivé avec succès.',
                    placement: 'bottomRight',
                });

                this.refreshTable();
            })
            .catch((e) => {
                notification.open({
                    message: "Une erreur s'est produite",
                    description:
                        'Veuillez réessayer ou contacter un administrateur.',
                    placement: 'bottomRight',
                });
            });
    }

    handleEnable(user) {
        userService
            .enable(user.id)
            .then(() => {
                notification.open({
                    message: 'Utilisateur activé',
                    description:
                        "L'utilisateur " +
                        user.email +
                        ' a été activé avec succès.',
                    placement: 'bottomRight',
                });

                this.refreshTable();
            })
            .catch((e) => {
                notification.open({
                    message: "Une erreur s'est produite",
                    description:
                        'Veuillez réessayer ou contacter un administrateur.',
                    placement: 'bottomRight',
                });
            });
    }

    refreshTable() {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    render() {
        return (
            <Fragment>
                <Helmet>{title('Liste des utilisateurs')}</Helmet>

                <div className="users-color">
                    <h1>Liste des utilisateurs</h1>

                    <article className="box table-box">
                        <ResetButton tableKey={this.state._tableKey} />

                        <MaterialTable
                            title={this.state._title}
                            columns={this.state.possibleColumns}
                            data={(query) => this.handlePagination(query)}
                            tableRef={this.tableRef}
                            {...materialTableProps(
                                this.state._tableKey,
                                this.state._title
                            )}
                            onRowClick={(event, rowData) => {
                                history.push(`/users/${rowData.id}`);
                            }}
                            actions={[
                                {
                                    icon: 'person_add',
                                    iconProps: { className: 'main' },
                                    tooltip: 'Ajouter un utilisateur',
                                    isFreeAction: true,
                                    onClick: (event) =>
                                        history.push('/users/new'),
                                },
                                {
                                    icon: 'edit',
                                    tooltip: "Éditer l'utilisateur",
                                    onClick: (event, rowData) =>
                                        history.push(
                                            '/users/' + rowData.id + '/edit'
                                        ),
                                },
                                (rowData) => ({
                                    icon: () => (
                                        <Popconfirm
                                            placement="topRight"
                                            title="Êtes-vous certain de vouloir désactiver l’utilisateur ?"
                                            onConfirm={() =>
                                                this.handleDisable(rowData)
                                            }
                                            onCancel={null}
                                            okText="Oui"
                                            cancelText="Non"
                                        >
                                            <Icon>visibility_on</Icon>
                                        </Popconfirm>
                                    ),
                                    tooltip: "Désactiver l'utilisateur",
                                    onClick: (event, rowData) => null,
                                    hidden: rowData.disabled,
                                }),
                                (rowData) => ({
                                    icon: () => (
                                        <Popconfirm
                                            placement="topRight"
                                            title="Êtes-vous certain de vouloir activer l’utilisateur ?"
                                            onConfirm={() =>
                                                this.handleEnable(rowData)
                                            }
                                            onCancel={null}
                                            okText="Oui"
                                            cancelText="Non"
                                        >
                                            <Icon>visibility_off</Icon>
                                        </Popconfirm>
                                    ),
                                    tooltip: "Activer l'utilisateur",
                                    onClick: (event, rowData) => null,
                                    hidden: !rowData.disabled,
                                }),
                            ]}
                        />
                    </article>
                </div>
            </Fragment>
        );
    }
}

export default UserListPage;
