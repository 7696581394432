import React, { Component, Fragment } from 'react';
import { Icon } from '@material-ui/core';

import { notification, Modal } from 'antd';
import { Answer, formatAnswers } from '../../../models'
import { formatFileName, formatDownloadFile, formatYear } from '../../../utils'
import { Can, FIDC_FIDUCIARY_DOCS } from '../../../constants'

import { documentService, formService } from "../../../services";

export const showModal = (_) => { _.setState({ documentsModal: true }) }
export const hideModal = (_) => { _.setState({ documentsModal: false }) }
export const allDocsImported = (docs, ans) => {
    var allDocsImported = true;

    for (var d of docs) {
        var r = d.question.required
        if (!r && r !== true && r !== 'true' && r != '1' && r !== 't') continue
        if (!(d.option.id in ans)) {
            allDocsImported = false;
            break;
        }
    }

    return allDocsImported
}

export function fetch(_, formId) {
    documentService.getQuestionFromForm(formId).then((questions) => {
        if (questions.length > 0) {
            documentService.getOptionsFromQuestions(questions).then((options) => {
                var docs = options.map(o => {
                    return {
                        question: o.question,
                        option: o
                    }
                })

                _.setState({ docs: docs, docsDone: allDocsImported(docs, _.state.ans) })
            }).catch((e) => {
                _.setState(() => { throw new Error(e); })
            });
        }
        else {
            _.setState({ docs: [], docsDone: allDocsImported([], _.state.ans) })
        }

    }).catch((e) => {
        _.setState(() => { throw new Error(e); })
    })
}

export function handleChange(_, e, option) {
    if (!e.target.files[0]) return

    if (e.target.files[0].size > 20000000) {
        notification.open({
            message: 'Document trop volumineux',
            description: 'Le document dépasse la taille maximale (max. 20mo).',
            placement: 'bottomRight',
        });
        e.target.value = null;
        return
    }

    var reader = new FileReader(),
        save = _.props.saveForm,
        file = e.target.files[0],
        name = formatFileName(file.name),
        requestId = _.state.id;

    formService.uploadDocument(option.label, file, option.document_type, requestId).then((result) => {
        var a = formatAnswers({ ...Answer(option.id, name) }, requestId)

        save(a, requestId).then(() => {
            var ans = { ..._.state.ans, [option.id]: a[0] }
            _.setState({ ans: ans, docsDone: allDocsImported(_.state.docs, ans) })

            notification.open({
                message: 'Fichier téléversé',
                description: 'Le document a été enregistré avec succès.',
                placement: 'bottomRight',
            });
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        })
    })
}

export function renderDocument(_, doc, answerKey, answers, small = false) {
    const { req } = _.state;
    var fiduciary = (req.isFIDC() && FIDC_FIDUCIARY_DOCS.indexOf(doc.option.document_type) >= 0)

    var downloader = (html) => formatDownloadFile(answers[answerKey].value, _.state.req.id, doc.option.document_type, html)
    return small ? (
        <li key={ doc.option.document_type } className={ 'small ' + (answerKey ? 'done' : 'not-done') }>
            { (answerKey) ? (
                    downloader(
                        <span className="file-item-header">
                            <Icon className={ answerKey ? 'done' : '' }>{ answerKey ? 'done' : 'close' }</Icon>
                            <i>
                                { (doc.question.required) ? <span className="required">*</span> : '' }
                                { formatYear(doc.option.label, req.financial_year) }
                            </i>
                            <Icon>keyboard_arrow_down</Icon>
                        </span>
                    )
                ) : (
                    <span className="file-item-header">
                        <Icon className={ answerKey ? 'done' : '' }>{ answerKey ? 'done' : 'close' }</Icon>
                        <i>
                            { (doc.question.required) ? <span className="required">*</span> : '' }
                            { formatYear(doc.option.label, req.financial_year) }
                        </i>
                        <Icon>keyboard_arrow_down</Icon>
                    </span>
                ) }
        </li>
    ) : (
        <li key={ doc.option.document_type } className={ ((doc.opened && doc.opened === true) ? 'opened' : 'opened') + (answerKey ? ' done' : ' not-done') }>
            <span className="file-item-header">
                <Icon className={ answerKey ? 'done' : '' }>{ answerKey ? 'done' : 'close' }</Icon>
                <i>
                    { (doc.question.required) ? <span className="required">*</span> : '' }
                    { formatYear(doc.option.label, req.financial_year) }
                    { fiduciary && <b> (organisme fiduciaire)</b> }
                </i>
                <Icon>keyboard_arrow_down</Icon>
            </span>

            <Can do="edit_form" on={ req }>
                <span className="file-item-body">
                    <i>{ formatYear(doc.question.description, req.financial_year) }</i>
                    { answerKey ? <span>Fichier courant : <b>{ downloader(answers[answerKey].value) }</b></span> : null}
                    { !req.isFormLocked() && (
                        <span>
                            Importer{ (answerKey) && ' à nouveau'} (max. 20 mo) :
                            <input id={ 'file-' + doc.option.id } type="file" onChange={ (e) => handleChange(_, e, doc.option) } />
                        </span>
                    ) }
                </span>
            </Can>
        </li>
    )
}

export function render(_) {
    const { req, docs, ans } = _.state;
    var documents = [];

    docs.forEach(function (d, i) {
        if (!d.option) return;
        const answerKey = Object.keys(ans).find(key => key == d.option.id);
        documents.push(renderDocument(_, d, answerKey, ans, false))
    });

    return (
        <article className="box documents-box">
            <h3>{ !req.isFormLocked() ? 'Documents à fournir' : 'Documents fournis' }</h3>
            { req.isFIDC() && <small>Dans le cas où votre projet est soutenu par un organisme fiduciaire, les prochains documents à transmettre doivent provenir de ce dernier (organisme fiduciaire).</small>}
            <ul>{ documents }</ul>
        </article>
    )
}

export function renderModal(_) {
    const { req, docs, ans, documentsModal } = _.state;
    var documents = [];

    docs.forEach(function (d, i) {
        if (!d.option) return;
        const answerKey = Object.keys(ans).find(key => key == d.option.id);
        documents.push(renderDocument(_, d, answerKey, ans, true))
    });

    return (
        <Modal
            visible={ documentsModal }
            className="request-documents-modal"
            centered
            onCancel={ () => hideModal(_) }>

            <h3>Documents fournis</h3>
            <ul>{ documents }</ul>
        </Modal>
    )
}
