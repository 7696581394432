import React from 'react';
import { Hint, Crosshair, ChartLabel } from 'react-vis';

export const defaultLabel = 'N/A'
export const label = (l) => (l !== null ? l : defaultLabel)

export const colors = {
    'request_types': ['#FF4B4B', '#FF9D4B', '#34B2B2', '#9036A9'],
    'amounts': ['#9891DD', '#6E65C6', '#3FD93F'],
    '1_2': ['#6E65C6', '#9891DD'],
    'colors': ['#3FD93F', '#FFCC4B', '#FF9D4B', '#FF4B4B'],
    'default': ['#FF4B4B', '#34B2B2', '#FFCC4B', '#3FD93F', '#FF9D4B', '#9036A9']
}

export const color = (i, label, palette = 'default') => {
    if (label && label == defaultLabel) return '#eaeaea';

    if (!palette || !(palette in colors)) return '#555555';

    var range = colors[palette];
    if (i >= range.length) return '#555555';
    return range[i]
}

export const defaultChartHeight = '300px';
export const defaultChartHeightRaw = 300;

export const formatNo = (no) => parseFloat(no).toLocaleString('fr')

export const sortFunction = (a, b, key) => {
    if (a[key] == defaultLabel) return 1;
    if (b[key] == defaultLabel) return -1;
    if (a[key] == 'Autre' || a[key] == 'Autres') return 1;
    if (b[key] == 'Autre' || b[key] == 'Autres') return -1;
    if (a[key] < b[key]) return -1
    if (a[key] > b[key]) return 1
    return 0
}

export const sortFunctionByValue = (a, b) => {
    if (a.label == defaultLabel) return 1;
    if (b.label == defaultLabel) return -1;
    if (a.label == 'Autre' || a.label == 'Autres') return 1;
    if (b.label == 'Autre' || b.label == 'Autres') return -1;
    return a.value - b.value
}

export const renderXAxisLabel = (label) => (
    <ChartLabel
        className="x-axis-label"
        text={ label }
        includeMargin={ false }
        xPercent={ 0.5 } yPercent={ 1.19 }
        style={{ textAnchor: 'middle' }} />
)

export const renderYAxisLabel = (label, ml = 50) => (
    <ChartLabel
        className="y-axis-label"
        text={ label }
        includeMargin={ false }
        xPercent={ 0 } yPercent={ 0.5 }
        style={{ transform: `translate(-${ ml - 10 } 0) rotate(-90)`, textAnchor: 'middle' }} />
)

export const renderDiscrete = (data) => (
    <span>{ data.label }<em>:</em><b>{ formatNo(data.value) }</b></span>
)

export const renderGroupedDiscrete = (group, suffix = '') => (
    <span>{ group[0].group }<em>:</em><b>{ formatNo(group.reduce((a, b) => a + b.y, 0)) }{ suffix }</b></span>
)

export const findMinMax = (arr, key, padding = 0) => {
    if (arr.length === 0) return [-Infinity, Infinity]
    let min = arr[0][key], max = arr[0][key];

    for (let i = 1, len = arr.length; i < len; i++) {
        let v = arr[i][key];
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    }

    max = max + padding;
    return [0, max < 5 ? 5 : max];
}

export const renderHint = (hint, html) => (
    <Hint value={ hint }>
        { html ? html : (
            <div className="vis-hint">
                <h4>{ hint.label } : { hint.value }</h4>
            </div>
        ) }
    </Hint>
)

export const renderCrosshair = (crosshair, html) => (
    <Crosshair values={ crosshair }>
        { html ? html : (
            <div className="vis-crosshair">
                <b>{ (crosshair[0] || {}).y }</b>
            </div>
        ) }
    </Crosshair>
)

export const renderTick = (t, i) => {
    if (!isNaN(t)) return formatNo(t)
    return t;
}
