import React from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds, formatId, isChecked } from '../../../utils'
import { Answer } from '../../../models'

import { formActions } from "../../../actions";
import { connect } from 'react-redux';

class Toggle extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        var optionId = e.target.value;
        // VALIDATION this.state.error
        this.props.saveAnswer(Answer(optionId, e.target.checked))
    }


    render() {
        if (this.props.readonly) {
            var ans = this.props.answer;
            return (
                <p style={{ color: (ans && ans.value === true) ? 'green' : 'red' }}>
                    { formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear) }
                </p>
            )
        }

        return (
            <p>
                <input  type="checkbox"
                        name={ formatId(this.props.id) }
                        value={ this.props.options[0].id }
                        id={ formatId(this.props.id) }
                        onChange={ this.handleChange }
                        defaultChecked={ this.props.answer.value } />

                { formatLabel(this.props.label, this.props.id, this.props.required, this.props.help, this.props.question_number, this.props.financialYear) }

                { (this.state.error) && <small className="error">{ this.props.error }</small> }
            </p>
        )
    }
}

Toggle.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answer: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionId = (fetchOptionIds(props.options) || [])[0]

    return {
        financialYear: state.form.base.financialYear,
        answer: state.form.answers[optionId] || {}
    };
}


const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toggle);
