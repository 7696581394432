import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, renderSelectYears } from '../../utils';
import { analyzeService, requestService } from "../../services";
import { connect } from 'react-redux';
import { AnswerTable } from '../../components';
import { ANALYZES_FOR_TYPE, AN_TYPE_SUMMARY, regionLookups } from '../../constants';

import { Select } from 'antd';


class ReportingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            _tableKey: 'reporting_list',
            _title: 'Centraide - Export Excel',
            type_reporting_selected: null,
            id_type_selected: null,
            year_selected: null,
            states_selected: [],
            regions_selected: [],
            type_reporting: {
                "Demande": requestService,
                "Analyse": analyzeService,
            },
            type: {
                "Demande": null,
                "Analyse": null,
            },
            request_state: {
                "Demande": null,
            },
            loaded: false,
        };

        this.renderTypeReporting = this.renderTypeReporting.bind(this);
        this.renderType = this.renderType.bind(this);
        this.renderYear = this.renderYear.bind(this);
        this.renderRequestState = this.renderRequestState.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        requestService.listTypes().then(result => {
            this.setState({ type: { ...this.state.type, "Demande": result } })
        }).catch(e => {
            this.setState(() => { throw new Error(e); })
        });

        requestService.listStates().then(result => {
            this.setState({ request_state: { ...this.state.request_state, "Demande": result } })
        }).catch(e => {
            this.setState(() => { throw new Error(e); })
        });

        analyzeService.listTypes().then(result => {
            this.setState({ type: { ...this.state.type, "Analyse": result } })
        }).catch(e => {
            this.setState(() => { throw new Error(e); })
        });
    }

    handleSelectChange = name => value => {
        this.setState({ [name]: value });
        this.setState({loaded: false});
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
        this.setState({loaded: false});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({loaded: true});
    }

    renderTypeReporting() {
        const { type_reporting, type_reporting_selected } = this.state;

        var options = [];

        Object.keys(type_reporting).forEach(k => {
            options.push(
                <Select.Option key={k}
                    value={k} >
                    {k}
                </Select.Option>
            );
        });

        return (
            <Select showSearch
                allowClear
                value={type_reporting_selected}
                onChange={this.handleSelectChange("type_reporting_selected")}>
                {options}
            </Select>
        );
    }

    renderType() {
        const { type_reporting_selected, id_type_selected } = this.state;
        const type = this.state.type[type_reporting_selected] || [];

        var options = [];
        if (type_reporting_selected === 'Analyse') {
            // Analyzes Select --->
            options = this.state.type['Demande'].map(rt => {
                var analyzesForType = ANALYZES_FOR_TYPE[rt.id] || [];
                var analyzes = type
                    .filter(at => analyzesForType.indexOf(at.id) !== -1)
                    .map(at => (
                        (at.id !== AN_TYPE_SUMMARY) && (
                            <Select.Option key={ at.id }
                                value={ at.id }>
                                { at.label }
                            </Select.Option>
                        )
                    ))

                return (
                    <Select.OptGroup label={ rt.label } key={ rt.id }>
                        { analyzes }
                    </Select.OptGroup>
                )
            })
        } else {
            // Requests Select --->
            type.forEach(t => {
                options.push(
                    <Select.Option key={t.id}
                        value={t.id} >
                        {t.label}
                    </Select.Option>
                );
            });
        }

        return (
            <Select showSearch
                allowClear
                value={id_type_selected}
                onChange={this.handleSelectChange("id_type_selected")}>
                {options}
            </Select>
        );
    }

    renderRegion() {
        const { regions_selected } = this.state;

        let regions = [];
        for (let region in regionLookups) {
            regions.push(<Select.Option key={ region } value={ region }>{ regionLookups[region] }</Select.Option>)
        }

        return (
            <Select showSearch allowClear
                mode="multiple"
                value={ regions_selected }
                onChange={ (val) => this.handleChange({ target: { name: 'regions_selected', value: val } }) }>
                { regions }
            </Select>
        )
    }

    renderYear() {
        const { year_selected } = this.state;

        return (
            <Select showSearch value={ year_selected } name={ "year_selected" } onChange={ (val) => this.handleChange({ target: { name: 'year_selected', value: val } }) }>
                { renderSelectYears(0, 10) }
            </Select>
        );
    }

    renderRequestState() {
        const { states_selected } = this.state;
        const { request_state } = this.state;

        let states = [];
        for (let state of request_state['Demande']) {
            states.push(<Select.Option key={state.id} value={state.id}>{state.label}</Select.Option>)
        }

        return (
            <Select showSearch allowClear
                mode="multiple"
                value={states_selected}
                onChange={(val) => this.handleChange({ target: { name: 'states_selected', value: val } })}>
                {states}
            </Select>
        )
    }

    renderButton() {
        return (
            <button
                name={"loaded"}
                onClick={(e) => this.handleSubmit(e)} >
                Charger les résultats
            </button>
        );
    }

    render() {
        const { _tableKey, _title, type_reporting, type_reporting_selected, id_type_selected, year_selected, states_selected, regions_selected, loaded } = this.state;

        return (
            <Fragment>
                <Helmet>
                    {title('Export Excel')}
                </Helmet>

                <div className="organisations-color reporting-page">
                    <h1>Export Excel</h1>

                    <article>
                        <form>
                            <div className="ctn">
                                <label>Régions :</label>
                                { this.renderRegion() }
                            </div>
                            <div className="ctn">
                                <label>Type de rapport :</label>
                                { this.renderTypeReporting() }
                            </div>
                            { type_reporting_selected && (
                                <div className="ctn">
                                    <label>{ type_reporting_selected === 'Analyse' ? 'Type d\'analyse' : 'Choix du fond' } :</label>
                                    { this.renderType() }
                                </div>
                            ) }
                            { id_type_selected && (
                                <div className="ctn">
                                    <label>Année :</label>
                                    { this.renderYear() }
                                </div>
                            ) }
                            { year_selected && type_reporting_selected === 'Demande' && (
                                <div className="ctn">
                                    <label>État de la demande :</label>
                                    { this.renderRequestState() }
                                </div>
                            ) }
                            { year_selected && (
                                <div className="buttons">
                                    { this.renderButton() }
                                </div>
                            ) }
                        </form>
                    </article>

                    <article className="box table-box">
                        { loaded && (
                            <AnswerTable
                                service={type_reporting[type_reporting_selected]}
                                tableKey={_tableKey}
                                title={_title + ' ' + type_reporting_selected}
                                id_type={id_type_selected}
                                year={year_selected}
                                regions={ regions_selected }
                                states={ states_selected }
                                orga_name={type_reporting_selected === 'Analyse'}
                                demande_display={type_reporting_selected === 'Demande'}
                            />
                        ) }
                    </article>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPage);
