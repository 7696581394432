import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

import { requestService, analyzeService } from "../../../services";
import * as documentsHelper from './documents';
import * as recommendationsHelper from './recommendations';
import * as analyzesController from '../../../constants/analyzeStates';

import { Popconfirm, notification } from 'antd';

import {
    Can,
    AN_TYPE_FIN,
} from '../../../constants'


export function fetch(_, reqId) {
    analyzeService.list({ request_id: reqId }).then(a => {
        a = a.map(analyze => formatAnalyzes(analyze))
        _.setState({ analyzes: a })
    }).catch(e => {
        _.setState(() => { throw new Error(e); })
    })
}

export function create(_, req) {
    analyzeService.create(req.id, req.type_id).then(a => {
        a = a.map(analyze => formatAnalyzes(analyze))
        _.setState({ analyzes: a })
    }).catch(e => {

    })
}

export function formatAnalyzes(a) {
    return { ...a, ...analyzesController.injectFunctions() }
}

export function areDone(_, analyzes = _.state.analyzes || []) {
    const { req } = _.state;
    if (!req.isInAnalysis()) return;

    var allSubmitted = true
    analyzes.forEach(a => {
        if (!a.isSubmitted())
            allSubmitted = false;
    })

    return allSubmitted;
}

export function acceptAnalyzes(_) {
    const { req } = _.state;

    requestService.update({
        id: req.id,
        state_id: _.getNextState()
    }).then((r) => {
        r = _.formatRequest(r);
        _.setState({ req: { ...req, ...r } })

        if (r.hasRecommendations()) {
            recommendationsHelper.fetchColors(_);
            recommendationsHelper.fetch(_, { ...req, ...r });

            notification.open({
                message: 'Analyses acceptées',
                description: 'Les analyses ont été acceptées avec succès.',
                placement: 'bottomRight',
            });
        } else {
            notification.open({
                message: 'Analyses acceptées',
                description: 'Les analyses ont été acceptées avec succès. Cette demande est maintenant complète, elle sera archivée sous peu.',
                placement: 'bottomRight',
            });
        }
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function updateAnalyze(_, a, updateType = 'submit') {
    if (updateType !== 'submit' && updateType !== 'unsubmit') return;

    switch (updateType) {
        case 'submit':
            var obj = { id: a.id, state_id: analyzesController.submitted() }
            var term = 'soumise';
            break;
        case 'unsubmit':
            var obj = { id: a.id, state_id: analyzesController.progress() }
            var term = 'retournée';
            break;
        default:
            var obj = { id: a.id, state_id: a.state_id }
            var term = 'mise à jour';
    }

    analyzeService.update(obj).then((a) => {
        var analyzes = _.state.analyzes.slice(0)
        var i = analyzes.findIndex(analyze => analyze.id == a.id)
        analyzes[i] = formatAnalyzes(a);

        _.setState({ analyzes: analyzes })

        notification.open({
            message: `Analyse ${ term }`,
            description: `L\'analyse a été ${ term } avec succès.`,
            placement: 'bottomRight',
        });
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function render(_) {
    const { req, analyzes, id } = _.state;
    if (!req.analyzesAreOpened()) return;

    const listAnalyzes = (analyzes || []).map((a, i) => {
        if (a.isSummary()) return false;

        return (
            <Can key={ i } do={ a.isFinancial() ? 'edit_financial_analyze' : 'edit_analyze' } on={ req } passThrough>
            { can => (
                <article className={ "analyze-item " + (a.isSubmitted() ? 'green' : '') }>
                    <h4>
                        <Link to={ `/requests/${ req.id }/analysis/${ a.id }`}>{ (a.analyze_type || {}).label }</Link>
                    </h4>
                    { (can && a.isInProgress()) && (
                        <Popconfirm
                            title={ "Êtes-vous certain de vouloir soumettre cette analyse?" + (a.isFinancial() ? " Assurez-vous d'avoir compléter le sommaire financier." : "") }
                            onConfirm={ () => updateAnalyze(_, a, 'submit') }
                            onCancel={ () => {} }
                            okText="Soumettre"
                            cancelText="Annuler">
                            <a className="button">Soumettre l'analyse</a>
                        </Popconfirm>
                    ) }

                    { (req.isInAnalysis() && a.isSubmitted()) && (
                        <Can do={ "revert_analyze" } on={ req }>
                            <a className="button green" onClick={ () => updateAnalyze(_, a, 'unsubmit') }>Retourner l'analyse</a>
                        </Can>
                    ) }
                </article>
            ) }
            </Can>
        )
    }).filter(a => a !== false)


    return (
        <Can do="see_backstage" on={ req }>
            <article className="buttons analyzes-buttons">
                <Can do={ "edit_financial_summary" } on={ req } passThrough>
                    { canFin => (
                        <Can do="edit_summary" on={ req } passThrough>
                            { canSum => (
                                <Fragment>
                                    { (canSum && req.hasSummary() && req.isInAnalysis()) && (
                                        <Link to={ '/requests/' + id + '/summary' } className={ "button alt" + ((!req.hasFinancialSummary() || !canFin) ? ' full' : '') }>{ "Remplir la fiche synthèse" }</Link>
                                    ) }
                                    { (canFin && req.hasFinancialSummary() && req.isInAnalysis()) && (
                                        <Link to={ '/organisations/' + req.organization_id + '/financial/' + req.financial_year + '/summary' } className={ "button alt" + ((!req.hasSummary() || !canSum) ? ' full' : '') }>Remplir le sommaire financier</Link>
                                    ) }
                                </Fragment>
                            ) }
                        </Can>
                    ) }
                </Can>
            </article>

            <Can do="read_analyze" on={ req }>
                { listAnalyzes.length > 0 && (
                    <article className="box analyzes-box">
                        <h3>Analyses</h3>
                        <div className="analyzes-list">
                            { listAnalyzes }
                        </div>
                    </article>
                ) }
            </Can>
        </Can>
    )
}
