import React, { Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import 'antd/es/tooltip/style/index.css';
import { Tooltip, Select } from 'antd';
import { documentService } from '../services';
import { saveAs } from 'file-saver';
import { isArray } from 'util';


// Format les labels
export function formatLabel(label, id = null, req = false, help = null, question_number = null, financialYear = new Date().getFullYear()) {
    // Solution ghetto pour espace insécable avec le point d'interrogation
    // d'une question.
    label = formatYear(label, financialYear);
    if (label.lastIndexOf(' ?') === label.length - 2) {
        label = label.substring(0, label.length - 2);
        var lastWord = label.split(' ').splice(-1).toString();
        label = label.substring(0, label.length - lastWord.length);
        label = <i>{label}<em>{lastWord} ?</em></i>
    }

    return (
        <label htmlFor={(id) ? formatId(id) : ''}>
            {(req) && <span className="required">*</span>}
            {(question_number) && <b>{question_number}.</b>} {label}
            {(help) &&
                <Tooltip title={formatYear(help, financialYear)}>
                    <Icon>help</Icon>
                </Tooltip>
            }
        </label>
    )
}

// Format le nom d'un fichier pour être safe
export function formatFileName(name) {
    return name.replace(/[^a-z0-9.]/gi, '_').toLowerCase();
}

// Fetch tous les IDs des options[] données
// Return : [OptionId, OptionId, ...]
export function fetchOptionIds(options) {
    return (options || []).map(o => o.id) || [];
}

// Format les IDs des inputs en fonction de la question/option
// Return String
export function formatId(questionId, optionI) {
    var id = "q" + questionId;
    if (optionI !== undefined) id += "-o" + optionI
    return id;
}

// Format les IDs d'un tableau en fonction de la question/option/colonne
// Return String
export function formatTableId(questionId, optionI, colI) {
    var id = formatId(questionId, optionI);
    if (colI !== undefined) id += "-c" + colI
    return id;
}

// Retourne les IDs des options selectionnées dans un Answers{} donné
// Return [ OptionId, OptionId, ...]
export function fetchCheckedAnswers(answers) {
    var checked = []
    Object.keys(answers).map(oid => {
        if (answers[oid] && (answers[oid].value === true || answers[oid].value === 'true' || answers[oid].value === '1' || answers[oid].value === 't')) checked.push(oid)
    })
    return checked
}

// Affiche les réponses cochées en fonction des Options[] et des Answers{}
// Return [Option.label ?(+ Answer.detail), Option.label ...]
export function displayCheckedAnswers(answers, options) {
    var checked = []
    Object.keys(answers).map(oid => {
        var option = (options || []).find(o => o.id == oid) || {};
        var str = option.label + ((option.detail && answers[oid].detail) ? ' - ' + answers[oid].detail : '');
        if (answers[oid] && (answers[oid].value === true || answers[oid].value === 'true' || answers[oid].value === '1' || answers[oid].value === 't')) checked.push(str)
    })
    return checked
}

// Indique si un Option donnée est selectionnée en fonction des Answers{}
// Retourn Boolean
export function isChecked(answers, option) {
    if (!answers) return false
    return (option.id in answers && (answers[option.id].value === true || answers[option.id].value === 'true' || answers[option.id].value === '1' || answers[option.id].value === 't'));
}

// Display les messages d'erreur
// Return String
export function displayError() {
    return "Erreur"
}

// Transforme un String d'un Array en Array
// Return Array
export function parseArrayString(str) {
    if (str  && !Array.isArray(str) && typeof str === "string") {
        str = str.replaceAll('\’', '\'')
        return JSON.parse(str);
    }
    return str;
}

export function validateProfileFields(profile, required) {
    var errors = {}, hasError = false;
    required.map((key) => {
        var v = profile[key];
        var error = (!v || v === '' || v === null);

        // Assignation de l'erreur à la bonne clé dans le state.
        if (error) hasError = true;
        errors = { ...errors, [key]: error }
    })

    return {
        hasError: hasError,
        errors: errors
    }
}


export function formatDownloadFile(filename, request_id, type_id, html = 'Télécharger') {
    const downloader = () => {
        documentService.get(request_id, type_id)
            .then(resulat => {
                saveAs(resulat, filename)
            });
    }

    return (
        filename && <a onClick={downloader}>{ html }</a>
    )
}

export function formatYear(str, year = new Date().getFullYear()) {
    year = parseInt(year);

    return str ? (str.replace(/(\${.*?\})/g, (x) => {
        var [operateur, value] = x.replace('${year', '').replace('}', '');
        switch(operateur) {
            case '-':
                return (year - parseInt(value)) + '-' + (year - (parseInt(value) - 1));

            case '+':
                return (year + parseInt(value)) + '-' + (year + (parseInt(value) + 1));

            default:
                return year + '-' + (year + 1);
        };
    })) : str;
}

export function renderSelectYears(past = null, to = 5) {
    var base = 2019,
        toBase = new Date().getFullYear(),
        min = (past !== null ? base - past : 2015),
        max = (to   !== null ? toBase + to : 2030),
        years = []

    for (var i = min; i <= max; i++)
        years.push(<Select.Option key={ i } value={ i }>{ i }</Select.Option>)

    return years
}

export function formatBackSpaces(str) {
    if (typeof str === 'string')
        return str.split('\n').map((item, key) => <Fragment key={ key }>{ item }<br/></Fragment>)
    return str
}
