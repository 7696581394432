import React from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds,formatId, formatFileName, formatDownloadFile } from '../../../utils'
import { Answer } from '../../../models'

import { formActions } from "../../../actions";
import { connect } from 'react-redux';

import { formService } from "../../../services";



class File extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false, blob: null }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        // VALIDATION this.state.error
        // TODO : sortir l'import de la demande, juste mettre true/false dans le answer? upload direct dans request
        if (!e.target.files[0]) return

        if (e.target.files[0].size > 20000000) {
            this.setState({
                error: 'Document trop volumineux'
            });
            e.target.value = null;
            return
        }

        var reader = new FileReader(),
            save = this.props.saveAnswer,
            option = this.props.options[0],
            file = e.target.files[0],
            name = formatFileName(file.name);

        formService.uploadDocument(option.label, file, option.document_type, this.props.requestId).then((result) => {
            save(Answer(option.id, name))
        })
    }

    render() {
        var filename = this.props.answer.value;

        if (this.props.readonly) {
            return (
                <p>
                    { formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear) }
                    { (filename) && <b>{ formatDownloadFile(filename, this.props.requestId, this.props.options[0].document_type, filename) }</b> }
                </p>
            )
        }

        return (
            <p className="file-type">
                { formatLabel(this.props.label, this.props.id, this.props.required, this.props.help, this.props.question_number, this.props.financialYear) }
                { (filename) && <span>Fichier courant : <b>{ formatDownloadFile(filename, this.props.requestId, this.props.options[0].document_type, filename) }</b></span> }
                <span>
                    Importer{ (filename) && ' à nouveau'} (max. 20 mo) :
                    <input  type="file"
                            name={ formatId(this.props.id) }
                            id={ formatId(this.props.id) }
                            onChange={ this.handleChange }
                            accept={ this.props.fields[0].validation !== "" ? this.props.fields[0].validation : null } />
                </span>

                { (this.state.error) && <small className="error">{ this.state.error }</small> }
            </p>
        )
    }
}

File.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answer: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionId = (fetchOptionIds(props.options) || [])[0]

    return {
        financialYear: state.form.base.financialYear,
        requestId: state.form.base.requestId,
        answer: state.form.answers[optionId] || {}
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(File);
