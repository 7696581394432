import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { history, title } from '../../utils';

import { Input, notification, Select, Checkbox } from 'antd';
import 'antd/es/switch/style/index.css';

import Moment from 'react-moment';
import 'moment/locale/fr';

import { validateProfileFields, formatBackSpaces } from '../../utils';
import { Can } from '../../constants';
import { BackButton } from '../../components';

import { noteService, organisationService } from '../../services';

import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import Icon from '@material-ui/core/Icon';


var isChecked = (val) => (val === true || val === 'true' || val == '1' || val === 't')
var linkToFile = (url = {}, html = "Consulter le fichier courant") => {
    if (!('url' in url)) return;
    url = url.url;
    return (url ? <a href={ url } target="_blank">{ html }</a> : null);
}

class NotePage extends Component {
    constructor(props) {
        super(props);
        const { id_note } = this.props.match.params

        this.state = {
            note: {
                __type: "Note",
                id: id_note
            },
            errors: {},
            edited: false,
            types: [],
            orgs: [],
            textBeforeFormat: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    componentDidMount() {
        const { edit, create } = this.props;

        if (!create) {
            noteService.get(this.state.note.id).then(result => {
                this.setState({ textBeforeFormat: result.text});
                this.setState({ note: { ...this.state.note, ...result } });
            }).catch((e) => {
                history.push('/notes');
            });
        } else {
            const { id_org } = this.props.match.params;

            organisationService.list().then(result => {
                var state = { ...this.state, orgs: result }
                if (id_org && result.find(o => o.id == id_org))
                    state = { ...state, note: { ...this.state.note, organization_id: parseInt(id_org) }}
                this.setState(state);
            }).catch((e) => {
                this.setState(() => { throw new Error(e); });
            });
        }

        if (edit || create) {
            noteService.listTypes().then(result => {
                this.setState({ types: result });
            }).catch((e) => {
                this.setState(() => { throw new Error(e); });
            });
        }

        window.onbeforeunload = function(event) {
            if (this.state.edited)
                return 'Refresh';
        }.bind(this)
    }

    formatTag(key, fixed = false, label = "") {
        const { note, errors } = this.state;
        const { edit, create } = this.props;

        if ((!edit && !create ) || (fixed && edit)) {
            if (key === 'text' && note[key])
                note[key] = formatBackSpaces(note[key])

            switch (key) {
                case 'type_id':
                    return ( note.note_type ? note.note_type.label : '' )
                case 'user_id':
                    return <b>{ note.user ? (note.user.surname + ' ' + note.user.name) : '' }</b>
                case 'organization_id':
                    return <b>{ note.organization ? note.organization.name : '' }</b>
                case 'date':
                    return (note[key]) ? <b><Moment format="DD-MM-YYYY" locale="fr">{ note[key] }</Moment></b> : <b>-</b>
                case 'confidential':
                case 'status':
                    return ( isChecked(note[key]) ? label : '' )
                case 'url':
                    var file = linkToFile(note[key]);
                    return (file ? <b>{ file }</b> : <i>Aucun fichier</i>)
                default:
                    return <b>{ note[key] }</b>
            }
        }

        var input;
        if (key === 'type_id' || key === 'organization_id') {
            var list = [], name = '';
            if (key === 'type_id') {
                list = this.state.types;
                name = 'label';
            }
            if (key === 'organization_id') {
                list = this.state.orgs;
                name = 'name';
            }

            var options = list.map((t) =>
                <Select.Option
                    key={ t.id }
                    value={ t.id }>
                    { t[name] }
                </Select.Option>
            );

            input = (
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={ note[key] }
                    onChange={ (value) => this.handleChange(value, key) }>
                    { options }
                </Select>
            )
        } else if (key === 'status' || key === 'confidential') {
            input = (
                <Checkbox
                    checked={ isChecked(note[key]) }
                    onChange={ (e) => this.handleChange(e.target.checked, key) }>
                    { label }
                </Checkbox>
            )
        } else if (key === 'url') {
            var file = linkToFile(note[key]);

            input = (
                <Fragment>
                    { file ? <span><b>{ file }</b></span> : null }
                    <span>
                        Importer{ (file) && ' à nouveau'} (max. 20 mo) :
                        <input type="file"
                            onChange={ (e) => this.handleChange(e.target.files[0], key) }
                            defaultValue={ note[key] } />
                    </span>
                </Fragment>
            )
        } else {
            var Tag = (key === 'text') ? Input.TextArea : Input;
            input = (
                <Tag
                    name={ key } 
                    value={ note[key] }
                    autoSize={{ minRows: 3, maxRows: 20 }}
                    onChange={ (e) => this.handleChange(e.target.value, key) }  />
            )
        }

        var error = (errors && errors[key]);
        return <span className={ error ? 'error-wrap' : '' }>{ input }</span>;
    }

    handleChange(value, key) {
        const { edit } = this.props;
        const { edited } = this.state;

        var extra = (edit && !edited) ? { edited: true } : {}
        this.setState({ note: { ...this.state.note, [key]: value }, ...extra })
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.validateFields()) return;

        var { note } = this.state;
        const { edit, create } = this.props;

        if (!note.status) note.status = 0
        if (!note.confidential) note.confidential = 0

        if (edit) {
            noteService.edit(note).then(() => {
                notification.open({
                    message: 'Enregistrement effectué',
                    description: 'Vos changements ont été enregistrés avec succès.',
                    placement: 'bottomRight',
                });

                this.setState({ edited: false });

                history.replace(`/notes/${note.id}`)
            }).catch((e) => {
                notification.open({
                    message: 'Une erreur s\'est produite',
                    description: 'Veuillez réessayer ou contacter un administrateur.',
                    placement: 'bottomRight',
                });
            })
        } else if (create) {
            note.date = new Date();
            note.user_id = this.props.user.id
            noteService.create(note).then((n) => {
                notification.open({
                    message: 'Enregistrement effectué',
                    description: 'Vos changements ont été enregistrés avec succès.',
                    placement: 'bottomRight',
                });

                history.replace(`/notes/${n.id}`)
            }).catch((e) => {
                notification.open({
                    message: 'Une erreur s\'est produite',
                    description: 'Veuillez réessayer ou contacter un administrateur.',
                    placement: 'bottomRight',
                });
            })
        }
    }

    validateFields() {
        const { note } = this.state;
        var validation = validateProfileFields(note, ['organization_id', 'type_id', 'text'])

        this.setState({ errors: validation.errors })
        return !validation.hasError;
    }


    handleNoteSoftDelete(note) {
        note.text = this.state.textBeforeFormat;
        note.ending_date = note.ending_date !== null ? null : new Date();
        noteService.edit(note).then(() => {
            note.ending_date === null ? window.location.reload() : history.push(`/notes`);
        }).catch((e) => {
            throw new Error(e);
        })
    }

    render() {
        const { note } = this.state;
        const { edit, create } = this.props;

        var pageTitle = "Consultation d'une note",
            mode = "read";

        if (edit) {
            pageTitle = "Édition d'une note";
            mode = "edit";
        } else if (create) {
            pageTitle = "Création d'une note";
            mode = "create";
        }

        return (
            <Fragment>
                <Helmet>
                    { title(pageTitle) }
                </Helmet>

                <div className="activity-color profile-styles">
                    <BackButton link={ (edit) ? () => history.replace(`/notes/${note.id}`) : null } />
                    <h1>{ pageTitle }</h1>

                    { (mode !== 'create') && (
                        <Fragment>
                            <Can do="read" on={ note }>
                                <article className="infos">
                                    <ul>
                                        <li>Organisme concerné : { this.formatTag('organization_id', true) }</li>
                                        <li>Créé par : { this.formatTag('user_id', true) }</li>
                                        <li>Date : { this.formatTag('date', true) }</li>
                                    </ul>
                                </article>
                            </Can>

                            { (mode === 'read') && (
                                <section className='note__section'>
                                    <Can do="edit" on={ note }>
                                        <article className="buttons">
                                            <a className="button full" onClick={ () => history.replace(`/notes/${note.id}/edit`) }>Éditer</a>
                                        </article>
                                    </Can>
                                    <Can do="delete" on={ note }>
                                        <article className="buttons">
                                            <button className="button" onClick={ () => this.handleNoteSoftDelete(note) }>{note.ending_date === null ? 'Supprimer' : 'Restaurer'}{note.ending_date === null && (<Icon>delete</Icon>)}{note.ending_date !== null && (<Icon>restore_from_trash</Icon>)}</button>
                                        </article>
                                    </Can>
                                </section>
                                
                            ) }
                        </Fragment>
                    ) }

                    <article className="box">
                        <Can do={ mode } on={ note }>
                            { (mode === 'read') && (
                                <ul className="tags">
                                    <li>{ this.formatTag('type_id') }</li>
                                    <li>{ this.formatTag('status', false, "Ouverte") }</li>
                                    <li>{ this.formatTag('confidential', false, "Note privée") }</li>
                                </ul>
                            ) }

                            <ul className={ "profile-main-content " + mode }>
                                { (mode === 'create') && (
                                    <li>
                                        <label>Organisme concerné :</label>
                                        { this.formatTag('organization_id', true) }
                                    </li>
                                ) }

                                <li className="note-content">
                                    <label>Commentaire :</label>
                                    { this.formatTag('text') }
                                </li>

                                { (mode !== 'read') && (
                                    <Fragment>
                                        <li>
                                            <label>Type de note :</label>
                                            { this.formatTag('type_id') }
                                        </li>
                                        <li className="checkboxes">
                                            { this.formatTag('status', false, "Note ouverte") }
                                            { this.formatTag('confidential', false, "Note privée") }
                                        </li>
                                    </Fragment>
                                ) }

                                <li className="note-file">
                                    <label>Fichier à joindre :</label>
                                    { this.formatTag('url') }
                                </li>
                            </ul>

                            <div className="buttons">
                                { (mode === 'edit') && <a className="button ghost" onClick={ (e) => { e.preventDefault(); history.replace(`/notes/${note.id}`) } }>Annuler</a> }
                                { (mode === 'create') && <a className="button ghost" onClick={ (e) => { e.preventDefault(); history.goBack(); } }>Annuler</a> }
                                { (mode === 'edit') && <a className={ "button" + (!this.state.edited ? ' disabled' : '') } onClick={ (e) => { if (this.state.edited) this.handleSubmit(e) }}>Enregistrer les changements</a>  }
                                { (mode === 'create') && <a className="button" onClick={ (e) => this.handleSubmit(e) }>Créer la note</a>  }
                            </div>
                        </Can>
                    </article>
                </div>

                <Prompt
                    when={ this.state.edited }
                    message="Attention, certaines de vos modifications n'ont pas été enregistrées. Voulez-vous vraiment quitter cette page ?"
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.infos
    };
};

export default connect(mapStateToProps, null)(NotePage);
