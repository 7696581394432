import React, { Component, Fragment } from 'react';
import {
    FlexibleXYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
} from 'react-vis';

import { findMinMax, sortFunction, label, color, renderCrosshair, renderXAxisLabel, renderYAxisLabel, renderTick } from '../../../utils';


// Composant graphique pour afficher un Histogram.
class Histogram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            crosshair: [],
            data: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes || !nextProps.data) return null

        return {
            data: (nextProps.data || []).map(d => ({ x: label(d.label), y: d.value, color: color(0, d.label, nextProps.colorPalette) })),
            refreshes: nextProps.refreshes
        }
    }

    render() {
        const { crosshair } = this.state;
        const { xAxisLabel, yAxisLabel } = this.props;

        // Logique pour les effets du hover.
        var data = [...this.state.data];
        (data || []).map(d => {
            if (crosshair.length > 0) {
                d.opacity = (d.x === crosshair[0].x) ? 1 : 0.1
            } else {
                d.opacity = 1
            }
        })

        // Gestion de l'axe des X en fonction des props.
        const plotProps = {
            xType: (this.props.xType) ? this.props.xType : 'ordinal',
            xDomain: (this.props.xDomain) ? this.props.xDomain : null,
        }

        return (
            <FlexibleXYPlot
                { ...plotProps }
                margin={{ left: 50 }}
                yDomain={ findMinMax(data, 'y', 5) }
                onMouseLeave={ () => this.setState({ crosshair: [] }) }>

                <HorizontalGridLines />
                <XAxis />
                <YAxis tickFormat={ renderTick } />

                <VerticalBarSeries
                    colorType="literal"
                    data={ data }
                    onNearestX={ (v) => this.setState({ crosshair: [v] }) } />

                { xAxisLabel && renderXAxisLabel(xAxisLabel) }
                { yAxisLabel && renderYAxisLabel(yAxisLabel) }

                { renderCrosshair(crosshair, this.props.crosshair && this.props.crosshair(crosshair)) }
            </FlexibleXYPlot>
        )
    }
}

export default Histogram;
