import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';

import { statsService } from '../../services';

import { defaultChartHeight, defaultChartHeightRaw, sortFunction, label } from '../../utils/charts';
import { Spin, Tooltip } from 'antd';
import '../../../node_modules/react-vis/dist/style.css';



// TODO : groupedhorizhisto
// TODO : setup autres props des graphs


// Gestionnaire de Chart pour la page dashboard.
// Traitement pour afficher le bon graph (en fonction de /src/constants/charts.js),
// fetch des données et refresh lorsque nécéssaire.
// Props.infos correspond au paramètres propres à ce Chart (type, titre, icone, etc.)
class Chart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            refreshes: 0
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    // Handle le changement des filtres.
    // On se base sur props.refreshes (qui provient de la page Dashboard)
    // pour savoir si on doit update le graphique. Si props.refreshes est différent
    // de state.refreshes, on sait que les filtres ont changés.
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes) return null

        return {
            loading: true
        };
    }

    // Fetch les datas si on est en chargement (donc en attente de data).
    componentDidUpdate(prevProps, prevState) {
        if (this.state.loading === true)
            this.fetchData()
    }

    // Fetch et sort les datas en fonction des filtres.
    fetchData(props = this.props) {
        const { filters, infos, refreshes } = props;

        statsService.get(infos.url, filters).then(data => {
            if (infos.formatData)
                data = infos.formatData(data)

            if (Array.isArray(data)) {
                data.map((d) => { d.label = label(d.label) })

                if (!infos.noSort)
                    data.sort((a, b) => sortFunction(a, b, 'label'));
            }

            this.setState({ data, loading: false, refreshes: refreshes })
        }).catch(error => {
            this.setState({ data: [], loading: false, refreshes: refreshes })
        })
    }

    // Le compte de refreshes est passé au graphique en soit, pour qu'il puisse se mettre à jour
    // sans avoir à faire de la comparaison d'objets.
    render() {
        const { infos, filters } = this.props;
        const { data, loading, refreshes } = this.state;

        // Lorsque mentionné dans les paramètres, certains graphiques
        // ne doivent pas s'afficher quand certains filtres sont sélectionnés.
        if (infos.excludedByFilters) {
            var hide = false;
            infos.excludedByFilters.forEach(ex => {
                if (ex in filters && filters[ex].length !== 0) {
                    hide = true;
                    return
                }
            })

            if (hide) return <></>
        }

        // Setup du nom de la classe (+ gestion de la width relative du graph).
        const className = ("graph-item " + (infos.classes ? `${ infos.classes } ` : '') + (infos.width ? `col-${ infos.width } ` : 'col-4'))

        // Affichage du "fallback" -- si aucune data pour ce Chart.
        if (!loading && (typeof data === 'undefined' || data.length === 0)) {
            return (
                <div className={ className + " unavailable-graph"}>
                    <h2>
                        { infos.icon && <Icon>{ infos.icon }</Icon> }
                        <span>{ infos.title }</span>
                    </h2>
                    <div className="error">
                        <Icon>error_outline</Icon>
                        <h3>Aucune donnée pour ce graphique</h3>
                        <p>Veuillez tenter de changer les filtres sélectionnés.</p>
                    </div>
                </div>
            )
        }

        // Loading du composant pour afficher le graphique approprié.
        const ChartType = infos.component;
        if (!ChartType) return <></>

        // Affichage du graphique.
        return (
            <div className={ className }>
                <Spin spinning={ loading }>
                    {/* Titre du chart */}
                    <h2>
                        { infos.icon && <Icon>{ infos.icon }</Icon> }
                        <span>{ infos.title }</span>
                        { infos.legend && (
                            <Tooltip title={ infos.legend } arrowPointAtCenter>
                                <Icon>info</Icon>
                            </Tooltip>
                        ) }
                    </h2>

                    {/* Content du chart */}
                    <div style={{ 'height': (infos.height ? infos.height : defaultChartHeight) }}>
                        <ChartType data={ data } refreshes={ refreshes } height={ infos.height ? infos.height : defaultChartHeightRaw } { ...infos.props } />
                    </div>
                </Spin>
            </div>
        )
    }
}

export default Chart;
