import { API_DEV } from '../constants';
import { header, handleAPIResult, serialize, serializeMaterialFilter } from '../utils';


export const statsService = {
    get,
    status,
};


function get(url, filters) {
    const getInfo = {
        method: 'GET',
        headers: header()
    }

    var qs = (url.indexOf('?') === -1 ? '?' : '&') + serialize(filters)

    return new Promise((resolve, reject) => {
        fetch(API_DEV + url + qs, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function status(filters) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    var qsRegions = serialize({ regions: filters.regions })
    return new Promise((resolve, reject) => {
        fetch(API_DEV + `/requests/status/${ filters.type }/${ filters.year }?${ qsRegions }`, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}
