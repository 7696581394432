import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, history, noteLookups, loadDefaultFilter } from '../../utils';
import { noteService } from "../../services";
import { columns } from '../../constants/tablesColumns';
import MaterialTable from 'material-table';
import { materialTableProps, ResetButton } from '../../utils';
import { Popconfirm } from 'antd';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';

class NoteListPage extends Component {
    constructor(props) {
        super(props);
        const tableKey = 'note_list';
        this.state = {
            _tableKey: tableKey,
            _title: "Centraide - Liste des notes",
            possibleColumns: (columns.getKeys('notes') || []).map(c => {
                var completeCol = { ...columns['notes']()[c], field: c.toLowerCase() };
                loadDefaultFilter(tableKey, completeCol);
                return { ...completeCol }
            })
        };

        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        noteLookups(this)
    }

    handlePagination(query, user) {
        return new Promise(function (resolve, reject) {
            noteService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection, user).then((result) => {
                result.data.map((el) => {
                    el.ending_date = el.ending_date === null ? false : true;
                    el.date = el.date ? new Date(el.date) : null;
                    return el;
                });

                resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e)
            })
        });
    }

    handleNoteSoftDelete(note) {
        note.ending_date = note.ending_date === true ? null : new Date();
        noteService.edit(note).then(() => {
            window.location.reload();
        }).catch((e) => {
            throw new Error(e);
        })
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    { title('Liste des notes') }
                </Helmet>

                <div className="organisations-color">
                    <h1>Liste des notes</h1>

                    <article className="box table-box">
                        <ResetButton tableKey={ this.state._tableKey } />
                        
                        <MaterialTable
                            title={ this.state._title }
                            columns={ this.state.possibleColumns }
                            data={ query => this.handlePagination(query, this.props.user) }
                            { ...materialTableProps(this.state._tableKey, this.state._title) }
                            onRowClick={ (event, rowData) => { history.push(`/notes/${rowData.id}`) } }
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Ajouter une note',
                                    iconProps: { className: 'main' },
                                    isFreeAction: true,
                                    onClick: (event, rowData) => history.push(`/notes/new`)
                                },
                                {
                                    icon: 'edit',
                                    tooltip: 'Éditer la note',
                                    onClick: (event, rowData) => history.push(`/notes/${rowData.id}/edit`)
                                },
                                rowData => ({
                                    icon: () => (
                                        <Popconfirm
                                            placement="topRight"
                                            title="Êtes-vous certain de vouloir supprimer la note?"
                                            onConfirm={() => this.handleNoteSoftDelete(rowData)}
                                            onCancel={null}
                                            okText="Oui"
                                            cancelText="Non">

                                            <Icon>delete</Icon>
                                        </Popconfirm>
                                    ),
                                    tooltip: 'Supprimer la note',
                                    onClick: (event, rowData) => null,
                                    hidden: this.props.user.user_type.rang === 1 && rowData.ending_date === false ? false : true
                                }),
                                rowData => ({
                                    icon: () => (
                                        <Popconfirm
                                            placement="topRight"
                                            title="Êtes-vous certain de vouloir sauver la note?"
                                            onConfirm={() => this.handleNoteSoftDelete(rowData)}
                                            onCancel={null}
                                            okText="Oui"
                                            cancelText="Non">

                                            <Icon>restore_from_trash</Icon>
                                        </Popconfirm>
                                    ),
                                    tooltip: 'Sauver la note',
                                    onClick: (event, rowData) => null,
                                    hidden: this.props.user.user_type.rang === 1 && rowData.ending_date === true ? false : true
                                }),
                              ]} />
                    </article>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: (state.user.infos || {}),
    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteListPage);
