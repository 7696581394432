import { API_DEV } from '../constants';
import { header, handleAPIResult, serialize, serializeMaterialFilter } from '../utils';
import {
    REQ_TYPE_FOA_RENOUVELLEMENT,
    REQ_TYPE_FOA_RECONDUCTION,
    REQ_TYPE_FIDC,
    REQ_TYPE_FMN,

    AN_STATE_PENDING,
    AN_STATE_COMPLETED,

    AN_TYPE_FIN,
    AN_TYPE_ADMIN_FIN,
    AN_TYPE_IMP_SOC,
    AN_TYPE_RECONDUCTION,
    AN_TYPE_FIN_RECONDUCTION,
    AN_TYPE_SUMMARY,
    AN_TYPE_FIN_FIDC,
    AN_TYPE_DEV_GESTION,
    AN_TYPE_AS,
    AN_TYPE_FMN
} from '../constants'


export const analyzeService = {
    list,
    listPaginate,
    get,
    listStates,
    listTypes,
    getSummary,
    create,
    update,
    getReporting,
};

function list(filters = {}) {
    const getInfo = {
        method: 'GET',
        headers: header()
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes?' + serialize(filters), getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function listPaginate(currentUser = '', page_number = 0, per_page = 0, filters = [], orderBy, orderDirection) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    let current_user = '';
    if(currentUser !== '' && currentUser.user_type.rang !== 1){
        current_user = '&user_id=' + currentUser.id;
    } 

    var pagination = '';
    if (page_number !== 0) {
        pagination = '&page_number=' + page_number + '&per_page=' + per_page;
    }

    var order = '';
    if (orderBy) {
        order = '&order_by=' + (orderBy.orderField ? orderBy.orderField : orderBy.field) + '&order_direction=' + orderDirection.toUpperCase();
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes?' + serializeMaterialFilter(filters) + current_user + pagination + order, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function get(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes/' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function listStates() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes/states', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function listTypes() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes/types', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getSummary(request_id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes?type_id=' + 3 + '&request_id=' + request_id, getInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function create(reqId, reqTypeId) {
    var analyzes = []
    switch (reqTypeId) {
        case REQ_TYPE_FOA_RENOUVELLEMENT:
            analyzes[0] = {
                type_id: AN_TYPE_FIN,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            analyzes[1] = {
                type_id: AN_TYPE_ADMIN_FIN,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            analyzes[2] = {
                type_id: AN_TYPE_IMP_SOC,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            analyzes[3] = {
                type_id: AN_TYPE_SUMMARY,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            break;

        case REQ_TYPE_FOA_RECONDUCTION:
            analyzes[0] = {
                type_id: AN_TYPE_FIN_RECONDUCTION,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            analyzes[1] = {
                type_id: AN_TYPE_RECONDUCTION,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            break;

        case REQ_TYPE_FIDC:
            analyzes[0] = {
                type_id: AN_TYPE_FIN_FIDC,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            analyzes[1] = {
                type_id: AN_TYPE_AS,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            analyzes[2] = {
                type_id: AN_TYPE_DEV_GESTION,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            break;

        case REQ_TYPE_FMN:
            analyzes[0] = {
                type_id: AN_TYPE_FMN,
                request_id: reqId,
                state_id: AN_STATE_PENDING
            }
            break;

        default:
            return;
    }


    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            analyzes: analyzes
        })
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function update(a) {
    const id = a.id;
    delete a.id;

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            analyze: a
        })
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/analyzes/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getReporting(type_id, year, regions) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    var qsRegions = serialize({ regions })
    return new Promise((resolve, reject) => {
        fetch(API_DEV + `/analyzes/reporting/${type_id}/${year}?${ qsRegions }`, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}
