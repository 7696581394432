import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";

import { InputManager } from './';

class FormSection extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        var s = this.props.section,
            questions = [];

        s.questions.sort(function(el1, el2) {
            return el1.order - el2.order;
        })

        s.questions.map((q, i) => {
            questions.push(<InputManager key={ q.id } question={ q } readonly={ this.props.readonly } />)
        })

        return(
            <section data-section-id={ s.id }>
                <h2>
                    <Icon>{ s.icon }</Icon>
                    <i>{ s.label }</i>
                </h2>
                <p className="section-descr">
                    { s.description }
                </p>
                { questions }
            </section>
        )
    }
}

FormSection.propTypes = {
    section: PropTypes.object,
    readonly: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default FormSection;
