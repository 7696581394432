import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds, formatId, displayError, numberFormatProps, formatBackSpaces } from '../../../utils'
import { Answer } from '../../../models'

import 'antd/es/input/style/index.css';
import { Input } from 'antd';

import { formActions } from "../../../actions";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';


class Text extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        var val = e.target.value;
        // VALIDATION this.state.error
        this.props.saveAnswer(Answer(fetchOptionIds(this.props.options)[0], val))
    }

    render() {
        if (this.props.readonly) {
            return (
                <p>
                    { formatLabel(this.props.label, null, null, null, this.props.question_number, this.financialYear) }
                    <span>{ formatBackSpaces(this.props.answer.value || "") }</span>
                </p>
            )
        }

        const { TextArea } = Input;

        return (
            <p>
                { formatLabel(this.props.label, this.props.id, this.props.required, this.props.help, this.props.question_number, this.props.financialYear) }
                {
                    (this.props.special !== 'textarea') ? (
                        (this.props.special !== 'number') ? (
                            <Input
                                type={ (!this.props.special) ? 'text' : this.props.special }
                                id={ formatId(this.props.id) }
                                name={ formatId(this.props.id) }
                                onChange={ (e) => this.handleChange(e) }
                                pattern={ this.props.fields[0].validation !== "" ? this.props.fields[0].validation : null }
                                title={ this.props.fields[0].validation_help }
                                placeholder={ this.props.fields[0].title }
                                value={ this.props.answer.value || "" } />
                        ) : (
                            <NumberFormat
                                id={ formatId(this.props.id) }
                                name={ formatId(this.props.id) }
                                onChange={ (e, value) => this.handleChange(e, value) }
                                placeholder={ this.props.fields[0].title }
                                value={ this.props.answer.value || "" }
                                { ...numberFormatProps() } />
                        )

                    ) : (
                        <Fragment>
                            <TextArea
                                id={ formatId(this.props.id) }
                                name={ formatId(this.props.id) }
                                onChange={ (e) => this.handleChange(e) }
                                pattern={ this.props.fields[0].validation !== "" ? this.props.fields[0].validation : null }
                                title={ this.props.fields[0].validation_help }
                                maxLength={ this.props.fields[0].length }
                                placeholder={ this.props.fields[0].title }
                                value={ this.props.answer.value || "" }
                                autoSize={{ minRows: 3, maxRows: 20 }} />
                            { (this.props.fields[0].length) &&
                                <small className="char-count">Caractères restants : { (this.props.answer.value || "").length } / { this.props.fields[0].length }</small>
                            }
                        </Fragment>
                    )
                }

                { (this.state.error) && <small className="error">{ displayError() }</small> }
            </p>
        )
    }
}

Text.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    question_number: PropTypes.string,
    help: PropTypes.string,
    answer: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionId = (fetchOptionIds(props.options) || [])[0]

    return {
        financialYear: state.form.base.financialYear,
        answer: state.form.answers[optionId] || {}
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Text);
