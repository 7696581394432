import React from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds,formatId, formatYear } from '../../../utils'

import { formActions } from "../../../actions";
import { connect } from 'react-redux';

import { history } from '../../../utils';
import { requestService } from '../../../services';
import { Link } from "react-router-dom";


class FinancialTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false, req: {} }
    }

    componentDidMount() {
        requestService.get(this.props.requestId).then((result) => {
            this.setState({ req: result})
        }).catch((e) => {
            this.setState(() => { throw new Error(e); })
        });
    }

    render() {
        return (
            <p className="financial-link">
                { formatYear(this.props.label, this.state.req.financial_year) }
                <Link to={ `/organisations/${this.state.req.organization_id}/financial/${this.state.req.financial_year ? this.state.req.financial_year : new Date().getFullYear()}` }>Aller à la page financière</Link>
            </p>
        )
    }
}

File.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answer: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionId = (fetchOptionIds(props.options) || [])[0]
    return {
        requestId: state.form.base.requestId,
        answer: state.form.answers[optionId] || {}
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialTable);
