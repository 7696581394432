import { LOGIN_USER, LOGIN_ERROR, LOGIN_DISABLED, LOGOUT_USER, EDIT_USER, PWD_ASK_RESET, PWD_ASK_RESET_ERROR,PWD_RESET, PWD_RESET_ERROR, PWD_RESET_LEAVE, PWD_RESET_VERIFY, PWD_RESET_VERIFY_ERROR, UPDATE_USER_PROFILE, UPDATE_USER_LOGO } from "../constants";

var user = JSON.parse(localStorage.getItem('user'));
const INITIAL_STATE = (user) ? {
        logged: true,
        loginError: false,
        disabledError: false,
        infos: user,
        reset: false,
        resetError: false,
        reset_infos: {},
        updateProfileError: false
    } : {
        logged: false,
        loginError: false,
        disabledError: false,
        infos: {},
        reset: false,
        resetError: false,
        reset_infos: {},
        updateProfileError: false
    };

function formReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN_USER:
            return {
                logged: true,
                loginError: false,
                disabledError: false,
                infos: action.payload,
                reset: false,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case LOGIN_ERROR:
            return {
                logged: false,
                loginError: true,
                disabledError: false,
                infos: {},
                reset: false,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case LOGIN_DISABLED:
            return {
                logged: false,
                loginError: true,
                disabledError: true,
                infos: {},
                reset: false,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            }

        case LOGOUT_USER:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: false,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case EDIT_USER:
            return {
                logged: true,
                loginError: false,
                disabledError: false,
                infos: action.payload,
                reset: false,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case PWD_ASK_RESET:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: true,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case PWD_ASK_RESET_ERROR:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: false,
                resetError: true,
                reset_infos: {},
                updateProfileError: false
            };

        case PWD_RESET_LEAVE:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: false,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case PWD_RESET_VERIFY:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: true,
                resetError: false,
                reset_infos: action.payload,
                updateProfileError: false
            };

        case PWD_RESET_VERIFY_ERROR:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: false,
                resetError: true,
                reset_infos: {},
                updateProfileError: false
            };

        case PWD_RESET:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: true,
                resetError: false,
                reset_infos: {},
                updateProfileError: false
            };

        case PWD_RESET_ERROR:
            return {
                logged: false,
                loginError: false,
                disabledError: false,
                infos: {},
                reset: false,
                resetError: true,
                reset_infos: {},
                updateProfileError: false
            };

        case UPDATE_USER_PROFILE:
            return {
                ...state,
                infos: action.payload,
            };

        case UPDATE_USER_LOGO:
            return {
                ...state,
                infos: { ...state.infos, logo: action.payload }
            };

        default:
            return state;
    }
};

export default formReducer;
