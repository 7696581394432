import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

import { requestService, organisationService, documentService } from '../../services';
// import { selectProps } from '../../utils';

import 'antd/es/slider/style/index.css';
import 'antd/es/tree-select/style/index.css';
import { Button, Radio, Select, Input, TreeSelect, Slider, Spin } from 'antd';

import { renderSelectYears } from '../../utils';


// Filtres unifiés pour la page de dashboard.
// Retourne un {} contenant les filtres sélectionnés au composant
// parent (par l'entremise de props.handleSubmit(___))
class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: { position_filter: 1, ...props.defaultFilters },
            selects: {},

            opened: false,
            loading: true,
            edited: false
        }

        this.handleToggleOpened = this.handleToggleOpened.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidMount() {
        // Fetch les selects.
        this.fetchSelects().then(selects => {
            this.setState({ loading: false, selects })
        })
    }

    // Fetch les selects de la page.
    fetchSelects() {
        const regionsQID = 8;
        const citiesQID = 115;
        const districtsQID = 4;
        const boroughsQID = 116;
        const territoriesQID = 85;

        return new Promise(async (res, rej) => {
            const types = await requestService.listTypes();
            const options = await documentService.getOptionsFromQuestions([{ id: regionsQID }, { id: citiesQID }, { id: districtsQID }, { id: boroughsQID }, { id: territoriesQID }])
            const actions = await organisationService.getActionFields();

            // Build a partir des options, en fonction du optionId.
            // Assure d'avoir des entrées uniques.
            var computeOptionsSelect = (QID) => {
                var select = options.filter(o => o.question_id === QID).map(o => ({ id: o.id, value: o.label, label: o.label }));
                return select.filter((o, i, self) => self.findIndex(o2 => o2.value === o.value) === i)
            }

            res({
                'RequestType': types,
                'Region': computeOptionsSelect(regionsQID),
                'City': computeOptionsSelect(citiesQID),
                'District': computeOptionsSelect(districtsQID),
                'Borough': computeOptionsSelect(boroughsQID),
                'Territory': computeOptionsSelect(territoriesQID),
                'ActionField': actions,
            })
        })
    }

    // Remplit les options des selects en fonction d'une clé donnée.
    fillSelect(key) {
        const { selects } = this.state;
        const options = (selects[key] || []).map(o => (
            <Select.Option key={ o.id } value={ o.value || o.id }>{ o.label || o.name }</Select.Option>
        ))

        return options;
    }

    // Handle le toggle des filtres.
    handleToggleOpened() {
        this.setState({ opened: !this.state.opened })
    }

    // Handle le change d'un filtre.
    handleChange(value, key) {
        var extra = (key === 'position_filter') ? { region : [], city: [], district: [], borough: [], territory: [] } : {}
        this.setState({ filters: { ...this.state.filters, [key]: value, ...extra }, edited: true })
    }

    // Handle le submit des filtres, et passe le tout au composant parent.
    handleSubmit() {
        var filters = { ...this.state.filters }
        this.props.handleSubmit(filters)
        this.setState({ edited: false, opened: false })
    }

    // Handle le clear de filtres, et notifie le composant parent.
    handleClear() {
        this.setState({ filters: { position_filter: 1 } }, () => {
            this.props.handleSubmit(this.state.filters)
            this.setState({ edited: false, opened: false })
        })
    }

    render() {
        const { selects, filters, edited, loading, opened } = this.state;

        // Compteur des filtrs actifs.
        var filtersCount = 0;
        for (var f in filters) {
            if (filters[f] && filters[f] !== "" && filters[f].length > 0) filtersCount++
        }

        var positionSelect = () => {
            var { position_filter } = filters;
            var key, select;

            switch (position_filter) {
                case 1: key = 'organization_region'; select = 'Region';
                    break;
                case 2: key = 'organization_city'; select = 'City';
                    break;
                case 3: key = 'organization_district'; select = 'District';
                    break;
                case 4: key = 'organization_borough'; select = 'Borough';
                    break;
                case 5: key = 'organization_territory'; select = 'Territory';
                    break;
                default: key = 'organization_region'; select = 'Region';
            }

            return (
                <Select mode="multiple"
                    value={ filters[key] }
                    onChange={ (values) => this.handleChange(values, key) }>
                    { this.fillSelect(select) }
                </Select>
            )
        }


        return (
            <div className="stats-filters">
                <Spin spinning={ loading }>
                    <h2 onClick={ this.handleToggleOpened }>
                        <b>{ filtersCount }</b>
                        Filtres
                        <Icon>{ opened ? "arrow_drop_up" : "arrow_drop_down" }</Icon>
                    </h2>

                    <div className={ "filters-wrap" + (opened ? " opened" : " closed") }>
                        <div className="left-block">
                            <div className="ctn">
                                <label>Type de demande :</label>
                                <Select mode="multiple"
                                    value={ filters.type_id }
                                    onChange={ (values) => this.handleChange(values, 'type_id') }>

                                    { this.fillSelect('RequestType') }
                                </Select>
                            </div>

                            <div className="ctn">
                                <label>Période (années) :</label>
                                {/* <Slider range min={ 2019 } max={ 2030 }
                                    defaultValue={ filters.financial_year_range || [2019, 2030] }
                                    onAfterChange={ (values) => this.handleChange(values, 'financial_year_range') } /> */}
                                    <Select mode="multiple" showSearch value={ filters.financial_year_range } name={ "year_selected" } onChange={ (val) => this.handleChange(val, 'financial_year_range') }>
                                        { renderSelectYears(0, 10) }
                                     </Select>
                            </div>

                            <div className="ctn">
                                <label>Champ d'action :</label>
                                <Select mode="multiple"
                                    value={ filters.organization_action_field_id }
                                    onChange={ (values) => this.handleChange(values, 'organization_action_field_id') }>

                                    { this.fillSelect('ActionField') }
                                </Select>
                            </div>
                        </div>

                        <div className="right-block">
                            <Radio.Group
                                name="position_filter"
                                defaultValue={ 1 }
                                value={ filters.position_filter }
                                onChange={ (e) => this.handleChange(e.target.value, 'position_filter') }>

                                <label>Emplacement :</label>
                                <Radio value={ 1 }>Région administrative</Radio>
                                <Radio value={ 2 }>MRC ou Ville</Radio>
                                <Radio value={ 3 }>Quartier, secteur ou municipalité</Radio>
                                <Radio value={ 4 }>Arrondissement</Radio>
                                <Radio value={ 5 }>Territoire d'intervention</Radio>
                            </Radio.Group>

                            <div className="ctn">
                                { positionSelect() }
                            </div>
                        </div>

                        <div className="filters-buttons">
                            <Button type="link" onClick={ this.handleClear }>Réinitialiser les filtres</Button>
                            <Button type="primary" onClick={ this.handleSubmit } disabled={ !edited }>Appliquer les filtres</Button>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default Filters;
