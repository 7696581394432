import React, { Component, Fragment } from 'react';
import { Link, NavLink } from "react-router-dom";
import Helmet from 'react-helmet';
import { title } from '../../utils';
import Icon from '@material-ui/core/Icon';

import { StatsPage, ReportingPage, StatusPage } from '../../pages';


class DashboardPage extends Component {
    render() {
        const { mode } = this.props;

        let Page;
        switch (mode) {
            case "stats":
                Page = StatsPage;
                break;
            case "reporting":
                Page = ReportingPage;
                break;
            case "status":
                Page = StatusPage;
                break;
            default:
                Page = StatsPage;
                break;
        }

        return (
            <div className="dashboard-wrap">
                <div className="dashboard-main-nav">
                    <NavLink exact to="/dashboard"><Icon>dashboard</Icon>Statistiques</NavLink>
                    <NavLink exact to="/dashboard/reporting"><Icon>list</Icon>Export Excel</NavLink>
                    <NavLink exact to="/dashboard/status"><Icon>trending_up</Icon>Statut des demandes</NavLink>
                </div>

                <Page />
            </div>
        )
    }
}

export default DashboardPage;
