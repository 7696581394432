import { API_DEV } from '../constants';
import { header, handleAPIResult } from '../utils';


export function log(e) {

    const user = JSON.parse(localStorage.getItem('user'));

    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            log: {
                date: new Date(),
                type_log: e.name || 'Error',
                message: e.message || null,
                stack: e.stack || null,
                user_id: user.id
            }
        })
    };

    fetch(API_DEV + '/logs', postInfo)
};
