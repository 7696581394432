import React from 'react';

export const FAQ_QA = [
    {
        Q: "Comment accéder à la plateforme de manière optimale ?",
        A: <p>
        La plateforme est optimisée pour fonctionner avec les navigateurs les plus récents pour des raisons de sécurité. Nous vous recommandons d’utiliser la dernière version de Chrome disponible gratuitement ici : <a href='https://www.google.com/intl/fr/chrome/' target='_blank'>https://www.google.com/intl/fr/chrome/</a>.<br/>
        Bien que la plateforme soit visible sur des tablettes, pour votre confort d’utilisation, nous vous recommandons d’utiliser un écran d’au moins 22 pouces pour compléter le formulaire.<br/>
        Pour bénéficier des pleines performances de la plateforme, veillez à utiliser un ordinateur de 5 ans et moins. Cela rendre votre expérience meilleure ! Pour rappel, la plateforme est disponible sur n’importe quel navigateur.
        </p>
    },
    {
        Q: "Comment me connecter à la plateforme Centraide ?",
        A: <p>
        Vous pouvez accéder à la plateforme sur n’importe quel ordinateur à l’adresse URL suivante : <a href='https://www.clicdemande.org' target='_blank'>https://www.clicdemande.org</a>
        <br/>
        Utilisez votre identifiant (courriel) qui vous a été communiqué par Centraide par courriel (au besoin, faites une recherche dans vos courriels reçus).<br/>
        Indiquez votre mot de passe.<br/>
        Si vous avez perdu votre mot de passe, vous pouvez le modifier avec la fonction mot de passe oubliée. Vous allez recevoir un courriel pour générer un nouveau mot de passe.
        </p>
    },
    {
        Q: "Je n’arrive pas à accéder à la plateforme - comment faire ?",
        A: <p>
        Si malgré les étapes listées ci-dessous vous n’arrivez pas à vous connecter, contactez-nous par téléphone ou courriel : Lamia Dine, adjointe de direction au 418 660‑2100 poste 247, <a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a>.
        </p>
    },
    {
        Q: "Je ne vois pas où je ne peux saisir ma demande - comment faire ?",
        A: <p>
        Une fois connectée à l’interface - si vous êtes éligible à soumettre une demande - votre demande va s’afficher sur votre page d’accueil. Il vous suffit de cliquer dessus pour ouvrir le formulaire et commencer la saisie.
        </p>
    },
    {
        Q: "J’ai perdu mon identifiant (courriel) pour accéder à la plateforme Centraide, comment faire ?",
        A: <p>
        Votre identifiant (courriel) vous a été communiqué par Centraide par courriel. Il s’agit du courriel - principal- de votre organisation. Au besoin, faites une recherche dans vos courriels reçus.<br/>
        Si malgré les étapes listées ci-dessous vous n’arrivez pas à vous connecter, contactez-nous par téléphone ou courriel : Lamia Dine, adjointe de direction au 418 660‑2100 poste 247, <a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a>.
        </p>
    },
    {
        Q: "J’ai perdu mon mot de passe pour accéder à la plateforme Centraide, comment faire ?",
        A: <p>
        Si vous avez perdu votre mot de passe, vous pouvez le modifier avec la fonction mot de passe oubliée. Vous allez recevoir un courriel pour générer un nouveau mot de passe.<br/>
        Si malgré les étapes listées ci-dessous vous n’arrivez pas à vous connecter, contactez-nous par téléphone ou courriel : Lamia Dine, adjointe de direction au 418 660‑2100 poste 247, <a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a>.
        </p>
    },
    {
        Q: "Quand je clique sur enregistrer, il y a une erreur qui s’affiche en bas à droite de la page - que faire ?",
        A: <p>
        Si une erreur apparaît lors de la sauvegarde, merci de nous contacter par téléphone ou courriel : Lamia Dine, adjointe de direction au 418 660‑2100 poste 247, <a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a>.<br/>
        <br/>
        <b>Important :</b> pour ne pas perdre les données saisies avant la sauvegarde, vous devez faire un copier-coller de vos réponses dans un document extérieur à la plateforme AVANT de rafraîchir la page.
        </p>
    },
    {
        Q: "Les informations sur mon organisme ne sont pas bonnes / pas à jour. Comment les modifier ?",
        A: <p>
        Une fois connecté, vous pouvez modifier les informations en cliquant en haut à droite sur le nom de votre organisme. Vous allez accéder à la page profil et cliquez sur « éditer ».
        </p>
    },
    {
        Q: "Je ne vois qu’une partie de certains tableaux dans le formulaire - comment faire ? ",
        A: <p>
        Les tableaux les plus grands ne sont peut-être pas tous visibles sur votre écran. Si c’est le cas utilisez le curseur de votre souris pour naviguer de gauche à droite pour voir l’ensemble des champs à compléter.
        </p>
    },
    {
        Q: "Je veux soumettre d’autres documents, mais je n’y arrive pas - comment faire ?",
        A: <p>
        Nous avons identifié les documents à nous fournir. Il n’est donc pas nécessaire d’en fournir d’autres. Si vous avez quand même besoin d’en joindre plus, nous vous invitons à les fusionner AVANT de les importer dans la plateforme.
        </p>
    },
    {
        Q: "Est-ce que je peux imprimer ma demande ?",
        A: <p>
        Oui, une fois votre demande complétée et soumise, vous pouvez la consulter en ligne et l’imprimer en utilisant le bouton prévu à gauche dans la navigation de la demande.
        </p>
    },
    {
        Q: "Les documents que je veux envoyer sont trop lourds - comment faire ?",
        A: <p>
        Vous pouvez déposer des documents jusqu’à 20mo.<br/>
        Si votre fichier est plus lourd, il faudra le réduire pour l’envoyer. Vous pouvez utiliser l’utilitaire en ligne gratuit suivant : <a href='https://www.ilovepdf.com/fr/compresser_pdf' target='_blank'>https://www.ilovepdf.com/fr/compresser_pdf</a>.
        </p>
    },
    {
        Q: "La plateforme est-elle sécurisée ?",
        A: <p>
        La plateforme est accessible uniquement en HTTPS et nous nous efforçons d'assurer une sécurité maximale à vos informations. Des mises à jour régulières de sécurité seront appliquées de manière transparente pour vous.<br/>
        Pensez à vous déconnecter (en haut à droite de l’interface) si vous utilisez un ordinateur public pour éviter qu’une personne ne puisse accéder à vos données.<br/>
        Si vous pensez qu’une personne pourrait avoir pris connaissance de votre mot de passe, assurez-vous de le modifier le plus rapidement possible en utilisant la fonction mot de passé oublié au moment de la connexion.
        </p>
    },
    {
        Q: "Puis-je supprimer mon compte ? ",
        A: <p>
        Si vous souhaitez supprimer votre compte et les données associées, merci de contacter Centraide : Lamia Dine, adjointe de direction au 418 660‑2100 poste 247, <a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a>.<br/>
        <br/>
        <b>Attention cette suppression sera définitive et irréversible.</b>
        </p>
    },
    {
        Q: "J’ai un problème particulier - qui contacter ? ",
        A: <p>
        Pour toute question relative à la plateforme, merci de contacter : Lamia Dine, adjointe de direction au 418 660‑2100 poste 247, <a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a>.
        </p>
    },
];
