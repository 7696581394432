import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, history } from '../../utils';

import logo from '../../assets/images/logo.png';
import logoWhite from '../../assets/images/logo-image-white.png';

import { userActions } from "../../actions";
import { connect } from 'react-redux';


class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.state = { username: "", pwd: "" };

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
    }

    handleInputChange(e) {
        this.setState({ [e.target.name] : e.target.value })
    }

    handleLogin(e) {
        e.preventDefault();
        this.props.loginUser(this.state.username, this.state.pwd)
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    { title('Connexion') }
                </Helmet>

                <div className="login-page">
                    <div className="left-block">
                        <form className="login-form">
                            <header>
                                <h1>Connexion</h1>
                            </header>

                            <div>
                                <p>
                                    <label htmlFor="username">Courriel :</label>
                                    <input type="text"
                                        name="username"
                                        id="username"
                                        value={ this.state.username }
                                        onChange={ this.handleInputChange } />
                                </p>

                                <p>
                                    <label htmlFor="pwd">Mot de passe :</label>
                                    <input type="password"
                                        name="pwd"
                                        id="pwd"
                                        value={ this.state.password }
                                        onChange={ this.handleInputChange } />
                                </p>

                                { (this.props.error) && <b className="error">{ this.props.disabledError ? 'Ce compte est présentement désactivé' : 'Courriel ou mot de passe invalide' }</b> }

                                <div className="buttons">
                                    <a className="button ghost slim" onClick={() => history.push('/password/ask') }>Mot de passe oublié ?</a>
                                    <button onClick={ this.handleLogin }>Se connecter</button>
                                </div>
                            </div>

                            <img src={ logo } alt="Centraide QCA Logo" />
                        </form>
                    </div>

                    <div className="right-block">
                        <img src={ logoWhite } alt="Centraide QCA Logo" />
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        error: state.user.loginError,
        disabledError: state.user.disabledError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginUser: (email, password) => dispatch(userActions.login(email, password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
