import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { formatLabel, fetchOptionIds, formatTableId, formatId, displayTextAnswer, parseArrayString, numberFormatProps, formatYear } from '../../../utils'
import { Answer } from '../../../models'

import 'antd/es/input/style/index.css';
import 'antd/es/select/style/index.css';
import { Input, Select as S } from 'antd';

import { formActions } from "../../../actions";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';


var isCustom = (o) => (o.custom === true || o.custom == 'true' || o.custom == '1');

class Table extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false, custom: 0 }

        this.handleColumnAddition = this.handleColumnAddition.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleColumnDelete = this.handleColumnDelete.bind(this)
    }

    handleChange(e) {
        var val = e.value, optionId = e.option, colI = e.col, lineI = e.line;
        var ans = { [optionId] : parseArrayString(this.props.answers[optionId]) }

        var c = this.props.options.find((o) => isCustom(o))
        if (c && c.id == optionId) {
            // Custom option --> une profondeur de plus, mais array tjrs set
            ans[optionId].value[lineI][colI] = val;
        } else {
            if (!ans || !ans[optionId] || !ans[optionId].value || ans[optionId].value.length < colI)
                ans = Answer(optionId, Array.apply(null, new Array(this.props.fields.length)))
            ans[optionId].value[colI] = val;
        }

        // VALIDATION this.state.error
        this.props.saveAnswer(ans);
    }

    handleColumnAddition() {
        var c = this.props.options.find((o) => isCustom(o))
        var a = this.props.answers[c.id];

        if (a) {
            var val = parseArrayString(a.value);
            if (!val || val.length === 0) val = [];
            else {
                var lastLine = val[val.length - 1],
                    allNull = true;

                lastLine.map(l => {
                    if (l && Array.isArray(l) && l.length > 0) {
                        allNull = false;
                    }
                    else if (l && l.trim() !== '') {
                        allNull = false;
                    }
                })

                if (allNull) return;
            }

            val.push(new Array(this.props.fields.length))
            this.props.saveAnswer(Answer(c.id, val));
        }
    }

    handleColumnDelete(e) {
        var optionId = e.option, lineI = e.line;
        var ans = { [optionId] : parseArrayString(this.props.answers[optionId]) }
        ans[optionId].value.splice(lineI, 1);
        this.props.saveAnswer(ans);
    }

    computeInput(field, optionId, colI, lineI, value, custom = false) {
        let tempField = {...field}
        if (this.props.readonly) return value

        // Vérifie si les questions ID sont dans les questions qu'on traite de manière custom dans le computeInput
        // Les ID ci-dessous sont pour le tableau des fonction au CA
        const isCustom = this.props.id === 53 || this.props.id === 230 || this.props.id === 339
        if(isCustom && tempField.title === 'Fonction au CA' && lineI === 0) value = ['Président(e)']

        if (tempField.type === "select" || tempField.type === "multiple-select") {
            value = parseArrayString(value);

            const multiple = (tempField.type === "multiple-select");
            var options = [];
            
            if(isCustom && tempField.title === 'Fonction au CA') {
                tempField.values = (tempField.values || []).filter((value)=> {
                    return lineI === 0 ? value === 'Président(e)' : value !== 'Président(e)' 
                })
            }
            else {
                (tempField.values || []).sort(function (el1, el2) {
                    if(el1 < el2) { return -1; }
                    if(el1 > el2) { return 1; }
                    return 0;
                });
            }

            

            (tempField.values || []).map((o, i) => options.push(
                <S.Option key={ i }
                        value={ o }
                        id={ formatId(this.props.id, i) }>
                    { o }
                </S.Option>
            ));

            return <S   name={ formatTableId(this.props.id, optionId, colI) }
                        onChange={ (val) => this.handleChange({ value: (Array.isArray(val)) ? val : [val], option: optionId, col: colI, line: lineI }) }
                        value={ value }
                        mode={ multiple ? 'multiple' : 'default' }
                        showSearch
                        allowClear 
                        dis={isCustom && tempField.title === 'Fonction au CA' && lineI === 0}
                    >
                        { options }
                    </S>
        }

        if (tempField.type === 'textarea') {
            const { TextArea } = Input;
            return <TextArea    type={ tempField.type }
                                name={ formatTableId(this.props.id, optionId, colI) }
                                onChange={ (e) => this.handleChange({ value: e.target.value, option: optionId, col: colI, line: lineI }) }
                                value={ value }
                                maxLength={ this.props.fields[0].length }
                                autoSize={{ minRows: 1, maxRows: 10 }} />
        }

        if (tempField.type !== 'number') {
            return <Input
                    type={ tempField.type }
                    name={ formatTableId(this.props.id, optionId, colI) }
                    onChange={ (e) => this.handleChange({ value: e.target.value, option: optionId, col: colI, line: lineI }) }
                    pattern={ tempField.validation !== "" ? tempField.validation : null }
                    title={ tempField.validation_help }
                    value={ value } 
                    readOnly={isCustom && tempField.title === 'Téléphone' && lineI !== 0}/>
         }
         else {
            return <NumberFormat
                        name={ formatTableId(this.props.id, optionId, colI) }
                        onChange={ (e) => this.handleChange({ value: e.target.value, option: optionId, col: colI, line: lineI }) }
                        value={ value }
                        { ...numberFormatProps() } />
        }
    }

    createTable() {
        var ths = [];

        this.props.fields.map((th,i) => {
            ths.push(<th key={ i }>{ formatYear(th.title, this.props.financialYear) }</th>)
        })

        var trs = []
        var options = this.props.options;
        var custom = false;
        options.forEach((tr) => {
            if (isCustom(tr)) {
                custom = true;
            }
        });

        options.map((tr, i) => {
            // Pas le plus optimal, mais obligé pcq on a besoin du option id
            var ans = this.props.answers[tr.id];
            ans.value = parseArrayString(ans.value);

            // Fonctionne avec des lines pour les customs options,
            // permet de loop pour chaque lignes ajoutées.
            // Si l'option n'est pas custom, line n'a qu'un enfant.
            var lines = [];
            if (isCustom(tr)) {
                if (ans.value) {
                    ans.value.map((a, ai) => lines.push(a))
                }
            } else {
                lines.push(ans.value)
            }

            lines.map((l, li) => {
                var tds = [];
                this.props.fields.map((f,j) => {
                    if(f.type !== 'title') {
                        var a = l;
                        var value = (a && a.length > j) ? a[j] : null;
                        tds.push(<td key={ j }>{ this.computeInput(f, tr.id, j, li, value, isCustom(tr)) }</td>)
                    }
                })

                trs.push(
                    <tr key={ i + '-' + li }>
                        {
                            !isCustom(tr) ?
                            <Fragment>
                                {(custom && !this.props.readonly) && <td></td>}
                                {
                                    tr.label !== null &&
                                    <td colSpan={ tr.title ? this.props.fields.length : 1 } className={ "line-title" + ((tr.title) ? " full" : "") }>
                                        { tr.label.length === 0 ? null : formatYear(tr.label, this.props.financialYear) }
                                    </td>
                                }
                            </Fragment>
                            :
                            <Fragment>
                                {(!this.props.readonly) && <td className="delete-btn" onClick={ () => this.handleColumnDelete({option:  tr.id, line: li }) }><Icon>delete</Icon></td>}
                                {
                                    tr.label !== null &&
                                    <td>
                                        {(!this.props.readonly) ? (
                                            <Input  type="text"
                                            name={ formatTableId(this.props.id, tr.id, 0) }
                                            onChange={ (e) => this.handleChange({ value: e.target.value, option: tr.id, col: 0, line: li }) }
                                            value={ (l && l.length > 0) ? l[0] : null }
                                            />
                                        ) : (
                                            (l && l.length > 0) ? l[0] : null
                                        )}
                                    </td>
                                }
                            </Fragment>
                        }
                        { !tr.title && tds }
                    </tr>
                )
            })
        })

        // Ajout du bouton "+" si custom
        if (custom && !this.props.readonly) {
            ths.unshift(<th key={ -1 }></th>)
            if(!this.props.limit || (this.props.limit && parseInt(this.props.limit) > trs.length)) {
                trs.push(<tr key={ -1 } className="plus-btn"><td colSpan={ ths.length } align="center" onClick={ this.handleColumnAddition }><Icon>add</Icon></td></tr>)
            }
        }

        return (
            <table>
                <thead>
                    <tr>
                        { ths }
                    </tr>
                </thead>

                <tbody>
                    { trs }
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <div className="ctn">
                { (this.props.readonly) ? (
                    formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear)
                ) : (
                    formatLabel(this.props.label, null, this.props.required, this.props.help, this.props.question_number, this.props.financialYear)
                ) }
                <div className="table-wrap">
                    { this.createTable() }
                </div>
            </div>
        )
    }
}

Table.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answers: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionIds = (fetchOptionIds(props.options) || []),
        answers = {};
    optionIds.map(oid => answers[oid] = state.form.answers[oid] || {})
    return {
        financialYear: state.form.base.financialYear,
        answers: answers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
