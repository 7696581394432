import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, history, organisationLookups, generateMaterialFilter, loadDefaultFilter } from '../../utils';
import { organisationService, organisationUserService } from "../../services";
import { columns } from '../../constants/tablesColumns';
import MaterialTable from 'material-table';
import { materialTableProps, ResetButton } from '../../utils';
import { Can } from '../../constants';

class OrganisationListPage extends Component {
    constructor(props) {
        super(props);
        const tableKey = 'organisation_list';
        this.state = {
            _tableKey: tableKey,
            _title: "Centraide - Liste des organismes",
            possibleColumns: (columns.getKeys('organizations') || []).map(c => {
                var completeCol = { ...columns['organizations']()[c], field: c.toLowerCase() };
                loadDefaultFilter(tableKey, completeCol);
                return { ...completeCol }
            }),
            association: [],
            org_user_filter: null,
            update_fitler: false,
        };

        this.tableRef = React.createRef();
        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        organisationUserService.get().then(result => {
            this.setState({association: result});
            this.refreshTable();
        }).catch((e) => {
            this.setState(() => { throw new Error(e); })
        });

        organisationLookups(this);
    }

    handlePagination(query) {
        var _ = this;

        query = JSON.parse(JSON.stringify(query));
        query.filters = query.filters.filter(el => {
            if(el.column.field === 'user_name') {
                el.column.field = "has_organization_users"
                if (el.value.length === 1) {
                    el.value = el.value[0];
                    return el;
                }
                else  {
                    return false;
                }
            }
            else {
                return true;
            }
        });

        return new Promise(function (resolve, reject) {
            organisationService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                result.data.map((el) => {
                    const user = _.state.association.filter(a => a.organization_id === el.id);
                    el.user_name = user.length > 0 ? user[0].user.name + ' ' + user[0].user.surname : '';
                    return el;
                });

                resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    refreshTable() {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    { title('Liste des organismes') }
                </Helmet>

                <div className="organisations-color">
                    <h1>Liste des organismes</h1>

                    <article className="box table-box">
                        <Can do={ "create" } on={ "Organisation" } passThrough>
                            {can_create =>
                                <Can do={ "edit" } on={ "Organisation" } passThrough>
                                    {can_edit =>
                                        <Fragment>
                                            <ResetButton tableKey={ this.state._tableKey } />

                                            <MaterialTable
                                            title={ this.state._title }
                                            columns={ this.state.possibleColumns }
                                            data={ query => this.handlePagination(query) }
                                            tableRef={this.tableRef}
                                            { ...materialTableProps(this.state._tableKey, this.state._title) }
                                            onRowClick={ (event, rowData) => { history.push(`/organisations/${rowData.id}`) } }
                                            actions={[
                                                ... (can_create) ? [{
                                                    icon: 'group_add',
                                                    tooltip: 'Ajouter un organisme',
                                                    iconProps: { className: 'main' },
                                                    isFreeAction: true,
                                                    onClick: (event) => history.push('/organisations/new')
                                                }] : [],
                                                ... (can_edit) ? [{
                                                    icon: 'edit',
                                                    tooltip: 'Éditer l\'organisme',
                                                    onClick: (event, rowData) => history.push('/organisations/' + rowData.id + '/edit')
                                                }] : [],
                                            ]} />
                                        </Fragment>
                                    }
                                </Can>
                            }
                        </Can>
                    </article>
                </div>
            </Fragment>
        )
    }
}

export default OrganisationListPage;
