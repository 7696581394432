import React, { Fragment } from 'react';
import { Header, Footer, ErrorBoundary } from './components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { ABILITIES, AbilityContext, routes } from './constants';
import { LoginPage, E404Page, ResetPage } from './pages';

import { connect } from 'react-redux';
import { history } from './utils';


class AppRouter extends React.Component {

    getAbilities() {
        return (this.props.rank) ? ABILITIES[this.props.rank] : {};
    }

    render() {
        const isIE = /*@cc_on!@*/false || !!document.documentMode;

        var r = (this.props.logged) ? (
            <Fragment>
                <AbilityContext.Provider value={ this.getAbilities() }>
                    <Header />

                    <div className="container">
                        <ErrorBoundary>
                            <Switch>
                                { routes.map((route, index) =>
                                    (route.allowed.indexOf(this.props.rank) != -1) &&
                                        <Route
                                            key={ index }
                                            path={ route.path }
                                            exact={ route.exact }
                                            component={ route.component } />
                                    )
                                }

                                <Redirect from="/login" to="/" />

                                <Route component={ E404Page } />
                            </Switch>
                        </ErrorBoundary>
                    </div>

                    <Footer />
                </AbilityContext.Provider>
            </Fragment>
        ) : (
            <Fragment>
                {/*<Redirect to="/login" />*/}
                <Switch>
                    <Route exact path='/login' component={ LoginPage } />
                    <Route exact path='/password/:type' component={ ResetPage } />
                    <Route component={ LoginPage } />
                </Switch>
            </Fragment>
        )

        return (
            <Fragment>
                { (!isIE) ? (
                    <Router history={ history }>
                        { r }
                    </Router>
                    ) : (
                        <div className="container" style={ { maxWidth: '600px', margin: '200px auto' }}>
                            <h1 style={ { marginBottom: '20px' } }>Attention</h1>
                            <p>Votre navigateur est trop désuet pour supporter cette application.</p>
                            <p>Veuillez utiliser un navigateur plus récent.</p>
                            <div style={ { display: 'flex', marginTop: '20px', justifyContent: 'flex-start' } }>
                                <a style={ { width: '120px', justifyContent: 'center', marginRight: '10px' } } className="button" href="https://www.google.com/intl/fr/chrome/">Chrome</a>
                                <a style={ { width: '120px', justifyContent: 'center' } } className="button" href="https://www.mozilla.org/fr/firefox/new/">Firefox</a>
                            </div>
                        </div>
                    )
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        logged: state.user.logged,
        rank: (state.user.logged) ? (state.user.infos.user_type || {}).rang : 0
    };
}

export default connect(mapStateToProps)(AppRouter);
