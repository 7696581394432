import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import { Can, ABILITIES, USER_RANK_ORG } from '../../constants';
import { history, title, validateProfileFields } from '../../utils';

import { userActions } from '../../actions';
import { connect } from 'react-redux';

import { userService, organisationService, organisationUserService } from '../../services';

import { BackButton } from '../../components';
import { Input, notification, Select, Checkbox } from 'antd';
import { Prompt } from 'react-router';


var isChecked = (val) => (val === true || val === 'true' || val == '1' || val === 't')
class UserPage extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params
        this.state = {
            id: this.props.user.id,
            id_profile: id,
            isUpToDate: true,
            user_info: {
                __type: "User"
            },
            user_types: [],
            orgs: [],
            associations: [],
            pwd_error: false,
            errors: {},
            mounted: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
        this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    componentDidMount() {
        if (!this.props.create && this.state.id_profile) {
            userService.get(this.state.id_profile).then(result => {
                var user_info = { ...this.state.user_info, ...result }
                var abilities = ABILITIES[this.props.rank];
                this.setState({ user_info: user_info });

                if ((this.props.edit && !abilities.can('edit', user_info)) || !abilities.can('read', user_info)) {
                    this.setState({ mounted: false })
                    history.replace('/')
                    return;
                }

                organisationUserService.getAssociationUser(this.state.id_profile).then((result) => {
                    if (!this.state.mounted) return;
                    if (result.length > 0) {
                        this.setState({
                            user_info: {
                                ...this.state.user_info,
                                associated_org_id: result[0].organization.id,
                                association_id: result[0].id
                            }
                        });
                        if (!(this.props.create || this.props.edit)) {
                            this.setState({
                                user_info: {
                                    ...this.state.user_info,
                                    associated_org_name: result[0].organization.name
                                }
                            })
                        }
                    }
                })
            }).catch((e) => {
                history.push('/users')
            });
        }
        else if (this.props.create) {
            this.setState({
                user_info: {
                    ...this.state.user_info,
                    type_id: 1
                }
            });
        }

        if (this.props.create || this.props.edit) {
            userService.getTypes().then(result => {
                if (!this.state.mounted) return;
                this.setState({
                    user_types: result
                });
            });

            organisationService.list().then(result => {
                if (!this.state.mounted) return;
                this.setState({
                    orgs: result
                });
            });

            organisationUserService.get().then(result => {
                if (!this.state.mounted) return;
                this.setState({
                    associations: result
                });
            });
        }

        window.onbeforeunload = function(event) {
            if (!this.state.isUpToDate)
                return 'Refresh';
        }.bind(this)
    }

    handleChange(e) {
        e.preventDefault();
        const { edit } = this.props;
        const { isUpToDate } = this.state;

        this.setState({
            user_info: {
                ...this.state.user_info,
                [e.target.name]: e.target.value
            },
            isUpToDate: (edit && isUpToDate) ? false : isUpToDate
        });
    }

    handleCheckboxChange(e) {
        e.preventDefault();

        this.setState({
            user_info: {
                ...this.state.user_info,
                [e.target.name]: e.target.checked
            },
            isUpToDate: false
        });
    }

    handleEditSubmit(e) {
        e.preventDefault();

        if (!this.validateFields()) return;

        const { id_profile, user_types, user_info } = this.state;
        var new_user = {
            ...user_info
        };

        if (!user_info.password || user_info.password === '') {
            delete new_user.password
        } else if (user_info.password !== user_info.password_bis) {
            this.setState({ pwd_error: true });
            return;
        }

        delete new_user.password_bis;

        const rang = (user_types.filter(el => new_user.type_id === el.id)[0] || {}).rang;
        if(rang !== USER_RANK_ORG) {
            new_user.associated_org_id = null;
        }

        this.props.editUser(id_profile, new_user).then(() => {
            notification.open({
                message: 'Enregistrement effectué',
                description: 'Vos changements ont été enregistrés avec succès.',
                placement: 'bottomRight',
            });
            this.setState({
                isUpToDate: true,
                pwd_error: false,
            });

            history.replace(`/users/${id_profile}`)
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
            this.setState({
                isUpToDate: false,
                pwd_error: false,
            });
        })
    }

    handleSelectChange = name => value => {
        const { edit } = this.props;
        const { isUpToDate } = this.state;

        this.setState({
            user_info: {
                ...this.state.user_info,
                [name]: value,
            },
            isUpToDate: (edit && isUpToDate) ? false : isUpToDate
        });
    }

    handleCreateSubmit(e) {
        e.preventDefault();

        if (!this.validateFields()) return;

        const { user_info } = this.state;

        if (user_info.password !== user_info.password_bis) {
            this.setState({ pwd_error: true });
            return;
        }

        var new_user = {
            ...user_info
        };
        delete new_user.password_bis;

        userService.create(new_user).then((u) => {
            notification.open({
                message: 'Utilisateur créé',
                description: 'L\'utilisateur a été créé avec succès.',
                placement: 'bottomRight',
            });

            history.replace(`/users/${u.id}`)
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        })
    }

    validateFields() {
        const { user_info } = this.state;
        const { create } = this.props;

        var fields = ['type_id', 'name', 'surname', 'email']
        if (create) fields.push('password')
        var validation = validateProfileFields(user_info, fields)

        this.setState({ errors: validation.errors })
        return !validation.hasError;
    }

    generateTypeOption() {
        var options = [];
        var { user_types } = this.state;

        (user_types || []).sort(function (el1, el2) {
            if(el1.label < el2.label) { return -1; }
            if(el1.label > el2.label) { return 1; }
            return 0;
        });

        user_types.forEach((el) => {
            options.push(
                <Select.Option
                    key={el.id}
                    value={el.id} >
                    {el.label}
                </Select.Option>
            )
        });

        return options;
    }

    generateOrgOption() {
        var options = [];

        this.state.orgs.forEach((el) => {
            var found = false;

            var assoc = this.state.associations.find(a => el.id === a.organization_id);
            if (!assoc || assoc.id === this.state.user_info.association_id) {
                options.push(
                    <Select.Option
                        key={el.id}
                        value={el.id} >
                        {el.name}
                    </Select.Option>
                );
            }
        });

        return options;
    }

    generateEditPage(pageTitle) {
        const { user_info, user_types, errors } = this.state;
        const rang = (user_types.filter(el => user_info.type_id === el.id)[0] || {}).rang;

        return (
            <Can do="edit" on={ user_info }>
                <BackButton link={ () => history.replace(`/users/${user_info.id}`) } />
                <h1>{ pageTitle }</h1>

                <article className="box">
                    <form onSubmit={this.handleEditSubmit}>
                        <ul className="profile-main-content">
                            <Can do="edit_type" on={ user_info } passThrough>
                                {can =>
                                    <li>
                                        <label>Type : </label>
                                        <Select
                                            showSearch
                                            value={user_info.type_id}
                                            onChange={this.handleSelectChange("type_id")}
                                            disabled={!can}
                                            className={ errors.type_id ? 'error-input' : '' } >
                                            {this.generateTypeOption()}
                                        </Select>
                                    </li>
                                }
                            </Can>
                            <li>
                                <label>Prénom : </label>
                                <Input
                                    name="name"
                                    value={user_info.name}
                                    className={ errors.name ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Nom : </label>
                                <Input
                                    name="surname"
                                    value={user_info.surname}
                                    className={ errors.surname ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Courriel : </label>
                                <Input
                                    name="email"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    title="Veuillez entrer une adresse courriel valide au format xxxx@xxx.xxx"
                                    value={user_info.email}
                                    className={ errors.email ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Nouveau mot de passe : </label>
                                <Input
                                    type="password"
                                    name="password"
                                    value={user_info.password}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Confirmation du mot de passe : </label>
                                <Input
                                    type="password"
                                    name="password_bis"
                                    value={user_info.password_bis}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>

                            <Can do="organisation" on={ user_info } passThrough>
                                    {can =>
                                        rang === USER_RANK_ORG &&
                                        <li>
                                            <label>Organisme : </label>
                                            <Select
                                                allowClear
                                                showSearch
                                                disabled={!can}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={user_info.associated_org_id}
                                                onChange={this.handleSelectChange("associated_org_id")}
                                                notFoundContent="Aucun organisme est libre d'assocation, ou correspond à vos critères." >
                                                {this.generateOrgOption()}
                                            </Select>
                                        </li>
                                    }
                            </Can>

                            <Can do="disable" on={ user_info }>
                                <li>
                                    <Checkbox
                                        name="disabled"
                                        checked={ isChecked(user_info.disabled) }
                                        onChange={ (e) => this.handleCheckboxChange(e) }>
                                        Utilisateur désactivé
                                    </Checkbox>
                                </li>
                            </Can>
                        </ul>

                        { (this.state.pwd_error) && <small className="error">Les mots de passe doivent être identiques</small> }

                        <div className="buttons">
                            <a className="button ghost slim" onClick={ () => history.replace(`/users/${user_info.id}`) }>Annuler</a>
                            <button disabled={this.state.isUpToDate}>Enregistrer les changements</button>
                        </div>
                    </form>
                </article>
            </Can>
        );
    }

    generateShowPage(pageTitle) {
        const { user_info } = this.state;

        return (
            <Fragment>
                <BackButton />
                <h1>{ pageTitle }</h1>

                <Can do="edit" on={ user_info }>
                    <article className="buttons">
                        <a className="button full" onClick={() => history.replace('/users/' + user_info.id + '/edit')}>Éditer</a>
                    </article>
                </Can>
                <Can do="read" on={ user_info }>
                    <article className="box">
                        <ul className="tags">
                            <li>{ user_info.disabled === true ? 'Utilisateur désactivé' : '' }</li>
                            <li>{ user_info.user_type ? user_info.user_type.label : '' }</li>
                            <li>{ user_info.associated_org_name }</li>
                        </ul>

                        <ul className="profile-main-content read">
                            <li><label>Prénom : </label><b>{user_info.name}</b></li>
                            <li><label>Nom : </label><b>{user_info.surname}</b></li>
                            <li><label>Courriel : </label><b>{user_info.email}</b></li>
                        </ul>
                    </article>
                </Can>
            </Fragment>
        );
    }

    generateCreatePage(pageTitle) {
        const { user_info, user_types, errors } = this.state;
        const rang = (user_types.filter(el => user_info.type_id === el.id)[0] || {}).rang;

        return (
            <Can do="create" on={ user_info }>
                <BackButton />
                <h1>{ pageTitle }</h1>

                <article className="box">
                    <form onSubmit={this.handleCreateSubmit}>
                        <ul className="profile-main-content">
                            <Can do="edit_type" on={ user_info } passThrough>
                                {can =>
                                    <li>
                                        <label>Type : </label>
                                        <Select
                                            value={user_info.type_id}
                                            onChange={this.handleSelectChange("type_id")}
                                            className={ errors.type_id ? 'error-input' : '' }
                                            disabled={!can}>
                                            {this.generateTypeOption()}
                                        </Select>
                                    </li>
                                }
                            </Can>
                            <li>
                                <label>Prénom : </label>
                                <Input
                                    name="name"
                                    value={user_info.name}
                                    className={ errors.name ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)}>
                                </Input>
                            </li>
                            <li>
                                <label>Nom : </label>
                                <Input
                                    name="surname"
                                    value={user_info.surname}
                                    className={ errors.surname ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)}>
                                </Input>
                            </li>
                            <li>
                                <label>Courriel : </label>
                                <Input
                                    name="email"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    title="Veuillez entrer une adresse courriel valide au format xxxx@xxx.xxx"
                                    value={user_info.email}
                                    className={ errors.email ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Mot de passe : </label>
                                <Input
                                    type="password"
                                    name="password"
                                    value={user_info.password}
                                    className={ errors.password ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)}>
                                </Input>
                            </li>
                            <li>
                                <label>Confirmation du mot de passe : </label>
                                <Input
                                    type="password"
                                    name="password_bis"
                                    value={user_info.password_bis}
                                    className={ errors.password ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)}>
                                </Input>
                            </li>
                            <Can do="organisation" on={ user_info } passThrough>
                                {can =>
                                    rang === USER_RANK_ORG &&
                                        <li>
                                            <label>Organisme : </label>
                                            <Select
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={user_info.associated_org_id}
                                                onChange={this.handleSelectChange("associated_org_id")}
                                                notFoundContent="Aucun organisme est libre d'assocation, ou correspond à vos critères." >
                                                {this.generateOrgOption()}
                                            </Select>
                                        </li>
                                }
                            </Can>

                            <Can do="disable" on={ user_info }>
                                <li>
                                    <Checkbox
                                        name="disabled"
                                        checked={ isChecked(user_info.disabled) }
                                        onChange={ (e) => this.handleCheckboxChange(e) }>
                                        Utilisateur désactivé
                                    </Checkbox>
                                </li>
                            </Can>

                        </ul>
                        { (this.state.pwd_error) && <small className="error">Les mots de passe doivent être identiques</small> }
                        <div className="buttons">
                            <a className="button ghost slim" onClick={ () => history.goBack() }>Annuler</a>
                            <button>Créer l'utilisateur</button>
                        </div>
                    </form>
                </article>
            </Can>
        );
    }

    generatePage(pageTitle = '') {
        if (this.props.edit) {
            return this.generateEditPage(pageTitle);
        }
        else if (this.props.create) {
            return this.generateCreatePage(pageTitle);
        }
        else {
            return this.generateShowPage(pageTitle);
        }
    }

    render() {
        const { edit, create } = this.props;

        var pageTitle = "Profil d'un utilisateur"
        if (edit) {
            pageTitle = "Édition d'un utilisateur"
        } else if (create) {
            pageTitle = "Création d'un utilisateur"
        }

        return (
            <Fragment>
                <Helmet>
                    { title(pageTitle) }
                </Helmet>

                <div className="users-color profile-styles">
                    { this.generatePage(pageTitle) }
                </div>

                <Prompt
                    when={ !this.state.isUpToDate }
                    message="Attention, certaines de vos modifications n'ont pas été enregistrées. Voulez-vous vraiment quitter cette page ?"
                />
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        error: state.user.updateProfileError,
        user: state.user.infos,
        rank: (((state.user.infos || {}).user_type || {}).rang || 0)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editUser: (id, new_user, other_profile) => dispatch(userActions.edit(id, new_user, other_profile))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
