import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';

import { Can, API_DEV, columns, REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ACCEPTED, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION } from '../../constants'
import { header, history, materialTableProps, requestHomeLookups, generateMaterialFilter, noteLookups, loadDefaultFilter, ResetButton } from '../../utils';

import { connect } from 'react-redux';

import { organisationService, requestService, noteService } from "../../services";

import { Table } from '../../components';
import DataTable from 'react-data-table-component';
import MaterialTable from 'material-table';


class HomeAdmin extends Component {

    constructor(props) {
        super(props);

        const tableKey_request = "request_home_admin";
        const tableKey_notesHome = "notesHome_home_admin";

        this.state = {
            _tableKey_request: tableKey_request,
            _title_request: "Centraide - Liste des demandes en cours",
            requests: {
                possibleColumns: (columns.getKeys('requests') || []).map(c => {
                    var completeCol = { ...columns['requests']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_request, completeCol);
                    return { ...completeCol }
                })
            },
            _tableKey_notesHome: tableKey_notesHome,
            _title_note: "Centraide - Liste des notes ouvertes",
            notes: {
                possibleColumns: (columns.getKeys('notesHome') || []).map(c => {
                    var completeCol = { ...columns['notesHome']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_notesHome, completeCol);
                    return { ...completeCol }
                })
            }
        };
    }

    componentDidMount() {
        requestHomeLookups(this, this.state.requests.possibleColumns);
        noteLookups(this, this.state.notes.possibleColumns);
    }

    handleRequestData(query) {
        query = JSON.parse(JSON.stringify(query));
        const defaultStateFilterValue = [REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ACCEPTED, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION];
        var index = -1;
        query.filters.forEach((el, i) => { if (el.column.field === "state_id") index = i; });

        if (index === -1) {
            query.filters.push(generateMaterialFilter('state_id', defaultStateFilterValue));
            index = query.filters.length - 1;
        }
        if (query.filters[index].value.length === 0) {
            query.filters[index].value = defaultStateFilterValue;
        }

        return new Promise(function (resolve, reject) {
            requestService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    handleNoteData(query, user) {
        query = JSON.parse(JSON.stringify(query));
        query.filters.push(generateMaterialFilter('status', [1]));

        return new Promise(function (resolve, reject) {
            noteService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection, user).then((result) => {
                result.data.map((el) => {
                    el.date = el.date ? new Date(el.date) : null;
                    return el;
                });

                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    render() {
        return (
            <Fragment>
                <section>
                    <article className="box table-box">
                        <h3 onClick={() => history.push("/requests")} className="title-link">Demande(s) en cours</h3>
                        <ResetButton tableKey={ this.state._tableKey_request } />
                        <MaterialTable
                            title={this.state._title_request}
                            columns={this.state.requests.possibleColumns}
                            data={query => this.handleRequestData(query, this.props.user)}
                            {...materialTableProps(this.state._tableKey_request, this.state._title_request, true)}
                            onRowClick={(event, rowData) => { history.push(`/requests/${rowData.id}`) }} />
                    </article>
                    <article className="box table-box">
                        <h3 onClick={() => history.push("/notes")} className="title-link">Note(s) ouverte(s)</h3>
                        <ResetButton tableKey={ this.state._tableKey_notesHome } />
                        <MaterialTable
                            title={this.state._title_note}
                            columns={this.state.notes.possibleColumns}
                            data={query => this.handleNoteData(query)}
                            {...materialTableProps(this.state._tableKey_notesHome, this.state._title_note, true)}
                            onRowClick={(event, rowData) => { history.push(`/notes/${rowData.id}`) }} />
                    </article>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: (state.user.infos || {}),
    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeAdmin);
