
// Form action types
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const STOCK_TMP_ANSWER = 'STOCK_TMP_ANSWER';
export const SAVE_FORM = 'SAVE_FORM';
export const FORM_BASE_LOADED = 'FORM_LOADED';
export const ANSWERS_LOADED = 'ANSWERS_LOADED';

// User action types
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_DISABLED = 'LOGIN_DISABLED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const EDIT_USER = 'EDIT_USER';
export const PWD_ASK_RESET = 'PWD_ASK_RESET';
export const PWD_ASK_RESET_ERROR = 'PWD_ASK_RESET_ERROR';
export const PWD_RESET = 'PWD_RESET';
export const PWD_RESET_ERROR = 'PWD_RESET_ERROR';
export const PWD_RESET_LEAVE = 'PWD_RESET_LEAVE';
export const PWD_RESET_VERIFY = 'PWD_RESET_VERIFY';
export const PWD_RESET_VERIFY_ERROR = 'PWD_RESET_VERIFY_ERROR';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_LOGO = 'UPDATE_USER_LOGO';

// Financial action types
export const SAVE_FINANCIAL_TABLE = 'SAVE_FINANCIAL_TABLE';
