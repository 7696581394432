import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import { title, parseArrayString, numberFormatProps, history } from '../../utils';
import { isUndefined } from "util";

import { financialService } from "../../services";
import { Can, ABILITIES } from "../../constants";

import 'antd/es/notification/style/index.css';
import { notification, Input, Popconfirm } from 'antd';

import { connect } from 'react-redux';

import { BackButton } from '../../components';
import { financialActions } from '../../actions';
import NumberFormat from 'react-number-format';
import 'antd/es/tooltip/style/index.css';
import { Select, Tooltip } from 'antd';
import { Prompt } from 'react-router';


var checkMath = function (calc, round = false) {
    return (!isNaN(calc) && isFinite(calc)) ? (round ? parseInt(calc) : parseFloat(calc.toFixed(2))) : 0
}


class OrganisationFinancialPage extends Component {
    constructor(props) {
        super(props);
        var { id, year } = this.props.match.params

        year = parseInt(year);
        isNaN(year) && history.goBack();

        this.state = { id: id, year: year, req: {}, ans: [], org: {}, isUpToDate: true }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        var { id, year } = this.props.match.params

        year = parseInt(year);

        this.load(id, year);

        window.onbeforeunload = function (event) {
            if (!this.state.isUpToDate)
                return 'Refresh';
        }.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        var { id, year } = nextProps.match.params;
        year = parseInt(year);

        this.load(id, year);
    }

    load(id, year) {
        isNaN(year) && history.goBack();
        var abilities = ABILITIES[this.props.rank];
        this.setState({ readOnly: !abilities.can('edit_financial_page', 'Request') })

        financialService.getTable(parseFloat(id), year).then((result) => {
            const organisation = result[0] ? result[0].organization : [];
            result.map(function (el) {
                Object.keys(el).filter((key) => key.includes('_details')).forEach((elem) => {
                    el[elem] = parseArrayString(el[elem]);
                });
                delete el.id;
                delete el.organization;
                delete el.financial_analyze_type
                return el;
            });
            this.setState({
                ans: result,
                org: organisation
            });
        }).catch((e) => {
            this.setState(() => { throw new Error(e); })
        });
    }

    handleChange(e) {
        var { ans, year } = this.state;
        ans = JSON.parse(JSON.stringify(ans));

        const key = e.target.id.split('_')[0].replace(new RegExp('-', 'g'), '_');
        const col = parseFloat(e.target.id.split('_')[1]);
        var row = e.target.id.split('_')[2];
        const value = e.target.value;

        if (ans.length === 0 || ans.length === 1) {
            for (let i = ans.length; i < 5; i++) {
                ans.push({
                    organization_id: parseFloat(this.state.id),
                    type_id: (i + 1),
                    year: year
                });
            }
        }

        if (!row) {
            const tmp_val = value.replace(new RegExp(' ', 'g'), '').replace(',', '.');
            ans[col][key] = isNaN(tmp_val) ? value : parseFloat(tmp_val);
            if( col > 0 && ans.length === 5 ) {
                ans[col + 2][key] = isNaN(tmp_val) ? value : parseFloat(tmp_val);
            }
        }
        else {
            row = parseFloat(row);
            if (col === 0) {
                ans.map((el) => {
                    if (!el[key]) {
                        el[key] = [];
                    }

                    el[key] = el[key].concat(new Array((row + 1) - el[key].length >= 0 ? (row + 1) - el[key].length : 0));

                    for (let i = 0; i < el[key].length; i++) {
                        if (!el[key][i]) {
                            el[key][i] = {
                                label: "",
                                value: ""
                            };
                        }
                    }

                    return el[key][row].label = value;
                })
            }
            else {
                if (!ans[col - 1][key]) {
                    ans[col - 1][key] = [];
                }

                ans[col - 1][key] = ans[col - 1][key].concat(new Array((row + 1) - ans[col - 1][key].length >= 0 ? (row + 1) - ans[col - 1][key].length : 0));

                for (let i = 0; i < ans[col - 1][key].length; i++) {
                    if (!ans[col - 1][key][i]) {
                        ans[col - 1][key][i] = {
                            label: "",
                            value: ""
                        };
                    }
                }

                ans[col - 1][key][row].value = value;

                if (col > 1 && ans.length === 5) {
                    if (!ans[col + 1][key]) {
                        ans[col + 1][key] = [];
                    }

                    ans[col + 1][key] = ans[col + 1][key].concat(new Array((row + 1) - ans[col + 1][key].length >= 0 ? (row + 1) - ans[col + 1][key].length : 0));

                    for (let i = 0; i < ans[col + 1][key].length; i++) {
                        if (!ans[col + 1][key][i]) {
                            ans[col + 1][key][i] = {
                                label: "",
                                value: ""
                            };
                        }
                    }

                    ans[col + 1][key][row].value = value;
                }
            }
        }

        this.calculAllTotal(ans);
    }

    calculAllTotal(ans) {
        ans.map(function (el, col) {

            // Calcul des subtotaux
            Object.keys(el).filter((key) => key.includes('_details')).forEach((elem) => {
                el[elem.replace('details', 'total')] = this.getSubTotal(elem, col, ans);
            });

            // Calcul des totaux
            el['income_gvt_total'] = this.getTotal(['income-centraide', 'income-gvt-provincial-details', 'income-gvt-federal-details', 'income-gvt-municipal-details'], col, ans);
            el['income_total'] = this.getTotal(['income-gvt-provincial-details', 'income-gvt-federal-details',
                'income-gvt-municipal-details', 'income-subvention-details', 'income-operation-details'], col, ans);
            el['outcome_total'] = this.getTotal(['outcome-salary-details', 'outcome-service-details', 'outcome-housing-details',
                'outcome-other-details', 'outcome-amortization-details'], col, ans);
            el['income_amortization_total'] = this.getTotal(['income-gvt-provincial-details', 'income-gvt-federal-details', 'income-gvt-municipal-details',
                'income-subvention-details', 'income-operation-details', 'income-amortization'], col, ans);
            el['surplus_total'] = this.getTotalSurplus(col, ans);

            return el;
        }, this);
        this.setState({
            ans: ans,
            isUpToDate: false
        });
    }

    handleSubmit() {
        this.props.saveTable(this.state.ans).then(() => {
            notification.open({
                message: 'Enregistrement effectué',
                description: 'Vos changements ont été enregistrés avec succès.',
                placement: 'bottomRight',
            });
            this.setState({ isUpToDate: true });
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
            this.setState({ isUpToDate: false });
        })
    }

    getSubTotal(key, col, ans = null) {
        var value = 0;

        ans = ans ? ans : this.state.ans;

        if (ans.length < col + 1) {
            return value;
        }

        (ans[col][key.replace(new RegExp('-', 'g'), '_')] || []).forEach((el) => {
            value += el ? (el.value !== '' ? parseFloat(el.value.replace(new RegExp(' ', 'g'), '').replace(',', '.')) : 0) : 0;
        });

        return checkMath(value);
    }

    getTotal(keys, col, ans = null) {
        var value = 0;

        ans = ans ? ans : this.state.ans;

        if (ans.length < col + 1) {
            return value;
        }

        keys.forEach((key) => {
            if (key.includes('-details')) {
                (ans[col][key.replace(new RegExp('-', 'g'), '_')] || []).forEach((el) => {
                    value += el ? (el.value !== '' ? parseFloat(el.value.replace(new RegExp(' ', 'g'), '').replace(',', '.')) : 0) : 0;
                });
            }
            else {
                value += ans[col][key.replace(new RegExp('-', 'g'), '_')] ? ans[col][key.replace(new RegExp('-', 'g'), '_')] : 0;
            }
        })

        return checkMath(value);
    }

    getTotalAmortization(col, ans = null) {
        return this.getTotal(['income-amortization'], col, ans) - this.getTotal(['outcome-amortization'], col, ans);
    }

    getTotalSurplus(col, ans = null) {
        const total_dep = this.getTotal(['outcome-salary-details', 'outcome-service-details', 'outcome-housing-details',
            'outcome-other-details', 'outcome-amortization'], col, ans);

        const total_rev = this.getTotal(['income-centraide', 'income-gvt-provincial-details', 'income-gvt-federal-details', 'income-gvt-municipal-details',
            'income-subvention-details', 'income-operation-details', 'income-amortization'], col, ans);

        return checkMath(total_rev - total_dep);
    }

    getValue(key, col, row = null) {
        key = key.replace(new RegExp('-', 'g'), '_');

        if (this.state.ans.length === 0) {
            return '';
        }

        if (row === null) {
            return (this.state.ans[col] && !isUndefined(this.state.ans[col][key])) ? this.state.ans[col][key] : '';
        }
        else {
            if (col === 0) {
                return (this.state.ans[col] && this.state.ans[col][key]) ? (this.state.ans[col][key][row] ? this.state.ans[col][key][row].label : '') : '';
            }
            else {
                return (this.state.ans[col - 1] && this.state.ans[col - 1][key]) ? (this.state.ans[col - 1][key][row] ? this.state.ans[col - 1][key][row].value : '') : '';
            }
        }
    }

    getOrgValue(key) {
        const { org } = this.state;
        if (!org) return;
        return org[key];
    }

    generateInputRow(rowNum, rowLabel, rowKey, countRow = -1) {
        const { readOnly } = this.state;
        const loop = countRow === -1 ? (Array.isArray(rowLabel) ? rowLabel.length : 1) : countRow;
        countRow = Array.isArray(rowLabel) ? 0 : countRow;
        var rows = [];
        for (let i = 0; i < loop; i++) {
            var rowsCol = [];
            const start = countRow !== -1 ? 1 : 0;

            for (let j = start; j < (start + 3); j++) {
                rowsCol.push(
                    <td key={j}>
                        <NumberFormat
                            displayType={readOnly ? "text" : null}
                            {...numberFormatProps()} decimalScale={2}
                            id={countRow !== -1 ? (rowKey + "_" + j + "_" + i) : (rowKey + "_" + j)}
                            onChange={(e) => this.handleChange(e)}
                            value={countRow !== -1 ? this.getValue(rowKey, j, i) : this.getValue(rowKey, j)} >
                        </NumberFormat>
                    </td>
                );
            }

            var col0 = null;
            if (!Array.isArray(rowLabel)) {
                if (!readOnly) {
                    col0 = rowLabel ? rowLabel : <input type="text" id={countRow !== -1 ? rowKey + "_0_" + i : rowKey + '_0'} onChange={(e) => this.handleChange(e)} value={this.getValue(rowKey, 0, i)} ></input>;
                }
                else {
                    col0 = rowLabel ? rowLabel : this.getValue(rowKey, 0, i);
                }
            }
            else {
                const label = rowLabel[i];
                if (label.haveInput) {
                    if (!readOnly) {
                        col0 = <Fragment>
                            {label.value}  (précisez) : <input type="text" id={countRow !== -1 ? rowKey + "_0_" + i : rowKey + '_0'} onChange={(e) => this.handleChange(e)} value={this.getValue(rowKey, 0, i)} ></input>
                        </Fragment>
                    }
                    else {
                        col0 = <Fragment>
                            {label.value} : {this.getValue(rowKey, 0, i)}
                        </Fragment>
                    }
                }
                else {
                    col0 = label.value;
                }
            }

            rows.push(
                <tr key={i} className="financial-element">
                    <td>{rowNum + i}.</td>
                    <td>{col0}</td>
                    {rowsCol}
                </tr>
            );
        }
        return rows;
    }

    generateTotalRow(rowNum, rowLabel, rowKey, keys, type = 'financial-subsection-total', specialType = null) {
        var cols = [];
        for (let i = 0; i < 3; i++) {
            var value = 0;

            switch (specialType) {
                case 'amortization':
                    value = this.getTotalAmortization(i);
                    break;
                case 'surplus':
                    value = this.getTotalSurplus(i);
                    break;
                default:
                    value = !Array.isArray(keys) ? this.getSubTotal(keys, i) : this.getTotal(keys, i);
            };

            cols.push(
                <td key={i}>
                    <NumberFormat
                        displayType={'text'}
                        {...numberFormatProps()}
                        decimalScale={2}
                        renderText={value => <input type='text'
                            id={rowKey + "_" + (i + 1)}
                            value={value}
                            disabled={true}>
                        </input>}
                        value={value} />
                </td>
            );
        }

        return (
            <tr className={type}>
                <td>{rowNum}.</td>
                <td>{rowLabel}</td>
                {cols}
            </tr>
        );
    }

    generateIncomeCentraide() {
        return this.generateInputRow(1, 'CENTRAIDE', 'income-centraide');
    }

    generateIncomeGvtProvincial() {
        var elements = this.generateInputRow(2, null, 'income-gvt-provincial-details', 5);
        var total = this.generateTotalRow(7, 'Sous-total (ligne 2 à 6)', 'income-gvt-provincial-total', 'income-gvt-provincial-details');

        return (
            <Fragment>
                <tr className="financial-subsection-name">
                    <td></td>
                    <td>Gouvernement provincial</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td></td>
                    <td></td> */}
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateIncomeGvtFederal() {
        var elements = this.generateInputRow(8, null, 'income-gvt-federal-details', 3);
        var total = this.generateTotalRow(11, 'Sous-total (ligne 8 à 10)', 'income-gvt-federal-total', 'income-gvt-federal-details');

        return (
            <Fragment>
                <tr className="financial-subsection-name">
                    <td></td>
                    <td>Gouvernement fédéral</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td></td>
                    <td></td> */}
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateIncomeGvtMunicipal() {
        var elements = this.generateInputRow(12, null, 'income-gvt-municipal-details', 1);
        var total = this.generateTotalRow(13, 'Sous-total (ligne 12)', 'income-gvt-municipal-total', 'income-gvt-municipal-details');

        return (
            <Fragment>
                <tr className="financial-subsection-name">
                    <td></td>
                    <td>Gouvernement municipal</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td></td>
                    <td></td> */}
                </tr>
                {elements}
                {/*total*/}
            </Fragment>
        )
    }

    generateGvtSubventions() {
        var total = this.generateTotalRow(13, 'Sous-total (lignes 2 à 12)', 'income-gvt-total',
            ['income-gvt-provincial-details', 'income-gvt-federal-details', 'income-gvt-municipal-details'], 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>SUBVENTIONS GOUVERNEMENTALES</td>
                </tr>
                {this.generateIncomeGvtProvincial()}
                {this.generateIncomeGvtFederal()}
                {this.generateIncomeGvtMunicipal()}
                {total}
            </Fragment>
        )
    }

    generateSubventions() {
        const labels = [
            { value: 'Fondations', haveInput: true },
            { value: 'Communautés religieuses', haveInput: true },
            { value: 'Clubs sociaux', haveInput: true },
            { value: 'Dons et legs', haveInput: true },
            { value: 'Autres', haveInput: true },
            { value: 'Autres', haveInput: true }
        ]
        var elements = this.generateInputRow(14, labels, 'income-subvention-details');
        var total = this.generateTotalRow(20, 'Sous-total (lignes 14 à 19)', 'income-subvention-total', 'income-subvention-details', 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>SUBVENTIONS ET DONS PRIVÉS</td>
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateOperations() {
        const labels = [
            { value: 'Cotisation annuelle des membres', haveInput: false },
            { value: 'Contribution aux activités', haveInput: false },
            { value: "Revenus d'hébergement", haveInput: false },
            { value: 'Location (salles,équipement,etc.)', haveInput: false },
            { value: "Activités d'autofinancement (revenus bruts)", haveInput: false },
            { value: "Vente de produits ou services", haveInput: false },
            { value: "Revenus de stages", haveInput: false },
            { value: "Dividendes et intérêts", haveInput: false },
            { value: 'Autres', haveInput: true }
        ]
        var elements = this.generateInputRow(21, labels, 'income-operation-details');
        var total = this.generateTotalRow(30, 'Sous-total (lignes 21 à 29)', 'income-operation-total', 'income-operation-details', 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>REVENUS D'OPÉRATION ET AUTOFINANCEMENT</td>
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateTotalIncome() {
        var total = this.generateTotalRow(31, 'TOTAL DES REVENUS (lignes 1, 12, 19, 30)', 'income-total',
            ['income-centraide', 'income-gvt-provincial-details', 'income-gvt-federal-details',
                'income-gvt-municipal-details', 'income-subvention-details', 'income-operation-details'], 'financial-total');
        return total;
    }

    generateSalary() {
        const labels = [
            { value: 'Salaires bruts (incluant les charges sociales) Personnel régulier', haveInput: false },
            { value: 'Salaires bruts (incluant les charges sociales) Autre personnel', haveInput: false }
        ]
        var elements = this.generateInputRow(32, labels, 'outcome-salary-details');
        var total = this.generateTotalRow(34, 'Sous-total (lignes 32 à 33)', 'outcome-salary-total', 'outcome-salary-details', 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>MASSE SALARIALE</td>
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateService() {
        const labels = [
            { value: 'Téléphone et télécommunications', haveInput: false },
            { value: 'Achat de nourriture', haveInput: false },
            { value: 'Frais reliés au matériel roulant', haveInput: false },
            { value: "Entretien/réparation d'équipement", haveInput: false },
            { value: "Matériel de bureau et d'activités", haveInput: false },
            { value: 'Location (salles, équipement, etc.)', haveInput: false },
            { value: 'Frais de publicité', haveInput: false },
            { value: 'Formation/perfectionnement', haveInput: false },
            { value: 'Frais de déplacement', haveInput: false },
            { value: 'Autres frais liés aux bénévoles', haveInput: false },
            { value: "Frais liés aux activités d'autofinancement", haveInput: false },
            { value: 'Autres', haveInput: true }
        ]
        var elements = this.generateInputRow(35, labels, 'outcome-service-details');
        var total = this.generateTotalRow(47, 'Sous-total (lignes 35 à 46)', 'outcome-service-total', 'outcome-service-details', 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>ACTIVITÉS/SERVICES</td>
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateHousing() {
        const labels = [
            { value: 'Loyer ou intérêt sur hypothèque', haveInput: false },
            { value: 'Taxes et permis', haveInput: false },
            { value: 'Assurances', haveInput: false },
            { value: "Électricité et chauffage", haveInput: false },
            { value: "Entretien/réparation des locaux/déneigement", haveInput: false },
            { value: 'Réparations majeures', haveInput: false },
            { value: 'Autres', haveInput: true }
        ]
        var elements = this.generateInputRow(48, labels, 'outcome-housing-details');
        var total = this.generateTotalRow(55, 'Sous-total (lignes 48 à 54)', 'outcome-housing-total', 'outcome-housing-details', 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>LOGEMENTS</td>
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateOther() {
        const labels = [
            { value: 'Frais de banque/intérêts sur prêt', haveInput: false },
            { value: 'Vérification comptable', haveInput: false },
            { value: 'Autres honoraires professionnels', haveInput: true },
            { value: 'Autres', haveInput: true }
        ]
        var elements = this.generateInputRow(56, labels, 'outcome-other-details');
        var total = this.generateTotalRow(60, 'Sous-total (lignes 56 à 59)', 'outcome-other-total', 'outcome-other-details', 'financial-section-total');

        return (
            <Fragment>
                <tr className="financial-section-name">
                    <td colspan={6}>AUTRES DÉPENSES</td>
                </tr>
                {elements}
                {total}
            </Fragment>
        )
    }

    generateAmortization() {
        var element_in = this.generateInputRow(61, "Amortissement revenus", 'income-amortization');
        var element_out = this.generateInputRow(62, "Amortissement dépenses", 'outcome-amortization');
        var total = this.generateTotalRow(63, 'Sous-total (lignes 61 à 62)', 'amortization-total', null, 'financial-section-total', 'amortization');

        return (
            <Fragment>
                {element_in}
                {element_out}
                {total}
            </Fragment>
        )
    }

    generateTotalOutcome() {
        var total = this.generateTotalRow(64, 'TOTAL DES DÉPENSES (lignes 34, 47, 55, 60, 62)', 'outcome-total',
            ['outcome-salary-details', 'outcome-service-details', 'outcome-housing-details',
                'outcome-other-details', 'outcome-amortization'], 'financial-total');
        return total;
    }

    generateTotalIncomeAmortization() {
        var total = this.generateTotalRow(65, 'TOTAL DES REVENUS + revenus amortissement', 'income-amortization-total',
            ['income-centraide', 'income-gvt-provincial-details', 'income-gvt-federal-details', 'income-gvt-municipal-details',
                'income-subvention-details', 'income-operation-details', 'income-amortization'], 'financial-total');
        return total;
    }

    generateSurplus() {
        var total = this.generateTotalRow(66, 'Excédent (ou insuffisance) des revenus sur les dépenses', 'surplus-total',
            null, 'financial-total', 'surplus');
        return total;
    }

    generateNonAffectedActif() {
        const { year } = this.state;
        const { readOnly } = this.state;
        return (
            <tr className="financial-total">
                <td colSpan={4}>67. Actifs nets non affectés (selon vos derniers états financiers) :</td>
                <td><NumberFormat displayType={readOnly ? "text" : null} {...numberFormatProps()} id={"non-affected-actif_0"} onChange={(e) => this.handleChange(e)} value={this.getValue('non-affected-actif', 0)}></NumberFormat></td>
            </tr>
        )
    }

    generateNonAffectedActifPercentage() {
        const { year } = this.state;
        const { readOnly } = this.state;
        return (
            <tr className="financial-total">
                <td colSpan={4}>68. Pourcentage des actifs nets non affectés sur les dépenses {year - 1} - {year} (pour le calcul voir question 38A du formulaire) :
                                (excluant de ces dépenses les montants d'amortissements)</td>
                <td><NumberFormat displayType={readOnly ? "text" : null} {...numberFormatProps()} id={"non-affected-actif-percentage_0"} onChange={(e) => this.handleChange(e)} value={this.getValue('non-affected-actif-percentage', 0)}></NumberFormat></td>
            </tr>
        )
    }

    generateBody() {
        return (
            <tbody>
                <tr>
                    <th colSpan={6}>REVENUS</th>
                </tr>
                {this.generateIncomeCentraide()}
                {this.generateGvtSubventions()}
                {this.generateSubventions()}
                {this.generateOperations()}
                {this.generateTotalIncome()}
                <tr>
                    <th colSpan={6}>DÉPENSES</th>
                </tr>
                {this.generateSalary()}
                {this.generateService()}
                {this.generateHousing()}
                {this.generateOther()}
                {this.generateAmortization()}
                {this.generateTotalOutcome()}
                <tr className="total-tilte">
                    <th colSpan={6}>TOTAUX</th>
                </tr>
                {this.generateTotalIncomeAmortization()}
                {this.generateSurplus()}
                {this.generateNonAffectedActif()}
                {this.generateNonAffectedActifPercentage()}
            </tbody>
        );
    }

    generateHead() {
        const { year } = this.state;

        return (
            <thead>
                <tr>
                    <th colSpan={2} rowSpan={3}></th>
                    <th colSpan={1} rowSpan={2}>États financiers</th>
                    {/* <th colSpan={1} rowSpan={2}>Budget projeté (année en cours)</th> */}
                    <th colSpan={1} rowSpan={2}>Budget année en cours</th>
                    <th colSpan={1} rowSpan={2}>Budget projeté</th>
                </tr>
                <tr>
                    {/* <th colSpan={1} rowSpan={1}>Réel {this.getValue('real-month', 2) || 6} mois</th>
                    <th colSpan={1} rowSpan={1}>Réel anticipé après 12 mois</th> */}
                </tr>
                <tr>
                    <th colSpan={1} rowSpan={1}>{year - 1}-{year}</th>
                    {/* <th colSpan={1} rowSpan={1}>{year}-{year + 1}</th> */}
                    {/* <th colSpan={1} rowSpan={1}>{year}-{year + 1}</th> */}
                    <th colSpan={1} rowSpan={1}>{year}-{year + 1}</th>
                    <th colSpan={1} rowSpan={1}>{year + 1}-{year + 2}</th>
                </tr>
            </thead>
        );
    }

    generateTable() {
        return (
            <table>
                {this.generateHead()}
                {this.generateBody()}
            </table>
        );
    }

    handleSelectChange = name => value => {
        this.handleChange({
            target: {
                id: name,
                value: value.toString()
            }
        })
    }

    generateRealMonthInput() {
        const { req } = this.state;

        var options = [];
        for (let i = 1; i <= 12; i++) {
            options.push(
                <Select.Option
                    key={i}
                    value={i} >
                    {i}
                </Select.Option>
            )
        }

        return (
            (!this.state.readOnly) &&
            <tr>
                <td>
                    Nombre réel de mois
                    <Tooltip title="Correspond au nombre réel de mois complété dans l'année financière en cours. Pour des précisions supplémentaire, voir le guide section Volet 4.">
                        <Icon>help</Icon>
                    </Tooltip>
                </td>
                <td colSpan={2} style={{ textAlign: "center" }}>
                    <Can do={"edit_financial_page_year"} on={"Request"} passThrough>
                        {can => (
                            <Fragment>
                                {(can) ? (
                                    <Select
                                        Select
                                        showSearch
                                        value={this.getValue('real-month', 2) || 6}
                                        onChange={this.handleSelectChange("real-month_2")} >
                                        {options}
                                    </Select>
                                ) : (this.getValue('real-month', 2) || 6)}
                            </Fragment>

                        )}
                    </Can>

                </td>
            </tr>
        )
    }

    generateNumOrg() {
        return (
            <tr>
                <td>No. de  dossier</td>
                <td colSpan={2}>{this.getOrgValue("organisation_number")}</td>
            </tr>
        )
    }

    generateNameOrg() {
        this.getOrgValue();
        return (
            <tr>
                <td>Nom de l'organisme</td>
                <td colSpan={2}>{this.getOrgValue("name")}</td>
            </tr>
        )
    }

    generateAccountigFirmName() {
        const { readOnly } = this.state;

        return (
            <tr>
                <td>Nom de la firme comptable</td>
                <td colSpan={2}>
                    {readOnly ? (
                        this.getValue('accounting-firm-name', 0)
                    ) : (
                            <input
                                id="accounting-firm-name_0"
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                                value={this.getValue("accounting-firm-name", 0)} >
                            </input>
                        )}
                </td>
            </tr>
        )
    }

    generateFinancialYear() {
        const { readOnly } = this.state;

        return (
            <Fragment>
                <tr className="dates">
                    <td rowSpan={2}>Année financière</td>
                    <td>Début</td>
                    <td>Fin</td>
                </tr>
                <tr>
                    <td className="not-first">
                        {readOnly ? (
                            this.getValue('financial-year-start', 0)
                        ) : (
                                <input
                                    id="financial-year-start_0"
                                    type="date"
                                    value={this.getValue('financial-year-start', 0)}
                                    onChange={(e) => this.handleChange(e)} >
                                </input>
                            )}
                    </td>
                    <td>
                        {readOnly ? (
                            this.getValue('financial-year-end', 0)
                        ) : (
                                <input
                                    id="financial-year-end_0"
                                    type="date"
                                    value={this.getValue('financial-year-end', 0)}
                                    onChange={(e) => this.handleChange(e)} >
                                </input>
                            )}
                    </td>
                </tr>
            </Fragment>
        )
    }

    generateFinancialResult() {
        const { readOnly, year } = this.state;

        return (
            <tr>
                <td>Résultat des états financiers de l'année {year - 1}-{year}</td>
                <td colSpan={2}>
                    {readOnly ? (
                        this.getValue('', 0)
                    ) : (
                            <NumberFormat
                                id="verif-surplus_0"
                                {...numberFormatProps()}
                                decimalScale={2}
                                onChange={(e) => this.handleChange(e)}
                                value={this.getValue("verif-surplus", 0)} >
                            </NumberFormat>
                        )}
                </td>
            </tr>
        )
    }

    generateHeader() {
        return (
            <table className="table-header">
                <tbody>
                    {this.generateNumOrg()}
                    {this.generateNameOrg()}
                    {this.generateAccountigFirmName()}
                    {this.generateFinancialYear()}
                    {/* {this.generateRealMonthInput()} */}
                    {this.generateFinancialResult()}
                </tbody>
            </table>
        );
    }

    render() {
        const { id, readOnly, year, isUpToDate, org } = this.state;

        return (
            <Fragment>
                <Helmet>
                    {title('Pages financières')}
                    <body data-financial="true" />
                </Helmet>

                <div className="requests-color">
                    <div className="container">
                        <BackButton />
                        <Can do={"read_financial_summary"} on={"Request"}>
                            <a className="top-link back-button" onClick={() => history.replace(`/organisations/${id}/financial/${year}/summary`)}>Consulter le sommaire financier</a>
                        </Can>

                        <h1>
                            Pages financières
                        </h1>

                        <Can do={"edit_financial_page"} on="Request">
                            {!readOnly &&
                                <Popconfirm
                                        title='Attention ! Le résultat de la ligne 66 de la colonne "États financiers" ne correspond pas au résultat de vos états financier. Votre demande ne pourra être acceptée et son analyse retardée. Veuillez-vous assurer de la concordance des résultats.'
                                        disabled={isUpToDate || (this.getValue("verif-surplus", 0) === this.getTotalSurplus(0))}
                                        onConfirm={this.handleSubmit}
                                        okText="D'accord"
                                        cancelText="Annuler">
                                    <a className={!isUpToDate ? "button" : "button disabled"} onClick={(!isUpToDate && this.getValue("verif-surplus", 0) === this.getTotalSurplus(0)) ? this.handleSubmit : null} >Enregistrer les changements</a>
                                </Popconfirm>
                            }
                        </Can>

                        <article className="infos">
                            <ul>
                                <li>Organisme : <b><Link to={`/organisations/${org.id}`}>{org.name}</Link></b></li>
                                <li>Année : <b>{year}</b></li>
                            </ul>
                        </article>
                    </div>

                    <Can do={readOnly ? "read_financial_page" : "edit_financial_page"} on="Request">
                        <div className={"form-box financial" + (readOnly ? ' read-only' : '')}>
                            {this.generateHeader()}
                            {this.generateTable()}
                        </div>
                    </Can>

                    <Can do={"edit_financial_page"} on="Request">
                        <div className="container">
                            {!readOnly &&
                                <Popconfirm
                                        title="Attention ! Ce résultat ne correspond pas au résultat de vos états financiers. Votre demande ne pourra être acceptée et son analyse retardée. Veuillez-vous assurer de la concordance des résultats."
                                        disabled={isUpToDate || (this.getValue("verif-surplus", 0) === this.getTotalSurplus(0))}
                                        onConfirm={this.handleSubmit}
                                        okText="D'accord"
                                        cancelText="Annuler">
                                    <a className={!isUpToDate ? "button" : "button disabled"} onClick={(!isUpToDate && this.getValue("verif-surplus", 0) === this.getTotalSurplus(0)) ? this.handleSubmit : null} >Enregistrer les changements</a>
                                </Popconfirm>
                            }
                            <a className="button alt" onClick={(e) => window.print()}>Imprimer les pages financières</a>
                        </div>
                    </Can>
                </div>

                <Prompt
                    when={!isUpToDate}
                    message="Attention, certaines de vos réponses n'ont pas été enregistrées. Voulez-vous vraiment quitter cette page ?"
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        rank: (((state.user.infos || {}).user_type || {}).rang || 0)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveTable: (answers) => dispatch(financialActions.saveTable(answers))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrganisationFinancialPage);
