import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, history, analyzeLookups, loadDefaultFilter } from '../../utils';
import { analyzeService } from "../../services";
import { columns, AN_TYPE_SUMMARY } from '../../constants';
import MaterialTable from 'material-table';
import { materialTableProps, findAnalystForAnalyse, ResetButton } from '../../utils';
import { connect } from 'react-redux';

class AnalyzeListPage extends Component {
    constructor(props) {
        super(props);
        const tableKey = 'analyze_list';
        this.state = {
            _tableKey: tableKey,
            _title: "Centraide - Liste des analyses",
            possibleColumns: (columns.getKeys('analyzes') || []).map(c => {
                var completeCol = { ...columns['analyzes']()[c], field: c.toLowerCase() };
                loadDefaultFilter(tableKey, completeCol);
                return { ...completeCol }
            })
        };

        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        analyzeLookups(this)
    }

    handlePagination(query, current_user) {
        return new Promise(function (resolve, reject) {
            var assigneeFilter = query.filters.find(q => q.column.field === "assignee_name")
            query.filters = query.filters.filter(f => f.column.field !== "assignee_name")

            analyzeService.listPaginate(current_user, query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                if (assigneeFilter) {
                    result.data = result.data.filter(d => {
                        const user = findAnalystForAnalyse(d);
                        if (!user) return false;
                        return (user.name + ' ' + user.surname).toLowerCase().indexOf(assigneeFilter.value.toLowerCase()) !== -1;
                    })
                }

                resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e)
            })
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    { title('Liste des analyses') }
                </Helmet>

                <div className="organisations-color">
                    <h1>Liste des analyses</h1>

                    <article className="box table-box">
                        <ResetButton tableKey={ this.state._tableKey } />
                        <MaterialTable
                            title={ this.state._title }
                            columns={ this.state.possibleColumns }
                            data={ query => this.handlePagination(query, this.props.user) }
                            { ...materialTableProps(this.state._tableKey, this.state._title) }
                            onRowClick={ (event, rowData) => {
                                if (rowData.type_id === AN_TYPE_SUMMARY)
                                    history.push(`/requests/${rowData.request_id}/summary`) 
                                else
                                    history.push(`/requests/${rowData.request_id}/analysis/${rowData.id}`)
                            } }
                            actions={[]} />
                    </article>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: (state.user.infos || {}),
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeListPage);
