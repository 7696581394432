import React, { Component, Fragment } from 'react';
import {
    FlexibleXYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineSeries,
} from 'react-vis';

import { color, findMinMax, renderCrosshair, renderXAxisLabel, renderYAxisLabel  } from '../../../utils';


// Composant graphique pour afficher un Line chart.
class Line extends Component {
    constructor(props) {
        super(props);

        this.state = {
            crosshair: [],
            data: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes || !nextProps.data) return null

        return {
            data: (nextProps.data || []).map(d => ({ x: d.label, y: d.value })),
            refreshes: nextProps.refreshes
        }
    }

    render() {
        const { crosshair, data } = this.state;
        const { xAxisLabel, yAxisLabel } = this.props;

        return (
            <FlexibleXYPlot
                xDomain={ [0, 50] }
                yDomain={ findMinMax(data, 'y') }
                onMouseLeave={ () => this.setState({ crosshair: [] }) }>

                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis />
                <LineSeries
                    color={ color(0) }
                    animation="noWobble"
                    data={ data }
                    onNearestX={ (v) => this.setState({ crosshair: [v] }) } />

                { xAxisLabel && renderXAxisLabel(xAxisLabel) }
                { yAxisLabel && renderYAxisLabel(yAxisLabel) }

                { renderCrosshair(crosshair, this.props.crosshair && this.props.crosshair(crosshair)) }
            </FlexibleXYPlot>
        )
    }
}

export default Line;
