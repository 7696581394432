import React from 'react';
import Icon from '@material-ui/core/Icon';
import { userService } from '../services';

import Moment from 'react-moment';
import 'moment/locale/fr';

import { findAnalystForAnalyse } from '../utils';

//import { categories, priorities, icons } from 'constants/mapVariables';

// COLONNES DES DIFFÉRENTS TABLEAUX :
// On liste toutes les colonnes possibles avec quelques options
// (si elles sont visibles par défaut, le format de ces colonnes,
// leur largeur, etc.) On indique aussi le type de filtrage sur ces
// colonnes. Si c'est un filtre multiple (dropdown), on spécifie les
// sources des options (ref = comment on les stock dans le tableau,
// url = url à fetch pour obtenir les datas, key = clé contenant la
// valeur voulue si l'API retour un tableau d'objets)
//
// On recoit aussi la fonction de traduction (pour les textes).

export const regionLookups = { 'Capitale-Nationale': 'Capitale-Nationale', "Chaudière-Appalaches": "Chaudière-Appalaches", "Bas-Saint-Laurent": "Bas-Saint-Laurent" }

// ORGANISMES
const organizations = () => {
    return {
        'ORGANISATION_NUMBER': {
            title: "Numéro d'organisme",
            type: "string",
            hidden: true
        },
        'ARC': {
            title: "Enregistrement ARC",
            type: "string",
            hidden: true
        },
        'NEQ': {
            title: "Enregistrement NEQ",
            type: "string",
            hidden: true
        },
        'PATENT_NAME': {
            title: "Nom ORGANISMES, selon lettres-patentes",
            type: "string",
            hidden: true
        },
        'NAME': {
            title: "Nom organismes, Lettres",
            type: "string",
            hidden: false
        },
        'USER_NAME': {
            title: "Utilisateur associé",
            orderField: "users.name",
            render: rowData => rowData.user_name,
            type: "string",
            sorting: false,
            lookup: {1: "Avec", 0: "Sans"},
            hidden: false
        },
        'ADDRESS': {
            title: "Adresse",
            type: "string",
            hidden: false
        },
        'CITY': {
            title: "Ville / Municipalité",
            type: "string",
            hidden: false
        },
        'POSTAL_CODE': {
            title: "Code Postal",
            type: "string",
            hidden: false
        },
        'REGION': {
            title: "Région",
            type: "string",
            hidden: true,
            lookup: regionLookups,
            render: rowData => rowData.region
        },
        'MRC': {
            title: "MRC",
            type: "string",
            hidden: true
        },
        'BOROUGH': {
            title: "Arrondissement",
            type: "string",
            hidden: true
        },
        'DISTRICT': {
            title: "Quartier",
            type: "string",
            hidden: true
        },
        'WEBSITE': {
            title: "Site internet",
            type: "string",
            hidden: true
        },
        'TEL_SERVICE': {
            title: "Téléphone service",
            type: "string",
            hidden: true
        },
        'TEL_ADMIN': {
            title: "Téléphone administration",
            type: "string",
            hidden: true
        },
        'EMAIL_GENERAL': {
            title: "Courriel général",
            type: "string",
            hidden: true
        },
        'TEL_RESPONSIBLE': {
            title: "Cellulaire responsable",
            type: "string",
            hidden: true
        },
        'MAIL_RESPONSIBLE': {
            title: "Courriel responsable",
            type: "string",
            hidden: true
        },
        'PRESIDENCY_APPELATION': {
            title: "Appellation présidence",
            type: "string",
            hidden: true
        },
        'PRESIDENCY_NAME': {
            title: "Prénom présidence",
            type: "string",
            hidden: true
        },
        'PRESIDENCY_SURNAME': {
            title: "Nom présidence",
            type: "string",
            hidden: true
        },
        'RESPONSIBLE_APPELATION': {
            title: "Appelation responsable",
            type: "string",
            hidden: true
        },
        'RESPONSIBLE_NAME': {
            title: "Prénom responsable",
            type: "string",
            hidden: true
        },
        'RESPONSIBLE_SURNAME': {
            title: "Nom responsable",
            type: "string",
            hidden: true
        },
        'ORGANIZATION_YEAR': {
            title: "Année d'association",
            type: "string",
            hidden: true
        },
        'INCORPORATION_YEAR': {
            title: "Année d'incorporation",
            type: "string",
            hidden: true
        },
        'YEARLY_FOLLOW': {
            title: "Suivi triennal",
            type: "string",
            hidden: true
        },
        'TERRITORY': {
            title: "Territoire desservi",
            type: "string",
            hidden: true
        },
        'MISSION': {
            title: "Mission",
            type: "string",
            hidden: true
        },
        'SERVICE': {
            title: "Services offerts",
            type: "string",
            hidden: true
        },
        'CUSTOMER': {
            title: "Clientèle",
            type: "string",
            hidden: true
        },
        'BUILDING_OWNER': {
            title: "Propriétaire de l'édifice qu'il occupe",
            type: "boolean",
            hidden: true
        },
        'OTHER_BUILDING_OWNER': {
            title: "Propriétaire d'un autre édifice",
            type: "boolean",
            hidden: true
        },
        'BUILDING_ADDRESS': {
            title: "Adresse de l'édifice",
            type: "string",
            hidden: true
        },
        'FOUNDATION_OWNER': {
            title: "Propriétaire de la Fondation",
            type: "boolean",
            hidden: true
        },
        'FOUNDATION_NAME': {
            title: "Nom de la Fondation",
            type: "string",
            hidden: true
        },
        'FIDUCIARY_ADDRESS': {
            title: "Adresse fiduciaire",
            type: "string",
            hidden: true
        },
        'FIDUCIARY_CITY': {
            title: "Ville fiduciaire",
            type: "string",
            hidden: true
        },
        'FIDUCIARY_PROVINCE': {
            title: "Province fiduciaire",
            type: "string",
            hidden: true
        },
        'FIDUCIARY_POSTALE_CODE': {
            title: "Code postal fiduciaire",
            type: "string",
            hidden: true
        },
        'SERVICE_POINT_1': {
            title: "Point de service #1",
            type: "string",
            hidden: true
        },
        'SERVICE_POINT_2': {
            title: "Point de service #2",
            type: "string",
            hidden: true
        },
        'SERVICE_POINT_3': {
            title: "Point de service #3",
            type: "string",
            hidden: true
        },
        'ACTION_FIELD_ID': {
            title: "Champ d'action",
            render: rowData => rowData.action_field ? rowData.action_field.label : '',
            orderField: "action_fields.label",
            type: "string",
            lookup: {},
            hidden: true
        },
        'SECTOR_ID': {
            title: "Secteur d'intervention",
            render: rowData => rowData.sector ? rowData.sector.label : '',
            orderField: "sectors.label",
            type: "string",
            lookup: {},
            hidden: true
        },
        'SUBSECTOR_ID': {
            title: "Sous-secteur",
            render: rowData => rowData.subsector ? rowData.subsector.label : '',
            orderField: "subsectors.label",
            type: "string",
            lookup: {},
            hidden: true
        },
    }
};

// UTILISATEURS
const users = () => {
    return {
        'TYPE_ID': {
            title: "Type",
            render: rowData => rowData.user_type ? rowData.user_type.label : '',
            orderField: "user_types.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'NAME': {
            title: "Prénom",
            type: "string",
            hidden: false
        },
        'SURNAME': {
            title: "Nom",
            type: "string",
            hidden: false
        },
        'EMAIL': {
            title: "Courriel",
            type: "string",
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            orderField: "organizations.name",
            render: rowData => rowData.organization_name,
            type: "string",
            hidden: false,
            cellStyle: { minWidth: '220px' },
        },
        'CREATED_AT': {
            title: "Créé le",
            type: "date",
            hidden: false,
            filtering: false,
            cellStyle: { minWidth: '130px' },
            render: rowData => (rowData.created_at) ? <Moment format="DD-MM-YYYY" locale="fr">{ rowData.created_at }</Moment> : ''
        },
        'UPDATED_AT': {
            title: "Modifié le",
            type: "date",
            hidden: false,
            filtering: false,
            cellStyle: { minWidth: '130px' },
            render: rowData => (rowData.updated_at) ? <Moment format="DD-MM-YYYY" locale="fr">{ rowData.updated_at }</Moment> : ''
        },
    }
};

// TODO : Lookups dynamiques...
// NOTES
const notes = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userTypeID = user.type_id;
    const archived = userTypeID !== 2 ? {} : {'ending_date': {
        title: 'Supprimée',
        field: "ending_date",
        type: 'boolean',
        defaultFilter: "unchecked",
        hidden: false
    }}
    return {
        ...archived,
        'TYPE_ID': {
            title: "Type de note",
            render: rowData => rowData.note_type ? rowData.note_type.label : '',
            orderField: "note_types.label",
            type: "string",
            lookup: {},
            hidden: false,
            cellStyle: { minWidth: '150px' },
        },
        'USER_NAME': {
            title: "Créé par",
            render: rowData => rowData.user ? (rowData.user.name + ' ' + rowData.user.surname) : '',
            orderField: "users.name",
            type: "string",
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            render: rowData => rowData.organization ? rowData.organization.name : '',
            orderField: "organizations.name",
            type: "string",
            hidden: false,
            cellStyle: { minWidth: '220px' },
        },
        'ORGANIZATION_REGION': {
            title: "Région",
            render: rowData => rowData.organization ? rowData.organization.region : '',
            orderField: "organizations.region",
            lookup: regionLookups,
            type: "string",
            hidden: false
        },
        'TEXT': {
            title: "Commentaire",
            type: "string",
            hidden: false,
            cellStyle: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: '200px',
                maxWidth: '500px',
            }
        },
        'STATUS': {
            title: "Statut",
            render: rowData => rowData.status == '1' ? 'Ouverte' : 'Fermée',
            type: "string",
            lookup: { 0: "Fermée", 1: "Ouverte" },
            hidden: false,
        },
        'CONFIDENTIAL': {
            title: "Confidentialité",
            render: rowData => rowData.confidential == '1' ? 'Privée' : 'Publique',
            type: "string",
            lookup: { 0: "Publique", 1: "Privée" },
            hidden: false,
        },
        'DATE': {
            title: "Date",
            type: "date",
            hidden: false,
            filtering: false,
            cellStyle: { minWidth: '130px' },
            render: rowData => (rowData.date) ? <Moment format="DD-MM-YYYY" locale="fr">{ rowData.date }</Moment> : ''
        },
    }
};

// NOTES_HOME
const notesHome = () => {
    return {
        'TYPE_ID': {
            title: "Type de note",
            render: rowData => rowData.note_type ? rowData.note_type.label : '',
            orderField: "note_types.label",
            type: "string",
            lookup: {},
            hidden: false,
            cellStyle: { minWidth: '150px' },
        },
        'USER_NAME': {
            title: "Créé par",
            render: rowData => rowData.user ? (rowData.user.name + ' ' + rowData.user.surname) : '',
            orderField: "users.name",
            type: "string",
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            render: rowData => rowData.organization ? rowData.organization.name : '',
            orderField: "organizations.name",
            type: "string",
            hidden: false,
            cellStyle: { minWidth: '220px' },
        },
        'TEXT': {
            title: "Commentaire",
            type: "string",
            hidden: false,
            cellStyle: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: '200px',
                maxWidth: '500px',
            }
        },
        'CONFIDENTIAL': {
            title: "Confidentialité",
            render: rowData => rowData.confidential == '1' ? 'Privée' : 'Publique',
            type: "string",
            lookup: { 0: "Publique", 1: "Privée" },
            hidden: false,
        },
        'DATE': {
            title: "Date",
            type: "date",
            hidden: false,
            filtering: false,
            cellStyle: { minWidth: '130px' },
            render: rowData => (rowData.date) ? <Moment format="DD-MM-YYYY" locale="fr">{ rowData.date }</Moment> : ''
        },
    }
};

// REQUESTS
const requests = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const userTypeID = user.type_id;
    const archived = userTypeID !== 2 ? {} : {'archived': {
        title: 'Archivée',
        field: "data",
        type: 'boolean',
        defaultFilter: "unchecked",
        hidden: false
    }}

    return {...archived,
        'STATE_ID': {
            title: "Statut",
            render: rowData => rowData.request_state ? rowData.request_state.label : '',
            orderField: "request_states.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'FINANCIAL_YEAR': {
            title: "Année",
            type: "string",
            hidden: false,
            cellStyle: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: '60px',
            }
        },
        'TYPE_ID': {
            title: "Type de demande",
            render: rowData => rowData.request_type ? (rowData.request_type.label) : '',
            orderField: "request_types.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            render: rowData => rowData.organization ? rowData.organization.name : '',
            orderField: "organizations.name",
            type: "string",
            hidden: false,
            cellStyle: { minWidth: '220px' },
        },
        'ORGANIZATION_REGION': {
            title: "Région",
            render: rowData => rowData.organization ? rowData.organization.region : '',
            orderField: "organizations.region",
            lookup: regionLookups,
            type: "string",
            hidden: false
        },
    }
};

// REQUESTS_HISTORIC
const requestsHistoric = () => {
    return {
        'TYPE_ID': {
            title: "Type de demande",
            render: rowData => rowData.request_type ? (rowData.request_type.label) : '',
            orderField: "request_types.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            render: rowData => rowData.organization ? rowData.organization.name : '',
            orderField: "organizations.name",
            type: "string",
            hidden: false
        },
        'ORGANIZATION_REGION': {
            title: "Région",
            render: rowData => rowData.organization ? rowData.organization.region : '',
            orderField: "organizations.region",
            lookup: regionLookups,
            type: "string",
            hidden: false
        },
        'DATE_CLOSE': {
            title: "Complétée le",
            type: "date",
            filtering: false,
            hidden: false,
            cellStyle: { minWidth: '130px' },
            render: rowData => (rowData.date_close) ? <Moment format="DD-MM-YYYY" locale="fr">{ rowData.date_close }</Moment> : ''
        },
    }
};

// REQUESTS_ANALYZE
const requestsAnalyze = () => {
    return {
        'FINANCIAL_YEAR': {
            title: "Année",
            type: "string",
            hidden: false,
            cellStyle: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: '60px',
            }
        },
        'TYPE_ID': {
            title: "Type de demande",
            render: rowData => rowData.request_type ? (rowData.request_type.label) : '',
            orderField: "request_types.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            render: rowData => rowData.organization ? rowData.organization.name : '',
            orderField: "organizations.name",
            type: "string",
            hidden: false
        },
        'ORGANIZATION_REGION': {
            title: "Région",
            render: rowData => rowData.organization ? rowData.organization.region : '',
            orderField: "organizations.region",
            lookup: regionLookups,
            type: "string",
            hidden: false
        },
    }
};

// ANALYZES
const analyzes = () => {
    return {
        'STATE_ID': {
            title: "Statut",
            render: rowData => rowData.analyze_state ? (rowData.analyze_state.label) : '',
            orderField: "analyze_states.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'TYPE_ID': {
            title: "Type d'analyse",
            render: rowData => rowData.analyze_type ? (rowData.analyze_type.label) : '',
            orderField: "analyze_types.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'REQUEST_FINANCIAL_YEAR': {
            title: "Année",
            render: rowData => rowData.request ? rowData.request.financial_year ? rowData.request.financial_year : '' : '',
            type: "string",
            hidden: false,
            cellStyle: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: '60px',
            }
        },
        'REQUEST_TYPE_ID': {
            title: "Type de demande",
            render: rowData => rowData.request ? rowData.request.request_type ? rowData.request.request_type.label : '' : '',
            orderField: "request_types.label",
            type: "string",
            lookup: {},
            hidden: false
        },
        'RESPONSIBLE_NAME': {
            title: "Responsable",
            render: rowData => rowData.request ? rowData.request.user ? (rowData.request.user.name + ' ' + rowData.request.user.surname) : '' : '',
            orderField: "users.name",
            type: "string",
            hidden: false
        },
        'ASSIGNEE_NAME': {
            title: "Assigné",
            render: rowData => {
                var user = findAnalystForAnalyse(rowData);
                return user ? (user.name + ' ' + user.surname) : ''
            },
            sorting: false,
            type: "string",
            hidden: false
        },
        'ORGANIZATION_NAME': {
            title: "Organisme",
            render: rowData => rowData.request ? rowData.request.organization ? rowData.request.organization.name : '' : '',
            orderField: "organizations.name",
            type: "string",
            hidden: false,
            cellStyle: { minWidth: '220px' },
        },
        'ORGANIZATION_REGION': {
            title: "Région",
            render: rowData => rowData.request ? rowData.request.organization ? rowData.request.organization.region : '' : '',
            orderField: "organizations.region",
            lookup: regionLookups,
            type: "string",
            hidden: false
        },
    }
};

// FONCTIONS UTILITAIRES :

// Retourne toutes les clés des colonnes d'un type.
// Le paramète "all" précise si on retourne aussi
// celles invisibles par défaut.
// Exemple : getKeys(trigger, true) --> ["PRIORITY", "ALARM_CATEGORY", "RCU_NAME", ...]
export function getKeys(type, all = true) {
    switch (type) {
        case "organizations":
            if (all) return Object.keys(organizations())

            var keys = []
            Object.keys(organizations()).map((key, index) => {
                var c = organizations()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "users":
            if (all) return Object.keys(users())

            var keys = []
            Object.keys(users()).map((key, index) => {
                var c = users()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "notes":
            if (all) return Object.keys(notes())

            var keys = []
            Object.keys(notes()).map((key, index) => {
                var c = notes()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "notesHome":
            if (all) return Object.keys(notesHome())

            var keys = []
            Object.keys(notesHome()).map((key, index) => {
                var c = notesHome()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "requests":
            if (all) return Object.keys(requests())

            var keys = []
            Object.keys(requests()).map((key, index) => {
                var c = requests()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "requestsHistoric":
            if (all) return Object.keys(requestsHistoric())

            var keys = []
            Object.keys(requestsHistoric()).map((key, index) => {
                var c = requestsHistoric()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "requestsAnalyze":
            if (all) return Object.keys(requestsAnalyze())

            var keys = []
            Object.keys(requestsAnalyze()).map((key, index) => {
                var c = requestsAnalyze()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        case "analyzes":
            if (all) return Object.keys(analyzes())

            var keys = []
            Object.keys(analyzes()).map((key, index) => {
                var c = analyzes()[key]
                if (c.visible !== false) keys.push(key)
            });

            return keys;

        default:
            return [];
    }
}

// Retourne toutes les types d'options à fetch dans l'API
// (pour le filtrage futur).
// Exemple : getOptionsTypes() -> [{ title: "categories", url: "alarm_inventory_skill/getCategorys" }, {...}, ...]
export function getOptionsTypes() {
    var types = [];
    var seen = [];

    Object.keys(organizations()).map((key, index) => {
        var f = organizations()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(users()).map((key, index) => {
        var f = users()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(notes()).map((key, index) => {
        var f = notes()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(notesHome()).map((key, index) => {
        var f = notesHome()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(requests()).map((key, index) => {
        var f = requests()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(requestsHistoric()).map((key, index) => {
        var f = requestsHistoric()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(requestsAnalyze()).map((key, index) => {
        var f = requestsAnalyze()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    Object.keys(analyzes()).map((key, index) => {
        var f = analyzes()[key].filter;
        if (f.url && seen.indexOf(f.ref) === -1) {
            seen.push(f.ref)
            types.push({ title: f.ref, url: f.url })
        }
    });

    return types;
}

export const columns = {
    organizations: organizations,
    users: users,
    notes: notes,
    notesHome: notesHome,
    requests: requests,
    requestsHistoric: requestsHistoric,
    requestsAnalyze: requestsAnalyze,
    analyzes: analyzes,
    getKeys,
    getOptionsTypes
}
