import React from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds, formatId, displayError, parseArrayString, numberFormatProps } from '../../../utils'
import { Answer } from '../../../models'

import 'antd/es/input/style/index.css';
import { Input } from 'antd';

import { formActions } from "../../../actions";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';


var checkMath = function(calc, round = false) {
    return (!isNaN(calc) && isFinite(calc)) ? (round ? parseInt(calc) : calc) : 0
}

class Formule extends React.Component {

    constructor(props) {
        super(props);
        this.state = { error: false };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const optionId = fetchOptionIds(this.props.options);
        var ans = { [optionId] : {
            value: parseArrayString(this.props.answers[optionId].value),
            detail: this.props.answers[optionId].detail
        }};

        if (!ans || !ans[optionId] || !ans[optionId].value || ans[optionId].value.length === 0) {
            ans = Answer(optionId, new Array(3))
        }

        ans[optionId].value[e.target.id.split('_')[1]] = parseFloat(e.target.value.replace(new RegExp(' ', 'g'), '').replace(',', '.'));

        this.props.saveAnswer(ans);
    }

    render() {
        const ids = fetchOptionIds(this.props.options);
        const value = this.props.answers[ids] ? this.props.answers[ids].value ? eval(this.props.answers[ids].value) : null : null;
        return (
            <p className="formula">
                { (this.props.readonly) ? (
                    formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear)
                ) : (                    
                    formatLabel(this.props.label, this.props.id, this.props.required, this.props.help, this.props.question_number, this.props.financialYear)
                ) }
                <span className="help-formula">
                    <span>Solde non affecté à la fin de l'exercice</span>
                    <i>÷</i>
                    <span>Total des charges</span>
                    <i>-</i>
                    <span>Amortissements nets</span>
                    <i>=</i>
                    <span>Résultat (en %)</span>
                </span>

                <span style={ { width: '100%' } }>
                    <span className="upper-formula">
                        <NumberFormat
                            customInput={Input}
                            id={ formatId(this.props.id) + '_1' }
                            name={ formatId(this.props.id) }
                            onChange={ (e) => this.handleChange(e) }
                            placeholder=""
                            value={ value ? value[1] : "" }
                            { ...numberFormatProps() }
                            readOnly={ this.props.readonly }
                            disabled={ this.props.readonly } />
                        <i>-</i>
                        <NumberFormat
                            customInput={Input}
                            id={ formatId(this.props.id) + '_2' }
                            name={ formatId(this.props.id) }
                            onChange={ (e) => this.handleChange(e) }
                            placeholder=""
                            value={ value ? value[2] : "" }
                            { ...numberFormatProps() }
                            readOnly={ this.props.readonly }
                            disabled={ this.props.readonly } />
                    </span>

                    <span className="lower-formula">
                        <NumberFormat
                            customInput={Input}
                            id={ formatId(this.props.id) + '_0'}
                            name={ formatId(this.props.id) }
                            onChange={ (e) => this.handleChange(e) }
                            placeholder=""
                            value={ value ? value[0] : "" }
                            { ...numberFormatProps() } 
                            readOnly={ this.props.readonly }
                            disabled={ this.props.readonly } />
                        <i>÷</i>
                        <NumberFormat
                            type="text"
                            customInput={Input}
                            id={ formatId(this.props.id) + '_3' }
                            name={ formatId(this.props.id) }
                            onChange={ (e) => this.handleChange(e) }
                            placeholder=""
                            value={ value ? checkMath(value[1] - value[2]) : "" }
                            { ...numberFormatProps() }
                            readOnly
                            disabled={ true } />
                        <i>=</i>
                        <NumberFormat
                            type="text"
                            customInput={Input}
                            id={ formatId(this.props.id) + '_4' }
                            name={ formatId(this.props.id) }
                            onChange={ (e) => this.handleChange(e) }
                            placeholder=""
                            value={ value ? checkMath((value[0] / (value[1] - value[2]) * 100), true) : "" }
                            { ...numberFormatProps() }
                            readOnly
                            disabled={ true } />
                        <i>%</i>
                    </span>
                </span>

                { (this.state.error) && <small className="error">{ displayError() }</small> }
            </p>
        )
    }
}

Text.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    help: PropTypes.string,
    answer: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionIds = (fetchOptionIds(props.options) || []),
        answers = {};
    optionIds.map(oid => answers[oid] = state.form.answers[oid] || {})

    return {
        financialYear: state.form.base.financialYear,
        answers: answers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Formule);
