import React from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds, formatId, fetchCheckedAnswers, displayCheckedAnswers, formatYear } from '../../../utils'
import { Answer } from '../../../models'

import 'antd/es/select/style/index.css';
import { Select as S }  from 'antd';

import { formActions } from "../../../actions";
import { connect } from 'react-redux';

class Select extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(val) {
        // VALIDATION this.state.error
        var selected = (Array.isArray(val)) ? val : [val]

        var options = {};
        (this.props.options || []).map(o => {
            options = { ...options, ...Answer(o.id, (selected.indexOf(o.id) !== -1) ? "t" : "") };
        })
        console.debug(this.props.options)
        this.props.saveAnswer(options)
    }

    render() {
        if (this.props.readonly) {
            var val = formatYear(displayCheckedAnswers(this.props.answers, this.props.options).join(", "), this.props.financialYear);
            return (
                <p>
                    { formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear) }
                    { (val) && <span>{ val }</span> }
                </p>
            )
        }

        var options = [];
        (this.props.options || []).sort(function (el1, el2) {
            if(el1.label < el2.label) { return -1; }
            if(el1.label > el2.label) { return 1; }
            return 0;
        });

        // Logique gangster pour afficher Oui avant Non
        var opts = (this.props.options || []);
        if (opts.length === 2 && opts[0].label === 'Non' && opts[1].label === 'Oui') opts = opts.reverse()

        opts.map((o, i) => options.push(
            <S.Option key={ i }
                    value={ o.id }
                    id={ formatId(this.props.id, i) }
                    disabled={ o.title === "1" }>
                { formatYear(o.label, this.props.financialYear) }
            </S.Option>
        ));

        var defaultValue = fetchCheckedAnswers(this.props.answers)
        defaultValue = defaultValue.map(v => parseInt(v))

        return (
            <div className="ctn">
                { formatLabel(this.props.label, this.props.id, this.props.required, this.props.help, this.props.question_number, this.props.financialYear) }
                <S  id={ formatId(this.props.id) }
                    name={ formatId(this.props.id) }
                    onChange={ this.handleChange }
                    placeholder={ this.props.fields[0].title }
                    value={ defaultValue }
                    mode={ (this.props.multiple) ? 'multiple' : 'default' }
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    { options }
                </S>

                { (this.state.error) && <small className="error">{ this.props.error }</small> }
            </div>
        )
    }
}

Select.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answers: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionIds = (fetchOptionIds(props.options) || []),
        answers = {};
    optionIds.map(oid => answers[oid] = state.form.answers[oid] || {})

    return {
        financialYear: state.form.base.financialYear,
        answers: answers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Select);
