import React from 'react';
import { Link } from "react-router-dom";

import { userActions } from "../../actions";
import { connect } from 'react-redux';

class Footer extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <footer className="general-footer">
                <div className="container">
                    <small>
                        <i>© 2019 Centraide</i>
                        <i className="separator">|</i>
                        <Link to={ '/faq' }>Foire aux questions</Link>
                    </small>
                    <small>Réalisé par <a href={"https://www.04h11.com/"} target={"_blank"}>04h11</a></small>
                </div>
            </footer>
        )

    }
}

const mapStateToProps = (state) => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
