// TABLE
// Request_Types
export const REQ_TYPE_FOA_RENOUVELLEMENT = 1;
export const REQ_TYPE_FOA_RECONDUCTION = 2;
export const REQ_TYPE_FIDC = 3;
export const REQ_TYPE_FMN = 4;

// TABLE
// Request_States
export const REQ_STATE_SUBMISSION = 1;
export const REQ_STATE_SUBMITTED = 2;
export const REQ_STATE_CORRECTION = 3;
export const REQ_STATE_ACCEPTED = 4;
export const REQ_STATE_ANALYSIS = 5;
export const REQ_STATE_ANALYZED = 6;
export const REQ_STATE_READY_FOR_APPROBATION = 7;
export const REQ_STATE_COMPLETED = 8;

// Association des Request_States à la progression d'un Request_Types
export const STATES = {
    // FOA renouvellement
    [REQ_TYPE_FOA_RENOUVELLEMENT]: [ REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION, REQ_STATE_COMPLETED ],
    [REQ_TYPE_FOA_RECONDUCTION]:   [ REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION, REQ_STATE_COMPLETED ],
    [REQ_TYPE_FIDC]:               [ REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION, REQ_STATE_COMPLETED ],
    [REQ_TYPE_FMN]:                [ REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ANALYSIS, REQ_STATE_COMPLETED ]
}

// TABLE
// Analyze_Types
export const AN_TYPE_FIN = 4;
export const AN_TYPE_FIN_RECONDUCTION = 6;
export const AN_TYPE_FIN_FIDC = 7;

export const AN_TYPE_ADMIN_FIN = 1;
export const AN_TYPE_IMP_SOC = 2;
export const AN_TYPE_SUMMARY = 3;
export const AN_TYPE_RECONDUCTION = 5;
export const AN_TYPE_DEV_GESTION = 8;
export const AN_TYPE_AS = 9;
export const AN_TYPE_FMN = 10;

// TABLE
// Analyze_States
export const AN_STATE_PENDING = 1;
export const AN_STATE_PROGRESS = 2;
export const AN_STATE_COMPLETED = 3;


// TABLE
// Request_User_Types
export const REQ_USER_TYPE_BEN_ADMIN_FIN = 1;
export const REQ_USER_TYPE_BEN_IMP_SOC = 2;
export const REQ_USER_TYPE_BEN_AN_FIN = 3;
export const REQ_USER_TYPE_BEN_RECONDUCTION = 4;


// TABLE
// Users.Rank
export const USER_RANK_ADMIN = 1;
export const USER_RANK_CONSEILLE = 2;
export const USER_RANK_ANALYSTE = 3;
export const USER_RANK_BENEVOLE = 4;
export const USER_RANK_ORG = 5;

// Users qui pourraient être associés à une Request
export const USER_RANKS_COULD_BE_CONSEILLE = [USER_RANK_ADMIN, USER_RANK_CONSEILLE]
export const USER_RANKS_COULD_BE_BENEVOLE = [USER_RANK_ADMIN, USER_RANK_CONSEILLE, USER_RANK_BENEVOLE, USER_RANK_ANALYSTE]
export const USER_RANKS_COULD_BE_ANALYSTE = [USER_RANK_ADMIN, USER_RANK_CONSEILLE, USER_RANK_BENEVOLE, USER_RANK_ANALYSTE]

// TABLE
// Document_Types
export const FIDC_FIDUCIARY_DOCS = [7, 11, 5, 6, 10, 2, 23]


// ----- STATUS PAGE -----
// Association de Analyzes à un Request_Types
export const ANALYZES_FOR_TYPE = {
    [REQ_TYPE_FOA_RENOUVELLEMENT]: [ AN_TYPE_ADMIN_FIN, AN_TYPE_IMP_SOC, AN_TYPE_SUMMARY, AN_TYPE_FIN ],
    [REQ_TYPE_FOA_RECONDUCTION]:   [ AN_TYPE_RECONDUCTION, AN_TYPE_FIN_RECONDUCTION ],
    [REQ_TYPE_FIDC]:               [ AN_TYPE_DEV_GESTION, AN_TYPE_AS, AN_TYPE_FIN_FIDC ],
    [REQ_TYPE_FMN]:                [ AN_TYPE_FMN ]
}

export const ANALYZES_DISPLAY = {
    [AN_TYPE_FIN]: { label: 'Analyse financière', icon: 'attach_money' },
    [AN_TYPE_ADMIN_FIN]: { label: 'Analyse administriative et financière', icon: 'account_balance' },
    [AN_TYPE_IMP_SOC]: { label: 'Analyse de l\'impact social', icon: 'face' },
    [AN_TYPE_SUMMARY]: { label: 'Fiche synthèse', icon: 'work' },

    [AN_TYPE_FIN_RECONDUCTION]: { label: 'Validation financière', icon: 'attach_money' },
    [AN_TYPE_RECONDUCTION]: { label: 'Analyse de la demande de reconduction', icon: 'visibility' },

    [AN_TYPE_FIN_FIDC]: { label: 'Analyse financière', icon: 'attach_money' },
    [AN_TYPE_DEV_GESTION]: { label: 'Analyse du développement, de la gestion et des finances', icon: 'account_balance' },
    [AN_TYPE_AS]: { label: 'Analyse de l\'impact social, de l\'enracinement et de la gouvernance', icon: 'face' },

    [AN_TYPE_FMN]: { label: 'Analyse', icon: 'work' }
}
