import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { withRouter } from "react-router";
import Moment from 'react-moment';
import 'moment/locale/fr';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

import 'antd/es/notification/style/index.css';
import 'antd/es/spin/style/index.css';
import { Spin, notification } from 'antd';

import { progress, history } from '../../utils';
import { Answer, formatAnswers } from '../../models/Answer';
import { FormSection } from './';

import { formActions } from "../../actions";
import { connect } from 'react-redux';



// Composant d'un formulaire
// Reçoit un ID de form et un ID de demande et
// load les contenus appropriés.
// Peut être readonly ou non.
class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            section: {
                current: null,
                next: null,
                all: [],

                valid: [],
                progress: []
            },

            saved: null,
            percentage: 0,

            loaded: {
                form: false,
                answers: false,
                error: false,
            },

            printState: 'default',
        }

        this.handleMessage = this.handleMessage.bind(this);
        this.load = this.load.bind(this);
        this.fetchSections = this.fetchSections.bind(this);
        this.setSectionsState = this.setSectionsState.bind(this);
        this.updateNextSection = this.updateNextSection.bind(this);
        this.updateSection = this.updateSection.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.requestPrint = this.requestPrint.bind(this);
    }

    // Fonction qui handle les messages envoyées par la
    // page de "Print" du formulaire.
    handleMessage(event) {
        if (event.data.action === 'print-loaded') {
            if (this.state.printState === 'requested') printIframe('print')
            this.setState({ printState: 'loaded' });
        }
    }

    // Lors que le component est chargé, on demande
    // le chargement du formulaire en soit et des réponses.
    componentDidMount() {
        this.load();


        // Si le form est déjà loadé, on set l'état
        if (this.props.content.id) this.setSectionsState()

        // Handler pour avertir l'utilisateur lors du reload,
        // alerte seulement si il y a des réponses non-enregistrées.
        // TODO: trouver meilleurs solution pour reload (chrome)
        window.onbeforeunload = function(event) {
            if (this.props.mustSave)
                return 'Refresh';
        }.bind(this)

        // Handler pour savoir si la version "Print" est disponible
        if (!this.props.print)
            window.addEventListener('message', this.handleMessage);
    }

    // Lors que le composant se retire, on eneleve
    // le listener du reload sur la window.
    componentWillUnmount() {
        window.onbeforeunload = null;
        window.removeEventListener('message', this.handleMessage);
    }

    // Fonction qui est appelée lorsque les props
    // du composant changent.
    componentWillReceiveProps(nextProps) {
        // Si le ID du form ou de la demande a changé,
        // on reload le formulaire et les réponses.
        if (nextProps.formId !== this.props.formId || nextProps.sourceId !== this.props.sourceId) {
            this.load(nextProps);
        }

        // Si le content (form) en soit change, on
        // configure les sections et les états.
        // console.log("will receive");
        // console.log(this.props.content.id);
        // console.log(nextProps.content.id)
        // console.log((nextProps.content.id !== this.props.content.id));
        if (nextProps.content.id !== this.props.content.id ) {
            var sections = this.fetchSections(nextProps);
            if (sections) this.setState({ section: { current: sections[0], next: null, all: sections }})
        }
    }

    // On demande le chargement du formulaire (en
    // fonction du ID du form) et le chargement des
    // réponses (en fonction du ID de la demande).
    // Utilise : FormActions.fetchForm() / .fetchAnswers()
    async load(props = this.props) {
        props.fetchForm(props.formId, props.financialYear).then(() => {
            this.setState({ ...this.state, loaded: { ...this.state.loaded, form: true }})
            if (this.state.loaded.answers) this.setSectionsState();

            props.fetchAnswers().then(() => {
                this.setState({ ...this.state, loaded: { ...this.state.loaded, answers: true }})
                if (this.state.loaded.form) this.setSectionsState();

                if (this.props.print)
                    window.parent.postMessage({ action: 'print-loaded' });
            }).catch(() => {
                this.setState({ ...this.state, loaded: { ...this.state.loaded, error: true }})
            })

        }).catch(() => {
            this.setState({ ...this.state, loaded: { ...this.state.loaded, error: true }})
        })
    }

    // Fetch les IDs des sections et les retourne
    // dans un Array.
    // Exemple : [1, 2, 3, 4, 8]
    // Utilisé dans : this.state.section.all
    fetchSections(props = this.props) {
        var sections = props.content.sections;
        if (!sections) return;

        return sections.map(s => s.id)
    }

    // Set les états des sections (complétées / en cours).
    // Se base sur les Options dans les questions et sur
    // les Answers.
    // Utilisé pour : Calculer le pourcentage
    // Utilisé pour : Set this.state.section.valid + this.state.section.done
    // Utilisé pour : Set this.state.percentage
    setSectionsState(props = this.props) {
        // Reset le state des sections.
        this.setState({ section: { ...this.state.section, valid: [], progress: [] }})

        // En readonly, on se fou du progress
        if (props.readonly) return;

        // Fetch les sections du form
        var sections = props.content.sections;

        // Call de la fonction qui calcule les états
        var p = progress(sections, this.props.answers);

        // Set le pourcentage et l'état des sections
        this.setState({ ...this.state, percentage: p.percentage, section: { ...this.state.section, valid: p.valid.slice(0), progress: p.progress.slice(0) } });
    }

    // Fonction qui set la prochaine section active du formulaire.
    // Comme on travail avec la validation HTML, la validation
    // doit se faire AVANT la navigation. C'est pourquoi on travaille
    // avec "current" et "next" pour naviguer entre les sections.
    // Utilisé pour : Set this.state.section.next
    updateNextSection(s) {
        this.setState({ section: { ...this.state.section, next: s }})
    }

    // Fonction qui set la section active du formulaire.
    // Se base sur la section "next" prédéfinie.
    // Utilise : this.state.section.next
    // Utilisé pour : Set this.state.section.current
    updateSection() {
        var next = this.state.section.next;
        if (!next) return;
        this.setState({ section: { ...this.state.section, current: next, next: null }})
    }

    // Fonction du soumission du formulaire.
    // Cette fonction est executée suite à la validation
    // de la section active.
    // Appelée lors du clic sur un bouton de sauvegarde,
    // ou bien lors de la navigation entre les sections
    // (puisque les liens dans le menu sont des <button>).
    handleSubmit(e) {
        e.preventDefault();
        //if (this.props.readonly) return;

        // Si on doit sauvegarder, on le fait.
        if (this.props.mustSave && !this.props.readonly) {
            var answers = this.props.formatAnswers(this.props.answers)
            // LIGNE DE TEST -->
            // Tentative d'optimisation, on envoie seulement les
            // réponses pas encore enregistrées
            answers = answers.filter(a => this.props.unsavedAnswers.hasOwnProperty(a.option_id))
            // <-- Retirer cette ligne si certaines réponses ne sont pas enregistrées
            // On save le form et on gère le cas
            // ou une erreur se produirait avec l'API
            this.props.saveForm(answers).then(() => {
                // Le timeout permet de set les deux états sans faire planter react.
                setTimeout(function() { this.setSectionsState(); }.bind(this), 200);
                this.setState({ saved: new Date() })

                notification.open({
                    message: 'Enregistrement effectué',
                    description: 'Vos changements ont été enregistrés avec succès.',
                    placement: 'bottomRight',
                });
            }).catch((e) => {
                notification.open({
                    message: 'Une erreur s\'est produite',
                    description: 'Veuillez réessayer ou contacter un administrateur.',
                    placement: 'bottomRight',
                });
            })
        }

        // Met à jour la section appropriée.
        this.updateSection()

        // Scroll au top de la page
        // TODO: fix - animate
        window.scrollTo(0,0)
    }

    requestPrint() {
        const { printState } = this.state;
        if (printState === 'default') {
            this.setState({ printState: 'requested' })
        } else if (printState === 'loaded') {
            printIframe('print')
        }
    }

    render() {
        var { section, percentage, saved } = this.state;
        percentage = Math.round(percentage);

        var bufferPrint = this.state.printState === 'requested'

        var printLocation = { ...this.props.location };
        printLocation.pathname += '/print';

        var form = this.props.content,
            sections = [],
            nav = []

        // Fonctions utilitaires dans le render().
        var displaySection = (sectionId, i) => ((section.current === null && i === 0) || section.current === sectionId),
            computeIcon = (sectionId, icon) => {
                if ((section.valid || []).indexOf(sectionId) !== -1) return 'done'
                if ((section.progress || []).indexOf(sectionId) !== -1) return 'more_horiz'
                return icon
            }

        // Pour chaque section :
        // try {
        //     (form.sections || []).sort((el1, el2) => el1.order - el2.order)
        // } catch(e) {}

        (form.sections || []).map((s, i) => {
            // Setup des sections + gestion de celle active.
            sections.push(
                displaySection(s.id, i) && <FormSection key={ s.id } section={ s } readonly={ this.props.readonly } orgId={ this.props.orgId } />
            )

            // Setup de la navigationdes sections.
            nav.push(
                <li key={ s.id } className={ (displaySection(s.id, i) ? 'active' : '') + ' ' + computeIcon(s.id, s.icon) }>
                    <button onClick={ (e) => this.updateNextSection(s.id) }>
                        <Icon>{ computeIcon(s.id, s.icon) }</Icon>
                        <i>{ s.label }</i>
                    </button>
                </li>
            )
        })

        if (this.props.print) {
            return (
                <div className={ "form-wrap" + ( this.props.readonly ? " read-only" : "") }>
                    <form data-form-id={ form.id } onSubmit={ this.handleSubmit } ref="form">
                        {
                            (form.sections || []).map((s, i) => (
                                <div className="box form-box" key={ i }>
                                    <FormSection key={ s.id } section={ s } readonly={ true } orgId={ this.props.orgId } />
                                </div>
                            ))
                        }
                    </form>
                </div>
            )
        }

        return(
            <Fragment>
                <div className="form-infos" id="form-infos">
                    <b className="date">{ this.props.financialYear }</b>
                    { ! this.props.readonly && <b className="status">{ (percentage >= 100) ? 'Complétée' : 'En cours' }</b> }
                </div>

                { this.props.statusBar && (
                    <article className="infos">
                        { this.props.statusBar }
                    </article>
                ) }

                { ! this.props.readonly && (
                    <div className="form-progress-wrap">
                        <b className="percentage">{ percentage } %</b>
                        <div className="form-progress">
                            <div className="fill" style={ { width: percentage + '%' } }></div>
                        </div>
                    </div>
                ) }

                <div className={ "form-wrap" + ( this.props.readonly ? " read-only" : "") }>
                    <form data-form-id={ form.id } onSubmit={ this.handleSubmit } ref="form">
                        <nav className="form-nav">
                            <ul>
                                { nav }
                            </ul>

                            <Fragment>
                                <Spin spinning={ bufferPrint } wrapperClassName="print-btn">
                                    <button className={ "alt" } onClick={ this.requestPrint } disabled={ bufferPrint }>Imprimer cette page</button>
                                </Spin>

                                { this.state.printState !== 'default' && (
                                    <iframe id="print" src={ history.createHref(printLocation) } style={ { display: 'none' } } title="Print Form" />
                                ) }
                            </Fragment>

                            { (!this.props.readonly) &&
                                <button disabled={ !this.props.mustSave } className="save">Sauvegarder le progrès</button>
                            }

                            { (saved) &&
                                <small>
                                    <Icon>save</Icon>
                                    <i>Dernière sauvegarde : <Moment fromNow locale="fr" interval={ 60000 }>{ saved }</Moment></i>
                                </small>
                            }
                        </nav>

                        <div className="box form-box">
                            { sections }
                            <p>{ /* JSON.stringify(this.props.answers) */ }</p>
                            {
                                (!this.props.readonly) &&
                                    <button disabled={ !this.props.mustSave }>Sauvegarder cette section</button>
                            }
                        </div>
                    </form>
                </div>

                <Prompt
                    when={ this.props.mustSave }
                    message="Attention, certaines de vos réponses n'ont pas été enregistrées. Voulez-vous vraiment quitter cette page ?"
                />
            </Fragment>
        )
    }
}

const printIframe = (id) => {
    const iframe = document.frames ? document.frames[id] : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
};


Form.propTypes = {

}


const mapStateToProps = state => {
    return {
        content: state.form.base,
        answers: state.form.answers,
        unsavedAnswers: state.form.unsavedAnswers,
        mustSave: (Object.keys(state.form.unsavedAnswers).length !== 0 || state.form.unsavedAnswers.constructor !== Object)
    };
};

export default connect(mapStateToProps, null)(withRouter(Form));
