import React, { Component, Fragment } from 'react';

import 'antd/es/modal/style/index.css';
import { Modal, notification } from 'antd';

import { requestService } from "../../../services";



export const validConsent = (req) => (req.consent === true && req.initials !== '')
export const showModal = (_) => { _.setState({ consentModal: true }) }
export const hideModal = (_) => { _.setState({ consentModal: false }) }

function handleInputChange(_, key, value) {
    const { req } = _.state;
    _.setState({ req: { ...req, [key]: value } })
}

export function consent(_, req) {
    if (!validConsent(req)) return

    var nextStateId = (!req.isInCorrection()) ? _.getNextState() : _.getPrevState()
    requestService.update({
        id: req.id,
        consent: true,
        initials: req.initials,
        state_id: nextStateId
    }).then((r) => {
        r = _.formatRequest(r);
        _.setState({ req: { ...req, ...r } })
        hideModal(_);
        notification.open({
            message: 'Demande soumise',
            description: 'La demande a été soumise avec succès.',
            placement: 'bottomRight',
        });
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function render(_) {
    const { req, consentModal } = _.state;

    return (
        <Modal
            visible={ consentModal }
            className="request-presubmit-modal"
            centered

            okText="Soumettre la demande"
            okButtonProps={{ className: 'button' + (!validConsent(req) ? ' disabled' : '') }}
            onOk={ () => consent(_, req) }

            cancelText="Annuler"
            cancelButtonProps={{ className: 'button ghost' }}
            onCancel={ () => hideModal(_) }>

            <h3>Consentement</h3>
            <b>Assurez-vous d'avoir complété adéquatement la page financière de votre organisme</b>
            <br />
            <p>Par la présente, j'atteste que la demande transmise à Centraide est complète et qu'elle reflète la situation de l'organisme en date de la transmission de la demande. J'accepte que l'information sera utilisée par Centraide dans le respect de ses règles d'éthique et de déontologie comprenant le respect de la confidentialité, à des fins d'analyse et de décision en matière d'association d'une part, et de soutien financier d'autre part, de même qu'à des fins d'analyse et de communication. Centraide s'engage pour sa part à informer l'organisme de toute utilisation potentielle d'information nominative qui ne serait pas déjà publique afin d'obtenir son consentement préalable. Centraide se réserve le droit d'écarter une demande incomplète, insuffisante ou hors délai. En tout temps, l'organisme a accès à son dossier électronique et aux documents qui le constituent.</p>
            <br />
            <input type="text" onChange={ (e) => handleInputChange(_, 'initials', e.target.value) } placeholder="Initiales" defaultValue={ req.initials } />
            <br />
            <input type="checkbox" id="accept" onChange={ (e) => handleInputChange(_, 'consent', e.target.checked) } defaultChecked={ req.consent } />
            <label htmlFor="accept">J'ai lu et j'accepte les termes et conditions</label>
        </Modal>
    )
}
