import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as I from './inputs';
import { parseArrayString, formatYear } from '../../utils';

class InputManager extends React.Component {
    constructor(props) {
        super(props);
    }

    components = {
        text: I.Text,
        radio: I.Radio,
        checkbox: I.Checkbox,
        toggle: I.Toggle,
        file: I.File,
        select: I.Select,
        table: I.Table,
        'table-mixed': I.TableMixed,
        formule: I.Formule,
        'checkbox-list': I.CheckboxList,
        'financial-table': I.FinancialTable,
    };

    render() {
        const question = this.props.question;
        const fields = parseArrayString(question.fields);
        const computed = question.computed ? parseArrayString(question.computed) : {};

        var multiple = false,
            type = question.question_type.label;
        if (type === 'title')
            return (
                <h3
                    className={
                        fields[0].isImportant === 'true' ? 'important' : ''
                    }
                >
                    {formatYear(question.label, this.props.financialYear)}
                </h3>
            );

        if (type.indexOf('multiple-') !== -1) {
            multiple = true;
            type = type.replace('multiple-', '');
        }

        var special = null;
        if (type === 'date') {
            type = 'text';
            special = 'date';
        }
        if (type === 'number') {
            type = 'text';
            special = 'number';
        }
        if (type === 'textarea') {
            type = 'text';
            special = 'textarea';
        }

        const Tag = this.components[type || 'text'];

        return (
            <Tag
                id={question.id}
                label={question.label}
                question_number={question.question_number}
                help={question.help}
                required={
                    question.required === 1
                        ? true
                        : question.required === 0 && false
                }
                options={question.options}
                fields={fields}
                readonly={this.props.readonly || computed.readOnly === 'true'}
                detail_text={computed.detailText}
                multiple={multiple}
                special={special}
                children_question={question.children_question}
                limit={computed.limit}
            />
        );
    }
}

InputManager.propTypes = {
    question: PropTypes.object,
    readonly: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        financialYear: state.form.base.financialYear,
    };
};

export default connect(mapStateToProps, null)(InputManager);
