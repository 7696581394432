import React from 'react';
import {
    AN_TYPE_FIN,
    AN_TYPE_ADMIN_FIN,
    AN_TYPE_IMP_SOC,
    AN_TYPE_FIN_RECONDUCTION,
    AN_TYPE_RECONDUCTION,
    AN_TYPE_SUMMARY,
    AN_TYPE_FIN_FIDC,
    AN_TYPE_DEV_GESTION,
    AN_TYPE_AS,
    AN_TYPE_FMN,

    STATES,

    REQ_TYPE_FOA_RENOUVELLEMENT,
    REQ_TYPE_FOA_RECONDUCTION,
    REQ_TYPE_FIDC,
    REQ_TYPE_FMN,

    REQ_STATE_SUBMISSION,
    REQ_STATE_SUBMITTED,
    REQ_STATE_CORRECTION,
    REQ_STATE_ACCEPTED,
    REQ_STATE_ANALYSIS,
    REQ_STATE_ANALYZED,
    REQ_STATE_READY_FOR_APPROBATION,
    REQ_STATE_COMPLETED,

    REQ_USER_TYPE_BEN_AN_FIN,
    REQ_USER_TYPE_BEN_ADMIN_FIN,
    REQ_USER_TYPE_BEN_IMP_SOC,
    REQ_USER_TYPE_BEN_RECONDUCTION
} from './dbValues'

export function isPastOrOnTargetState(type, state, targetState) {
    var getIndex = (s) => ((STATES[type] || []).indexOf(s))
    var index = getIndex(state);
    return (getIndex(targetState) !== -1) ? (index >= getIndex(targetState)) : false
}

export function injectFunctions() {
    return {
        getNextState: getNextState,
        getPrevState: getPrevState,
        getStateFromIncrement: getStateFromIncrement,

        isFormLocked: isFormLocked,
        isFormSubmitted: isFormSubmitted,
        isInCorrection: isInCorrection,
        isApproved: isApproved,
        isInAnalysis: isInAnalysis,
        isInPostAnalysis: isInPostAnalysis,
        isStandbyForApprobation: isStandbyForApprobation,
        isCompleted: isCompleted,

        canApproveOrReject: canApproveOrReject,
        analyzesAreOpened: analyzesAreOpened,

        hasSummary: hasSummary,
        hasPreformulatedRecommendations: hasPreformulatedRecommendations,
        hasRecommendations: hasRecommendations,
        hasFinancialPage: hasFinancialPage,
        hasFinancialSummary: hasFinancialSummary,
        isFIDC: isFIDC
    }
}

function getNextState() {
    var index = STATES[this.type_id].indexOf(this.state_id);
    if (index++ > STATES.length) index = STATES.length;
    return STATES[this.type_id][index]
}

function getPrevState() {
    var index = STATES[this.type_id].indexOf(this.state_id);
    if (index-- < 0) index = 0;
    return STATES[this.type_id][index]
}

function getStateFromIncrement(increment) {
    var getter = (increment > 0) ? 'getNextState' : 'getPrevState';
    var req = { type_id: this.type_id, state_id: this.state_id, getPrevState: getPrevState, getNextState: getNextState }
    for (var i = 0; i < Math.abs(increment); i++)
        req.state_id = req[getter]()

    return req.state_id
}


// Fonctions utilitaires
function isFormLocked() {
    return (this.state_id !== REQ_STATE_SUBMISSION && this.state_id !== REQ_STATE_CORRECTION)
}

function isFormSubmitted() {
    return (this.state_id !== REQ_STATE_SUBMISSION)
}

function isInCorrection() {
    return (this.state_id === REQ_STATE_CORRECTION)
}

function canApproveOrReject() {
    return (this.state_id === REQ_STATE_SUBMITTED)
}

function isInAnalysis() {
    return (this.state_id === REQ_STATE_ANALYSIS)
}

function isStandbyForApprobation() {
    return (this.state_id === REQ_STATE_READY_FOR_APPROBATION)
}

function isCompleted() {
    return (this.state_id === REQ_STATE_COMPLETED)
}

function isApproved() {
    return isPastOrOnTargetState(this.type_id, this.state_id, REQ_STATE_ANALYSIS)
}

function analyzesAreOpened() {
    return isPastOrOnTargetState(this.type_id, this.state_id, REQ_STATE_ANALYSIS)
}

function isInPostAnalysis() {
    return (
        isPastOrOnTargetState(this.type_id, this.state_id, REQ_STATE_ANALYZED) || 
        isPastOrOnTargetState(this.type_id, this.state_id, REQ_STATE_READY_FOR_APPROBATION) ||
        isPastOrOnTargetState(this.type_id, this.state_id, REQ_STATE_COMPLETED)
    )
}


// Fonctions plus générales
function hasSummary() {
    return this.type_id === REQ_TYPE_FOA_RENOUVELLEMENT
}

function hasPreformulatedRecommendations() {
    return this.type_id === REQ_TYPE_FOA_RECONDUCTION
}

function hasRecommendations() {
    return this.type_id !== REQ_TYPE_FMN
}

function hasFinancialPage() {
    return this.type_id !== REQ_TYPE_FMN
}

function hasFinancialSummary() {
    return this.type_id !== REQ_TYPE_FMN
}

function isFIDC() {
    return this.type_id === REQ_TYPE_FIDC
}

// Fonction pour les timelines
export function generateTimeline(type, state) {
    var t = []
    switch (type) {
        case REQ_TYPE_FOA_RENOUVELLEMENT:
            t = [
                { color: 'grey', text: 'Demande validée' },
                { color: 'grey', text: 'Fiche synthèse complétée', analyzeType: AN_TYPE_SUMMARY },
                { color: 'grey', text: 'Analyse financière complétée', analyzeType: AN_TYPE_FIN },
                { color: 'grey', text: 'Analyse administriative et financière complétée', analyzeType: AN_TYPE_ADMIN_FIN },
                { color: 'grey', text: 'Analyse de l\'impact social complétée', analyzeType: AN_TYPE_IMP_SOC },
                { color: 'grey', text: 'Recommandations formulées' },
                { color: 'grey', text: 'Demande approuvée' }
            ]

            if (isPastOrOnTargetState(type, state, REQ_STATE_SUBMITTED)) t[0].color = 'blue';
            if (isPastOrOnTargetState(type, state, REQ_STATE_CORRECTION)) t[0].color = 'red';
            if (isPastOrOnTargetState(type, state, REQ_STATE_ANALYSIS)) { t[0].color = 'green'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_ANALYZED)) { t[1].color = 'green'; t[2].color = 'green'; t[3].color = 'green'; t[4].color = 'green'; t[5].color = 'blue'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_READY_FOR_APPROBATION)) { t[5].color = 'green'; t[6].color = 'blue'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_COMPLETED)) t[6].color = 'green';

            return t;

        case REQ_TYPE_FOA_RECONDUCTION:
            t = [
                { color: 'grey', text: 'Demande validée' },
                { color: 'grey', text: 'Validation financière complétée', analyzeType: AN_TYPE_FIN_RECONDUCTION },
                { color: 'grey', text: 'Analyse de la demande de reconduction complétée', analyzeType: AN_TYPE_RECONDUCTION },
                { color: 'grey', text: 'Demande approuvée' }
            ]

            if (isPastOrOnTargetState(type, state, REQ_STATE_SUBMITTED)) t[0].color = 'blue';
            if (isPastOrOnTargetState(type, state, REQ_STATE_CORRECTION)) t[0].color = 'red';
            if (isPastOrOnTargetState(type, state, REQ_STATE_ANALYSIS)) { t[0].color = 'green'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_READY_FOR_APPROBATION)) { t[1].color = 'green'; t[2].color = 'green'; t[3].color = 'blue'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_COMPLETED)) t[3].color = 'green';

            return t;

        case REQ_TYPE_FIDC:
            t = [
                { color: 'grey', text: 'Demande validée' },
                { color: 'grey', text: 'Analyse financière complétée', analyzeType: AN_TYPE_FIN_FIDC },
                { color: 'grey', text: 'Analyse du développement, de la gestion et des finances complétée', analyzeType: AN_TYPE_DEV_GESTION },
                { color: 'grey', text: 'Analyse de l\'impact social, de l\'enracinement et de la gouvernance complétée', analyzeType: AN_TYPE_AS },
                { color: 'grey', text: 'Recommandations formulées' },
                { color: 'grey', text: 'Demande approuvée' }
            ]

            if (isPastOrOnTargetState(type, state, REQ_STATE_SUBMITTED)) t[0].color = 'blue';
            if (isPastOrOnTargetState(type, state, REQ_STATE_CORRECTION)) t[0].color = 'red';
            if (isPastOrOnTargetState(type, state, REQ_STATE_ANALYSIS)) { t[0].color = 'green'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_ANALYZED)) { t[1].color = 'green'; t[2].color = 'green'; t[3].color = 'green'; t[4].color = 'blue'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_READY_FOR_APPROBATION)) { t[4].color = 'blue'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_COMPLETED)) t[4].color = 'green';

            return t;

        case REQ_TYPE_FMN:
            t = [
                { color: 'grey', text: 'Demande validée' },
                { color: 'grey', text: 'Analyse complétée', analyzeType: AN_TYPE_FMN },
                { color: 'grey', text: 'Demande approuvée' }
            ]

            if (isPastOrOnTargetState(type, state, REQ_STATE_SUBMITTED)) t[0].color = 'blue';
            if (isPastOrOnTargetState(type, state, REQ_STATE_CORRECTION)) t[0].color = 'red';
            if (isPastOrOnTargetState(type, state, REQ_STATE_ANALYSIS)) { t[0].color = 'green'; }
            if (isPastOrOnTargetState(type, state, REQ_STATE_COMPLETED)) { t[1].color = 'green'; t[2].color = 'green'; }

            return t;

        default:
            return t;

    }
}

// Fonction pour les assignés
export function generateAssignees(type) {
    switch (type) {
        case REQ_TYPE_FOA_RENOUVELLEMENT:
            return [
                { name: <><i>Analyste financier</i></>, can: 'assign_financial', type: REQ_USER_TYPE_BEN_AN_FIN },
                { name: <><i>Bénévole :</i><small>(Administration et finance)</small></>, can: 'assign_secondaries', type: REQ_USER_TYPE_BEN_ADMIN_FIN },
                { name: <><i>Bénévole :</i><small>(Impact social)</small></>, can: 'assign_secondaries', type: REQ_USER_TYPE_BEN_IMP_SOC }
            ]

        case REQ_TYPE_FOA_RECONDUCTION:
            return [
                { name: <><i>Analyste financier</i></>, can: 'assign_financial', type: REQ_USER_TYPE_BEN_AN_FIN },
                { name: <><i>Bénévole :</i><small>(Reconduction)</small></>, can: 'assign_secondaries', type: REQ_USER_TYPE_BEN_RECONDUCTION }
            ]

        case REQ_TYPE_FIDC:
            return [
                { name: <><i>Analyste financier</i></>, can: 'assign_financial', type: REQ_USER_TYPE_BEN_AN_FIN },
                { name: <><i>Conseiller :</i><small>(AS, enracinnement, gouvernance)</small></>, can: 'assign_secondaries', type: REQ_USER_TYPE_BEN_ADMIN_FIN },
                { name: <><i>Conseiller :</i><small>(développement, gestion, finances)</small></>, can: 'assign_secondaries', type: REQ_USER_TYPE_BEN_IMP_SOC }
            ]

        case REQ_TYPE_FMN:
            return []

        default:
            return []
    }
}
