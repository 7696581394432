import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import Icon from '@material-ui/core/Icon';
import * as d3 from 'd3';
import moment from 'moment';
import { title, renderSelectYears } from '../../utils';

import { requestService, statsService } from '../../services';
import {
    STATES as STATES_FOR_TYPE,
    ANALYZES_FOR_TYPE,
    ANALYZES_DISPLAY,

    REQ_STATE_ANALYSIS,
    AN_TYPE_SUMMARY,

    AN_STATE_PENDING,
    AN_STATE_PROGRESS,
    AN_STATE_COMPLETED,

    regionLookups
} from '../../constants';

import { Spin, Tooltip, Button, Select } from 'antd';

// Page Statut des demandes.
// Illustre le state des demandes par types/années.
class StatusPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            types: [],
            states: [],

            loading: true,

            // On cache les filtres et le sort (type + direction)
            filters: JSON.parse(localStorage.getItem("status[filters]")) || { year: null, type: null, regions: [] },
            sort: localStorage.getItem("status[sort]") || 'organization',
            sort_asc: (localStorage.getItem("status[sort_asc]") || 'true') === 'true',
        }

        // Pour l'instant, la seule chose qu'on anime vraiment
        // c'est le sort, et d3 se base sur scaleBand pour resort.
        this.y = d3.scaleBand()
            .padding(0)

        this.handleSort = this.handleSort.bind(this);
        this.setYDomain = this.setYDomain.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.drawGraph = this.drawGraph.bind(this);
        this.updateGraph = this.updateGraph.bind(this);
    }

    componentDidMount() {
        this.fetchSelects();
    }

    // Fetch les Selects (types de demandes + états possibles d'une demande).
    // On set ensuite les valeurs par défaut des filtres, si rien n'a été
    // trouvé en cache dans le constructeur.
    async fetchSelects() {
        const { filters } = this.state;
        const types = await requestService.listTypes()
        const states = await requestService.listStates()

        this.setState({
            types,
            states,
            filters: {
                year: filters.year || moment().format('YYYY'),
                regions: filters.regions || [],
                type: filters.type || types[0].id
            }
        }, () => {
            // Une fois qu'on a les options + les filtres définitifs,
            // on peut fetch les données une première fois.
            this.fetchData();
        })
    }

    // Fetch des données en fonction d'un type de demande + d'une année.
    async fetchData() {
        const { filters } = this.state;

        this.setState({ loading: true }, async () => {
            const data = await statsService.status(filters);

            // On draw le graph une fois qu'on a les données
            this.setState({ data }, () => this.drawGraph())
        })
    }

    // Handle le click sur une des colonnes, ie on update le state
    // et cache le type + dir de sort.
    handleSort(new_sort, e) {
        var { data, sort, sort_asc } = this.state;

        if (e) {
            e.preventDefault()
            sort_asc = false
        }
        else if (new_sort == sort) sort_asc = !sort_asc;
        else sort_asc = true

        localStorage.setItem('status[sort]', new_sort)
        localStorage.setItem('status[sort_asc]', sort_asc)
        this.setState({ sort: new_sort, sort_asc }, () => this.updateGraph())
    }

    // Set this.y.domain en fonction du type + dir de sort stockés dans le state.
    setYDomain() {
        var { data, sort, sort_asc } = this.state;

        // Même sort que les MaterialTable, utile pour comparer
        const comparator = new Intl.Collator('fr')

        if (sort === 'organization') {
            // Sort par Org.
            if (sort_asc)
                data = data.sort((a, b) => comparator.compare(a.organization.name, b.organization.name))
            else
                data = data.sort((a, b) => comparator.compare(b.organization.name, a.organization.name))
        } else if (typeof sort !== 'undefined') {
            // Sort par état de demande.

            // Ici, l'ordre alphabétique dans les sous-ensembles n'est pas le plus intéressant,
            // donc tout est d'abord trié par state croissant puis par ordre alphabétique (pour les states égaux).
            // Dans les deux cas (asc et desc), le state **égal** à la colonne triée est placé avant le state **supérieur**,
            // car c'est la colonne sélectionnée donc il faut mettre en avant ce qui match exactement.

            const stateThenName = (a, b) => {
                if (a.state_id !== b.state_id)
                    return a.state_id - b.state_id
                
                return comparator.compare(a.organization.name, b.organization.name)
            }

            if (sort_asc) {
                // --> Focus sur les demandes **dépassant** le state
                // En premier, le state **égal** à la colonne triée
                // En deuxième, le state **supérieur** (ce qui a dépassé le state)
                // Finalement, le state **inférieur** (ce n'est pas le focus ici)
                data = [
                    ...data.filter(d => d.state_id >= parseInt(sort)).sort(stateThenName),
                    ...data.filter(d => d.state_id < parseInt(sort)).sort(stateThenName),
                ]
            } else {
                // --> Focus sur les demandes n'étant **pas arrivées** au state
                // En premier, le state **inférieur** à la colonne triée (ce qui n'a pas atteint le state)
                // En deuxième, le state **égal**
                // Finalement, le state **supérieur** (ce n'est pas le focus ici)
                data = [
                    ...data.filter(d => d.state_id < parseInt(sort)).sort(stateThenName),
                    ...data.filter(d => d.state_id >= parseInt(sort)).sort(stateThenName),
                ]
            }
        }

        this.y.domain(data.map(d => d.id));
    }

    // Handle le change d'un filtre (refetch datas + redraw).
    handleFiltersChange(key, value) {
        const filters = { ...this.state.filters, [key]: value }

        this.setState({ filters }, () => {
            localStorage.setItem('status[filters]', JSON.stringify(filters))
            this.fetchData()
        })
    }

    // Handle le reset des filtres (refetch datas + redraw).
    handleFiltersReset() {
        const filters = { type: this.state.types[0].id, year: moment().format('YYYY'), regions: [] }

        this.setState({
            filters,
            sort: 'organization',
            sort_asc: true
        }, () => {
            localStorage.setItem('status[filters]', JSON.stringify(filters))
            this.fetchData()
        })
    }

    // Render le Header de l'évolution.
    renderHeader() {
        const { states: states_list, sort, sort_asc } = this.state;
        const { type } = this.state.filters;
        if (!type || !states_list) return <></>;

        // États + Analyses pour le type de demande sélectionné.
        const states_for_current_type = STATES_FOR_TYPE[type];
        const analyzes_for_current_type = ANALYZES_FOR_TYPE[type];

        // Icon de sort à afficher à côté de chaque col en fonction du state.
        const sort_icon = (contextual_sort) => {
            if (contextual_sort == sort)
                return (sort_asc) ? 'arrow_drop_up' : 'arrow_drop_down'
            return ''
        }

        // Setup du html des Analyses
        const artefacts = (
            <div className="section-artefacts">
                {
                    analyzes_for_current_type.map(analyze_id => (
                        <span key={ analyze_id }>
                            <Tooltip title={ ANALYZES_DISPLAY[analyze_id].label }>
                                <Icon>{ ANALYZES_DISPLAY[analyze_id].icon }</Icon>
                            </Tooltip>
                        </span>
                    ))
                }
            </div>
        )


        return (
            <Fragment>
                <div className="header-buffer" onClick={ () => this.handleSort('organization') }>
                    <Icon>{ sort_icon('organization') }</Icon>
                    Organismes
                </div>
                <div className="header-sections">
                    { states_for_current_type.map((state_id, i) => {
                        var state = states_list.find(s => s.id === state_id);
                        var is_analyze_state =  state_id === REQ_STATE_ANALYSIS;

                        return (
                            <div key={ i }
                                className={ "section " + (is_analyze_state ? "section-analysis" : "") }
                                style={{ width: `${ 100 / states_for_current_type.length }%` }}
                                onClick={ () => this.handleSort(state_id) }
                                onContextMenu={ (e) => { this.handleSort(state_id, e)} }>

                                { state && (
                                    <Fragment>
                                        <h2 className="section-main-title">
                                            <Icon>{ sort_icon(state_id) }</Icon>
                                            { state.label }
                                        </h2>

                                        { is_analyze_state && (artefacts) }
                                    </Fragment>
                                ) }
                            </div>
                        )
                    }) }
                </div>
            </Fragment>
        )
    }

    // Render du graph.
    // Est bati à tous les changements de datas, par contre l'ordre des rows
    // peut être updatée.
    drawGraph() {
        const { data, filters } = this.state;
        const states = STATES_FOR_TYPE[filters.type]

        // Setup du height.
        const line_height = 45;
        const height = line_height * data.length;

        // Setup du domain + range Y.
        this.y.range([0, height])
        this.setYDomain();

        // Gestion en cas de data vide.
        d3.select(this.refs.status_graph_error)
            .attr('style', `display: ${ (data.length === 0) ? 'block' : 'none' }`)

        d3.select(this.refs.status_header)
            .attr('style', `opacity: ${ (data.length === 0) ? '0.2' : '1' }`)

        // Setup du container du graph.
        let graph = d3.select(this.refs.status_graph)
            .attr('style', `height: ${ height }px`)

        // Cleanup du dernier graph.
        graph.selectAll(".status-line").remove()

        // Si data vide, le reste n'est pas pertinent.
        if (data.length === 0) {
            this.setState({ loading: false })
            return;
        }


        // -------------------------------
        // ----- STATUT DES DEMANDES -----
        // -------------------------------

        // Fonction utilitaire qui indique la progression d'une demande à un état donné.
        // Utilisé pour render le "X", le crochet ou les "..." à chaque état.
        const compute_state_progress = (state_id, req_state_id) => {
            if (req_state_id === states[states.length - 1]) return { icon: "done", color: "green" }
            if (state_id > req_state_id) return { icon: "close", color: "red" }
            if (state_id < req_state_id) return { icon: "done", color: "green" }
            return { icon: "more_horiz", color: "white" }
        }

        // Peuplage des rows.
        let status_rows = graph
            .selectAll(".status-line")
            .data(data)

        let status_row = status_rows.enter()
            .append("div")
            .attr("class", "status-line")
            .attr("style", d => `top: ${ this.y(d.id) }px`)

        // Nom + lien vers l'Org.
        status_row.append("a")
            .attr("class", "status-line-title")
            .attr("href", d => `/#/requests/${ d.id }`)
            .html(d => {
                return `<span>${ d.organization.name }</span><small>${ d.organization.region }</small>`
            })

        // Blocs d'évolution d'une row.
        let status_row_sections = status_row.append("div")
            .attr("class", d => `status-line-sections state-${ states.indexOf(d.state_id) } ${ states[states.length - 1] === d.state_id && 'complete' }`)
            .selectAll('.section')
            .data(d => states.map(state_id => ({ contextual_state_id: state_id, request: d }) ))

        let status_row_section = status_row_sections.enter()
            .append("div")
            .attr("style", `width: ${ 100 / states.length }%`)
            .attr("class", "section")

        // Ajout de l'icône pour tous les états.
        status_row_section.append("a")
            .attr("href", d => `/#/requests/${ d.request.id }`)
            .attr("class", d => `material-icons MuiIcon-root ${ compute_state_progress(d.contextual_state_id, d.request.state_id).color }`)
            .text(d => compute_state_progress(d.contextual_state_id, d.request.state_id).icon)

        // Sélection de la colonne "En analyse" pour ajouter les artéfacts.
        let status_row_section_artefacts = status_row_section.filter(d => d.contextual_state_id === REQ_STATE_ANALYSIS)
            .classed("section-analysis", true)
            .append("div")
            .attr("class", "section-artefacts")

        let status_row_section_artefact = status_row_section_artefacts.selectAll('.artefact')
            .data(d => {
                return ANALYZES_FOR_TYPE[filters.type].map(type_id => {
                    var analyze = (d.request.analyzes || []).find(a => a.type_id === type_id) || {}
                    return { contextual_an_type_id: type_id, analyze }
                })
            })

        status_row_section_artefact.enter()
            .append("a")
            .attr("href", d => {
                // Gestion du lien vers l'artefact.
                if (!d.analyze.id) return '/#/status'
                if (d.analyze.type_id === AN_TYPE_SUMMARY)
                    return `/#/requests/${ d.analyze.request_id }/summary`
                else
                    return `/#/requests/${ d.analyze.request_id }/analysis/${ d.analyze.id }`
            })
            .attr("class", d => {
                // Gestion de la couleur de l'artefact + setup de la classe.
                let base = "artefact material-icons MuiIcon-root";
                if (d.analyze.state_id === AN_STATE_PENDING) return `${ base } red`
                if (d.analyze.state_id === AN_STATE_PROGRESS) return `${ base } white`
                if (d.analyze.state_id === AN_STATE_COMPLETED) return `${ base } green`
                return `${ base } red`
            })
            .text(d => {
                // Gestion de l'icône de l'artefact.
                if (d.analyze.state_id === AN_STATE_PENDING) return "close"
                if (d.analyze.state_id === AN_STATE_PROGRESS) return "more_horiz"
                if (d.analyze.state_id === AN_STATE_COMPLETED) return "done"
                return "close"
            })

        // On set un timeout pour animer le progress bar après le render du graph.
        // On met aussi state.loading à false.
        setTimeout(function () {
            graph.selectAll(".status-line-sections ")
                .classed("animate", true)

            this.setState({ loading: false })
        }.bind(this), 100)
    }

    // Update le graph (un simple re-order en fonction des
    // paramètres de sort dans le state).
    updateGraph() {
        this.setYDomain();

        d3.selectAll(".status-line")
            .transition()
            .attr("style", d => `top: ${ this.y(d.id) }px`)
    }

    render() {
        const { loading, types, filters } = this.state;

        let regions = [];
        for (let region in regionLookups)
            regions.push(<Select.Option key={ region } value={ region }>{ regionLookups[region] }</Select.Option>)

        return (
            <Fragment>
                <Helmet>
                    { title('Statut des demandes') }
                </Helmet>

                <h1>Statut des demandes</h1>

                <div className="status-page">
                    <div className="status-filters">
                        <div className="ctn">
                            <label>Choix du fond :</label>
                            <Select showSearch
                                value={ filters.type }
                                onChange={ (value) => this.handleFiltersChange('type', value) }>
                                { (types || []).map(t => <Select.Option key={ t.id } value={ t.id }>{ t.label }</Select.Option> )}
                            </Select>
                        </div>

                        <div className="ctn">
                            <label>Régions :</label>
                            <Select showSearch allowClear
                                mode="multiple"
                                value={ filters.regions }
                                onChange={ (value) => this.handleFiltersChange('regions', value) }>
                                { regions }
                            </Select>
                        </div>

                        <div className="ctn">
                            <label>Année :</label>
                            <Select showSearch
                                value={ filters.year }
                                onChange={ (value) => this.handleFiltersChange('year', value) }>
                                { renderSelectYears(0, 10) }
                            </Select>
                        </div>

                        <Button onClick={ () => this.handleFiltersReset() }>Réinitialiser les filtres</Button>
                    </div>

                    <Spin spinning={ loading }>
                        <div className="status-graph">
                            <div className="status-header" ref="status_header">{ this.renderHeader() }</div>
                            <div className="status-body" ref="status_graph"></div>
                            <div className="status-body-error" ref="status_graph_error">Aucune demande pour les filtres sélectionnés.</div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        )
    }
}

export default StatusPage;
