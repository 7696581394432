import React from 'react';

const ToggleColumns = ({allColumns, noCheckName, checkName}) => {
  return (
    <div className="togglecolumns">
        <label className="switch">
            <input type="checkbox" defaultChecked={allColumns}></input>
            <span className="slider round"></span>
            <span className='switchlabel'>{allColumns === true ? checkName : noCheckName}</span>
        </label>
    </div>
  )
}

export default ToggleColumns