// class Answer {
//     constructor(optionId, value, details) {
//         this.optionId = optionId;
//         this.value = (value) ? value : null;
//         this.details = (details) ? value : null;
//     }
//
//     getValue() { return this.value; }
//     setValue(v) { this.value = v; }
//     getDetails() { return this.details; }
//     setDetails(d) { this.details = d; }
// }
//
// export { Answer };

export function Answer(optionId, value, detail) {
    return {
        [optionId] : {
            value: (value !== undefined) ? value : null,
            detail: (detail !== undefined) ? detail : null
        }
    }
}

export function formatAnswers(answers, sourceId, isAnalyse=false) {
    var formatted = [];

    for (var id in answers) {
        if (!answers.hasOwnProperty(id)) continue;
        var a = answers[id];
        if (Array.isArray(a.value)) {
            // TODO: gérer les arrays dans arrays vides (custom option vide)
            a.value = a.value.filter(el => {
                var isEmpty = true;
                if(Array.isArray(el)) {
                    el.forEach(subEl => {
                        if(subEl &&subEl !== null && subEl != '') isEmpty = false;
                    });
                }
                else if (el && el.hasOwnProperty('name') && el.name === "Autres") {
                    el.value = el.value.filter(subEl => (subEl[0] !== "" || parseInt(subEl[1]) !== 0));
                    isEmpty = false;
                }
                else {
                    isEmpty = false;
                }
                return !isEmpty;
            })
            a.value = JSON.stringify(a.value)
        }

        formatted.push({
            option_id: parseInt(id),
            [(isAnalyse ? 'analyze_id' : 'request_id')]: sourceId,
            value: a.value,
            details: a.detail
        })
    }

    return formatted;
}
