import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { Can, AN_STATE_PENDING, AN_STATE_PROGRESS, AN_STATE_COMPLETED } from '../../constants';
import { title, parseArrayString, progress } from '../../utils';
import { analyzeService, requestService } from '../../services';
import { Form } from '../../components/forms';
import { formActions } from '../../actions';
import { formatAnswers } from '../../models';
import { connect } from 'react-redux';
import { BackButton } from '../../components';

import * as requestsController from '../../constants/requestStates';
import * as analyzesController from '../../constants/analyzeStates';


class RequestSummaryPage extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params
        this.state = {
            summary: { ...analyzesController.injectFunctions() },
            request: { ...requestsController.injectFunctions(), __type: 'Request' },
            requestId: id,
        }

        this.fetchAnswers = this.fetchAnswers.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.formatAnswers = this.formatAnswers.bind(this);
    }

    componentDidMount() {
        analyzeService.getSummary(this.state.requestId).then(result => {
            var s = result[0]
            this.setState({
                summary: { ...this.state.summary, ...s,
                request: { __type: 'Request', ...requestsController.injectFunctions(), ...s.request } },
                financialYear: s.request.financial_year,
                loaded: (result)
            });

            requestService.get(this.state.requestId).then((r) => {
                this.setState({ request: { ...this.state.request, ...r } })
            }).catch((e) => {
                this.setState(() => { throw new Error(e); })
            })
        })
        .catch(error => {
            this.setState(() => { throw new Error(error); })
        });
    }

    fetchAnswers() {
        return this.props.fetchAnswers(this.state.summary.id)
    }

    saveForm(answers) {
        const { summary } = this.state;
        var progressing = (summary.isPending() && answers && answers.length > 0);

        return new Promise((resolve, reject) => {
            this.props.saveForm(answers, summary.id).then((r) => {
                var state;
                if (summary.isPending() && answers && answers.length > 0) {
                    state = analyzesController.progress()
                } else if (!summary.isPending()) {
                    const { percentage } = progress(this.props.content.sections, this.props.answers);
                    if (summary.isInProgress() && percentage >= 100)
                        state = analyzesController.submitted()
                    if (summary.isSubmitted() && percentage < 100)
                        state = analyzesController.progress()
                }

                if (state)
                    analyzeService.update({ id: summary.id, state_id: state })
                        .then(a => this.setState({ summary: { ...summary, ...a, request: summary.request } }))

                resolve(r);
            }).catch(error => {
                reject(error);
            });
        });
    }

    formatAnswers(answers) {
        return this.props.formatAnswers(answers, this.state.summary.id)
    }

    render() {
        const { summary, loaded, request, financialYear } = this.state;

        const statusBar = (
            <ul>
                <li>Demande : <b><Link to={ `/requests/${request.id}` }>{ (request.request_type || {}).label }</Link></b></li>
                <li>Organisme : <b><Link to={ `/organisations/${request.organization_id}` }>{ (request.organization || {}).name }</Link></b></li>
                <li>Statut : <b>{ summary.state_id === AN_STATE_PENDING ? 'Débuté' : (summary.state_id === AN_STATE_PROGRESS ? 'En cours' : 'Terminée') }</b></li>
            </ul>
        )

        return (
            <Fragment>
                <Helmet>
                    { title('Résumé d\'une demande') }
                </Helmet>

                <div className="requests-color summary-wrap">
                    <BackButton />
                    <h1>Fiche synthèse</h1>
                    <Can do={ "read_summary" } on={ "Request" }>
                        { (loaded) ? (
                            <Can do={ "edit_summary" } on={ summary.request } passThrough>
                                { can => (
                                    <Form
                                        formId={ (summary.analyze_type || {}).form_id }
                                        financialYear={ financialYear }
                                        sourceId={ summary.id }
                                        orgId={ summary.organization_id }
                                        readonly={ (can) ? (!summary.request.isInAnalysis()) : true }
                                        print={ this.props.print === true }
                                        summary={ true }
                                        fetchForm={ this.props.fetchForm }
                                        fetchAnswers={ this.fetchAnswers }
                                        saveForm={ this.saveForm }
                                        formatAnswers={ this.formatAnswers }
                                        statusBar={ statusBar } />
                                ) }
                            </Can>
                        ) : <div></div> }
                    </Can>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        content: state.form.base,
        answers: state.form.answers,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { id } = ownProps.match.params
    return {
        fetchForm: (formId, financialYear) => dispatch(formActions.fetchForm(formId, id, financialYear)),
        fetchAnswers: (analyzeId) => dispatch(formActions.fetchAnswers(analyzeId, true)),
        saveForm: (answers, analyzeId) => dispatch(formActions.saveForm(answers, analyzeId, true)),
        formatAnswers: (answers, analyzeId) => formatAnswers(answers, analyzeId, true)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestSummaryPage);
