import React, { Component } from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Skeleton, Tooltip } from "antd";
import { materialTableProps, formatYear, loadDefaultFilter, ResetButton, parseArrayString } from "../utils";
import { isArray } from "util";
import { Switch} from 'antd';
import { request } from "http";
import { Toggle } from "./forms/inputs";
import ToggleColumns from "./dashboard/ToggleColumns";


const width = (type_id) => {
    switch (type_id) {
        case 1: return '400px'
        case 2: return '200px'
        case 3: return '300px'
        case 4: return '300px'
        case 5: return '300px'
        case 6: return '300px'
        case 7: return '300px'
        case 8: return '300px'
        case 9: return '300px'
        case 10: return '300px'
        case 11: return '300px'
        case 12: return '300px'
        case 13: return '300px'
        case 14: return '300px'
        case 15: return '200px'
        case 16: return '250px'
        case 17: return '700px'
        default: return '300px'

    }
}

function findMaxAnswers(body, question_id) {
    const allAnswers = body.map(request => request[question_id]).filter(r => !!r)
    const answerCounts = allAnswers.map(r => {
        const firstValue = Object.values(r)[0]
        if (!firstValue) return 0

        try {
            const answers = parseArrayString(firstValue)
            return answers.length
        } catch (e) {
            return 0
        }
    })

    return Math.max(...answerCounts)
}


class AnswerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            column: [],
            ans: [],
            colors: ["red", "green", "yellow", "grey", "cyan", "purple"],
            loading: true,
            allColumns: false,
        };
        this.updateAllCollumn = this.updateAllCollumn.bind(this);
        this.updateLoading = this.updateLoading.bind(this);
    }

    componentDidMount() {
        const { id_type, year, regions, states, orga_name, demande_display } = this.props;

        this.props.service.getReporting(id_type, year, regions, states).then(result => {
            var col = [];

            if (orga_name){
                if(!this.findIfEmpty('organization_name', result)) {
                    col = [ this.renderHeaderType({ id: 'orga', label: "Nom de l'organisme" }, year) ];
                }
            }

            if (demande_display) {
                if(!this.findIfEmpty('conclusion_color', result)) col.push(this.renderHeaderType({ id: 'color', label: "Couleur demande" }, year));
                if(!this.findIfEmpty('conclusion_amount_recommended', result)) col.push(this.renderHeaderType({ id: 'amount_recommended', label: "Montant recommandé" }, year));
                if(!this.findIfEmpty('conclusion_amount_final', result)) col.push(this.renderHeaderType({ id: 'amount_final', label: "Montant final" }, year));
            }   
             
            if(!this.findIfEmpty('request_state', result)) col.push(this.renderHeaderType({ id: 'request_state', label: "État de la demande" }, year));

            /*
            /   header (entête colonne) : id (question_id), type_id (question_type), label (question_label),  options, question_number, fields
            /   body (contenu) : string --> answer || object --> (option_id, answer)
            */
            (result.header || []).forEach(h => {
                 if(!this.findIfEmpty(h.id, result)) {
                
                    var c = this.renderHeaderType(h, year);

                    if (!isArray(c)) {
                        if (typeof c === 'function') {
                            const maxAnswers = findMaxAnswers(result.body, h.id)
                            for (let i = 0; i < maxAnswers; i++) {
                                col.push(loadDefaultFilter(this.props.tableKey, c(i)));
                            }
                        } else {
                            col.push(loadDefaultFilter(this.props.tableKey, c));
                        }
                    }
                    else {
                        c.forEach(el => col.push(loadDefaultFilter(this.props.tableKey, el)))
                    }
                }
            });

            var body = [];

            (result.body || []).forEach(request => {
                var r = {};

                if (orga_name)
                    r.orga = request.organization_name

                if (demande_display){
                    r.color = request.conclusion_color
                    r.amount_recommended = request.conclusion_amount_recommended
                    r.amount_final = request.conclusion_amount_final
                }
                Object.entries(request).forEach(([i, cell]) => {
                    r = this.renderCellType(result.header, cell, i, r);
                });
                body.push(r);
            });

            this.setState({ column: col, ans: body, loading: false });
        })
        .catch(e => {
            this.setState(() => { throw new Error(e); })
        });
    }

    renderHeaderType(h, year) {
        var col,
            options = (title) => {
                return {
                    title: title,
                    hidden: true,
                    tableData: true,
                    cellStyle: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        minWidth: width(h.type_id),
                        maxWidth: width(h.type_id)
                    }
                }
            }

        if (h.type_id === 10 && h.options && h.options[0] && h.options[0].custom) {
            const option = h.options[0];
            return index => (
                {
                    field: `${h.id}_${option.id}_${index}`,
                    ...options(`${h.question_number ? `${h.question_number}.` : ''} ${formatYear(`${h.label}`, year)} [${index + 1}]`)
                }
            )
        }
        else if (h.type_id === 10 || h.type_id === 13) {
            const f = parseArrayString(h.fields);
            const o = h.options;
            col = [];

            // Génération entête : option_label (field_title)
            o.forEach(option => {
                f.forEach((field, i) => {
                    if (field.type !== "title") {
                        col.push({
                            field: `${h.id}_${option.id}_${i}`,
                            ...options(`${h.question_number ? `${h.question_number}.` : ''} ${formatYear(`${option.label} ${field.title ? `(${field.title})` : ""}`, year)}`)
                        });
                    }
                });
            });
        }
        else if (h.type_id === 16) {
            const f = parseArrayString(h.fields);
            col = [];

            f.forEach((field, i) => {
                field.options.forEach((subField, j) => {
                    col.push({
                        field: `${h.id}_${i}_${j}`,
                        ...options(`${h.question_number}. ${field.categorie_name[0].toUpperCase()}${field.categorie_name.substr(1)} -  ${subField[0].toUpperCase()}${subField.substr(1)}`)
                    })
                })
            })
        }
        else {
            col = {
                field: h.id.toString(),
                ...options(`${h.question_number ? `${h.question_number}.` : ''} ${formatYear(h.label, year)}`)
            }
        }
        return col;
    }

    renderCellType(header, cell, id, r) {
        const h = header.filter(head => head.id.toString() === id)[0];
        const type_id = h ? h.type_id : h;

        switch (type_id) {
            case 10: // Table
                return { ...r, ...this.renderTable(h.id, cell) };
            case 13: // TableMixed
                return { ...r, ...this.renderTable(h.id, cell) };
            case 16: // CheckboxList
                return { ...r, ...this.renderCheckboxList(h.id, h.fields, cell) };
            default:
                return { ...r, ...{ [id]: cell } };
        }
    }

    renderTable(id, cells) {
        var cellValues = {};
        Object.entries(cells).forEach(([option_id, c]) => {
            const value = parseArrayString(c);
            if (value === null)
                return

            value.forEach((val, i) => {
                if (val === null) return
                if (isArray(val)) {
                    cellValues[`${id}_${option_id}_${i}`] = val.join(', ');
                } else {
                    cellValues[`${id}_${option_id}_${i}`] = val;
                }
            });
        });

        return cellValues;
    }

    renderCheckboxList(id, fields, cells) {
        const f = parseArrayString(fields);
        const c = parseArrayString(cells);
        var cellValues = {};

        f.forEach((field, i) => {
            const result = []
            const values = c.filter(cell => cell.name === field.categorie_name)[0].value;
            values.forEach((v, j) => {
                if (!isArray(v)) {
                    if (v === "1" || v === "2") {
                        //result.push(`${field.options[i]}(${v})`);
                        cellValues[`${id}_${i}_${j}`] = v
                    }
                }
                else {
                    result.push(`${v[0]}(${v[1]})`);
                }
            });
        });
        return cellValues;
    }

    findIfEmpty(id, result) {
        let empty = true;
        let i = 0;
        while(empty === true && i < result.body.length) {      
            if(result.body[i][id] !== undefined && result.body[i][id] !== null && result.body[i][id] !== "t" && result.body[i][id] !== "f" && result.body[i][id] !== "" ) {
                empty = false;
            }
            i++;
        }
        return empty;
    }

    updateAllCollumn() {
        let updateLoadingState = {...this.state};
        updateLoadingState.column.forEach(col => {
            col.hidden = updateLoadingState.allColumns;
        })
        this.setState({...updateLoadingState, allColumns: !updateLoadingState.allColumns});
    }

    updateLoading(loading = true) {
        this.setState({...this.state, loading: loading})
        if(loading) {
            setTimeout(() => {this.updateAllCollumn()}, 1)
            setTimeout(() => {this.updateLoading(false)}, 1)
        }
    }

    render() {
        const { column, ans, loading, allColumns } = this.state;

        if (ans.length === 0 && !loading) {
            return <div className="error">Aucun résultat...</div>
        }
        return (                
                <Skeleton loading={ this.state.loading }>
                    <ResetButton tableKey={ this.props.tableKey } />
                    <MaterialTable
                        title={this.props.title}
                        { ...materialTableProps(this.props.tableKey, this.props.title) }
                        columns={ column }
                        data={ ans }
                        actions={[{
                            icon:() => <ToggleColumns allColumns={allColumns} noCheckName='Aucun' checkName='Tous'/>,
                            iconProps: { className: 'main' },
                            tooltip: 'Afficher toutes les colonnes',
                            isFreeAction: true,
                            onClick: (event) => this.updateLoading()
                        }]
                        }

                        components={ {
                            Cell: props => (
                                <Tooltip placement="topLeft" title={ props.value }>
                                    <MTableCell { ...props } />
                                </Tooltip>
                            )
                        } }
                    />
                </Skeleton>
        );
    }
}

export default AnswerTable;
