import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { Icon } from '@material-ui/core';
import { Prompt } from 'react-router';
import { Can, ABILITIES } from "../../constants";
import { title, numberFormatProps, history } from "../../utils";
import { BackButton } from '../../components';
import NumberFormat from 'react-number-format';
import { financialService } from '../../services';
import { isNull } from 'util';
import { Select, notification } from 'antd';
import { financialActions } from '../../actions';
import { connect } from 'react-redux';


var checkMath = function (calc, round = false) {
    return (!isNaN(calc) && isFinite(calc) && !isNull(calc)) ? (round ? parseInt(calc) : parseFloat(calc.toFixed(2))) : 0
}

class OrganisationFinancialSummaryPage extends Component {
    constructor(props) {
        super(props);
        var { id, year } = this.props.match.params

        year = parseInt(year);
        isNaN(year) && history.goBack();

        this.state = { id: id, year: year, sum: [], org: {}, isUpToDate: true }

        this.getValue = this.getValue.bind(this);
        this.getIncomeTotal = this.getIncomeTotal.bind(this);
        this.getOutcomeTotal = this.getOutcomeTotal.bind(this);
        this.getSurplusTotal = this.getSurplusTotal.bind(this);
        this.getBalanceTotal = this.getBalanceTotal.bind(this);
        this.getOtherTotal = this.getOtherTotal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        var { id, year } = this.props.match.params;
        year = parseInt(year);

        this.load(id, year);

        window.onbeforeunload = function (event) {
            if (!this.state.isUpToDate)
                return 'Refresh';
        }.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        var { id, year } = nextProps.match.params;
        year = parseInt(year);

        this.load(id, year);
    }

    load(id, year) {
        isNaN(year) && history.goBack();
        var abilities = ABILITIES[this.props.rank];
        this.setState({ readOnly: !abilities.can('edit_financial_summary', 'Request') })

        financialService.getSummary(id, year)
            .then(result => {
                if (result.length === 0) {
                    this.setState({ readOnly: true });
                }

                const org = result[0] ? result[0].organization : {};
                result.map(function (el) {
                    delete el.id;
                    delete el.organization;
                    delete el.financial_analyze_type
                    return el;
                });
                const sum = [
                    result.filter(el => el.year === year - 2)[0],
                    result.filter(el => el.year === year - 1)[0],
                    result.filter(el => (el.year === year && el.type_id === 1))[0],
                    result.filter(el => (el.year === year && el.type_id === 4))[0],
                    result.filter(el => (el.year === year && el.type_id === 5))[0]
                ];

                this.setState({
                    sum: sum,
                    org: org,
                    id: id,
                    year: year
                });
            });
    }

    getValue(cellKey, rowKey, isCheck = true) {
        const { sum } = this.state;
        var value = null;
        rowKey = rowKey.replace(new RegExp('-', 'g'), '_');

        if (sum[cellKey]) {
            value = sum[cellKey][rowKey];
        }

        return isCheck ? checkMath(value) : value;
    }

    getOrgValue(key) {
        const { org } = this.state;
        if (!org) return;
        return org[key];
    }

    getIncomeTotal(cellKey) {
        const income_key = ['income-centraide', 'income-gvt-provincial-total', 'income-gvt-federal-total',
            'income-gvt-municipal-total', 'income-subvention-total', 'income-operation-total', 'income-amortization'];
        var value = 0;

        income_key.forEach(rowKey => {
            value += this.getValue(cellKey, rowKey);
        });

        return checkMath(value);
    }

    getOutcomeTotal(cellKey) {
        const outcome_key = ['outcome-salary-total', 'outcome-service-total', 'outcome-housing-total',
            'outcome-other-total', 'outcome-amortization'];
        var value = 0;

        outcome_key.forEach(rowKey => {
            value += this.getValue(cellKey, rowKey);
        });

        return checkMath(value);
    }

    getSurplusTotal(cellKey) {
        return checkMath(this.getIncomeTotal(cellKey) - this.getOutcomeTotal(cellKey));
    }

    getBalanceTotal(cellKey) {
        const balance_key = ['balance-immobilisation-fund', 'balance-free-affectation', 'balance-affected'];
        var value = 0;

        balance_key.forEach(rowKey => {
            value += this.getValue(cellKey, rowKey);
        });

        return checkMath(value);
    }

    getOtherTotal(cellKey) {
        return checkMath(this.getValue(cellKey, 'income-centraide') / this.getIncomeTotal(cellKey) * 100);
    }

    handleChange(e) {
        const { id, value } = e.target;
        var sum = JSON.parse(JSON.stringify(this.state.sum));
        var [rowKey, cellKey] = id.split('_');
        rowKey = rowKey.replace(new RegExp('-', 'g'), '_');
        cellKey = parseInt(cellKey);
        var val = value.length > 1 ? parseFloat(value.slice(0, value.length - 1).replace(new RegExp(' ', 'g'), '').replace(',', '.')) : value.length !== 0 ? value : 0;

        if (sum[cellKey]) {
            sum[cellKey][rowKey] = val;
            sum[cellKey]["balance_fund_total"] = this.getBalanceTotal(cellKey);
        }

        this.setState({
            sum: sum,
            isUpToDate: false
        });
    }

    handleSubmit() {
        this.props.saveTable(this.state.sum).then(() => {
            notification.open({
                message: 'Enregistrement effectué',
                description: 'Vos changements ont été enregistrés avec succès.',
                placement: 'bottomRight',
            });
            this.setState({ isUpToDate: true });
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
            this.setState({ isUpToDate: false });
        })
    }

    generateCell(cellKey, rowKey, value, suffix = "$", isInput = false) {
        const { readOnly } = this.state;
        return (
            <td key={cellKey}>
                <NumberFormat
                    {...numberFormatProps()}
                    id={rowKey + '_' + cellKey}
                    suffix={suffix}
                    displayType={isInput && !readOnly ? "input" : "text"}
                    value={value}
                    decimalScale={2}
                    onChange={(e) => this.handleChange(e)} />
            </td>
        )
    }

    generateRow(rowInfo) {
        const { title, key, valueFunc, totalFunc, haveMiddle, haveEcart, haveEcartPercentage, havePart, isTotal, isPercentage, haveMiddleInput, classes } = rowInfo;
        var rows = [];

        for (let i = 0; i < 5; i++) {
            rows.push(
                ((haveMiddle && i >= 3) || i < 3) ? this.generateCell(i, key, valueFunc(i, key), isPercentage ? '%' : '$', ((haveMiddleInput && i === 2) && !isTotal)) : <td key={i}></td>
            );
        }

        const ecart = checkMath(valueFunc(4, key) - valueFunc(3, key));
        const ecart_pourcentage = checkMath(ecart / valueFunc(3, key) * 100);
        const part = checkMath(valueFunc(3, key) / totalFunc(3) * 100)

        if (haveEcart) {
            rows.push(
                this.generateCell(5, key, ecart)
            );
        }

        if (haveEcartPercentage) {
            rows.push(
                this.generateCell(6, key, ecart_pourcentage, '%')
            );
        }

        if (havePart) {
            rows.push(
                this.generateCell(7, key, part, '%')
            );
        }

        return (
            <tr className={(isTotal ? "row-total" : "row-data") + (classes ? (' ' + classes) : '')}>
                <td className="cell-title">{title}</td>
                {rows}
            </tr>
        );
    }

    generateIncomeRow(rowTitle, rowKey) {
        return this.generateRow(
            {
                title: rowTitle,
                key: rowKey,
                valueFunc: this.getValue,
                totalFunc: this.getIncomeTotal,
                haveMiddle: true,
                haveEcart: true,
                haveEcartPercentage: true,
                havePart: true,
                isTotal: false,
                isPercentage: false,
                haveMiddleInput: false,
                classes: 'first-lines'
            }
        );
    }

    generateTotalIncomeRow() {
        return this.generateRow(
            {
                title: "Produits-Grand total",
                key: 'income-total',
                valueFunc: this.getIncomeTotal,
                totalFunc: this.getIncomeTotal,
                haveMiddle: true,
                haveEcart: true,
                haveEcartPercentage: true,
                havePart: true,
                isTotal: true,
                isPercentage: false,
                haveMiddleInput: false,
                classes: 'first-lines'
            }
        );
    }

    generateOutcomeRow(rowTitle, rowKey) {
        return this.generateRow(
            {
                title: rowTitle,
                key: rowKey,
                valueFunc: this.getValue,
                totalFunc: this.getOutcomeTotal,
                haveMiddle: true,
                haveEcart: true,
                haveEcartPercentage: true,
                havePart: false,
                isTotal: false,
                isPercentage: false,
                haveMiddleInput: false,
                classes: 'second-lines'
            }
        );
    }

    generateTotalOutcomeRow() {
        return this.generateRow(
            {
                title: "Charges-Grand total",
                key: 'outcome-total',
                valueFunc: this.getOutcomeTotal,
                totalFunc: this.getOutcomeTotal,
                haveMiddle: true,
                haveEcart: true,
                haveEcartPercentage: true,
                havePart: false,
                isTotal: true,
                isPercentage: false,
                haveMiddleInput: false,
                classes: 'second-lines'
            }
        );
    }

    generateTotalSurplusRow() {
        return this.generateRow(
            {
                title: "Excédent (insuffisance)",
                key: 'surplus-total',
                valueFunc: this.getSurplusTotal,
                totalFunc: this.getSurplusTotal,
                haveMiddle: true,
                haveEcart: true,
                haveEcartPercentage: true,
                havePart: false,
                isTotal: true,
                isPercentage: false,
                haveMiddleInput: false,
                classes: 'second-lines'
            }
        );
    }

    generateBalanceRow(rowTitle, rowKey) {
        return this.generateRow(
            {
                title: rowTitle,
                key: rowKey,
                valueFunc: this.getValue,
                totalFunc: this.getBalanceTotal,
                haveMiddle: false,
                haveEcart: false,
                haveEcartPercentage: false,
                havePart: false,
                isTotal: false,
                isPercentage: false,
                haveMiddleInput: true,
                classes: 'soldes'
            }
        );
    }

    generateTotalBalanceRow() {
        return this.generateRow(
            {
                title: "Total du solde des fonds",
                key: 'balance-total',
                valueFunc: this.getBalanceTotal,
                totalFunc: this.getBalanceTotal,
                haveMiddle: false,
                haveEcart: false,
                haveEcartPercentage: false,
                havePart: false,
                isTotal: true,
                isPercentage: false,
                haveMiddleInput: false,
                classes: 'soldes'
            }
        );
    }

    generateOtherRow(rowTitle, rowKey, isPercentage=false) {
        return this.generateRow(
            {
                title: rowTitle,
                key: rowKey,
                valueFunc: this.getValue,
                totalFunc: this.getOtherTotal,
                haveMiddle: false,
                haveEcart: false,
                haveEcartPercentage: false,
                havePart: false,
                isTotal: false,
                isPercentage: isPercentage,
                haveMiddleInput: true,
                classes: 'others'
            }
        );
    }

    generateTotalOtherRow() {
        return this.generateRow(
            {
                title: "Part relative (%) de Centraide par rapport aux produits",
                key: 'other-total',
                valueFunc: this.getOtherTotal,
                totalFunc: this.getOtherTotal,
                haveMiddle: true,
                haveEcart: false,
                haveEcartPercentage: false,
                havePart: false,
                isTotal: true,
                isPercentage: true,
                haveMiddleInput: false,
                classes: 'others'
            }
        );
    }

    generateIncome() {
        return (
            <Fragment>
                <tr><th colSpan={9}>Produits</th></tr>
                {this.generateIncomeRow('Centraide', 'income-centraide')}
                {this.generateIncomeRow('Gouvernement provincial', 'income-gvt-provincial-total')}
                {this.generateIncomeRow('Gouvernement fédéral', 'income-gvt-federal-total')}
                {this.generateIncomeRow('Gouvernement municipal', 'income-gvt-municipal-total')}
                {this.generateIncomeRow('Subventions et dons privés', 'income-subvention-total')}
                {this.generateIncomeRow('Revenus d\'opération et autofinancement', 'income-operation-total')}
                {this.generateIncomeRow('Amortissement des revenus', 'income-amortization')}
                {this.generateTotalIncomeRow()}
            </Fragment>
        );
    }

    generateOutcome() {
        return (
            <Fragment>
                <tr><th colSpan={9}>Charges</th></tr>
                {this.generateOutcomeRow('Masse salariale', 'outcome-salary-total')}
                {this.generateOutcomeRow('Activités et services', 'outcome-service-total')}
                {this.generateOutcomeRow('Logements', 'outcome-housing-total')}
                {this.generateOutcomeRow('Autres dépenses', 'outcome-other-total')}
                {this.generateOutcomeRow('Amortissement des dépenses', 'outcome-amortization')}
                {this.generateTotalOutcomeRow()}
                {this.generateTotalSurplusRow()}
            </Fragment>
        );
    }

    generateBalance() {
        return (
            <Fragment>
                <tr><th colSpan={9}>Solde des fonds</th></tr>
                {this.generateBalanceRow('Solde des fonds d\'immobilisation', 'balance-immobilisation-fund')}
                {this.generateBalanceRow('Solde libre d\'affectation', 'balance-free-affectation')}
                {this.generateBalanceRow('Solde affecté', 'balance-affected')}
                {this.generateTotalBalanceRow()}
            </Fragment>
        );
    }

    generateOther() {
        return (
            <Fragment>
                <tr><th colSpan={9}>Autres informations</th></tr>
                {this.generateOtherRow('Actif total', 'total-actif')}
                {this.generateOtherRow('% des actifs nets non affectés sur les dépenses', 'non-affected-actif-percentage-2', true)}
                {this.generateTotalOtherRow()}
            </Fragment>
        )
    }

    generateBody() {
        return (
            <tbody>
                {this.generateIncome()}
                {this.generateOutcome()}
                {this.generateBalance()}
                {this.generateOther()}
            </tbody>
        );
    }

    generateHead() {
        const { year } = this.state;

        return (
            <thead>
                <tr>
                    <th></th>
                    <th>États financiers</th>
                    <th>États financiers</th>
                    <th>États financiers</th>
                    <th>Budget année en cours</th>
                    <th>Budget projeté</th>
                    <th>Écarts entre budget année en cours et budget projeté</th>
                    <th>% entre les résulats du budget année en cours et le budget projeté</th>
                    <th>Part relative (%) par rapport aux produits</th>
                </tr>
                <tr>
                    <th></th>
                    <th>{year - 3}-{year - 2}</th>
                    <th>{year - 2}-{year - 1}</th>
                    <th>{year - 1}-{year}</th>
                    <th>{year}-{year + 1}</th>
                    <th>{year + 1}-{year + 2}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
        );
    }

    generateTable() {
        return (
            <table>
                {this.generateHead()}
                {this.generateBody()}
            </table>
        );
    }

    generateOrgName() {
        return (
            <tr>
                <td>Nom de l'organisme</td>
                <td>{this.getOrgValue("name")}</td>
            </tr>
        );
    }

    generateFinancialYear() {
        const start = this.getValue(2, "financial-year-start", false);
        const end = this.getValue(2, "financial-year-end", false);
        return (
            <tr>
                <td>Année financières de l'organisme</td>
                <td>{`Du ${start ? start : ""} au ${end ? end : ""}`}</td>
            </tr>
        );
    }

    handleSelectChange = name => value => {
        this.handleChange({
            target: {
                id: name,
                value: value
            }
        })
    }

    generateMissionExam() {
        const { readOnly } = this.state;
        var options = [
            <Select.Option key={0} value={1} >Oui</Select.Option>,
            <Select.Option key={1} value={0} >Non</Select.Option>
        ];

        return (
            <tr>
                <td>Mission d'examen</td>
                <td>
                    {!readOnly ? (
                        <Select
                            id="mission-exam_2"
                            onChange={this.handleSelectChange("mission-exam_2")}
                            value={this.getValue(2, "mission-exam", false)} >
                            {options}
                        </Select>
                    ) : (
                            this.getValue(2, "mission-exam", false) ? "Oui" : "Non"
                        )}
                </td>
            </tr>
        );
    }

    generateAccountingFirmName() {
        return (
            <tr>
                <td>Nom de la firme comptable</td>
                <td>{this.getValue(2, "accounting-firm-name", false)}</td>
            </tr>
        );
    }

    generateFinancialState() {
        const { readOnly } = this.state;
        var options = [
            <Select.Option key={0} value={1} >Oui</Select.Option>,
            <Select.Option key={1} value={0} >Non</Select.Option>
        ];

        return (
            <tr>
                <td>États financiers audités</td>

                <td>
                    {!readOnly ? (
                        <Select
                            id="financial-state_2"
                            onChange={this.handleSelectChange("financial-state_2")}
                            value={this.getValue(2, "financial-state", false)} >
                            {options}
                        </Select>
                    ) : (
                            this.getValue(2, "financial-state", false) ? "Oui" : "Non"
                        )}
                </td>
            </tr>
        );
    }

    generateBuildingOwner() {
        return (
            <tr>
                <td>L'organisme est-il propriétaire de l'édifice qu'il occupe</td>
                <td>{this.getOrgValue("building_owner") ? "Oui" : "Non"}</td>
            </tr>
        );
    }

    generateFoundationOwner() {
        return (
            <tr>
                <td>L'organisme a-t-il sa propre fondation</td>
                <td>{this.getOrgValue("foundation_owner") ? "Oui" : "Non"}</td>
            </tr>
        )
    }

    generateHBody() {
        return (
            <tbody>
                {this.generateOrgName()}
                {this.generateFinancialYear()}
                {this.generateMissionExam()}
                {this.generateAccountingFirmName()}
                {this.generateFinancialState()}
                {this.generateBuildingOwner()}
                {this.generateFoundationOwner()}
            </tbody>
        );
    }

    generateHHead() {
        const { year } = this.state;
        return (
            <thead>
                <tr>
                    <th colSpan={2}>Fiche de données financières {year + 1}-{year + 2}</th>
                </tr>
                <tr>
                    <th colSpan={2}>Dossier analysé</th>
                </tr>
            </thead>
        );
    }

    generateHeader() {
        return (
            <table className="table-header">
                {this.generateHHead()}
                {this.generateHBody()}
            </table>
        );
    }

    summaryExist(col) {
        return (
            this.getValue(col, 'balance-fund-total', false) !== null &&
            this.getValue(col, 'total-actif', false) !== null &&
            this.getValue(col, 'non-affected-actif-percentage-2', false) !== null
        );
    }

    buttonHeader() {
        const { old } = this.props;
        const { sum, year, id, readOnly } = this.state;

        const second_financial_missing = !readOnly && !sum[1];
        const second_analysis_missing = !readOnly && !this.summaryExist(1);
        const first_financial_missing = !readOnly && !sum[0];
        const first_analysis_missing = !readOnly && !this.summaryExist(0);

        if (!first_financial_missing && !first_analysis_missing && !second_financial_missing && !second_analysis_missing) return;

        const navigate = (url, summary = false) => {
            if (!summary)
                notification.open({
                    message: 'Édition en retrospéctive des pages financières',
                    description: 'Comme vous êtes en train de bâtir l\'historique des états financiers pour le sommaire de cette année, vous n\'avez qu\'à remplir la colonne "États financiers" de ce tableau.',
                    placement: 'bottomRight',
                    duration: 10
                });
            history.push(url)
        }

        return (
            <Fragment>
                {!old &&
                    <Fragment>
                        <b>Certaines pages financières et/ou analyses doivent être complétées</b>
                        <div className="wrap">
                            <div>
                                <b>{(year - 2)} : </b>
                                <Can do={'edit_financial_page'} on={'Request'}>
                                    <a className={first_financial_missing ? "button-link" : "button-link validate"} onClick={() => navigate(`/organisations/${id}/financial/${(year - 2)}`)} ><i>Remplir les pages financières</i><Icon>keyboard_arrow_right</Icon></a>
                                </Can>
                                <Can do={'edit_financial_summary'} on={'Request'}>
                                    <a className={first_analysis_missing ? "button-link" : "button-link validate"} onClick={() => navigate(`/organisations/${id}/financial/${(year - 2)}/summary/old`, true)} ><i>Remplir le sommaire financier</i><Icon>keyboard_arrow_right</Icon></a>
                                </Can>
                            </div>

                            <div>
                                <b>{(year - 1)} : </b>
                                <Can do={'edit_financial_page'} on={'Request'}>
                                    <a className={second_financial_missing ? "button-link" : "button-link validate"} onClick={() => navigate(`/organisations/${id}/financial/${(year - 1)}`)} ><i>Remplir les pages financières</i><Icon>keyboard_arrow_right</Icon></a>
                                </Can>
                                <Can do={'edit_financial_summary'} on={'Request'}>
                                    <a className={second_analysis_missing ? "button-link" : "button-link validate"} onClick={() => navigate(`/organisations/${id}/financial/${(year - 1)}/summary/old`, true)} ><i>Remplir le sommaire financier</i><Icon>keyboard_arrow_right</Icon></a>
                                </Can>
                            </div>
                        </div>
                    </Fragment>}
            </Fragment>
        );
    }

    render() {
        const { id, readOnly, year, isUpToDate, org } = this.state;

        return (
            <Fragment>
                <Helmet>
                    {title('Sommaire financier')}
                    <body data-financial="true" />
                </Helmet>

                <div className="requests-color">
                    <div className="container">
                        <BackButton />
                        <Can do={"read_financial_page"} on={"Request"}>
                            <a className="top-link back-button" onClick={() => history.replace(`/organisations/${id}/financial/${year}/show`)}>Consulter les pages financières</a>
                        </Can>

                        <h1>
                            Sommaire financier
                        </h1>

                        <Can do={"edit_financial_summary"} on="Request">
                            {!readOnly && <a className={!isUpToDate ? "button" : "button disabled"} onClick={!isUpToDate ? this.handleSubmit : null} >Enregistrer les changements</a>}
                        </Can>

                        <article className="infos">
                            <ul>
                                <li>Organisme : <b><Link to={ `/organisations/${org.id}` }>{ org.name }</Link></b></li>
                                <li>Année : <b>{ year }</b></li>
                            </ul>
                        </article>
                    </div>


                    <Can do={readOnly ? "read_financial_summary" : "edit_financial_summary"} on={"Request"}>
                        <div className="container buttons-container">
                            {this.buttonHeader()}
                        </div>

                        <div className="form-box financial-summary read-only">
                            {this.generateHeader()}
                            {this.generateTable()}
                        </div>

                        <Can do={"edit_financial_summary"} on="Request">
                            <div className="container">
                                {!readOnly && <a className={!isUpToDate ? "button" : "button disabled"} onClick={!isUpToDate ? this.handleSubmit : null} >Enregistrer les changements</a>}
                                <a className="button alt" onClick={ (e) => window.print() }>Imprimer le sommaire financier</a>
                            </div>
                        </Can>
                    </Can>
                </div>

                <Prompt
                    when={!isUpToDate}
                    message="Attention, certaines de vos réponses n'ont pas été enregistrées. Voulez-vous vraiment quitter cette page ?"
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        rank: (((state.user.infos || {}).user_type || {}).rang || 0)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveTable: (answers) => dispatch(financialActions.saveTable(answers))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrganisationFinancialSummaryPage);
