import { API_DEV } from '../constants';
import { header, handleAPIResult, serialize, serializeMaterialFilter, log } from '../utils';
import { organisationUserService } from './organisationUser.service';


export const organisationService = {
    listPaginate,
    list,
    get,
    getActionFields,
    getSectors,
    getSubSectors,
    edit,
    create,
};

function listPaginate(page_number = 0, per_page = 0, filters = [], orderBy, orderDirection) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    var pagination = '';
    if (page_number !== 0) {
        pagination = '&page_number=' + page_number + '&per_page=' + per_page;
    }

    var order = '';
    if (orderBy) {
        order = '&order_by=' + (orderBy.orderField ? orderBy.orderField : orderBy.field) + '&order_direction=' + orderDirection.toUpperCase();
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organizations?' + serializeMaterialFilter(filters) + pagination + order, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function list(filters = {}) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organizations?' + serialize(filters), getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function get(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organizations/' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                if ('yearly_follow' in result && result.yearly_follow) {
                    var splitTerm = result.yearly_follow.indexOf("de") !== -1 ? " de " : "/";
                    result['yearly_follow_1'] = result.yearly_follow.split(splitTerm)[0]
                    result['yearly_follow_2'] = result.yearly_follow.split(splitTerm)[1]
                }
                resolve(result);
            })
            .catch(error => {
                log(error);
                reject(error);
            });
    });
}

function getActionFields() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/action_fields', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getSectors() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/sectors', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getSubSectors() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/subsectors', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function edit(id, new_org) {
    var org = { ...new_org };
    const { associated_user_id, association_id } = org;
    delete org.associated_user_id;
    delete org.association_id;

    if (org.yearly_follow_1 && org.yearly_follow_2)
        org.yearly_follow = org.yearly_follow_1 + '/' + org.yearly_follow_2;
    delete org.yearly_follow_1;
    delete org.yearly_follow_2;

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            organization: org
        })
    }

    return new Promise((resolve, reject) => {
        if (Object.keys(org).length > 0) {
            fetch(API_DEV + '/organizations/' + id, patchInfo)
                .then(result => {
                    handleAPIResult(result, reject)
                    return result.json()
                })
                .then(result => {
                    if (associated_user_id) {
                        if (association_id) {
                            organisationUserService.update(association_id, result.id, associated_user_id)
                                .then(result => {
                                    resolve(result);
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        } else {
                            organisationUserService.create(result.id, associated_user_id)
                                .then(result => {
                                    resolve(result);
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        }
                    }
                    else {
                        organisationUserService.destroy(association_id)
                            .then((res) => {
                                resolve(true);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                })
                .catch(error => {
                    reject(error);
                });
        }
        else {
            if (associated_user_id) {
                organisationUserService.update(association_id, id, associated_user_id)
                    .then(result => {
                        resolve(result);
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        }
    });
}

function create(new_org) {
    var org_info = { ...new_org };
    const { associated_user_id } = org_info;
    delete org_info.associated_user_id;
    delete org_info.association_id;

    if (org_info.yearly_follow_1 && org_info.yearly_follow_2)
        org_info.yearly_follow = org_info.yearly_follow_1 + '/' + org_info.yearly_follow_2;
    delete org_info.yearly_follow_1;
    delete org_info.yearly_follow_2;

    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            organization: org_info
        })
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organizations', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                if (associated_user_id) {
                    organisationUserService.create(result.id, associated_user_id)
                        .then(r => {
                            resolve(result);
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}
