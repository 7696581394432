import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import Icon from '@material-ui/core/Icon';

import { title } from '../../utils';

import { Filters, Chart } from '../../components/charts';
import { sections } from '../../constants/dashboard';


const cachePath = 'filters[dashboard]';


class StatusPage extends Component {
    constructor(props) {
        super(props);

        // Logiques particulières.
        // 1 - On cache la valeur des filtres pour une expérience plus fluide.
        // 2 - Pour faire un refresh optimal des charts, on incrémente this.state.refresh
        //     lorsque les charts doivent être mis à jour (changement des filtres). Cela permet
        //     de ne pas avoir à faire une comparaison d'objets dans le composant Chart (savoir
        //     si les filtres ont changés), et optimise la vitesse de refresh.
        this.state = {
            filters: JSON.parse(localStorage.getItem(cachePath)) || {},
            refreshes: 0
        }

        this.handleFiltersUpdate = this.handleFiltersUpdate.bind(this);
    }

    // Handle le update des filtres. Caching + met à jour this.state.refresh pour
    // update les charts.
    handleFiltersUpdate(filters) {
        this.setState({ filters, refreshes: this.state.refreshes + 1 }, () => {
            localStorage.setItem(cachePath, JSON.stringify(filters))
        })
    }

    render() {
        const { filters, refreshes } = this.state;

        return (
            <Fragment>
                <Helmet>
                    { title('Statistiques') }
                </Helmet>

                <div className="stats-page">
                    <h1>
                        Statistiques
                        <Icon onClick={ () => window.print() }>print</Icon>
                    </h1>
                    <Filters defaultFilters={ filters } handleSubmit={ (filters) => this.handleFiltersUpdate(filters) } />

                    <div className="stats-graphs">
                        { (sections || []).map((section, i) => (
                            <section key={ i }>
                                { section.title && <h2 className="section-title">{ section.title }</h2> }
                                { (section.charts || []).map((chart, j) => (
                                    <Chart infos={ chart } refreshes={ refreshes } key={ j } filters={ filters } />
                                )) }
                            </section>
                        )) }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default StatusPage;
