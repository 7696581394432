import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title } from '../../utils';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';

import { Can, API_DEV } from '../../constants';
import { header, history } from '../../utils';

import { requestService } from '../../services';

import { connect } from 'react-redux';

import { BackButton } from '../../components';
import { Form } from '../../components/forms/';
import { formActions } from '../../actions';
import { formatAnswers } from '../../models';

import * as statesController from '../../constants/requestStates';

class RequestFormPage extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            id: id,
            req: { __type: 'Request', ...statesController.injectFunctions() },
            loaded: false,
        };
    }

    componentDidMount() {
        requestService
            .get(this.state.id)
            .then((result) => {
                this.setState({
                    req: { ...this.state.req, ...result },
                    loaded: result,
                });
            })
            .catch((e) => {
                this.setState(() => {
                    throw new Error(e);
                });
            });
    }

    render() {
        const { id, req, loaded } = this.state;

        const statusBar = (
            <ul>
                <li>
                    Demande :{' '}
                    <b>
                        <Link to={`/requests/${req.id}`}>
                            {(req.request_type || {}).label}
                        </Link>
                    </b>
                </li>
                <li>
                    Organisme :{' '}
                    <b>
                        <Link to={`/organisations/${req.organization_id}`}>
                            {(req.organization || {}).name}
                        </Link>
                    </b>
                </li>
            </ul>
        );

        return (
            <Fragment>
                <Helmet>
                    {loaded
                        ? title(
                              "Formulaire d'une demande - " +
                                  req.request_type.label
                          )
                        : title("Formulaire d'une demande")}
                </Helmet>

                <div className="requests-color">
                    <BackButton />

                    <h1 style={{ maxWidth: '80%' }}>
                        {loaded ? req.request_type.label : 'Formulaire'}
                    </h1>

                    {loaded && (
                        <Can do="edit_form" on={req} passThrough>
                            {(can) => (
                                <Form
                                    formId={(req.request_type || {}).form_id}
                                    financialYear={req.financial_year}
                                    sourceId={id}
                                    orgId={req.organization_id}
                                    readonly={!can || req.isFormLocked()}
                                    print={this.props.print === true}
                                    fetchForm={this.props.fetchForm}
                                    fetchAnswers={this.props.fetchAnswers}
                                    saveForm={this.props.saveForm}
                                    formatAnswers={this.props.formatAnswers}
                                    statusBar={statusBar}
                                />
                            )}
                        </Can>
                    )}

                    {loaded &&
                        !req.isFormLocked() &&
                        this.props.guide &&
                        'url' in this.props.guide &&
                        this.props.guide.url && (
                            <a
                                className="doc-popup"
                                href={this.props.guide.url}
                                target="_blank"
                            >
                                <Icon>assignment</Icon>
                                <i>Document guide</i>
                            </a>
                        )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        guide: (state.form.base || {}).url,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { id } = ownProps.match.params;
    return {
        fetchForm: (formId, financialYear) =>
            dispatch(formActions.fetchForm(formId, id, financialYear)),
        fetchAnswers: () => dispatch(formActions.fetchAnswers(id, false)),
        saveForm: (answers) =>
            dispatch(formActions.saveForm(answers, id, false)),
        formatAnswers: (answers) => formatAnswers(answers, id, false),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestFormPage);
