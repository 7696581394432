import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';

class QA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            opened: !this.state.opened,
        });
    }

    render() {
        const { opened } = this.state;
        const { question, answer } = this.props;
        return (
            <li>
                <span onClick={ this.toggle } className="title-qa">
                    <Icon>{ opened ? "keyboard_arrow_up" : "keyboard_arrow_down" }</Icon>
                    {question}
                </span>
                { opened && (
                    <div className="content-qa">
                        {answer}
                    </div>
                ) }
            </li>
        )
    }
}

export default QA;
