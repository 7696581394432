import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

import { Can, API_DEV, REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ACCEPTED, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION, REQ_STATE_COMPLETED } from '../../constants'
import { header, history, requestHomeLookups, generateMaterialFilter, noteLookups, loadDefaultFilter } from '../../utils';

import { connect } from 'react-redux';

import { requestService, organisationUserService, noteService } from "../../services";

import { columns } from '../../constants/tablesColumns';
import MaterialTable from 'material-table';
import { materialTableProps, ResetButton } from '../../utils';


class HomeBenevole extends Component {

    constructor(props) {
        super(props);

        const tableKey_requestsAnalyze = "requestsAnalyze_home_benevole";

        this.state = {
            _tableKey_requestsAnalyze: tableKey_requestsAnalyze,
            _title_analyse: "Centraide - Liste des demandes attribuées",
            requests: {
                possibleColumns: (columns.getKeys('requestsAnalyze') || []).map(c => {
                    var completeCol = { ...columns['requestsAnalyze']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_requestsAnalyze, completeCol);
                    return { ...completeCol }
                })
            },
        }
    }

    componentDidMount() {
        requestHomeLookups(this, this.state.requests.possibleColumns);
    }

    handleRequestData(query) {
        return new Promise(function (resolve, reject) {
            requestService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    render() {
        return (
            <Fragment>
                <section>
                    <article className="box table-box">
                        <h3 onClick={() => history.push("/requests")} className="title-link">Demande(s) attribuées</h3>
                        <ResetButton tableKey={ this.state._tableKey_requestsAnalyze } />
                        <MaterialTable
                            title={this.state._title_analyse}
                            columns={this.state.requests.possibleColumns}
                            data={query => this.handleRequestData(query)}
                            {...materialTableProps(this.state._tableKey_requestsAnalyze, this.state._title_analyse, true)}
                            onRowClick={(event, rowData) => { history.push(`/requests/${rowData.id}`) }} />
                    </article>
                </section>
            </Fragment>
        )

    }
}

const mapStateToProps = (state) => {
    return {

    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBenevole);
