import React from 'react';
import Icon from '@material-ui/core/Icon';

import { history } from '../utils';


class BackButton extends React.Component {
    render() {
        const { link } = this.props

        return (link) ?
            <a className="back-button" onClick={ () => link() }><Icon>keyboard_arrow_left</Icon>Retour</a>
            :
            <a className="back-button" onClick={ () => history.goBack() }><Icon>keyboard_arrow_left</Icon>Retour</a>
    }

}

export default BackButton;
