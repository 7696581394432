import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title } from '../../utils';
import { Link } from 'react-router-dom';

import {
    Can,
    API_DEV,
    REQ_USER_TYPE_BEN_AN_FIN,
    AN_STATE_PENDING,
    AN_STATE_PROGRESS,
    AN_STATE_COMPLETED,
} from '../../constants';
import { header, history } from '../../utils';

import { analyzeService, requestService } from '../../services';

import { connect } from 'react-redux';

import { BackButton } from '../../components';
import { Form } from '../../components/forms';
import { formActions } from '../../actions';
import { formatAnswers } from '../../models';

import * as analyzesController from '../../constants/analyzeStates';
import * as requestsController from '../../constants/requestStates';

const formatRequest = (r) => {
    r.benevoles = {};
    (r.request_users || []).map((u) => {
        u.id = u.user_id;
        r.benevoles[u.type_id] = u.user_id;
        if (u.type_id === REQ_USER_TYPE_BEN_AN_FIN) r.analyste_id = u.user_id;
    });

    return r;
};

class AnalyzeFormPage extends Component {
    constructor(props) {
        super(props);
        const { requestId, id } = this.props.match.params;
        this.state = {
            id: id,
            requestId: requestId,
            analyze: { ...analyzesController.injectFunctions() },
            request: {
                ...requestsController.injectFunctions(),
                __type: 'Request',
            },
            loaded: false,
        };

        this.saveForm = this.saveForm.bind(this);
    }

    componentDidMount() {
        analyzeService
            .get(this.state.id)
            .then((result) => {
                this.setState({
                    analyze: { ...this.state.analyze, ...result },
                    request: {
                        ...this.state.request,
                        ...formatRequest(result.request),
                    },
                    financialYear: result.request.financial_year,
                    loaded: result,
                });
            })
            .catch((e) => {
                this.setState(() => {
                    throw new Error(e);
                });
            });
    }

    saveForm(answers) {
        const { analyze } = this.state;
        var setState = analyze.isPending() && answers && answers.length > 0;

        return new Promise((resolve, reject) => {
            this.props
                .saveForm(answers)
                .then((r) => {
                    if (setState)
                        analyzeService
                            .update({
                                id: analyze.id,
                                state_id: analyzesController.progress(),
                            })
                            .then((a) =>
                                this.setState({ analyze: { ...analyze, ...a } })
                            );
                    resolve(r);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    render() {
        const { id, analyze, loaded, request, financialYear } = this.state;
        const { rank, user } = this.props;
        const ability = analyze.isFinancial() ? 'financial_analyze' : 'analyze';

        const statusBar = (
            <ul>
                <li>
                    Demande :{' '}
                    <b>
                        {user.type_id === 1 || user.type_id === 4 ? (
                            <Link to={`/requests/${request.id}`}>
                                {(request.request_type || {}).label}
                            </Link>
                        ) : (
                            (request.request_type || {}).label
                        )}
                    </b>
                </li>
                <li>
                    Organisme :{' '}
                    <b>
                        <Link to={`/organisations/${request.organization_id}`}>
                            {(request.organization || {}).name}
                        </Link>
                    </b>
                </li>
                <li>
                    Statut :{' '}
                    <b>
                        {analyze.state_id === AN_STATE_PENDING
                            ? 'Débuté'
                            : analyze.state_id === AN_STATE_PROGRESS
                            ? 'En cours'
                            : 'Terminée'}
                    </b>
                </li>
            </ul>
        );

        return (
            <Fragment>
                <Helmet>
                    {loaded
                        ? title(
                              "Formulaire d'une analyse - " +
                                  analyze.analyze_type.label
                          )
                        : title("Formulaire d'une analyse")}
                </Helmet>

                <div className="requests-color">
                    <BackButton />

                    <h1 style={{ maxWidth: '80%' }}>
                        {loaded ? analyze.analyze_type.label : 'Formulaire'}
                    </h1>

                    {loaded && (
                        <Can do={`read_${ability}`} on={request}>
                            <Can
                                do={`edit_${ability}`}
                                on={request}
                                passThrough
                            >
                                {(edit) => (
                                    <Form
                                        formId={
                                            (analyze.analyze_type || {}).form_id
                                        }
                                        financialYear={financialYear}
                                        sourceId={id}
                                        orgId={analyze.organization_id}
                                        readonly={
                                            analyze.isSubmitted() || !edit
                                        }
                                        print={this.props.print === true}
                                        fetchForm={this.props.fetchForm}
                                        fetchAnswers={this.props.fetchAnswers}
                                        saveForm={this.saveForm}
                                        formatAnswers={this.props.formatAnswers}
                                        statusBar={statusBar}
                                    />
                                )}
                            </Can>
                        </Can>
                    )}
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const { requestId, id } = ownProps.match.params;
    return {
        fetchForm: (formId, financialYear) =>
            dispatch(formActions.fetchForm(formId, requestId, financialYear)),
        fetchAnswers: () => dispatch(formActions.fetchAnswers(id, true)),
        saveForm: (answers) =>
            dispatch(formActions.saveForm(answers, id, true)),
        formatAnswers: (answers) => formatAnswers(answers, id, true),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.user.infos,
        rank: (state.user.infos.user_type || {}).rang,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeFormPage);
