import React from 'react';
import { Icon } from '@material-ui/core';

import { userService, organisationService, noteService, requestService, analyzeService } from '../services';
import {
    AN_TYPE_FIN,
    AN_TYPE_FIN_RECONDUCTION,
    AN_TYPE_FIN_FIDC,
    AN_TYPE_ADMIN_FIN,
    AN_TYPE_IMP_SOC,
    AN_TYPE_SUMMARY,
    AN_TYPE_RECONDUCTION,
    AN_TYPE_DEV_GESTION,
    AN_TYPE_AS,
    AN_TYPE_FMN,

    REQ_USER_TYPE_BEN_ADMIN_FIN,
    REQ_USER_TYPE_BEN_IMP_SOC,
    REQ_USER_TYPE_BEN_AN_FIN,
    REQ_USER_TYPE_BEN_RECONDUCTION,

    REQ_STATE_ACCEPTED
} from '../constants';


// GÉNÉRAL
export function findAnalystForAnalyse(analyse) {
    const users = ((analyse.request || {}).request_users || []);
    const t = analyse.type_id;

    var userType = 0;
    if ([AN_TYPE_FIN, AN_TYPE_FIN_RECONDUCTION, AN_TYPE_FIN_FIDC].indexOf(t) !== -1)  userType = REQ_USER_TYPE_BEN_AN_FIN;
    else if ([AN_TYPE_IMP_SOC, AN_TYPE_DEV_GESTION].indexOf(t) !== -1)  userType = REQ_USER_TYPE_BEN_IMP_SOC;
    else if ([AN_TYPE_ADMIN_FIN, AN_TYPE_AS].indexOf(t) !== -1)  userType = REQ_USER_TYPE_BEN_ADMIN_FIN;
    else if ([AN_TYPE_RECONDUCTION].indexOf(t) !== -1)  userType = REQ_USER_TYPE_BEN_RECONDUCTION;

    return users.find(u => u.type_id == userType);
}

export const ResetButton = (props) => {
    const resetFilters = (tableKey) => {
        const currentFilters = JSON.parse(localStorage.getItem("table_filters")) || {};
        const currentColumns = JSON.parse(localStorage.getItem("column_filters")) || {};

        delete currentFilters[tableKey];
        delete currentColumns[tableKey];

        localStorage.setItem("table_filters", JSON.stringify(currentFilters));
        localStorage.setItem("column_filters", JSON.stringify(currentColumns));

        window.location.reload();
    }

    return (
        <span className="reload-filters-btn" onClick={ () => resetFilters(props.tableKey) }>
            <Icon>refresh</Icon>
            <i>Réinitialiser les filtres</i>
        </span>
    )
}

// LOOKUPS
function setLookup(component, possibleColumns, result, key) {
    var lookup = {};
    result.forEach(el => lookup[el.id] = el.label);

    var columns = possibleColumns.slice(0);
    (columns.find(el => el.field === key) || {}).lookup = lookup;
    component.setState({ possibleColumns: columns });
}

export function userLookups(component, possibleColumns = component.state.possibleColumns) {
    userService.getTypes().then(result => {
        setLookup(component, possibleColumns, result, 'type_id');
    });
}

export function organisationLookups(component, possibleColumns = component.state.possibleColumns) {
    organisationService.getActionFields().then((result) => {
        setLookup(component, possibleColumns, result, 'action_field_id');
    })

    organisationService.getSectors().then((result) => {
        setLookup(component, possibleColumns, result, 'sector_id');
    })

    organisationService.getSubSectors().then((result) => {
        setLookup(component, possibleColumns, result, 'subsector_id');
    })
}

export function noteLookups(component, possibleColumns = component.state.possibleColumns) {
    noteService.listTypes().then((result) => {
        setLookup(component, possibleColumns, result, 'type_id')
    })
}

export function requestLookups(component, possibleColumns = component.state.possibleColumns) {
    requestService.listStates().then((result) => {
        setLookup(component, possibleColumns, result, 'state_id')
    })

    requestService.listTypes().then((result) => {
        setLookup(component, possibleColumns, result, 'type_id')
    })
}

export function requestHomeLookups(component, possibleColumns = component.state.possibleColumns) {
    requestService.listStates().then((result) => {
        result = result.filter(el => el.id !== REQ_STATE_ACCEPTED);
        setLookup(component, possibleColumns, result, 'state_id')
    })

    requestService.listTypes().then((result) => {
        setLookup(component, possibleColumns, result, 'type_id')
    })
}

export function analyzeLookups(component, possibleColumns = component.state.possibleColumns) {
    analyzeService.listStates().then((result) => {
        setLookup(component, possibleColumns, result, 'state_id')
    })

    analyzeService.listTypes().then((result) => {
        setLookup(component, possibleColumns, result, 'type_id')
    })

    requestService.listTypes().then((result) => {
        setLookup(component, possibleColumns, result, 'request_type_id')
    })
}
