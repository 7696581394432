import React from 'react';
import { Link, NavLink } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

import logoGeneric from '../../assets/images/logo.png';
import logoQC from '../../assets/images/logo-qc.png';
import logoBSL from '../../assets/images/logo-bsl.png';

import { routes } from '../../constants';

import { userActions } from "../../actions";
import { connect } from 'react-redux';

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.handleLogout = this.handleLogout.bind(this)
    }

    handleLogout(e) {
        e.preventDefault();
        this.props.logoutUser()
    }

    render() {
        const { rank, user } = this.props;

        return (
            <header className="general-header">
                <nav className="container">

                    <Link to="/" className="home-link">
                        <img src={ (user.logo === 'bsl' ? logoBSL : (user.logo === 'qc' ? logoQC : logoGeneric)) } />
                    </Link>

                    <div className="primary-links">
                        { routes.map((route, index) =>
                            (route.allowed.indexOf(rank) !== -1 && route.main) &&
                                <NavLink key={ route.path } className={ (route.children ? 'has-subroutes' : '') } activeClassName={ 'active border-fill text-fill ' + route.class } to={ route.path }>
                                    <Icon>{ route.icon }</Icon>
                                    { route.display }

                                    <span className="subroutes">
                                        { route.children && route.children.map(c => (
                                            <Link to={ c.path }>{ c.display }</Link>
                                        )) }
                                    </span>
                                </NavLink>
                            )
                        }
                    </div>

                    <div className="secondary-links">
                        <Link to={ "/users/" + user.id } className="user-link">
                            { user.name + ' ' + user.surname }
                            <small>{ (user.user_type || {}).label }</small>
                        </Link>

                        <a onClick={ this.handleLogout } className="logout-button">
                            <Icon>logout</Icon>
                        </a>
                    </div>
                </nav>
            </header>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.infos,
        rank: (state.user.infos.user_type || {}).rang,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => dispatch(userActions.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
