import React from 'react';
import { APP_NAME, APP_SEPARATOR, USER_RANK_ADMIN, USER_RANK_CONSEILLE, USER_RANK_ANALYSTE, USER_RANK_BENEVOLE, USER_RANK_ORG } from '../constants';
import { organisationUserService, organisationService } from '../services';

import * as H from '../components/home';


export function title(pageName) {
    return <title>{ `${APP_NAME} ${APP_SEPARATOR} ${pageName}` }</title>
}

export function page(comp, rank) {
    // TODO : update cette solution
    const map = {
        'home': {
            [USER_RANK_ADMIN]: H.HomeAdmin,
            [USER_RANK_CONSEILLE]: H.HomeConseille,
            [USER_RANK_ANALYSTE]: H.HomeAnalyste,
            [USER_RANK_BENEVOLE]: H.HomeBenevole,
            [USER_RANK_ORG]: H.HomeOrganisation
        }
    }

    if (rank in map[comp]) return map[comp][rank];
    return map[comp][1]
}

export function logoForUser(id, rank) {
    return new Promise((resolve, reject) => {
        if (rank !== USER_RANK_ORG) return resolve('');

        organisationUserService.getAssociationUser(id).then(assoc => {
            if (!assoc || assoc.length === 0) return resolve('');
            assoc = Array.isArray(assoc) ? assoc[0] : assoc;

            organisationService.get(assoc.organization_id).then(org => {
                var logo = '';
                if (org.region === 'Bas-Saint-Laurent') logo = 'bsl'
                if (org.region === 'Capitale-Nationale' || org.region === 'Chaudière-Appalaches') logo = 'qc'
                resolve(logo)
            }).catch(error => {
                resolve('');
            })
        }).catch(error => {
            resolve('');
        })
    });
}

export function serialize(obj) {
    var str = [];
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                obj[p].forEach((val) =>
                    str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(val))
                );
            } else {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }

    return str.join("&");
}

export function serializeMaterialFilter(filters) {
    var str = [];
    filters.forEach(filter => {
        switch(filter.value) {
            case "checked":
                filter.value = 1;
                break;
            case "unchecked":
                filter.value = 0;
                break;
            default:
                break;
        }

        if (Array.isArray(filter.value)) {
            filter.value.forEach((val) => {
                str.push(encodeURIComponent(filter.column.field) + "[]=" + encodeURIComponent(val));
            });
        }
        else {
            str.push(encodeURIComponent(filter.column.field) + "=" + encodeURIComponent(filter.value));
        }
    });
    return str.join("&");
}


export function generateMaterialFilter(fieldName, value, operator="=") {
    return ({
        column: {
            field: fieldName
        },
        operator: operator,
        value: value
    });
}
