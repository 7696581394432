import {
    SAVE_ANSWER,
    SAVE_FORM,
    STOCK_TMP_ANSWER,
    FORM_BASE_LOADED,
    ANSWERS_LOADED
} from "../constants";

import { handleAnswersFormat } from "../utils";
import { formService } from "../services";
import { Answer } from '../models'


export const formActions = {
    saveAnswer,
    saveForm,
    fetchForm,
    fetchAnswers,
}

function saveAnswer(answer) {
    return function (dispatch) {
        var keys = Object.keys(answer),
            unsaved = {};
        keys.map(k => unsaved[k] = true)
        dispatch({ type: SAVE_ANSWER, payload: answer })
        dispatch({ type: STOCK_TMP_ANSWER, payload: unsaved })
    }
}

function saveForm(answers, sourceId, isAnalyze=false) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            formService.saveAnswers(answers, sourceId, isAnalyze)
                .then(payload => {
                    dispatch({ type: SAVE_FORM, payload: payload })
                    resolve('FORM SAVED');
                })
                .catch(error => {
                    console.log('ERROR SAVE FORM :', error);
                    reject('ERROR SAVE FORM :', error);
                });
        })

    }
}

function fetchForm(id, request = null, financialYear = new Date().getFullYear()) {
    console.log(financialYear);
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            formService.getForm(id)
                .then(payload => {
                    if (request) payload['requestId'] = request;
                    if (financialYear) payload['financialYear'] = financialYear;
                    dispatch({ type: FORM_BASE_LOADED, payload });
                    resolve('FORM LOADED');
                })
                .catch(error => {
                    console.log('ERROR GET FORM :', error);
                    reject('ERROR GET FORM :' + error);
                });
        });
    }
}

function fetchAnswers(requestId, isAnalyze=false) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            formService.getAnswers(requestId, isAnalyze)
                .then(payload => {
                    dispatch({ type: ANSWERS_LOADED, payload: handleAnswersFormat(payload) });
                    resolve('ANSWERS LOADED')
                })
                .catch(error => {
                    console.log('ERROR GET ANSWERS :', error);
                    reject('ERROR GET ANSWERS :' + error);
                });
        });
    }
}
