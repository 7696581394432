import { API_DEV, updateUserForAbilities } from '../constants';
import { header, handleAPIResult, serialize, serializeMaterialFilter, logoForUser, log } from '../utils';
import { organisationUserService } from './organisationUser.service';


export const userService = {
    login,
    logout,
    edit,
    askReset,
    verify,
    reset,
    listPaginate,
    list,
    get,
    create,
    disable,
    enable,
    getTypes,
}

// https://stackblitz.com/edit/react-redux-registration-login-example?file=_services%2Fuser.service.js
// Utilisation de headers (BEARER)

function login(email, password) {
    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },

        body: JSON.stringify({
            user: {
                email: email,
                password: password
            }
        })
    }

    return new Promise((resolve, reject) => {
        var token = '';
        fetch(API_DEV + '/login', postInfo)
            .then(result => {
                const error = (result.status !== 200);

                if (!error) {
                    token = result.headers.get('Authorization')

                    result.json().then(r => {
                        var user = {
                            ...r,
                            token
                        };

                        localStorage.setItem('user', JSON.stringify(user));
                        updateUserForAbilities(user);

                        logoForUser(user.id, (user.user_type || {}).rang).then(logo => {
                            user.logo = logo || '';
                            localStorage.setItem('user', JSON.stringify(user));
                            resolve(user);
                        }).catch(error => resolve(user))
                    })
                    .catch(e => {
                        reject(e);
                    });
                } else {
                    result.json().then(r => {
                        if (r.error && r.error === 'disabled') reject('Disabled');
                        else reject('Unauthorized')
                    })
                    .catch(e => {
                        reject(e);
                    });
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function logout() {
    localStorage.removeItem('user');
    return {};
}

function edit(id, new_user) {
    var user = { ...new_user };
    const { associated_org_id, association_id } = user;

    delete user.associated_org_id;
    delete user.association_id;

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            user: user
        })
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json();
            })
            .then(result => {
                var local_user = {
                    ...JSON.parse(localStorage.getItem('user'))
                };

                if (local_user.id === result.id) {
                    local_user = {
                        token: local_user.token,
                        ...result
                    }
                    localStorage.setItem('user', JSON.stringify(local_user));
                }
                if (association_id) {
                    if(associated_org_id) {
                        organisationUserService.update(association_id, associated_org_id, result.id)
                        .then((res) => {
                            resolve(local_user);
                        })
                        .catch(error => {
                            reject(error);
                        });
                    }
                    else {
                        organisationUserService.destroy(association_id)
                            .then((res) => {
                                resolve(local_user);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                }
                else if(associated_org_id && !association_id) {
                    organisationUserService.create(associated_org_id, result.id)
                        .then((res) => {
                            resolve(local_user);
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
                else {
                    resolve(local_user);
                }
            })
            .catch(error => {
                log(error);
                reject(error);
            });
    });
}

function askReset(email) {
    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/password?email=' + email, postInfo)
            .then(result => {
                handleAPIResult(result, reject)
                resolve();
            })
            .catch(error => {
                reject(error);
            });
    });
}

function verify(token, email) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/password/verify?email=' + email + '&reset_password_token=' + token, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function reset(pwd, id, token) {
    const getInfo = {
        method: 'PUT',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/password?id=' + id + '&reset_password_token=' + token + '&password=' + pwd, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                resolve();
            })
            .catch(error => {
                reject(error);
            });
    });
}

function listPaginate(page_number = 0, per_page = 0, filters = [], orderBy, orderDirection) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    var pagination = '';
    if (page_number !== 0) {
        pagination = '&page_number=' + page_number + '&per_page=' + per_page;
    }

    var order = '';
    if (orderBy) {
        order = '&order_by=' + (orderBy.orderField ? orderBy.orderField : orderBy.field) + '&order_direction=' + orderDirection.toUpperCase();
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users?' + serializeMaterialFilter(filters) + pagination + order, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function list(filters = {}) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users?' + serialize(filters), getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function get(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users/' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function create(new_user) {
    var user_info = { ...new_user };
    const { associated_org_id } = user_info;
    delete user_info.associated_org_id;
    delete user_info.association_id;

    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            user: user_info
        })
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                if (associated_org_id) {
                    organisationUserService.create(associated_org_id, result.id)
                        .then(r => {
                            resolve(result);
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function disable(id) {
    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            user: {
                disabled: true
            }
        })
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function enable(id) {
    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            user: {
                disabled: false
            }
        })
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/users/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getTypes() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/user_types', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}
