import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import { Can, ABILITIES, USER_RANK_ORG, FAQ_QA } from '../../constants';
import { history, title, validateProfileFields } from '../../utils';

import { userActions } from '../../actions';
import { connect } from 'react-redux';

import { userService, organisationService, organisationUserService } from '../../services';

import { BackButton } from '../../components';
import { Input, notification, Select, Checkbox } from 'antd';
import { Prompt } from 'react-router';
import QA from './_utils/QA';


class FAQPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        var pageTitle = "Foire aux questions";

        return (
            <Fragment>
                <Helmet>
                    {title(pageTitle)}
                </Helmet>

                <div>
                    <h1>{pageTitle}</h1>

                    <section className="faq-wrap">
                        <article className="faq-second-box">
                            <p><b>Sous cette rubrique, vous trouverez un ensemble de réponses à des questions fréquemment posées.</b></p>
                            <p>Dans l'éventualité où vous ne trouveriez pas de réponse à votre question, nous vous invitons à communiquer avec nous :</p>
                            <ul>
                                <li>Pour les organismes du Bas-Saint-Laurent : <b>Guylaine Lebel<br /><small>(418) 775-5555 poste 1<br /><a href='mailto:glebel@centraide-bsl.com' target='_blank'>glebel@centraide-bsl.com</a></small></b></li>
                                <li>Pour les organismes de Québec et Chaudière-Appalaches : <b>Lamia Dine, adjointe de direction<br /><small>(418) 660‑2100 poste 247<br /><a href='mailto:ldine@centraide-quebec.com' target='_blank'>ldine@centraide-quebec.com</a></small></b></li>
                            </ul>
                        </article>

                        <article className="box faq-main-box">
                            <ul>
                                {FAQ_QA.map((el, i) => <QA key={i}
                                    question={el.Q}
                                    answer={el.A}
                                />)}
                            </ul>
                        </article>
                    </section>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQPage);
