import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, page } from '../utils';

import { connect } from 'react-redux';

class HomePage extends Component {

    render() {
        const Component = page('home', this.props.rank);

        return (
            <Fragment>
                <Helmet>
                    { title('Accueil') }
                </Helmet>

                <div className="home-page">
                    <h1>Accueil</h1>

                    <Component />
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        rank: (state.user.logged) ? state.user.infos.user_type.rang : 0
    };
}

export default connect(mapStateToProps)(HomePage);
