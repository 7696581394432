import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        // On veut que cette page se cache lorsqu'on navigue
        // par la suite.
        const { history } = this.props;
        history.listen((location, action) => {
            if (this.state.hasError) this.setState({ hasError: false });
        });

        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // console.log(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Une erreur s'est produite</h1>
                    <p>Contacter les administrateurs si cette erreur persiste.</p>
                </div>
            )
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary)
