import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatLabel, fetchOptionIds, formatTableId, displayTextAnswer, formatId, parseArrayString, numberFormatProps, formatYear } from '../../../utils'
import { Answer } from '../../../models'

import 'antd/es/input/style/index.css';
import 'antd/es/select/style/index.css';
import { Input, Select as S } from 'antd';

import { formActions } from "../../../actions";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

class TableMixed extends React.Component {

    constructor(props) {
        super(props)
        this.state = { error: false }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        var val = e.value, optionId = e.option, colI = e.col, isChildren = e.children;
        var ans = { [optionId] : parseArrayString(this.props.answers[optionId]) }
        if (!ans || !ans[optionId] || !ans[optionId].value || ans[optionId].value.length < colI) {
            var length = this.props.fields.length;
            if (isChildren) {
                length = 0;
                this.props.fields.map(f => {
                    if ('colspan' in f) length += parseInt(f.colspan)
                    else length++
                })
            }

            ans = Answer(optionId, Array.apply(null, new Array(length)))
        }

        // VALIDATION this.state.error
        ans[optionId].value[colI] = val;
        this.props.saveAnswer(ans);
    }

    computeInput(field, optionId, colI, value, children = false) {
        if (this.props.readonly) return value

        if (field.type === "select" || field.type === "multiple-select") {
            const multiple = (field.type === "multiple-select");
            var options = [];

            (field.values || []).map((o, i) => options.push(
                <S.Option key={ i }
                        value={ o }
                        id={ formatId(this.props.id, i) }>
                    { o }
                </S.Option>
            ));

            return <S   id={ formatTableId(this.props.id, optionId, colI) }
                        name={ formatTableId(this.props.id, optionId, colI) }
                        onChange={ (val) => this.handleChange({ value: (Array.isArray(val)) ? val : [val], option: optionId, col: colI, children: children }) }
                        value={ value }
                        mode={ multiple ? 'multiple' : 'default' }
                        showSearch
                        allowClear >
                        { options }
                    </S>
        }

        if (field.type !== 'number') {
            return <Input
                        type={ field.type }
                        id={ formatTableId(this.props.id, optionId, colI) }
                        name={ formatTableId(this.props.id, optionId, colI) }
                        onChange={ (e) => this.handleChange({ value: e.target.value, option: optionId, col: colI, children: children }) }
                        pattern={ field.validation !== "" ? field.validation : null }
                        title={ field.validation_help }
                        value={ value } />
         }
         else {
            return <NumberFormat
                        id={ formatTableId(this.props.id, optionId, colI) }
                        name={ formatTableId(this.props.id, optionId, colI) }
                        onChange={ (e) => this.handleChange({ value: e.target.value, option: optionId, col: colI, children: children }) }
                        value={ value }
                        { ...numberFormatProps() } />
        }
    }

    createTable() {
        var ths = [];
        this.props.fields.map((th,i) => {
            ths.push(<th key={ i } colSpan={ th.colspan }>{ formatYear(th.title, this.props.financialYear) }</th>)
        })

        var trs = []
        this.props.options.map((tr, i) => {
            // Pas le plus optimal, mais obligé pcq on a besoin du option id
            var tds = [];
            var ans = this.props.answers[tr.id];
            ans.value = parseArrayString(ans.value);

            this.props.fields.map((f,j) => {
                if(f.type !== 'title') {
                    var value = (ans && ans.value && ans.value.length > j) ? ans.value[j] : null;
                    tds.push(<td key={ j } colSpan={ this.props.fields[j].colspan }>{ this.computeInput(f, tr.id, j, value) }</td>)
                }
            })

            trs.push(<tr key={ i }>{tr.label !== null && <td colSpan={ tr.title ? this.props.fields.length : 1 } className={ "line-title" + ((tr.title) ? " full" : "") }>{ tr.label.length === 0 ? null : formatYear(tr.label, this.props.financialYear) }</td>}{ !tr.title && tds }</tr>)
        })

        return (
            <table>
                <thead>
                    <tr>
                        { ths }
                    </tr>
                </thead>

                <tbody>
                    { trs }
                    { this.createChildren() }
                </tbody>
            </table>
        )
    }

    createChildren() {
        var ths = [];

        var fields = eval(this.props.children_question.fields);
        fields.map((th,i) => {
            ths.push(<th key={ i }>{ formatYear(th.title, this.props.financialYear) }</th>)
        })

        var trs = []
        this.props.children_question.options.map((tr, i) => {
            // Pas le plus optimal, mais obligé pcq on a besoin du option id
            var tds = [];

            var ans = this.props.answers[tr.id];
            ans.value = parseArrayString(ans.value);

            fields.map((f,j) => {
                if(f.type !== 'title') {
                    var value = (ans && ans.value && ans.value.length > j) ? ans.value[j] : null;
                    tds.push(<td key={ j }>{ this.computeInput(f, tr.id, j, value, true) }</td>)
                }
            })

            trs.push(<tr key={ i }>{tr.label !== null && <td colSpan={tr.title && 9999} className="line-title">{ tr.label.length === 0 ? null : formatYear(tr.label, this.props.financialYear) }</td>}{ !tr.title && tds }</tr>)
        })

        return (
            <Fragment>
                    <tr>
                        { ths }
                    </tr>
                    { trs }
            </Fragment>
        )
    }

    render() {
        return (
            <div className="ctn">
                { (this.props.readonly) ? (
                    formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear)
                ) : (
                    formatLabel(this.props.label, null, this.props.required, this.props.help, this.props.question_number, this.props.financialYear)
                ) }
                <div className="table-wrap">
                    { this.createTable() }
                </div>
            </div>
        )
    }
}

TableMixed.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answers: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool,
    children_question: PropTypes.object
}

const mapStateToProps = (state, props) => {
    var optionIds = (fetchOptionIds([...props.options, ...props.children_question.options]) || []),
        answers = {};
    optionIds.map(oid => answers[oid] = state.form.answers[oid] || {})

    return {
        financialYear: state.form.base.financialYear,
        answers: answers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableMixed);
