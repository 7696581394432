import { API_DEV } from '../constants';
import { injectFunctions } from '../constants/analyzeStates';
import { header, handleAPIResult,cleanString } from '../utils';


export const formService = {
    getForm,
    getAnswers,
    saveAnswers,
    uploadDocument,
    updateGuide,
    getAnswersUnformated
};

function getForm(id) {

    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/forms/' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};


function getAnswers(sourceId, isAnalyze=false) {

    const getInfo = {
        method: 'GET',
        headers: header()
    };

    const type = isAnalyze ? '/analyzes/' : '/requests/';

    return new Promise((resolve, reject) => {
        fetch(API_DEV + type + sourceId + '/answers/formated', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function getAnswersUnformated(sourceId, isAnalyze=false) {

    const getInfo = {
        method: 'GET',
        headers: header()
    };

    const type = isAnalyze ? '/analyzes/' : '/requests/';

    return new Promise((resolve, reject) => {
        fetch(API_DEV + type + sourceId + '/answers', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function saveAnswers(answers, sourceId, isAnalyze=false) {
    const formattedAnswers =answers.map(ans=>(
        {...ans,value:cleanString(ans.value)}
        ))
    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            answers : formattedAnswers
        })
    }

    const type = isAnalyze ? '/analyzes/' : '/requests/';

    return new Promise((resolve, reject) => {
        fetch(API_DEV + type + sourceId + '/answers/', postInfo)
            .then(result => {
                handleAPIResult(result, reject, 204)
                return {}
            })
            .then(result => {
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function uploadDocument(name, doc, type, request) {

    var formData = new FormData();
    formData.append("document[label]", name);
    formData.append("document[url]", doc);
    formData.append("document[type_id]", type);
    formData.append("document[request_id]", request);

    const postInfo = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete postInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/documents', postInfo)
            .then(result => {
                handleAPIResult(result, reject, 204)
                return {}
            })
            .then(result => {
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function updateGuide(infos) {
    var formData = new FormData();
    formData.append("form[id]", infos.id);
    formData.append("form[url]", infos.url);

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete patchInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/forms/' + infos.id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}
