import Header from './_parts/Header';
import Footer from './_parts/Footer';
import BackButton from './BackButton';
import AnswerTable from './AnswerTable';

import ErrorBoundary from './ErrorBoundary';

export {
    Header,
    Footer,
    BackButton,
    AnswerTable,

    ErrorBoundary
}
