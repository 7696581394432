import { API_DEV } from '../constants';
import { header, handleAPIResult } from '../utils';


export const financialService = {
    getTable,
    saveTable,
    getSummary,
};

function getTable(organizationId, year) {

    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/financial_analyzes/' + organizationId + '/' + year, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
};

function saveTable(answers) {

    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify(answers)
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/financial_analyzes', postInfo)
            .then(result => {
                handleAPIResult(result, reject, 204)
                return {}
            })
            .then(result => {
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });
}


function getSummary(organizationId, year) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/financial_analyzes/summary/' + organizationId + '/' + year, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}