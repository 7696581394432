import { API_DEV } from '../constants';
import { header, handleAPIResult } from '../utils';


export const organisationUserService = {
    getAssociationOrg,
    getAssociationUser,
    update,
    create,
    get,
    destroy,
}

function getAssociationOrg(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organization_users?organization_id=' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getAssociationUser(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organization_users?user_id=' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function destroy(id) {
    const deleteInfo = {
        method: 'DELETE',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organization_users/' + id, deleteInfo)
            .then(result => {
                handleAPIResult(result, reject, 204)
                resolve(true)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function update(id, org_id, user_id) {
    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            organization_user: {
                organization_id: org_id,
                user_id: user_id
            }
        })
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organization_users/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                resolve(true)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function create(org_id, user_id) {
    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            organization_user: {
                organization_id: org_id,
                user_id: user_id
            }
        })
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organization_users', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                resolve(true)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function get() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/organization_users', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}
