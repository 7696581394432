import React from 'react';
import PropTypes from 'prop-types';
import {
    formatLabel,
    fetchOptionIds,
    displayCheckedAnswers,
    formatId,
    isChecked,
    formatYear,
} from '../../../utils';
import { Answer } from '../../../models';
import Details from './_details';

import { formActions } from '../../../actions';
import { connect } from 'react-redux';

class Radio extends React.Component {
    constructor(props) {
        super(props);

        this.state = { error: false };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        var optionId = e.target.value;
        // VALIDATION this.state.error

        var options = {};
        (this.props.options || []).map((o) => {
            options = { ...options, ...Answer(o.id, o.id == optionId) };
        });

        this.props.saveAnswer(options);
    }

    render() {
        if (this.props.readonly) {
            var val = formatYear(
                displayCheckedAnswers(
                    this.props.answers,
                    this.props.options
                ).join(', '),
                this.props.financialYear
            );
            return (
                <p>
                    {formatLabel(
                        this.props.label,
                        null,
                        null,
                        null,
                        this.props.question_number,
                        this.props.financialYear
                    )}
                    <span>{val}</span>
                </p>
            );
        }

        var options = [],
            detail = false;
        (this.props.options || []).map((o, i) => {
            if (isChecked(this.props.answers, o) && o.detail) detail = o.id;
            options.push(
                <span className="radio-wrap" key={i}>
                    <input
                        type="radio"
                        name={formatId(this.props.id)}
                        value={o.id}
                        id={formatId(this.props.id, i)}
                        onChange={this.handleChange}
                        checked={isChecked(this.props.answers, o)}
                    />

                    <label htmlFor={formatId(this.props.id, i)}>
                        {formatYear(o.label, this.props.financialYear)}
                    </label>
                </span>
            );
        });

        return (
            <p>
                {formatLabel(
                    this.props.label,
                    null,
                    this.props.required,
                    this.props.help,
                    this.props.question_number,
                    this.props.financialYear
                )}
                {options}

                {detail && (
                    <Details
                        id={this.props.id}
                        required={this.props.required}
                        detail={detail}
                        detail_text={this.props.detail_text}
                        onChange={this.props.onChange}
                    />
                )}
                {this.state.error && (
                    <small className="error">{this.props.error}</small>
                )}
            </p>
        );
    }
}

Radio.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answers: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    var optionIds = fetchOptionIds(props.options) || [],
        answers = {};
    optionIds.map((oid) => (answers[oid] = state.form.answers[oid] || {}));

    return {
        financialYear: state.form.base.financialYear,
        answers: answers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Radio);
