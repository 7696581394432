import { Ability, AbilityBuilder } from '@casl/ability'
import {
    REQ_STATE_ANALYSIS,
    USER_RANK_ADMIN,
    USER_RANK_CONSEILLE,
    USER_RANK_ANALYSTE,
    USER_RANK_BENEVOLE,
    USER_RANK_ORG
} from './dbValues';


export function updateUserForAbilities(u) {
    var rank = (u.user_type || {}).rang;
    if (!(rank in ABILITIES)) return;

    switch (rank) {
        case USER_RANK_ADMIN:
            (ABILITIES[rank]).update(defineRulesForAdmin(u))
            break;
        case USER_RANK_CONSEILLE:
            (ABILITIES[rank]).update(defineRulesForConseille(u))
            break;
        case USER_RANK_BENEVOLE:
            (ABILITIES[rank]).update(defineRulesForBenevole(u))
            break;
        case USER_RANK_ANALYSTE:
            (ABILITIES[rank]).update(defineRulesForAnalyste(u))
            break;
        case USER_RANK_ORG:
            (ABILITIES[rank]).update(defineRulesForOrganisation(u))
            break;
    }
}

function subjectName(item) {
    if (!item || typeof item === 'string') {
        return item
    }

    return item.__type
}

const admin = new Ability(defineRulesForAdmin(), { subjectName })
const conseille = new Ability(defineRulesForConseille(), { subjectName })
const benevole = new Ability(defineRulesForBenevole(), { subjectName })
const analyste = new Ability(defineRulesForAnalyste(), { subjectName })
const organisation = new Ability(defineRulesForOrganisation(), { subjectName })
const def = AbilityBuilder.define({ subjectName }, can => {})

function defineRulesForAdmin(user) {
    const { can, rules } = AbilityBuilder.extract();
    if (!user) user = JSON.parse(localStorage.getItem('user'));

    // Organisations
    can(['list', 'read', 'edit', 'create', 'delete', 'num_org', 'action_fields', 'sectors', 'subsectors', 'yearly_follow', 'user', 'financial_summary'], 'Organisation')

    // Notes
    can(['list', 'read', 'edit', 'create', 'delete'], 'Note')

    // Requests
    can(['list', 'read', 'create_batch', 'assign_main', 'assign_secondaries', 'assign_financial', 'save_assignees'], 'Request')
    can(['read_form', 'edit_form', 'submit_form'], 'Request')
    can(['read_financial_page', 'edit_financial_page', 'edit_financial_page_year'], 'Request')
    can(['read_financial_summary', 'edit_financial_summary'], 'Request')
    can(['see_backstage', 'approve_or_reject'], 'Request')
    can(['read_summary', 'edit_summary'], 'Request')
    can(['read_financial_analyze', 'edit_financial_analyze'], 'Request')
    can(['read_analyze', 'edit_analyze', 'revert_analyze'], 'Request')
    can(['read_conclusion', 'edit_conclusion', 'approve_conclusion'], 'Request')

    // Users
    can(['list', 'read', 'edit', 'edit_type', 'create', 'delete', 'organisation', 'disable'], 'User')

    return rules;
}

function defineRulesForConseille(user) {
    const { can, rules } = AbilityBuilder.extract();
    if (!user) user = JSON.parse(localStorage.getItem('user'));

    // Organisations
    can(['read'], 'Organisation')

    // Notes
    can(['list', 'read', 'edit', 'create'], 'Note')

    // Requests
    can(['list', 'read'], 'Request')
    can(['read_form', 'read_form_always'], 'Request')
    can(['read_financial_page', 'edit_financial_page', 'edit_financial_page_year'], 'Request')
    can(['read_financial_summary', 'edit_financial_summary'], 'Request')
    can(['see_backstage'], 'Request')
    can(['read_summary'], 'Request')
    can(['read_analyze'], 'Request')
    can(['read_financial_analyze'], 'Request')
    can(['read_conclusion'], 'Request')

    if (user) {
        can(['assign_secondaries', 'assign_financial', 'save_assignees'], 'Request', { responsible_id: user.id })
        can(['edit_form', 'submit_form', 'edit_financial_page', 'edit_financial_page_year'], 'Request', { responsible_id: user.id })
        can(['approve_or_reject', 'edit_summary', 'edit_analyze', 'edit_financial_analyze', 'revert_analyze'], 'Request', { responsible_id: user.id })
        can(['read_conclusion', 'edit_conclusion', 'approve_conclusion'], 'Request', { responsible_id: user.id })

        can(['edit_analyze'], 'Request', { request_users: { user_id: user.id } })
        can(['edit_financial_analyze'], 'Request', { analyste_id: user.id })
    }

    // Users
    if (user) {
        can(['read', 'edit'], 'User', { id: user.id });
    }

    return rules;
}

function defineRulesForBenevole(user) {
    const { can, rules } = AbilityBuilder.extract();
    if (!user) user = JSON.parse(localStorage.getItem('user'));

    // Organisations
    can(['read'], 'Organisation')

    // Notes
    can(['list', 'read'], 'Note')
    // can(['edit'], 'Note', { confidential: 0 })

    // Requests
    can(['list', 'read'], 'Request')
    can(['read_form'], 'Request')
    can(['read_financial_page'], 'Request')
    can(['read_financial_summary'], 'Request')
    can(['see_backstage'], 'Request')
    can(['read_summary'], 'Request')
    can(['read_analyze', 'edit_analyze', 'read_financial_analyze'], 'Request')

    if (user) {
        can(['edit_financial_analyze'], 'Request', { analyste_id: user.id })
    }

    // Users
    if (user) {
        can(['read', 'edit'], 'User', { id: user.id });
    }

    return rules;
}

function defineRulesForAnalyste(user) {
    const { can, rules } = AbilityBuilder.extract();
    if (!user) user = JSON.parse(localStorage.getItem('user'));

    // Organisations
    can(['list', 'read'], 'Organisation')

    // Notes
    can(['list', 'read'], 'Note')

    // Requests
    can(['list', 'read', 'assign_financial_self'], 'Request', { state_id: REQ_STATE_ANALYSIS })
    can(['read_form'], 'Request')
    can(['read_financial_page', 'edit_financial_page'], 'Request')
    can(['read_financial_summary', 'edit_financial_summary'], 'Request')
    can(['see_backstage'], 'Request')
    can(['read_summary'], 'Request')
    can(['read_analyze', 'edit_analyze', 'read_financial_analyze', 'edit_financial_analyze'], 'Request')


    if (user) {
        can([], 'Request', { analyste_id: user.id })
    }

    // Users
    if (user) {
        can(['read', 'edit'], 'User', { id: user.id });
    }

    return rules;
}

function defineRulesForOrganisation(user) {
    const { can, rules } = AbilityBuilder.extract();
    if (!user) user = JSON.parse(localStorage.getItem('user'));

    // Organisations
    can(['read', 'edit'], 'Organisation')

    // Requests
    can(['list', 'read'], 'Request')
    can(['read_form', 'edit_form', 'submit_form'], 'Request')
    can(['read_financial_page', 'edit_financial_page', 'edit_financial_page_year'], 'Request')

    // Users
    if (user) {
        can(['read', 'edit'], 'User', { id: user.id });
    }

    return rules;
}

export var ABILITIES = { [USER_RANK_ADMIN]: admin, [USER_RANK_CONSEILLE]: conseille, [USER_RANK_BENEVOLE]: benevole, [USER_RANK_ANALYSTE]: analyste, [USER_RANK_ORG]: organisation, 0: def }
