import { API_DEV, REQ_STATE_SUBMISSION, REQ_STATE_COMPLETED, REQ_TYPE_FOA_RENOUVELLEMENT, REQ_TYPE_FOA_RECONDUCTION } from '../constants';
import { header, handleAPIResult, serialize, serializeMaterialFilter } from '../utils';


export const requestService = {
    list,
    listPaginate,
    get,
    update,
    createBatch,
    listStates,
    listTypes,
    getRecommendation,
    createRecommendation,
    updateRecommendation,
    preformulateRecommendations,
    getColors,
    getReporting,
};

function list(filters = {}) {
    const getInfo = {
        method: 'GET',
        headers: header()
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests?' + serialize(filters), getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
};

function listPaginate(page_number = 0, per_page = 0, filters = [], orderBy, orderDirection, currentUser = '',) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    let archived = '';
    if(currentUser === '' || currentUser.user_type.rang !== 1){
        let archivedFilter = false;
        let i = 0;
        while(archivedFilter === false && i < filters.length) {
            if(filters[i].column.field === "archived") archivedFilter = true
            i++;
        }
        if(archivedFilter === false) archived = '&archived=' + 0;
    } 

    var pagination = '';
    if (page_number !== 0) {
        pagination = '&page_number=' + page_number + '&per_page=' + per_page;
    }

    var order = '';
    if (orderBy) {
        order = '&order_by=' + (orderBy.orderField ? orderBy.orderField : orderBy.field) + '&order_direction=' + orderDirection.toUpperCase();
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests?' + serializeMaterialFilter(filters) + pagination + archived + order, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function get(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests/' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
};

function update(req) {
    const id = req.id;
    delete req.id;

    var reqUsers = []
    if ('benevoles' in req) {
        var benevoles = req.benevoles;
        delete req.benevoles;

        for (var type in benevoles)
            reqUsers.push({ request_id: id, user_id: benevoles[type], type_id: type })
    }

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            request: req
        })
    }

    var updateReq = () => new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });

    return new Promise((resolve, reject) => {
        if (reqUsers.length > 0) {
            updateOrCreateUserAssociated(reqUsers)
                .then(result => {
                    updateReq()
                        .then(result => resolve(result))
                        .catch(error => reject(error))
                })
                .catch(error => {
                    reject(error)
                })
        } else {
            updateReq()
                .then(result => resolve(result))
                .catch(error => reject(error))
        }
    })
}

function updateOrCreateUserAssociated(reqUsers) {
    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
           request_users: reqUsers
       })
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + `/request_users`, postInfo)
            .then(result => {
                handleAPIResult(result, reject, 204)
                resolve(true)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function createBatch(batch) {
    var { orgs } = batch;
    var requests = orgs.map(o => {
        var r = {
            type_id: batch.type_id,
            state_id: REQ_STATE_SUBMISSION,
            organization_id: o,
            date_created: new Date(),
            date_close: batch.date_close,
            financial_year: batch.financial_year
        }

        return r;
    })

    const postInfo = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...header()
        },

        body: JSON.stringify({
            requests: requests
        })
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function listStates() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests/states', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}

function listTypes() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests/types', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}

function getRecommendation(reqId) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/requests/' + reqId + '/conclusion', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}

function createRecommendation(rec) {
    var formData = new FormData();
    for (var key in rec) {
        if (rec[key]) formData.append(`conclusion[${key}]`, rec[key])
    }

    const postInfo = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete postInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/conclusions', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    });
}

function updateRecommendation(rec) {
    var id = rec.id;

    if (!id)
        return createRecommendation(rec);

    var formData = new FormData();
    for (var key in rec) {
        if (key === "id" || rec[key] === undefined || rec[key] ===  null) continue;
        formData.append(`conclusion[${key}]`, rec[key])
    }

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete patchInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/conclusions/' + id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function preformulateRecommendations(req) {
    return new Promise((resolve, reject) => {
        if (req.request_id) {
            getRecommendation(req.request_id).then(rec => {
                var clone = true;
                if (!rec) {
                    rec = {}
                    clone = false
                }

                rec.request_id = req.id;
                rec.done = 0;
                rec.ammount_recommended = rec.ammount_final;
                delete rec.id;
                delete rec.ammount_final;

                createRecommendation(rec).then(newRec => {
                    resolve({ ...newRec, updateState: clone });
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        } else {
            resolve(false);
        }
    });

}

function getColors() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/colors', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}

function getReporting(type_id, year, regions, states) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    var qs = serialize({ regions, states })

    return new Promise((resolve, reject) => {
        fetch(API_DEV + `/requests/reporting/${type_id}/${year}?${ qs }`, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}
