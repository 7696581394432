import React from 'react';
import { formatNo } from '../utils';
import {
    Counter,
    Donut,
    GroupedBar,
    Histogram,
    HorizontalHistogram,
    Line,
    Pie,
    CentraideGroupedHorizontalHistogram,
} from '../components/charts/viz';

export const sections = [
    {
        charts: [
            {
                title: 'Nombre de demandes complétées',
                icon: 'send',
                legend: '',
                url: '/statistiques/request_number',
                component: GroupedBar,
                width: 4,
                classes: '',
                props: {
                    colorPalette: 'request_types',
                    xAxisLabel: 'Année',
                    yAxisLabel: 'Nombre de demandes',
                    xKey: 'year',
                    groupBy: 'label',
                    crosshair: (crosshair) => {
                        return (
                            <div className="vis-crosshair">
                                <h4>Année : {(crosshair[0] || {}).x}</h4>
                                {(crosshair || []).map((c, i) => (
                                    <p key={i}>
                                        {c.group} : {formatNo(c.y)}
                                    </p>
                                ))}
                            </div>
                        );
                    },
                },
                // excludedByFilters: []
            },
        ],
    },
    {
        charts: [
            {
                title: 'Investissements',
                icon: 'attach_money',
                legend: '',
                url: '/statistiques/request_repartition/?repartition=amount',
                component: GroupedBar,
                width: 4,
                classes: '',
                formatData: (data) => {
                    const groups = [
                        {
                            key: 'value_ammount_asked',
                            label: 'Montant demandé',
                        },
                        {
                            key: 'value_ammount_recommended',
                            label: 'Montant recommandé',
                        },
                        {
                            key: 'value_ammount_final',
                            label: 'Montant accordé',
                        },
                    ];

                    var formatted = [];

                    var years = {};
                    var setYear = (year) => {
                        years[year] = {};
                        groups.map((g) => {
                            years[year][g.key] = 0;
                        });
                    };

                    data.forEach((d) => {
                        if (!(String(d.year) in years)) setYear(d.year);

                        groups.forEach((g) => {
                            years[d.year][g.key] += parseInt(d[g.key] || 0);
                        });
                    });

                    for (var year in years) {
                        groups.forEach((g) => {
                            formatted.push({
                                year: year,
                                type: g.label,
                                value: years[year][g.key],
                            });
                        });
                    }

                    return formatted;
                },
                props: {
                    colorPalette: 'amounts',
                    xAxisLabel: 'Année',
                    yAxisLabel: 'Montant ($)',
                    // xType: 'linear',
                    xKey: 'year',
                    groupBy: 'type',
                    marginLeft: 100,
                    units: ' $',
                    crosshair: (crosshair) => {
                        return (
                            <div className="vis-crosshair">
                                <h4>Année : {(crosshair[0] || {}).x}</h4>
                                {(crosshair || []).map((c, i) => (
                                    <p key={i}>
                                        {c.group} : {formatNo(c.y)} $
                                    </p>
                                ))}
                            </div>
                        );
                    },
                },
                // excludedByFilters: []
            },
        ],
    },
    {
        charts: [
            {
                title: 'Part relative moyenne de Centraide',
                icon: 'bubble_chart',
                legend: '',
                url: '/statistiques/request_repartition/?repartition=relative_part',
                component: Histogram,
                width: 4,
                classes: '',
                props: {
                    xAxisLabel: 'Année',
                    yAxisLabel: 'Part relative (%)',
                    crosshair: (crosshair) => {
                        return (
                            <div className="vis-crosshair">
                                <h4>Année : {(crosshair[0] || {}).x}</h4>
                                <p>{formatNo((crosshair[0] || {}).y || 0)} %</p>
                            </div>
                        );
                    },
                },
                // excludedByFilters: []
            },
        ],
    },
    {
        charts: [
            {
                title: 'Personnes rejointes',
                icon: 'people',
                legend: '',
                url: '/statistiques/people_repartition/?repartition=personne_rejointe',
                component: GroupedBar,
                width: 4,
                classes: '',
                formatData: (data) => {
                    var formatted = [];

                    for (var year in data) {
                        var yearData = data[year];

                        formatted.push({
                            label: 'Estimé',
                            year: year,
                            value: yearData.estime,
                        });

                        formatted.push({
                            label: 'Réel',
                            year: year,
                            value: yearData.reel,
                        });
                    }

                    return formatted;
                },
                props: {
                    xAxisLabel: 'Année',
                    yAxisLabel: 'Nombre de personnes',
                    xKey: 'year',
                    groupBy: 'label',
                    marginLeft: 80,
                    crosshair: (crosshair) => {
                        return (
                            <div className="vis-crosshair">
                                <h4>Année : {(crosshair[0] || {}).x}</h4>
                                {(crosshair || []).map((c, i) => (
                                    <p key={i}>
                                        {c.group} : {formatNo(c.y)}
                                    </p>
                                ))}
                            </div>
                        );
                    },
                },
                // excludedByFilters: []
            },
        ],
    },
    {
        charts: [
            {
                title: 'Membres et bénévoles',
                icon: 'assignment_ind',
                legend: '',
                url: '/statistiques/people_repartition/?repartition=benevole',
                component: GroupedBar,
                width: 4,
                classes: '',
                formatData: (data) => {
                    const labels = {
                        benevole: 'Bénévoles',
                        membre: 'Membres',
                    };

                    var formatted = [];

                    for (var year in data) {
                        var yearData = data[year];

                        for (var label in yearData) {
                            formatted.push({
                                year: year,
                                label: labels[label] || label,
                                value: yearData[label],
                            });
                        }
                    }

                    return formatted;
                },
                props: {
                    xAxisLabel: 'Année',
                    yAxisLabel: 'Nombre de personnes',
                    xKey: 'year',
                    groupBy: 'label',
                    marginLeft: 80,
                    crosshair: (crosshair) => {
                        return (
                            <div className="vis-crosshair">
                                <h4>Année : {(crosshair[0] || {}).x}</h4>
                                {(crosshair || []).map((c, i) => (
                                    <p key={i}>
                                        {c.group} : {formatNo(c.y)}
                                    </p>
                                ))}
                            </div>
                        );
                    },
                },
                // excludedByFilters: []
            },
        ],
    },
    {
        charts: [
            {
                title: 'Couleurs des organismes',
                icon: 'color_lens',
                legend: '',
                url: '/statistiques/request_repartition/?repartition=colors',
                component: HorizontalHistogram,
                width: 4,
                height: 'auto',
                classes: '',
                noSort: true,
                formatData: (data) => {
                    if (data.length === 0) return [];

                    const colors = ['Vert', 'Jaune', 'Orange', 'Rouge'];
                    var formatted = [];

                    colors.forEach((color) => {
                        var colorData = (data || []).filter(
                            (d) => d.label === color
                        );
                        var value = colorData.reduce((a, b) => a + b.value, 0);

                        formatted.push({
                            label: color,
                            value: value,
                        });
                    });

                    return formatted;
                },
                props: {
                    colorPalette: 'colors',
                },
                // excludedByFilters: []
            },
        ],
    },
    {
        charts: [
            {
                title: 'Activités et services',
                icon: 'insert_invitation',
                legend: '',
                url: '/statistiques/request_repartition/?repartition=activite',
                component: CentraideGroupedHorizontalHistogram,
                width: 4,
                height: 'auto',
                classes: '',
                formatData: (data) => {
                    var formatted = [];
                    var sum = {};

                    for (var year in data) {
                        var yearData = data[year];

                        if (Object.keys(sum).length === 0) {
                            sum = yearData;
                        } else {
                            for (var section in yearData) {
                                var yearSectionData = yearData[section];

                                for (var subSection in yearSectionData) {
                                    var yearSubSectionData =
                                        yearSectionData[subSection];

                                    if (
                                        typeof sum[section][subSection][1] ===
                                        'undefined'
                                    )
                                        sum[section][subSection][1] = 0;
                                    if (
                                        typeof sum[section][subSection][2] ===
                                        'undefined'
                                    )
                                        sum[section][subSection][2] = 0;
                                    sum[section][subSection][1] +=
                                        yearSubSectionData[1];
                                    sum[section][subSection][2] +=
                                        yearSubSectionData[2];
                                }
                            }
                        }
                    }

                    for (var section in sum) {
                        var sectionSum = sum[section];
                        var subSections = [];

                        for (var subSection in sectionSum) {
                            subSections.push({
                                label: subSection,
                                data: [
                                    {
                                        label: '1 - Activité régulière ou courante',
                                        value: sectionSum[subSection][1],
                                    },
                                    {
                                        label: '2 - Activité occasionnelle ou spéciale',
                                        value: sectionSum[subSection][2],
                                    },
                                ],
                            });
                        }

                        if (subSections.length > 0)
                            formatted.push({
                                label: section,
                                data: subSections,
                            });
                    }

                    return formatted;
                },
                props: {
                    colorPalette: '1_2',
                },
                // excludedByFilters: []
            },
        ],
    },
];
