import { userActions } from '../actions'
import store from '../store';
import { Answer } from '../models';
import { log } from '../utils';


export function handleAPIResult(result, reject, wanted = 200) {
    if (result.status === 401) {
        store.dispatch(userActions.logout());
        reject(result.statusText)
    }

    // 403 pour pas permission

    if (result.status !== wanted) {
        var e = new Error(`(${ result.statusText }) ${ result.url }`);
        e.name = 'APIError';
        log(e);
        reject(result.statusText);
    }
}

export function handleAnswersFormat(answers) {
    var a = {}
    Object.keys(answers).forEach(key => {
        a = { ...a, ...Answer(answers[key].option_id, answers[key].value, answers[key].details) };
    });

    return a;
}
