import { API_DEV } from '../constants';
import { header, handleAPIResult, serialize, serializeMaterialFilter } from '../utils';


export const noteService = {
    listPaginate,
    list,
    get,
    edit,
    create,
    listTypes
};

function listPaginate(page_number = 0, per_page = 0, filters = [], orderBy, orderDirection, currentUser = '') {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    let ending_date = '';
    if(currentUser !== '' && currentUser.user_type.rang !== 1){
        let endingDateFilter = false;
        let i = 0;
        while(endingDateFilter === false && i < filters.length) {
            if(filters[i].column.field === "ending_date") endingDateFilter = true
            i++;
        }
        if(endingDateFilter === false) ending_date = '&ending_date=' + 0;
        
    } 

    var pagination = '';
    if (page_number !== 0) {
        pagination = '&page_number=' + page_number + '&per_page=' + per_page;
    }

    var order = '';
    if (orderBy) {
        order = '&order_by=' + (orderBy.orderField ? orderBy.orderField : orderBy.field) + '&order_direction=' + orderDirection.toUpperCase();
    }

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/notes?' + serializeMaterialFilter(filters) + ending_date + pagination + order, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
};

function list(filters = {}) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/notes?' + serialize(filters), getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
};

function get(id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/notes/' + id, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
};

function edit(note) {
    var formData = new FormData();
    for (var key in note) {
        if (key in note) {
            if (note[key] === true) note[key] = '1'
            formData.append(`note[${key}]`, note[key])
        }
    }

    const patchInfo = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete patchInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/notes/' + note.id, patchInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json();
            })
            .then(result => {
                resolve(true);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function create(note) {
    var formData = new FormData();
    for (var key in note) {
        if (key in note) {
            if (note[key] === true) note[key] = '1'
            formData.append(`note[${key}]`, note[key])
        }
    }

    const postInfo = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete postInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/notes', postInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.json();
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function listTypes() {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/note_types', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
       });
}
