import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

import { Can, API_DEV, REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ACCEPTED, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION, REQ_STATE_COMPLETED } from '../../constants'
import { header, history, requestHomeLookups, generateMaterialFilter, loadDefaultFilter } from '../../utils';

import { connect } from 'react-redux';

import { requestService, organisationUserService } from "../../services";

import { columns } from '../../constants/tablesColumns';
import MaterialTable from 'material-table';
import { materialTableProps, ResetButton } from '../../utils';


class HomeOrganisation extends Component {

    constructor(props) {
        super(props);

        const tableKey_request = "request_home_organisme";
        const tableKey_requestHistoric = "requestHistoric_home_organisme";

        this.state = {
            org: {},
            _tableKey_request: tableKey_request,
            _title_request: "Centraide - Liste des demandes en cours",
            requests: {
                possibleColumns: (columns.getKeys('requests') || []).map(c => {
                    var completeCol = { ...columns['requests']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_request, completeCol);
                    return { ...completeCol }
                })
            },
            _tableKey_requestHistoric: tableKey_requestHistoric,
            _title_requestHistoric: "Centraide - Liste des demandes complétées",
            requestsHistoric: {
                possibleColumns: (columns.getKeys('requestsHistoric') || []).map(c => {
                    var completeCol = { ...columns['requestsHistoric']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_requestHistoric, completeCol);
                    return { ...completeCol }
                })
            }
        }
    }

    componentDidMount() {
        organisationUserService.getAssociationUser(this.props.id).then(result => {
            this.setState({
                org: result.length > 0 ? result[0].organization : {}
            });
        })
        .catch((e) => {
            this.setState(() => { throw new Error(e); })
        });

        requestHomeLookups(this, this.state.requests.possibleColumns);
        requestHomeLookups(this, this.state.requestsHistoric.possibleColumns);
    }

    handleRequestData(query) {
        query = JSON.parse(JSON.stringify(query));
        const defaultStateFilterValue = [REQ_STATE_SUBMISSION, REQ_STATE_SUBMITTED, REQ_STATE_CORRECTION, REQ_STATE_ACCEPTED, REQ_STATE_ANALYSIS, REQ_STATE_ANALYZED, REQ_STATE_READY_FOR_APPROBATION];
        var index = -1;
        query.filters.forEach((el, i) => { if(el.column.field === "state_id") index = i; } );

        if (index === -1) {
            query.filters.push(generateMaterialFilter('state_id', defaultStateFilterValue));
            index = query.filters.length - 1;
        }
        if (query.filters[index].value.length === 0) {
            query.filters[index].value = defaultStateFilterValue;
        }

        return new Promise(function (resolve, reject) {
            requestService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    handleHistoricData(query) {
        query = JSON.parse(JSON.stringify(query));
        query.filters.push(generateMaterialFilter('state_id', REQ_STATE_COMPLETED));

        return new Promise(function (resolve, reject) {
            requestService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                result.data.map((el) => {
                    el.date_close = el.date_close ? new Date(el.date_close) : null;
                    return el;
                });

                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    render() {
        const { org } = this.state;

        return (
            <Fragment>
                <section>
                    {(org) && (
                        <article className="box title-box">
                            <h2 onClick={ () => history.push(`/organisations/${org.id}`)} className="title-link">{org.name}</h2>
                        </article>
                    )}

                    <article className="box table-box">
                        <h3>Demande(s) en cours</h3>
                        <ResetButton tableKey={ this.state._tableKey_request } />
                        <MaterialTable
                            title={this.state._title_request}
                            columns={this.state.requests.possibleColumns}
                            data={query => this.handleRequestData(query)}
                            {...materialTableProps(this.state._tableKey_request, this.state._title_request, true)}
                            onRowClick={(event, rowData) => { history.push(`/requests/${rowData.id}`) }} />
                    </article>

                    <article className="box table-box">
                        <h3>Historique des demandes</h3>
                        <ResetButton tableKey={ this.state._tableKey_requestHistoric } />
                        <MaterialTable
                            title={this.state._title_requestHistoric}
                            columns={this.state.requestsHistoric.possibleColumns}
                            data={query => this.handleHistoricData(query)}
                            {...materialTableProps(this.state._tableKey_requestHistoric, this.state._title_requestHistoric, true)}
                            onRowClick={(event, rowData) => { history.push(`/requests/${rowData.id}`) }} />
                    </article>
                </section>
            </Fragment>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        id: state.user.infos.id,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeOrganisation);
