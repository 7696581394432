import React, { Component, Fragment } from 'react';
import CountUp from 'react-countup';

import { findMinMax, label, sortFunction, color } from '../../../utils';


// Composant graphique pour afficher un Histogram Horizontal.
class HorizontalHistogram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            active: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes || !nextProps.data) return null

        var sum = nextProps.data.reduce((a, b) => a + b.value, 0);
        return {
            data: (nextProps.data || []).map(d => ({
                ...d,
                perc: parseInt(d.value / sum * 100)
            })),
            refreshes: nextProps.refreshes
        }
    }


    render() {
        const { data, active } = this.state;
        const { perc } = this.props;

        return (
            <ul className="horiz-histo-wrap" onMouseOut={ () => this.setState({ active: false })}>
                { data.map((d, i) => (
                    <li key={ i } onMouseOver={ () => this.setState({ active: i })} className={ active !== false ? (i === active ? 'hovered' : 'not-hovered') : ''}>
                        <span className="histo-label">
                            <b>{ d.label }</b>
                            { perc ? (
                                <CountUp separator=" " decimal="," end={ d.perc } preserveValue suffix='%' />
                            ) : (
                                <CountUp separator=" " decimal="," end={ d.value } preserveValue />
                            )}
                        </span>

                        <span className="histo-bar">
                            <span className="histo-bar-fill" style={{ 'width': `${ d.perc }%`, 'background': color(this.props.colorIndex || i, d.label, this.props.colorPalette) }}></span>
                        </span>
                    </li>
                ))}
            </ul>
        )
    }
}

export default HorizontalHistogram;
