import React, { Component, Fragment } from 'react';
import {
    makeVisFlexible,
    DiscreteColorLegend,
    RadialChart,
} from 'react-vis';
import ReactResizeDetector from 'react-resize-detector';

import { color, defaultLabel, sortFunction, findMinMax, renderHint, sortFunctionByValue, renderDiscrete } from '../../../utils';


// Composant graphique pour afficher un Donut chart.
class Donut extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hint: false,
            data: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes || !nextProps.data) return null

        var data = (nextProps.data || [])
        data = data.sort((a, b) => sortFunctionByValue(a, b))

        data = data.map((d, i) => {
            if (typeof d.label === 'number') {
                if (d.label === 0) d.label = 'Non'
                if (d.label === 1) d.label = 'Oui'
            }

            d.label = d.label ? String(d.label) : defaultLabel
            d.color = color(i, d.label, nextProps.colorPalette)

            return d;
        })

        return {
            data,
            refreshes: nextProps.refreshes
        }
    }


    render() {
        const { hint } = this.state;
        var data = [...this.state.data];

        // Logique pour les effets du hover.
        (data || []).map(d => {
            if (hint) {
                d.className = (d.label === hint.label) ? "hovered" : "not-hovered"
            } else {
                d.className = ""
            }
        })

        return (
            <div style={{ 'width': '50%', 'height': '100%' }} className="pie-chart-wrap">
                <ReactResizeDetector handleWidth>
                    { ({ width }) => {
                        var diameter = ((width > this.props.height) ? this.props.height : width) - 30;
                        return (
                            <RadialChart
                                colorType="literal"
                                animation="noWobble"
                                width={ width || this.props.height }
                                height={ this.props.height }
                                innerRadius={ diameter / 2 - 25 }
                                radius={ diameter / 2 - 0 }
                                getAngle={ d => d.value }
                                data={ data || [] }
                                padAngle={ 0.06 }
                                onValueMouseOver={ (v) => this.setState({ hint: v }) }
                                onSeriesMouseOut={ (v) => this.setState({ hint: false }) } >

                                { hint && renderHint(hint, this.props.hint && this.props.hint(hint)) }

                                <DiscreteColorLegend
                                    items={ (data || []).map((d, i) => ({ title: renderDiscrete(d), color: color(i, d.label, this.props.colorPalette), disabled: d.className === "not-hovered", hint: { ...d, x: 1, y: -1 } })) }
                                    onItemMouseEnter={ (item) => this.setState({ hint: item.hint }) }
                                    onItemMouseLeave={ (item) => this.setState({ hint: false }) } />
                            </RadialChart>
                        )
                    } }
                </ReactResizeDetector>
            </div>
        )
    }
}

export default Donut;
