import React, { Component, Fragment } from 'react';
import {
    FlexibleXYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    DiscreteColorLegend
} from 'react-vis';

import { findMinMax, sortFunction, label, color, renderCrosshair, renderXAxisLabel, renderYAxisLabel, renderGroupedDiscrete, renderTick } from '../../../utils';


// Composant graphique pour afficher un Grouped Bar.
class GroupedBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            crosshair: [],
            data: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes || !nextProps.data) return null
        const { xKey, groupBy } = nextProps;

        var data = (nextProps.data || []);
        var groupKeys = data
            .map(d => d[groupBy])
            .filter((value, index, self) => self.indexOf(value) === index)

        var xKeys = data
            .map(d => d[xKey])
            .filter((value, index, self) => self.indexOf(value) === index)

        var groups = groupKeys.map((g, i) => {
            return xKeys.map(x => {
                var item = data.find(d => (d[xKey] === x && d[groupBy] === g)) || {}
                return ({ x: label(x), y: item.value || 0, group: g, color: color(i, item.label || "", nextProps.colorPalette) })
            })
        })

        return {
            data: groups,
            refreshes: nextProps.refreshes
        }
    }

    _onNearestX = (value, {index}) => {
        this.setState({ crosshair: this.state.data.map(d => d[index]) });
    };

    render() {
        const { crosshair } = this.state;
        const { xAxisLabel, yAxisLabel } = this.props;

        // Logique pour les effets du hover.
        var data = [...this.state.data];
        (data || []).map(set => {
            if (crosshair.length > 0) {
                set.map(d => { d.opacity = (d.x === crosshair[0].x) ? 1 : 0.1 })
            } else {
                set.map(d => { d.opacity = 1 })
            }
        })

        // Gestion de l'axe des X en fonction des props.
        const plotProps = {
            xType: (this.props.xType) ? this.props.xType : 'ordinal',
            xDomain: (this.props.xDomain) ? this.props.xDomain : null,
        }

        var yDomain = [0, 0];
        data.forEach(d => {
            var minMax = findMinMax(d, 'y', 5)
            if (minMax[1] > yDomain[1])
                yDomain = minMax;
        })

        return (
            <div className="grouped-bar-wrap">
                <FlexibleXYPlot
                    { ...plotProps }
                    margin={{ left: this.props.marginLeft || 50 }}
                    yDomain={ yDomain }
                    onMouseLeave={ () => this.setState({ crosshair: [] }) }>

                    <HorizontalGridLines />
                    <XAxis />
                    <YAxis tickFormat={ renderTick } />

                    { data.map((d, i) => (
                        <VerticalBarSeries
                            key={ i }
                            colorType="literal"
                            data={ d }
                            onNearestX={ (i === 0) ? this._onNearestX : null }  />
                    )) }

                    { xAxisLabel && renderXAxisLabel(xAxisLabel) }
                    { yAxisLabel && renderYAxisLabel(yAxisLabel, this.props.marginLeft || 50) }

                    { renderCrosshair(crosshair, this.props.crosshair && this.props.crosshair(crosshair)) }
                </FlexibleXYPlot>

                <DiscreteColorLegend
                    className="grouped-bar-legend"
                    items={ (data || []).map((d, i) => ({ title: renderGroupedDiscrete(d, this.props.units), color: color(i, d[0].group, this.props.colorPalette), strokeWidth: 6 })) } />
            </div>
        )
    }
}

export default GroupedBar;
