import { API_DEV } from '../constants';
import { header, handleAPIResult, serialize } from '../utils';


export const documentService = {
    getQuestionFromForm,
    getOptionsFromQuestions,
    getFromType,
    get,
    create
};

function getQuestionFromForm(formId) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/questions?form_id=' + formId + '&type_id=6', getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getOptionsFromQuestions(questions) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    var qs = questions.map(q => 'question_id[]=' + q.id).join('&')

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/options?' + qs, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function getFromType(typeId) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/documents?type_id=' + typeId, getInfo)
            .then(result => {
                handleAPIResult(result, reject)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function get(request_id, type_id) {
    const getInfo = {
        method: 'GET',
        headers: header()
    };

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/documents/' + request_id + '/' + type_id , getInfo)
            .then(result => {
                handleAPIResult(result, reject);
                return result.blob();
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function create(doc) {
    var formData = new FormData();
    formData.append("document[label]", doc.name);
    formData.append("document[url]", doc.url);
    formData.append("document[type_id]", doc.type_id);
    formData.append("document[request_id]", doc.request_id);

    const postInfo = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...header()
        },

        body: formData
    }

    delete postInfo.headers['Content-Type'];

    return new Promise((resolve, reject) => {
        fetch(API_DEV + '/documents', postInfo)
            .then(result => {
                handleAPIResult(result, reject, 204)
                return result.json()
            })
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}
