import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import { Can, API_DEV, columns, USER_RANK_ORG } from '../../constants'
import { title, requestLookups, noteLookups, history, materialTableProps, validateProfileFields, generateMaterialFilter, loadDefaultFilter, ResetButton } from '../../utils';

import { connect } from 'react-redux';

import { organisationService, requestService, userService, noteService, organisationUserService } from "../../services";

import { BackButton } from '../../components';
import MaterialTable from 'material-table';import { Input, Select, notification } from 'antd';
import { Prompt } from 'react-router';

class OrganisationPage extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params
        const tableKey_notes = "note_organisation";
        const tableKey_requests = "request_organisation";

        this.state = {
            id: id,
            org: {
                __type: "Organisation"
            },
            req: [],
            action_fields: [],
            sectors: [],
            subsectors: [],
            users: [],
            associations: [],
            isUpToDate: true,
            errors: {},

            _tableKey_notes: tableKey_notes,
            notes: {
                rendered: true,
                possibleColumns: (columns.getKeys('notes') || []).map(c => {
                    var completeCol = { ...columns['notes']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_notes, completeCol);
                    return { ...completeCol }
                })
            },
            _tableKey_requests: tableKey_requests,
            requests: {
                rendered: true,
                possibleColumns: (columns.getKeys('requests') || []).map(c => {
                    var completeCol = { ...columns['requests']()[c], field: c.toLowerCase() };
                    loadDefaultFilter(tableKey_requests, completeCol);
                    return { ...completeCol }
                })
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
        this.handleCreateSubmit = this.handleCreateSubmit.bind(this);
        this.handleNotesPagination = this.handleNotesPagination.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    componentDidMount() {
        if (!this.props.create && this.state.id) {
            organisationService.get(this.state.id).then((result) => {
                this.setState({ org: { ...this.state.org, ...result } })
            }).catch((e) => {
                this.setState(() => { throw new Error(e); })
            })

            organisationUserService.getAssociationOrg(this.state.id).then((result) => {
                if (result.length > 0) {
                    this.setState({
                        org: {
                            ...this.state.org,
                            associated_user_id: result[0].user.id,
                            association_id: result[0].id
                        }
                    });
                    if(!(this.props.create || this.props.edit)) {
                        this.setState({
                            org: {
                                ...this.state.org,
                                associated_user_name: result[0].user.name + ' ' + result[0].user.surname
                            }
                        })
                    }
                }
            })

            if (!this.props.edit) {
                requestLookups(this, this.state.requests.possibleColumns);
                noteLookups(this, this.state.notes.possibleColumns);
            }

        }

        if(this.props.create || this.props.edit) {
            organisationService.getActionFields().then((result) => {
                this.setState({
                    action_fields: result
                });
            })

            organisationService.getSectors().then((result) => {
                this.setState({
                    sectors: result
                });
            })

            organisationService.getSubSectors().then((result) => {
                this.setState({
                    subsectors: result
                });
            })

            userService.list().then((result) => {
                this.setState({
                    users: result
                });
            })

            organisationUserService.get().then(result => {
                this.setState({
                    associations: result
                });
            })
        }

        window.onbeforeunload = function(event) {
            if (!this.state.isUpToDate)
                return 'Refresh';
        }.bind(this)
    }

    handleChange(e) {
        e.preventDefault();
        const { edit } = this.props;
        const { isUpToDate } = this.state;

        this.setState({
            org: {
                ...this.state.org,
                [e.target.name]: e.target.value
            },
            isUpToDate: (edit && isUpToDate) ? false : isUpToDate
        })
    }

    handleSelectChange = name => value => {
        const { edit } = this.props;
        const { isUpToDate } = this.state;

        this.setState({
            org: {
                ...this.state.org,
                [name]: value,
            },
            isUpToDate: (edit && isUpToDate) ? false : isUpToDate
        });
    }

    handleEditSubmit(e) {
        e.preventDefault();

        if (!this.validateFields()) return;

        const { id, org } = this.state;

        organisationService.edit(id, org).then(() => {
            notification.open({
                message: 'Enregistrement effectué',
                description: 'Vos changements ont été enregistrés avec succès.',
                placement: 'bottomRight',
            });
            this.setState({
                isUpToDate: true,
            });

            history.replace(`/organisations/${id}`)
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
            this.setState({
                isUpToDate: false,
            });
        })
    }

    handleCreateSubmit(e) {
        e.preventDefault();

        if (!this.validateFields()) return;

        const { org } = this.state;

        organisationService.create(org).then((o) => {
            notification.open({
                message: 'Organisme créé',
                description: 'L\'organisme a été créé avec succès.',
                placement: 'bottomRight',
            });

            history.replace(`/organisations/${o.id}`)
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        })
    }

    validateFields() {
        const { org } = this.state;
        var validation = validateProfileFields(org, ['name', 'email_general', 'tel_service', 'region', 'action_field_id', 'sector_id', 'subsector_id'])

        this.setState({ errors: validation.errors })
        return !validation.hasError;
    }

    generatePage(pageTitle) {
        if (this.props.edit) {
            return this.generateEditPage(pageTitle);
        }
        else if (this.props.create) {
            return this.generateCreatePage(pageTitle);
        }
        else {
            return this.generateShowPage(pageTitle);
        }
    }

    generateShowPage(pageTitle) {
        const { org, req, requests, notes } = this.state;

        return (
            <Fragment>
                <BackButton />
                <h1>{ pageTitle }</h1>

                <section>
                    <Can do="read" on={ org }>
                        <Can do="edit" on={ org }>
                            <article className="buttons">
                                <a className="button full" onClick={() => history.replace('/organisations/' + this.state.id + '/edit')}>Éditer</a>
                            </article>
                        </Can>
                        <article className="box title-box">
                            <h2>{ org.name }</h2>
                        </article>
                        <article className="box">
                            <ul className="profile-main-content larger read">
                                <Can do="num_org" on={ org }>
                                    <li><label>Numéro d'organisme : </label><b>{org.organisation_number}</b></li>
                                </Can>
                                <li><label>Nom, selon lettres-patentes : </label><b>{org.patent_name}</b></li>
                                <li><label>Nom organismes, Lettres : </label><b>{org.name}</b></li>
                                <li><label>Enregistrement ARC : </label><b>{org.arc}</b></li>
                                <li><label>Enregistrement NEQ : </label><b>{org.neq}</b></li>
                                <li><label>Courriel général : </label><b>{org.email_general}</b></li>
                                <li><label>Site internet : </label><b>{org.website}</b></li>
                                <li><label>Téléphone service : </label><b>{org.tel_service}</b></li>
                                <li><label>Téléphone administration : </label><b>{org.tel_admin}</b></li>
                                <li><label>Cellulaire responsable : </label><b>{org.tel_responsible}</b></li>
                                <li><label>Courriel responsable : </label><b>{org.mail_responsible}</b></li>
                                <li><label>Adresse : </label><b>{org.address}</b></li>
                                <li><label>Code Postal : </label><b>{org.postal_code}</b></li>
                                <li><label>Ville / Municipalité : </label><b>{org.city}</b></li>
                                <li><label>Arrondissement : </label><b>{org.borough}</b></li>
                                <li><label>Quartier : </label><b>{org.district}</b></li>
                                <li><label>Région : </label><b>{org.region}</b></li>
                                <li><label>MRC : </label><b>{org.mrc}</b></li>
                                <Can do="action_fields" on={ org } >
                                    <li><label>Champ d'action : </label><b>{org.action_field ? org.action_field.label : ''}</b></li>
                                </Can>
                                <Can do="sectors" on={ org } >
                                    <li><label>Secteur d'intervention : </label><b>{org.sector ? org.sector.label : ''}</b></li>
                                </Can>
                                <Can do="subsectors" on={ org } >
                                    <li><label>Sous-secteur : </label><b>{org.subsector ? org.subsector.label : ''}</b></li>
                                </Can>
                                <li><label>Appellation présidence : </label><b>{org.presidency_appellation}</b></li>
                                <li><label>Prénom présidence : </label><b>{org.presidency_name}</b></li>
                                <li><label>Nom présidence : </label><b>{org.presidency_surname}</b></li>
                                <li><label>Appellation responsable : </label><b>{org.responsible_appellation}</b></li>
                                <li><label>Prénom responsable : </label><b>{org.responsible_name}</b></li>
                                <li><label>Nom responsable : </label><b>{org.responsible_surname}</b></li>
                                <li><label>Année d'association : </label><b>{org.organization_year}</b></li>
                                <Can do="yearly_follow" on={ org } >
                                    <li><label>Suivi triennal : </label><b>{org.yearly_follow}</b></li>
                                </Can>
                                <li><label>Territoire desservi : </label><b>{org.territory}</b></li>
                                <li><label>Mission : </label><b>{org.mission}</b></li>
                                <li><label>Services offerts : </label><b>{org.service}</b></li>
                                <li><label>Clientèle : </label><b>{org.customer}</b></li>
                                <li><label>Propriétaire de l'édifice qu'il occupe : </label><b>{org.building_owner ? 'Oui' : 'Non'}</b></li>
                                <li><label>Propriétaire d'un autre édifice : </label><b>{org.other_building_owner ? 'Oui' : 'Non'}</b></li>
                                <li><label>Adresse de l'édifice : </label><b>{org.building_address}</b></li>
                                <li><label>Propriétaire de la Fondation : </label><b>{org.foundation_owner ? 'Oui' : 'Non'}</b></li>
                                <li><label>Nom de la Fondation : </label><b>{org.foundation_name}</b></li>
                                <li><label>Adresse fiduciaire : </label><b>{org.fiduciary_address}</b></li>
                                <li><label>Ville fiduciaire : </label><b>{org.fiduciary_city}</b></li>
                                <li><label>Province fiduciaire : </label><b>{org.fiduciary_province}</b></li>
                                <li><label>Code postal fiduciaire : </label><b>{org.fiduciary_postal_code}</b></li>
                                <li><label>Point de service #1 : </label><b>{org.service_point_1}</b></li>
                                <li><label>Point de service #2 : </label><b>{org.service_point_2}</b></li>
                                <li><label>Point de service #3 : </label><b>{org.service_point_3}</b></li>
                                <li><label>Utilisateur associé : </label><b>{org.associated_user_name}</b></li>
                            </ul>
                        </article>
                    </Can>

                    <Can do="list" on={ "Request" }>
                        <article className={ "box table-box" + (requests.rendered ? " show" : " hide") }>
                            <h3>Demandes</h3>
                            <MaterialTable
                                title={ "Centraide - Liste des demandes - " + org.name }
                                columns={ requests.possibleColumns }
                                data={ query => this.handleRequestsPagination(query) }
                                { ...materialTableProps(this.state._tableKey_requests, "Centraide - Liste des demandes - " + org.name) }
                                onRowClick={ (event, rowData) => { history.push(`/requests/${rowData.id}`) } }
                                actions={ [] } />
                        </article>
                    </Can>

                    <Can do="read" on={ "Note" }>
                        <Can do="edit" on={ "Note" } passThrough>
                            { canEdit => (
                                <Can do="create" on={ "Note" } passThrough>
                                    { canCreate => (
                                        <article className={ "box table-box" + (notes.rendered ? " show" : " hide") }>
                                            <h3>Notes</h3>
                                            <ResetButton tableKey={ this.state._tableKey_notes } />

                                            <MaterialTable
                                                title={ "Centraide - Liste des notes - " + org.name }
                                                columns={ notes.possibleColumns }
                                                data={ query => this.handleNotesPagination(query) }
                                                { ...materialTableProps(this.state._tableKey_notes, "Centraide - Liste des notes - " + org.name) }
                                                onRowClick={ (event, rowData) => { history.push(`/notes/${rowData.id}`) } }
                                                actions={[
                                                    canCreate && {
                                                        icon: 'add',
                                                        tooltip: 'Ajouter une note',
                                                        iconProps: { className: 'main' },
                                                        isFreeAction: true,
                                                        onClick: (event, rowData) => history.push(`/organisations/${org.id}/notes/new`)
                                                    } || null,
                                                    canEdit && {
                                                        icon: 'edit',
                                                        tooltip: 'Éditer la note',
                                                        onClick: (event, rowData) => history.push(`/notes/${rowData.id}/edit`)
                                                    } || null
                                                  ]} />
                                        </article>
                                    ) }
                                </Can>
                            ) }
                        </Can>
                    </Can>

                </section>
            </Fragment>
        );
    }

    generateRegionOption() {
        var options = [];

        var regions = ["Bas-Saint-Laurent", "Capitale-Nationale", "Chaudière-Appalaches"];

        regions.forEach((el) => {
            options.push(
                <Select.Option
                    key={el}
                    value={el} >
                    {el}
                </Select.Option>
            )
        });

        return options;
    }

    generateActionFieldOption() {
        var options = [];

        var { action_fields } = this.state;
        (action_fields || []).sort(function (el1, el2) {
            if(el1.label < el2.label) { return -1; }
            if(el1.label > el2.label) { return 1; }
            return 0;
        });

        action_fields.forEach((el) => {
            options.push(
                <Select.Option
                    key={el.id}
                    value={el.id} >
                    {el.label}
                </Select.Option>
            )
        });

        return options;
    }

    generateSectorOption() {
        var options = [];

        var { sectors } = this.state;
        (sectors || []).sort(function (el1, el2) {
            if(el1.label < el2.label) { return -1; }
            if(el1.label > el2.label) { return 1; }
            return 0;
        });

        sectors.forEach((el) => {
            options.push(
                <Select.Option
                    key={el.id}
                    value={el.id} >
                    {el.label}
                </Select.Option>
            )
        });

        return options;
    }

    generateSubsectorOption() {
        var options = [];

        var { subsectors } = this.state;
        (subsectors || []).sort(function (el1, el2) {
            if(el1.label < el2.label) { return -1; }
            if(el1.label > el2.label) { return 1; }
            return 0;
        });

        subsectors.forEach((el) => {
            options.push(
                <Select.Option
                    key={el.id}
                    value={el.id} >
                    {el.label}
                </Select.Option>
            )
        });

        return options;
    }

    generateUserOption() {
        var options = [];

        var { users } = this.state;
        (users || []).sort(function (el1, el2) {
            if(el1.name + ' ' + el1.surname < el2.name + ' ' + el2.surname) { return -1; }
            if(el1.name + ' ' + el1.surname > el2.name + ' ' + el2.surname) { return 1; }
            return 0;
        });

        users.forEach((el) => {
            var found = (el.user_type.rang !== USER_RANK_ORG);

            this.state.associations.forEach((association) => {
                if ((el.id === association.user_id) && ((association.id !== this.state.org.association_id)) ) {
                    found = true;
                }
                else if (el.id !== this.state.org.associated_user_id && el.disabled) {
                    found = true;
                }
            });

            !found &&
            options.push(
                <Select.Option
                    key={el.id}
                    value={el.id} >
                    {el.name + ' ' + el.surname}
                </Select.Option>
            )
        });

        return options;
    }

    generateEditPage(pageTitle) {
        const { org, errors } = this.state;

        return (
            <Can do="edit" on={ org }>
                <BackButton link={ () => history.replace(`/organisations/${org.id}`) } />
                <h1>{ pageTitle }</h1>

                <article className="box">
                    <form onSubmit={this.handleEditSubmit}>
                        <ul className="profile-main-content">
                            <Can do="num_org" on={ org }>
                                <li>
                                    <label>Numéro d'organisme : </label>
                                    <Input
                                        name="organisation_number"
                                        value={org.organisation_number}
                                        onChange={(e) => this.handleChange(e)} >
                                    </Input>
                                </li>
                            </Can>
                            <li>
                                <label>Nom, selon lettres-patentes : </label>
                                <Input
                                    name="patent_name"
                                    value={org.patent_name}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Nom organismes, Lettres : </label>
                                <Input
                                    name="name"
                                    value={org.name}
                                    className={ errors.name ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Enregistrement ARC : </label>
                                <Input
                                    name="arc"
                                    value={org.arc}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Enregistrement NEQ : </label>
                                <Input
                                    name="neq"
                                    value={org.neq}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Courriel général : </label>
                                <Input
                                    name="email_general"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    title="Veuillez entrer une adresse courriel valide au format xxxx@xxx.xxx"
                                    value={org.email_general}
                                    className={ errors.email_general ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Site internet : </label>
                                <Input
                                    name="website"
                                    pattern='(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*'
                                    title="Veuillez entrer un site web valide au format https://xxxx ou http://xxxx"
                                    value={org.website}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Téléphone service : </label>
                                <Input
                                    name="tel_service"
                                    value={org.tel_service}
                                    className={ errors.tel_service ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Téléphone administration : </label>
                                <Input
                                    name="tel_admin"
                                    value={org.tel_admin}
                                    className={ errors.tel_admin ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Courriel responsable : </label>
                                <Input
                                    name="mail_responsible"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    title="Veuillez entrer une adresse courriel valide au format xxxx@xxx.xxx"
                                    value={org.mail_responsible}
                                    className={ errors.mail_responsible ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Adresse : </label>
                                <Input
                                    name="address"
                                    value={org.address}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Code postal : </label>
                                <Input
                                    name="postal_code"
                                    value={org.postal_code}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Région : </label>
                                <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.region}
                                        className={ errors.region ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("region")} >
                                        {this.generateRegionOption()}
                                </Select>
                            </li>
                            <li>
                                <label>Appellation présidence : </label>
                                <Input
                                    name="presidency_appellation"
                                    value={org.presidency_appellation}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Prénom présidence : </label>
                                <Input
                                    name="presidency_name"
                                    value={org.presidency_name}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Nom présidence : </label>
                                <Input
                                    name="presidency_surname"
                                    value={org.presidency_surname}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Appellation responsable : </label>
                                <Input
                                    name="responsible_appellation"
                                    value={org.responsible_appellation}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Prénom responsable : </label>
                                <Input
                                    name="responsible_name"
                                    value={org.responsible_name}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Nom responsable : </label>
                                <Input
                                    name="responsible_surname"
                                    value={org.responsible_surname}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Année d'association : </label>
                                <Input type="number"
                                    name="organization_year"
                                    value={org.organization_year}
                                    min={ 1900 } max={ 2100 }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <Can do="yearly_follow" on={ org } >
                                    <label>Suivi triennal : </label>
                                    <span style={ { display: 'flex', alignItems: 'center' }}>
                                        <Input type="number"
                                            style={ { width: '100px', textAlign: 'center', marginRight: '4px' } }
                                            name="yearly_follow_1"
                                            value={ org.yearly_follow_1 }
                                            min={ 0 } max={ 9 }
                                            onChange={(e) => this.handleChange(e)} >
                                        </Input>
                                        /
                                        <Input type="number"
                                            style={ { width: '100px', textAlign: 'center', marginLeft: '4px' } }
                                            name="yearly_follow_2"
                                            title="Veuillez entrer un chiffre entre 1 et 9"
                                            value={ org.yearly_follow_2 }
                                            min={ 0 } max={ 9 }
                                            onChange={(e) => this.handleChange(e)} >
                                        </Input>
                                    </span>
                                </Can>
                            </li>
                            <Can do="action_fields" on={ org } >
                                <li>
                                    <label>Champ d'action : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.action_field_id}
                                        className={ errors.action_field_id ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("action_field_id")} >
                                        {this.generateActionFieldOption()}
                                    </Select>
                                </li>
                            </Can>
                            <Can do="sectors" on={ org } >
                                <li>
                                    <label>Secteur d'intervention : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.sector_id}
                                        className={ errors.sector_id ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("sector_id")} >
                                        {this.generateSectorOption()}
                                    </Select>
                                </li>
                            </Can>
                            <Can do="subsectors" on={ org } >
                                <li>
                                    <label>Sous-secteur : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.subsector_id}
                                        className={ errors.subsector_id ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("subsector_id")} >
                                        {this.generateSubsectorOption()}
                                    </Select>
                                </li>
                            </Can>
                            <Can do="user" on={ org } >
                                <li>
                                    <label>Utilisateur associé : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.associated_user_id}
                                        onChange={this.handleSelectChange("associated_user_id")} >
                                        {this.generateUserOption()}
                                    </Select>
                                </li>
                            </Can>
                        </ul>
                        <div className="buttons">
                            <a className="button ghost slim" onClick={ () => history.replace(`/organisations/${org.id}`) }>Annuler</a>
                            <button disabled={this.state.isUpToDate}>Enregistrer les changements</button>
                        </div>
                    </form>
                </article>
            </Can>
        );
    }

    generateCreatePage(pageTitle) {
        const { org, errors } = this.state;

        return (
            <Can do="create" on={ org }>
                <BackButton />
                <h1>{ pageTitle }</h1>

                <article className="box">
                    <form onSubmit={this.handleCreateSubmit}>
                        <ul className="profile-main-content">
                            <Can do="num_org" on={ org }>
                                <li>
                                    <label>Numéro d'organisme : </label>
                                    <Input
                                        name="organisation_number"
                                        value={org.organisation_number}
                                        onChange={(e) => this.handleChange(e)} >
                                    </Input>
                                </li>
                            </Can>
                            <li>
                                <label>Nom, selon lettres-patentes : </label>
                                <Input
                                    name="patent_name"
                                    value={org.patent_name}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Nom organismes, Lettres : </label>
                                <Input
                                    name="name"
                                    value={org.name}
                                    className={ errors.name ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Enregistrement ARC : </label>
                                <Input
                                    name="arc"
                                    value={org.arc}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Enregistrement NEQ : </label>
                                <Input
                                    name="neq"
                                    value={org.neq}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Région : </label>
                                <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.region}
                                        className={ errors.region ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("region")} >
                                        {this.generateRegionOption()}
                                </Select>
                            </li>
                            <li>
                                <label>Courriel général : </label>
                                <Input
                                    name="email_general"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                    title="Veuillez entrer une adresse courriel valide au format xxxx@xxx.xxx"
                                    value={org.email_general}
                                    className={ errors.email_general ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Site internet : </label>
                                <Input
                                    name="website"
                                    pattern='(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*'
                                    title="Veuillez entrer un site web valide au format https://xxxx ou http://xxxx"
                                    value={org.website}
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Téléphone service : </label>
                                <Input
                                    name="tel_service"
                                    value={org.tel_service}
                                    className={ errors.tel_service ? 'error-input' : '' }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Année d'association : </label>
                                <Input type="number"
                                    name="organization_year"
                                    value={org.organization_year}
                                    min={ 1900 } max={ 2100 }
                                    onChange={(e) => this.handleChange(e)} >
                                </Input>
                            </li>
                            <li>
                                <label>Suivi triennal : </label>
                                <span style={ { display: 'flex', alignItems: 'center' }}>
                                    <Input type="number"
                                        style={ { width: '100px', textAlign: 'center', marginRight: '4px' } }
                                        name="yearly_follow_1"
                                        value={ org.yearly_follow_1 }
                                        min={ 1 } max={ 9 }
                                        onChange={(e) => this.handleChange(e)} >
                                    </Input>
                                    /
                                    <Input type="number"
                                        style={ { width: '100px', textAlign: 'center', marginLeft: '4px' } }
                                        name="yearly_follow_2"
                                        value={ org.yearly_follow_2 }
                                        min={ 1 } max={ 9 }
                                        onChange={(e) => this.handleChange(e)} >
                                    </Input>
                                </span>
                            </li>
                            <Can do="action_fields" on={ org } >
                                <li>
                                    <label>Champ d'action : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.action_field_id}
                                        className={ errors.action_field_id ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("action_field_id")} >
                                        {this.generateActionFieldOption()}
                                    </Select>
                                </li>
                            </Can>
                            <Can do="sectors" on={ org } >
                                <li>
                                    <label>Secteur d'intervention : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.sector_id}
                                        className={ errors.sector_id ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("sector_id")} >
                                        {this.generateSectorOption()}
                                    </Select>
                                </li>
                            </Can>
                            <Can do="subsectors" on={ org } >
                                <li>
                                    <label>Sous-secteur : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.subsector_id}
                                        className={ errors.subsector_id ? 'error-input' : '' }
                                        onChange={this.handleSelectChange("subsector_id")} >
                                        {this.generateSubsectorOption()}
                                    </Select>
                                </li>
                            </Can>
                            <Can do="user" on={ org } >
                                <li>
                                    <label>Utilisateur associé : </label>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={org.associated_user_id}
                                        onChange={this.handleSelectChange("associated_user_id")} >
                                        {this.generateUserOption()}
                                    </Select>
                                </li>
                            </Can>
                        </ul>
                        <div className="buttons">
                            <a className="button ghost slim" onClick={ () => history.goBack() }>Annuler</a>
                            <button>Créer l'organisme</button>
                        </div>
                    </form>
                </article>
            </Can>
        );
    }

    handleRequestsPagination(query) {
        const { id } = this.state;
        var _ = this;

        return new Promise(function (resolve, reject) {
            query.filters.push(generateMaterialFilter('organization_id', id))
            requestService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                if (result.data && result.data.length > 0) _.setState({ requests: { ..._.state.requests, rendered: true }})

                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    handleNotesPagination(query) {
        const { id } = this.state;
        var _ = this;

        return new Promise(function (resolve, reject) {
            query = JSON.parse(JSON.stringify(query));
            query.filters.push(generateMaterialFilter('organization_id', id));
            noteService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection).then((result) => {
                result.data.map((el) => {
                    el.date = el.date ? new Date(el.date) : null;
                    return el;
                });

                if (result.data && result.data.length > 0) _.setState({ notes: { ..._.state.notes, rendered: true }})
                resolve({
                    data: result.data,
                    page: result.total_pages > result.page ? result.page - 1 : (result.total_pages === 0) ? 0 : result.total_pages - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e)
            })
        });
    }

    render() {
        const { edit, create } = this.props;

        var pageTitle = "Profil d'un organisme"
        if (edit) {
            pageTitle = "Édition d'un organisme"
        } else if (create) {
            pageTitle = "Création d'un organisme"
        }

        return (
            <Fragment>
                <Helmet>
                    {title('Fiche d\'un organisme')}
                </Helmet>

                <div className="organisations-color profile-styles">
                    {this.generatePage(pageTitle)}
                </div>

                <Prompt
                    when={ !this.state.isUpToDate }
                    message="Attention, certaines de vos modifications n'ont pas été enregistrées. Voulez-vous vraiment quitter cette page ?"
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: {
            id: state.user.infos.id
        }
    };
};

export default connect(mapStateToProps, null)(OrganisationPage);
