import HomePage from './Home';
import E404Page from './E404';

import LoginPage from './_auth/Login';
import ResetPage from './_auth/Reset';

import OrganisationPage from './Organisation/Organisation';
import OrganisationListPage from './Organisation/List';
import OrganisationFinancialPage from './Organisation/Financial';
import OrganisationFinancialSummaryPage from './Organisation/FinancialSummary';

import NotePage from './Note/Note';
import NoteListPage from './Note/List';

import RequestPage from './Request/Request';
import RequestListPage from './Request/List';
import RequestFormPage from './Request/Form';
import RequestSummaryPage from './Request/Summary';

import UserPage from './User/User';
import UserListPage from './User/List';

import AnalyzeListPage from './Analyze/List';
import AnalyzeFormPage from './Analyze/Form';

import FAQPage from './FAQ/FAQ';

import DashboardPage from './Dashboard/Dashboard';
import ReportingPage from './Dashboard/Reporting';
import StatsPage from './Dashboard/Stats';
import StatusPage from './Dashboard/Status';

export {
    HomePage,
    E404Page,

    LoginPage,
    ResetPage,

    OrganisationPage,
    OrganisationListPage,
    OrganisationFinancialPage,
    OrganisationFinancialSummaryPage,

    NotePage,
    NoteListPage,

    RequestPage,
    RequestListPage,
    RequestFormPage,
    RequestSummaryPage,

    AnalyzeListPage,
    AnalyzeFormPage,

    UserPage,
    UserListPage,

    FAQPage,
    DashboardPage,
    StatsPage,
    StatusPage,
    ReportingPage,
}
