import React, { Component, Fragment } from 'react';

import 'antd/es/modal/style/index.css';
import 'antd/es/timeline/style/index.css';
import { Modal, Select, Timeline, notification, Popover, Popconfirm } from 'antd';

import { requestService, userService } from "../../../services";
import * as analyzesHelper from './analyzes'
import * as recommendationsHelper from './recommendations'
import * as statesController from '../../../constants/requestStates';

import {
    Can,

    REQ_USER_TYPE_BEN_AN_FIN,
    REQ_USER_TYPE_BEN_ADMIN_FIN,
    REQ_USER_TYPE_BEN_IMP_SOC,

    USER_RANKS_COULD_BE_CONSEILLE,
    USER_RANKS_COULD_BE_BENEVOLE,
    USER_RANKS_COULD_BE_ANALYSTE
} from '../../../constants'


export const findUserTypeInReq = (req, type) => (
    (req.request_users || []).find((u) => u.type_id === type)
)

export function fetchUsers(_) {
    userService.list().then(u => {
        _.setState({ users: u })
    }).catch(e => {
        _.setState(() => { throw new Error(e); })
    })
}

function handleAssigneeInputChange(_, key, value) {
    const { req } = _.state;
    value = value ? value : null;

    if (key.indexOf('.') >= 0) {
        var infos = key.split('.'),
            key = infos[1];

        // Détermine si la valeur a changée, affecte
        // l'état du bouton d'enregistrement.
        var change = (!req.benevoles[key] || req.benevoles[key] !== value)

        // Ajuste req.request_users avec les nouvelles informations.
        // Logique de insert si l'association n'existait pas.
        var found = false;
        var newRequestUsers = req.request_users.map(u => {
            u = u || {}
            if (u.type_id == key) {
                found = true;
                return { id: value, type_id: parseInt(key) }
            }
            return u
        })
        if (!found) newRequestUsers.push({ id: value, type_id: parseInt(key) })

        // Affectation de l'état.
        _.setState({ req: { ...req, benevoles: { ...req.benevoles, [key]: value}, request_users: newRequestUsers }, assigneeChange: change })
    } else {
        // Détermine si la valeur a changée, affecte
        // l'état du bouton d'enregistrement.
        var change = (!req[key] || req[key] !== value)

        // Ajuste req.user avec les nouvelles informations + Affectation de l'état.
        _.setState({ req: { ...req, [key]: value, user: { id: value } }, assigneeChange: change })
    }
}

export function assignSelfFinancial(_, req, userId) {
    requestService.update({
        id: req.id,
        benevoles: { [REQ_USER_TYPE_BEN_AN_FIN] : userId },
        type_id: req.type_id // GHETTO SOLUTION (pour pas faire planter le update en PATCH)
    }).then((r) => {
        r = _.formatRequest(r);
        _.setState({ req: { ...req, ...r } })
        analyzesHelper.fetch(_, r.id);

        notification.open({
            message: 'Assignés mis à jour',
            description: 'Cette demande vous est maintenant assignée.',
            placement: 'bottomRight',
        });
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function submitAssignees(_, req) {
    if (!_.state.assigneeChange) return;

    requestService.update({
        id: req.id,
        responsible_id: req.responsible_id,
        benevoles: req.benevoles,
    }).then((r) => {
        r = _.formatRequest(r);
        _.setState({ req: { ...req, ...r }, assigneeChange: false })

        notification.open({
            message: 'Assignés mis à jour',
            description: 'Les nouveaux utilisateurs ont été assignés avec succès.',
            placement: 'bottomRight',
        });
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function acceptRequest(_, req) {
    requestService.update({
        id: req.id,
        state_id: _.getStateFromIncrement(2)
    }).then((r) => {
        r = _.formatRequest(r);
        _.setState({ req: { ...req, ...r } })

        analyzesHelper.create(_, r);

        notification.open({
            message: 'Demande acceptée',
            description: 'La demande a été acceptée avec succès.',
            placement: 'bottomRight',
        });
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function rejectRequest(_, req) {
    requestService.update({
        id: req.id,
        state_id: _.getNextState()
    }).then((r) => {
        r = _.formatRequest(r);
        _.setState({ req: { ...req, ...r } })

        notification.open({
            message: 'Demande retournée',
            description: 'La demande a été retournée à l\'organisme avec succès.',
            placement: 'bottomRight',
        });
    }).catch((e) => {
        notification.open({
            message: 'Une erreur s\'est produite',
            description: 'Veuillez réessayer ou contacter un administrateur.',
            placement: 'bottomRight',
        });
    })
}

export function renderName(_, req, permission, user, key) {
    user = user || {};

    var allowed = [];
    switch (permission) {
        case 'assign_main':
            allowed = USER_RANKS_COULD_BE_CONSEILLE
            break;
        case 'assign_secondaries':
            allowed = USER_RANKS_COULD_BE_BENEVOLE
            break;
        case 'assign_financial':
            allowed = USER_RANKS_COULD_BE_ANALYSTE
            break;
        default:
            allowed = []
    }

    return (
        <Can do={ permission } on={ req } passThrough>
        { can =>
            (can && !req.isCompleted()) ? (
                <Select
                    showSearch
                    allowClear
                    onChange={ (value) => handleAssigneeInputChange(_, key, value) }
                    value={ user.id }
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                {
                    (_.state.users || []).map((u, i) => {
                        if (allowed.indexOf(u.user_type.rang) === -1 || (u.disabled && u.id !== user.id)) return

                        return (
                            <Select.Option
                                key={ i }
                                value={ u.id }>
                                { u.name + ' ' + u.surname }
                            </Select.Option>
                        )
                    } )
                }
                </Select>
            ) : (
                <b>{ (user.name && user.surname) ? user.name + ' ' + user.surname : '-' }</b>
            )
        }
        </Can>
    )
}

export function renderAssignees(_, req) {
    var assigneeItems = statesController.generateAssignees(req.type_id);

    return (
        <ul>
            <li>
                <label><i>Conseiller :</i></label>
                { renderName(_, req, 'assign_main', req.user, 'responsible_id') }
            </li>
            { assigneeItems.map((item, i) => (
                <li key={ i }>
                    <label>{ item.name }</label>
                    { renderName(_, req, item.can, findUserTypeInReq(req, item.type), `benevoles.${item.type}`) }
                </li>
            )) }
        </ul>
    )
}

export function renderTimeline(_, req) {
    if (!req.isFormSubmitted()) return;

    var t = statesController.generateTimeline(req.type_id, req.state_id) || [];
    var timelineItems = t.map(item => {
        if (item.analyzeType && _.state.analyzes) {
            var analyze = _.state.analyzes.find(a => a.type_id == item.analyzeType);
            if (analyze) {
                item.color = (analyze.isSubmitted()) ? 'green' : ((analyze.isInProgress()) ? 'blue' : 'grey')
            } else if (req.isInAnalysis()) {
                item.noInfos = true
            }
        }

        return <Timeline.Item key={ item.text } color={ item.color } className={ item.noInfos ? 'greyed' : '' }>{ item.text }</Timeline.Item>
    })

    return (
        <Timeline mode="right">
            { timelineItems }
        </Timeline>
    )
}

export function render(_) {
    const { req } = _.state;

    if (!req.isFormSubmitted()) return;

    return (
        <Can do="see_backstage" on={ req }>
            { req.isInCorrection() &&
                <article className="box notification">
                    <p>La demande est présentement en correction. Vous pouvez <a href={ "mailto:" + req.organization.email_general }>envoyer un courriel à l'organisme</a> pour préciser les aspects de leur demande à revoir.</p>
                </article>
            }

            <div className="status-bar">
                <div className="split-block">
                    <article className="timeline">
                        { renderTimeline(_, req) }
                    </article>

                    <article className="box status-box">
                        <div className="assignees">
                            { renderAssignees(_, req) }

                            { _.state.assigneeChange && (
                                <Can do={ 'save_assignees' } on={ req }>
                                    <div className="buttons">
                                        <a className={ 'button' + (!_.state.assigneeChange ? ' disabled' : '' )} onClick={ (e) => submitAssignees(_, req) }>Enregistrer les changements</a>
                                    </div>
                                </Can>
                            ) }
                        </div>
                    </article>
                </div>

                <article className="buttons main-buttons">
                    { req.canApproveOrReject() && (
                        <Can do={ 'approve_or_reject' } on={ req }>
                            <a className="button alt" onClick={ () => rejectRequest(_, req) }>Demander des modifications</a>
                            <Popconfirm
                                title={ "Êtes-vous certain de vouloir accepter cette demande? Cette action est irréversible, et le formulaire ne pourra être modifié à nouveau dans le futur." }
                                onConfirm={ () => acceptRequest(_, req) }
                                onCancel={ () => {} }
                                okText="Accepter"
                                cancelText="Annuler">
                                <a className="button">Accepter la demande</a>
                            </Popconfirm>
                        </Can>
                    ) }

                    { (req.isInAnalysis()) && (
                        <Can do={ 'approve_or_reject' } on={ req }>
                            { (!analyzesHelper.areDone(_)) ? (
                                <Popover content={
                                        <Fragment>
                                            <h4>Élément(s) manquant(s) :</h4>
                                            <ul>
                                                { _.state.analyzes.map((a, i) => {
                                                    if (a.isSubmitted()) return;
                                                    return <li key={ i }>{ (a.analyze_type || {}).label } à compléter</li>
                                                }) }
                                            </ul>
                                        </Fragment>
                                    }>
                                    <a className="button disabled full" onClick={ (e) => e.preventDefault() }>Accepter les analyses{ req.hasFinancialSummary() && ' et le sommaire financier' }</a>
                                </Popover>
                            ) : (
                                <Popconfirm
                                    title={ req.hasFinancialSummary() ? "Êtes-vous certain de vouloir accepter les analyses et le sommaire financier? Cette action est irréversible, et les analyses ne pourront être modifiées à nouveau dans le futur." : "Êtes-vous certain de vouloir accepter les analyses? Cette action est irréversible, et les analyses ne pourront être modifiées à nouveau dans le futur. Cette demande tombera en mode 'Complété'." }
                                    onConfirm={ () => analyzesHelper.acceptAnalyzes(_) }
                                    onCancel={ () => {} }
                                    okText="Accepter"
                                    cancelText="Annuler">
                                    <a className="button full">Accepter les analyses{ req.hasFinancialSummary() && ' et le sommaire financier' }</a>
                                </Popconfirm>
                            ) }
                        </Can>
                    ) }

                    { (req.analyzesAreOpened() && !findUserTypeInReq(req, REQ_USER_TYPE_BEN_AN_FIN)) && (
                        <Can do={ 'assign_financial_self' } on={ req }>
                            <a className="button full" onClick={ () => assignSelfFinancial(_, req, _.props.user.id) }>S'assigner cette demande</a>
                        </Can>
                    ) }

                    { (req.isInPostAnalysis() && !req.isCompleted()) && (
                        <Can do={ 'edit_conclusion' } on={ req }>
                            { (recommendationsHelper.validRec(_) === true && !_.state.recChange) ? (
                                req.isStandbyForApprobation() ? (
                                    <Can do={ 'approve_conclusion' } on={ req }>
                                        { recommendationsHelper.renderModal(_) }
                                        <a className={ "button full" } onClick={ () => recommendationsHelper.showModal(_) }>Approuver les recommandations</a>
                                    </Can>
                                ) : (
                                    <Popconfirm
                                        title={ "Voulez-vous vraiment soumettre ces recommandations ? Cette action est irréversible. Les recommandations demeureront disponibles pour consultation. La décision qui suivra à l'étape de l'approbation pourra être différente des recommandations soumises." }
                                        onConfirm={ () => recommendationsHelper.submitRec(_) }
                                        onCancel={ () => {} }
                                        okText="Soumettre"
                                        cancelText="Annuler">
                                        <a className={ "button full" }>Soumettre les recommandations</a>
                                    </Popconfirm>
                                )
                            ) : (
                                <Popover content={
                                    <Fragment>
                                        <h4>À faire avant { req.isStandbyForApprobation() ? 'd\'approuver' : 'de soumettre' } :</h4>
                                        <ul>
                                            { recommendationsHelper.validRec(_) !== true && ([ ...recommendationsHelper.validRec(_) ]).map((e, i) => {
                                                return <li key={ i }>{ e }</li>
                                            }) }
                                            { _.state.recChange && <li>Sauvegarder les recommandations</li>}
                                        </ul>
                                    </Fragment> }>
                                    <a className="button full disabled" onClick={ (e) => e.preventDefault() }>
                                        { req.isStandbyForApprobation() ? 'Approuver les recommandations' : 'Soumettre les recommandations' }
                                    </a>
                                </Popover>
                            ) }
                        </Can>
                    ) }
                </article>
            </div>
        </Can>
    )
}
