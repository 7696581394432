import { SAVE_ANSWER, SAVE_FORM, STOCK_TMP_ANSWER, FORM_BASE_LOADED, ANSWERS_LOADED } from "../constants";

const INITIAL_STATE = {
    base: {},
    answers: {},
    unsavedAnswers: {}
};

function formReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SAVE_ANSWER:
            return {
                base: { ...state.base },
                answers: { ...state.answers, ...action.payload },
                unsavedAnswers: {...state.unsavedAnswers}
            };

        case SAVE_FORM:
            return {
                base: { ...state.base },
                answers: { ...state.answers },
                unsavedAnswers: {}
            };

        case STOCK_TMP_ANSWER:
            return {
                base: { ...state.base },
                answers: { ...state.answers },
                unsavedAnswers: {...state.unsavedAnswers, ...action.payload}
            }

        case FORM_BASE_LOADED:
            return {
                base: action.payload,
                answers: { ...state.answers },
                unsavedAnswers: {}
            };

        case ANSWERS_LOADED:
            return {
                base: { ...state.base },
                answers: action.payload,
                unsavedAnswers: {}
            };

        default:
            return state;
    }
};

export default formReducer;
