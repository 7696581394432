import React, { Component, Fragment } from 'react';
import CountUp from 'react-countup';

import { DiscreteColorLegend } from 'react-vis';
import { findMinMax, label, sortFunction, color } from '../../../utils';


// Composant graphique pour afficher un Grouped Histogram Horizontal.
// (plus custom a centraide, a repenser si on veut reutiliser)
class CentraideGroupedHorizontalHistogram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            active: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.refreshes === prevState.refreshes || !nextProps.data) return null

        return {
            data: nextProps.data || [],
            refreshes: nextProps.refreshes
        }
    }


    render() {
        const { data, active } = this.state;

        var max = 0;
        data.forEach(section => {
            section.data.forEach(subSection => {
                subSection.data.forEach(d => {
                    if (d.value > max)
                        max = d.value;
                })
            })
        })

        return (
            <div className="grouped-horiz-histo-wrap">
                <DiscreteColorLegend
                    className="grouped-bar-legend"
                    items={ [
                        { title: '1 - Activité régulière ou courante', color: color(0, '', this.props.colorPalette), strokeWidth: 6 },
                        { title: '2 - Activité occasionnelle ou spéciale', color: color(1, '', this.props.colorPalette), strokeWidth: 6 }
                    ] } />

                { data.map((section, i) => (
                    <div key={ i } className="horiz-histo-section-wrap">
                        <h3 style={{ 'opacity' : (active !== false ? '0.1' : '1') }}>{ section.label }</h3>
                        <ul className="horiz-histo-wrap" onMouseOut={ () => this.setState({ active: false })}>
                            { section.data.map((subSection, j) => (
                                <li key={ j } onMouseOver={ () => this.setState({ active: `${ i }-${ j }` })} className={ active !== false ? (`${ i }-${ j }` === active ? 'hovered' : 'not-hovered') : ''}>
                                    <h4>{ subSection.label }</h4>
                                    { subSection.data.map((d, k) => (
                                        <div className="bar-wrap" key={ k }>
                                            <span className="histo-label">
                                                <span></span>
                                                <CountUp separator=" " decimal="," end={ d.value } preserveValue />
                                            </span>

                                            <span className="histo-bar">
                                                <span className="histo-bar-fill" style={{ 'opacity': (d.value === 0 ? '0.08' : '1'), 'width': `${ d.value / max * 100 }%`, 'background': color(k, d.label, this.props.colorPalette) }}></span>
                                            </span>
                                        </div>
                                    )) }
                                </li>
                            ))}
                        </ul>
                    </div>
                )) }
            </div>
        )
    }
}

export default CentraideGroupedHorizontalHistogram;
