import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, loadDefaultFilter } from '../../utils';

import { Can, API_DEV, USER_RANK_ADMIN } from '../../constants';
import { header, history, requestLookups, parseArrayString, renderSelectYears } from '../../utils';

import { requestService, organisationService, formService } from "../../services";

import { connect } from 'react-redux';

import { columns } from '../../constants/tablesColumns';
import MaterialTable from 'material-table';
import { materialTableProps, ResetButton } from '../../utils';

import 'antd/es/modal/style/index.css';
import 'antd/es/transfer/style/index.css';
import 'antd/es/checkbox/style/index.css';
import { Modal, Select, Input, Transfer, notification } from 'antd';



class RequestListPage extends Component {
    constructor(props) {
        super(props);
        const tableKey = 'request_list';
        this.state = {
            _tableKey: tableKey,
            _title: "Centraide - Liste des demandes",
            possibleColumns: (columns.getKeys('requests') || []).map(c => {
                var completeCol = { ...columns['requests']()[c], field: c.toLowerCase() };
                loadDefaultFilter(tableKey, completeCol);
                return { ...completeCol }
            }),
            filters: this.props.location.search.substr(1).split('&').map((el) => el.split("=")),
            createBatchOpened: false,
            fetched: { requestTypes: false, orgs: false },
            createBatchInfos: {}
        }

        this.openCreateBatch = this.openCreateBatch.bind(this);
        this.closeCreateBatch = this.closeCreateBatch.bind(this);
        this.handleTransferChange = this.handleTransferChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validForm = this.validForm.bind(this);
        this.submitCreateBatch = this.submitCreateBatch.bind(this);

        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        const { user } = this.props;
        const userId = user.id;
        const userRank = user.user_type.rang;
        var cols = [ ...this.state.possibleColumns]

        if (userRank !== USER_RANK_ADMIN) {
            cols.push({
                title: "Association",
                render: rowData => {
                    if (rowData.responsible_id == userId) return 'Conseiller'
                    var ru = (rowData.request_users || []).find(assoc => assoc.user_id == userId)
                    if (ru) return ru.label
                    return '-'
                },
                type: "string",
                hidden: false,
                field: 'test',
                filtering: false,
            })
        }

        requestLookups(this, cols);
    }

    openCreateBatch() {
        this.setState({ createBatchOpened: true })
        this.fetchRequestTypes();
        this.fetchOrganisations();
    }

    closeCreateBatch() {
        this.setState({ createBatchOpened: false })
    }

    fetchRequestTypes() {
        if (this.state.fetched.requestTypes) return;
        requestService.listTypes().then(result => {
            this.setState({ requestTypes: result, fetched: { ...this.state.fetched, requestTypes: true }})
        }).catch(e => {
            this.setState(() => { throw new Error(e); })
        })
    }

    fetchOrganisations() {
        if (this.state.fetched.orgs) return;
        organisationService.list().then(result => {
            this.setState({ orgs: result, fetched: { ...this.state.fetched, orgs: true }})
        }).catch(e => {
            this.setState(() => { throw new Error(e); })
        })
    }

    handleTransferChange(keys) {
        this.setState({ createBatchInfos: { ...this.state.createBatchInfos, orgs: keys }})
    }

    handleInputChange(key, value) {
        if (key === 'file' && value.size > 20000000) {
            notification.open({ message: 'Document trop volumineux', description: 'Le document dépasse la taille maximale (max. 20mo).', placement: 'bottomRight' });
            value = null;
        }

        this.setState({ createBatchInfos: { ...this.state.createBatchInfos, [key]: value }})
    }

    validForm() {
        const { createBatchInfos: inputs } = this.state;
        if (!inputs.requestType) return false;
        if (!inputs.orgs || inputs.orgs.length === 0) return false;
        if (!inputs.date || inputs.date === '') return false;
        if (!inputs.file) return false;
        if (!inputs.consent || inputs.consent === false) return false;

        return true;
    }

    submitCreateBatch() {
        if (!this.validForm()) return;
        const { createBatchInfos: inputs } = this.state;
        var typeInfos = JSON.parse(inputs.requestType);

        formService.updateGuide({
            url: inputs.file,
            id: typeInfos.form_id,
        }).then(d => {
            requestService.createBatch({
                type_id: typeInfos.id,
                orgs: inputs.orgs,
                date_close: inputs.date,
                financial_year: inputs.year,
            }).then(result => {
                notification.open({
                    message: 'Demandes créées',
                    description: 'Les demandes ont été créées avec succès. Les changements seront appliqués sous peu',
                    placement: 'bottomRight',
                });

                this.closeCreateBatch();
                window.location.reload();
            }).catch(error => {
                this.closeCreateBatch();
                notification.open({
                    message: 'Une erreur s\'est produite',
                    description: 'Veuillez réessayer ou contacter un administrateur.',
                    placement: 'bottomRight',
                });
            })
        }).catch(error => {
            this.closeCreateBatch();
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        })
    }

    handlePagination(query, currentUser) {
        return new Promise(function (resolve, reject) {
            requestService.listPaginate(query.page + 1, query.pageSize, query.filters, query.orderBy, query.orderDirection, currentUser).then((result) => {
                resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }

    render() {
        const { createBatchOpened, createBatchInfos: inputs } = this.state;
        var requestTypesOptions = [], orgsData = []

        if (createBatchOpened) {
            const { requestTypes, orgs, createBatchInfos } = this.state;
            requestTypesOptions = (requestTypes || []).map(t => (
                <Select.Option key={ t.id } value={ JSON.stringify(t) }>
                    { t.label }
                </Select.Option>
            ))

            orgsData = (orgs || []).map(o => ({
                key: o.id,
                title: o.yearly_follow ? `(${o.yearly_follow}) ${o.name}` : o.name
            }))
        }

        return (
            <Fragment>
                <Helmet>
                    { title('Liste des demandes') }
                </Helmet>

                <div className="requests-color">
                    <h1>Liste des demandes</h1>

                    <article className="box table-box">
                        <Can do={ "create_batch" } on={ "Request" } passThrough>
                            { can =>
                                <Fragment>
                                    <ResetButton tableKey={ this.state._tableKey } />

                                    <MaterialTable
                                        title={ this.state._title }
                                        columns={ this.state.possibleColumns }
                                        data={ query => this.handlePagination(query, this.props.user) }
                                        { ...materialTableProps(this.state._tableKey, this.state._title) }
                                        onRowClick={ (event, rowData) => { history.push(`/requests/${rowData.id}`) } }
                                        actions={ (can) ? (
                                            [{
                                                icon: 'add',
                                                iconProps: { className: 'main' },
                                                tooltip: 'Créer de nouvelles demandes',
                                                isFreeAction: true,
                                                onClick: (event) => this.openCreateBatch()
                                            }]
                                        ) : ( [] ) } />
                                </Fragment>
                            }
                        </Can>

                        <Can do={ "create_batch" } on={ "Request" }>
                            <Modal
                                visible={ createBatchOpened }
                                className="create-batch-modal"
                                centered

                                okText="Créer les demandes"
                                okButtonProps={{ className: 'button' + (!this.validForm() ? ' disabled' : '') }}
                                onOk={ this.submitCreateBatch }

                                cancelText="Annuler"
                                cancelButtonProps={{ className: 'button ghost' }}
                                onCancel={ this.closeCreateBatch }>

                                <h3>Création de demandes</h3>
                                <b>Attention, cette action est irréversible.</b>
                                <br />
                                <p>La procédure suivante créera de nouvelles demandes pour tous les organismes sélectionnés plus bas, même si une demande similaire a déjà été créée cette année pour un de ces organismes. Assurez-vous donc de remplir adéquatement ce formulaire.</p>
                                <br />

                                <div className="ctn">
                                    <label>Type de demande :</label>
                                    <Select
                                        onChange={ (val) => this.handleInputChange('requestType', val) }
                                        showSearch
                                        filterOption={ (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                        { requestTypesOptions }
                                    </Select>
                                </div>
                                <div className="ctn">
                                    <label>Organismes :</label>
                                    <Transfer
                                        dataSource={ orgsData }
                                        targetKeys={ inputs.orgs }
                                        onChange={ this.handleTransferChange }
                                        render={ item => item.title }
                                        locale={ {itemUnit: 'organisme', itemsUnit: 'organismes', notFoundContent: 'Aucun organisme', searchPlaceholder: 'Recherche un organisme' }}
                                        showSearch
                                        filterOption={ (value, option) => option.title.toLowerCase().indexOf(value.toLowerCase()) >= 0 } />
                                </div>
                                <div className="ctn">
                                    <label>Année financière des demandes :</label>
                                    <Select showSearch onChange={ (val) => this.handleInputChange('year', val) }>
                                        { renderSelectYears(3, 10) }
                                    </Select>
                                </div>
                                <div className="ctn">
                                    <label>Date de fermeture des demandes :</label>
                                    <Input type="date" value={ inputs.date } onChange={ (e) => this.handleInputChange('date', e.target.value) } />
                                </div>
                                <div className="ctn file">
                                    <label>Document guide :</label>
                                    <span>
                                        <input  type="file"
                                                onChange={ (e) => this.handleInputChange('file', e.target.files[0]) }
                                                accept={ "application/pdf" } />
                                        <small>Fichiers PDF uniquement, max. 20 mo</small>
                                    </span>
                                </div>
                                <input type="checkbox" id="consent"
                                    defaultChecked={ inputs.consent }
                                    onChange={ (e) => this.handleInputChange('consent', e.target.checked) } />
                                <label htmlFor="consent">Je confirme que les informations ci-dessus sont exactes</label>
                            </Modal>
                        </Can>
                    </article>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: (state.user.infos || {}),
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestListPage);
