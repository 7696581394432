import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, header, history, progress, handleAnswersFormat } from '../../utils';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { Icon } from '@material-ui/core';

import {
    Can, ABILITIES,

    REQ_USER_TYPE_BEN_AN_FIN,
} from '../../constants'

import { requestService, formService } from "../../services";
import { formActions } from '../../actions';
import { connect } from 'react-redux';

import { BackButton } from '../../components';

// import 'antd/es/popconfirm/style/css.js';
import { Popconfirm } from 'antd';
import 'antd/es/notification/style/index.css';
import { notification } from 'antd';
import 'antd/es/popover/style/index.css';
import { Popover } from 'antd';
import 'antd/es/skeleton/style/index.css';
import { Skeleton } from 'antd';
import 'antd/es/progress/style/index.css';
import { Progress } from 'antd';
import 'antd/es/switch/style/index.css';
import 'antd/es/grid/style/index.css';
import { Switch, Row, Col } from 'antd';

import * as documentsHelper from './_parts/documents';
import * as consentHelpers from './_parts/consent';
import * as analyzesHelper from './_parts/analyzes';
import * as recommendationsHelper from './_parts/recommendations';
import * as statusBarHelper from './_parts/statusBar';

import * as statesController from '../../constants/requestStates';


// TODO : Loader les answers partiellement quand on est pas en isFormLocked
class RequestPage extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params
        this.state = {
            loading: true,
            id: id,
            req: { __type: 'Request', ...statesController.injectFunctions() },
            docs: [], ans: {},
            editConclusion: false,
        }

        this.handleFormPreSubmit = this.handleFormPreSubmit.bind(this)
        this.getNextState = this.getNextState.bind(this)
        this.getPrevState = this.getPrevState.bind(this)
        this.getStateFromIncrement = this.getStateFromIncrement.bind(this)
    }

    componentDidMount() {
        const { id: reqId } = this.state;

        requestService.get(reqId).then((r) => {
            // Vérification des permissions
            var abilities = ABILITIES[this.props.rank]
            if (!abilities.can('read', { __type: 'Request', ...r })) {
                history.goBack();
                return;
            }

            // Traitement des benevoles
            r = this.formatRequest(r);

            // Assignation du state
            this.setState({ req: { ...this.state.req, ...r }, loading: false });

            const formId = r.request_type.form_id;
            const stateId = r.state_id;
            const typeId = r.type_id;

            // Fetch les answers (toujours besoin -- pour les documents
            // et pour savoir si le form est complet)
            // TODO : optimiser le call pour juste fetch les answers des documents, quand on est pas en isFormLocked?
            formService.getAnswers(reqId).then((a) => {
                const answers = handleAnswersFormat(a);
                this.setState({ ans: answers })

                // Fetch les documents
                documentsHelper.fetch(this, formId);

                // Fetch les sections (juste besoin pour submit)
                if (!r.isFormLocked()) {
                    formService.getForm(formId).then((f) => {
                        const { percentage } = progress(f.sections, answers)
                        // TODO : mettre bon props
                        this.setState({ formDone: (percentage >= 100), formPercentage: percentage, doc: f.url })
                        // this.setState({ formDone: (percentage !== 100), formPercentage: percentage })
                    }).catch((e) => {
                        this.setState(() => { throw new Error(e); })
                    })
                }

                // Fetch les users (si on peut assigner des users à la demande)
                if (abilities.can('assign_main', 'Request') || abilities.can('assign_secondaries', 'Request') || abilities.can('assign_financial', 'Request')) {
                    statusBarHelper.fetchUsers(this)
                }
            }).catch((e) => {
                this.setState(() => { throw new Error(e); })
            });

            // Fetch les analyses
            if (r.analyzesAreOpened()) {
                analyzesHelper.fetch(this, reqId);
            }

            // Fetch les recommandations
            if (r.isInPostAnalysis()) {
                recommendationsHelper.fetchColors(this);
                recommendationsHelper.fetch(this, r);
            }

        }).catch((e) => {
            this.setState(() => { throw new Error(e); })
        })

        window.onbeforeunload = function(event) {
            if (this.state.assigneeChange || this.state.recChange)
                return 'Refresh';
        }.bind(this)
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    handleFormPreSubmit(e) {
        e.preventDefault();
        consentHelpers.showModal(this);
    }

    toggleArchived = () => {
        const { req } = this.state
        const messages = {
            title: ['Demande archivée', 'Demande restorée'],
            description: ['Cette demande a été archivée.', 'Cette demande a été restorée.']
        }
        requestService.update({
            id: req.id,
            archived: req.archived ? 0 : 1
        }).then((r) => {
            this.setState({ req: { ...req, archived: r.archived } })
            const i = r.archived ? 0 : 1
            notification.open({
                message: messages['title'][i],
                description: messages['description'][i],
                placement: 'bottomRight',
            });
        }).catch((e) => {
            notification.open({
                message: 'Une erreur s\'est produite',
                description: 'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        })
    }

    getNextState() {
        const { req } = this.state;
        return req.getNextState()
    }

    getPrevState() {
        const { req } = this.state;
        return req.getPrevState()
    }

    getStateFromIncrement(increment) {
        const { req } = this.state;
        return req.getStateFromIncrement(increment);
    }

    formatRequest(req) {
        var r = { ...req, ...statesController.injectFunctions() }

        r.benevoles = {};
        (r.request_users || []).map((u) => {
            u.id = u.user_id;
            r.benevoles[u.type_id] = u.user_id;
            if (u.type_id === REQ_USER_TYPE_BEN_AN_FIN) r.analyste_id = u.user_id
        })

        return r;
    }

    render() {
        const { id, req } = this.state
        const { type_id } = this.props.user
        const stateId = req.state_id;
        const typeId = req.type_id;
        var _ = this;
        
        return (
            <Fragment>
                <Helmet>
                    { title('Détails d\'une demande') }
                </Helmet>

                <div className="requests-color request-details">
                    <BackButton />
                    <h1>Détails d'une demande</h1>
                    <section>
                        <Skeleton loading={ this.state.loading } paragraph={{ rows: 8 }}>    
                            <article className="infos">
                                <Row>
                                    <Col sm={24} md={18}>
                                        
                                            <ul>
                                                <li>Type : <b>{ (req.request_type || {}).label }</b></li>
                                                <li>Année : <b>{ req.financial_year }</b></li>
                                                <li>Statut : <b>{ (req.request_state || {}).label }</b></li>
                                                <li>Organisme : <b><Link to={ `/organisations/${req.organization_id}` }>{ (req.organization || {}).name }</Link></b></li>
                                                { !req.isFormLocked() && (<li>Date de limite : <b><Moment format="DD-MM-YYYY">{ req.date_close }</Moment></b></li>) }
                                            </ul>
                                    </Col>
                                    { /* Si admin on affiche le switch pour archiver la demande */ }
                                    {type_id == 2 && 
                                    <Col sm={24} md={6}>
                                        <Switch className="switch-lg" onClick={this.toggleArchived} checked={!req.archived} checkedChildren="Active" unCheckedChildren="Archivée" />
                                    </Col>
                                    }
                                </Row>
                            </article>

                            { statusBarHelper.render(this) }

                            { (req.hasRecommendations() && req.isInPostAnalysis()) && recommendationsHelper.render(this) }

                            { req.isApproved() && analyzesHelper.render(this) }

                            { /* Affichage de la page en mode formulaire "normal" */ }
                            { !req.isFormLocked() && (
                                <Fragment>
                                    { /* Boutons liens */ }
                                    <article className="buttons">
                                        <Can do="edit_form" on={ req } passThrough>
                                            { can => (
                                                can ? (
                                                    <Link to={ '/requests/' + id + '/form' } className={ "button" + (!req.hasFinancialPage() ? " full" : "") }>
                                                        { (!this.state.formDone) ? 'Remplir le formulaire' : 'Modifier le formulaire'}
                                                        <Progress percent={ this.state.formPercentage || 0 } showInfo={ false } />
                                                    </Link>
                                                ) : (
                                                    <Can do={ "read_form_always" } on={ req }>
                                                        <Link to={ '/requests/' + id + '/form' } className={ "button" + (!req.hasFinancialPage() ? " full" : "") }>
                                                            Consulter le formulaire
                                                        </Link>
                                                    </Can>
                                                )
                                            ) }
                                        </Can>



                                        { req.hasFinancialPage() && (
                                            <Can do="edit_financial_page" on={ req }>
                                                <Link to={ '/organisations/' + req.organization_id + '/financial/' + req.financial_year } className="button">Remplir les pages financières</Link>
                                            </Can>
                                        ) }
                                    </article>

                                    { /* Documents */ }
                                    { documentsHelper.render(this) }

                                    { /* Bouton de submit + modal */ }
                                    <article className="buttons end-button">
                                        <Can do="submit_form" on={ req }>
                                            { (!this.state.formDone || !this.state.docsDone) ? (
                                                <Popover content={
                                                        <Fragment>
                                                            <h4>Élément(s) manquant(s) :</h4>
                                                            <ul>
                                                                { !this.state.formDone && <li>Formulaire de la demande incomplet</li> }
                                                                { !this.state.docsDone && <li>Des documents ne sont pas encore importés</li> }
                                                            </ul>
                                                        </Fragment>
                                                    }>
                                                    <a className="button disabled" onClick={ (e) => e.preventDefault() }>Soumettre cette demande</a>
                                                </Popover>
                                            ) : (
                                                <a className="button" onClick={ (e) => this.handleFormPreSubmit(e) }>Soumettre cette demande</a>
                                            ) }

                                            { consentHelpers.render(this) }
                                        </Can>
                                    </article>
                                </Fragment>
                            ) }

                            { req.isFormLocked() && (
                                <Can do={ "see_backstage" } on={ req }>
                                    <div className={ "help-links" + (req.canApproveOrReject() ? ' validating' : '') }>
                                        <h4>Artéfacts</h4>
                                        <div className="links">
                                            <Can do={ "read_form" } on={ req }>
                                                <Link to={ '/requests/' + id + '/form' } className="button-link">
                                                    <i>Consulter le formulaire</i>
                                                    <Icon>keyboard_arrow_right</Icon>
                                                </Link>
                                            </Can>

                                            { (req.isInPostAnalysis() && req.hasSummary()) && (
                                                <Can do={ "read_summary" } on={ req }>
                                                    <Link to={ '/requests/' + id + '/summary' } className="button-link">
                                                        <i>Consulter la fiche synthèse</i>
                                                        <Icon>keyboard_arrow_right</Icon>
                                                    </Link>
                                                </Can>
                                            ) }

                                            <Can do={ "read_form" } on={ req }>
                                                { documentsHelper.renderModal(_) }
                                                <a className="button-link" onClick={ (e) => documentsHelper.showModal(_) }>
                                                    <i>Voir les pièces jointes fournies</i>
                                                    <Icon>keyboard_arrow_right</Icon>
                                                </a>
                                            </Can>

                                            { req.hasFinancialPage() && (
                                                <Can do={ "read_financial_page" } on={ req }>
                                                    <Link to={ '/organisations/' + req.organization_id + '/financial/' + req.financial_year + '/show' } className="button-link">
                                                        <i>Consulter les pages financières</i>
                                                        <Icon>keyboard_arrow_right</Icon>
                                                    </Link>
                                                </Can>
                                            ) }

                                            { req.hasFinancialSummary() && (
                                                <Can do={ "read_financial_summary" } on={ req }>
                                                    <Can do={ "edit_financial_summary" } on={ req } passThrough>
                                                    { edit => (
                                                        ((req.isInAnalysis() && !edit) || req.isInPostAnalysis()) && (
                                                            <Link to={ '/organisations/' + req.organization_id + '/financial/' + req.financial_year + '/summary' } className="button-link">
                                                                <i>Consulter le sommaire financier</i>
                                                                <Icon>keyboard_arrow_right</Icon>
                                                            </Link>
                                                        )
                                                    ) }
                                                    </Can>
                                                </Can>
                                            ) }

                                            { (req.isInAnalysis() && req.hasSummary()) && (
                                                <Can do="read_summary" on={ req }>
                                                    <Can not do="edit_summary" on={ req }>
                                                        <Link to={ '/requests/' + id + '/summary' } className={ "button-link" }>
                                                            <i>Consulter la fiche synthèse</i>
                                                            <Icon>keyboard_arrow_right</Icon>
                                                        </Link>
                                                    </Can>
                                                </Can>
                                            ) }
                                        </div>
                                    </div>
                                </Can>
                            ) }

                            { /* Affichage pour un org quand le form est submitted */ }
                            <Can not do={ "see_backstage" } on={ req }>
                                { req.isFormLocked() && (
                                    <Fragment>
                                        <article className="buttons">
                                            <Can do="read_form" on={ req }>
                                                <Link to={ '/requests/' + id + '/form' } className="button alt">Consulter le formulaire</Link>
                                            </Can>
                                            <Can do="read_financial_page" on={ req }>
                                                <Link to={ '/organisations/' + req.organization_id + '/financial/' + req.financial_year + '/show' } className="button alt">Consulter les pages financières</Link>
                                            </Can>
                                        </article>

                                        { documentsHelper.render(this) }
                                    </Fragment>
                                ) }
                            </Can>

                            { (!req.isFormLocked() && this.state.doc && 'url' in this.state.doc && this.state.doc.url) && (
                                <a className="doc-popup" href={ this.state.doc.url } target="_blank">
                                    <Icon>assignment</Icon>
                                    <i>Document guide</i>
                                </a>
                            ) }
                        </Skeleton>
                    </section>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.infos,
        rank: (((state.user.infos || {}).user_type || {}).rang || 0)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveForm: (answers, requestId) => dispatch(formActions.saveForm(answers, requestId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestPage);
