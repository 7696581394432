import {
    AN_STATE_PENDING,
    AN_STATE_PROGRESS,
    AN_STATE_COMPLETED,

    AN_TYPE_SUMMARY,
    AN_TYPE_FIN,
    AN_TYPE_FIN_RECONDUCTION,
    AN_TYPE_FIN_FIDC
} from './dbValues';

export function progress() {
    return AN_STATE_PROGRESS;
}

export function submitted() {
    return AN_STATE_COMPLETED;
}

export function injectFunctions() {
    return {
        isSummary: isSummary,
        isFinancial: isFinancial,
        isPending: isPending,
        isInProgress: isInProgress,
        isSubmitted: isSubmitted,
    }
}

function isSummary() {
    return (this.type_id === AN_TYPE_SUMMARY)
}

function isFinancial() {
    return (this.type_id === AN_TYPE_FIN || this.type_id === AN_TYPE_FIN_RECONDUCTION || this.type_id === AN_TYPE_FIN_FIDC)
}

function isPending() {
    return (this.state_id === AN_STATE_PENDING)
}

function isInProgress() {
    return (this.state_id === AN_STATE_PROGRESS)
}

function isSubmitted() {
    return (this.state_id === AN_STATE_COMPLETED)
}