import {
    SAVE_FINANCIAL_TABLE,
} from "../constants";

import { financialService } from "../services";

export const financialActions = {
    saveTable,
}

function saveTable(answers) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            financialService.saveTable(answers)
                .then(payload => {
                    dispatch({ type: SAVE_FINANCIAL_TABLE, payload: payload })
                    resolve('FORM SAVED');
                })
                .catch(error => {
                    console.log('ERROR SAVE FORM :', error);
                    reject('ERROR SAVE FORM :', error);
                });
        })

    }
}
