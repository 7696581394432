import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { title, history } from '../../utils';

import logo from '../../assets/images/logo.png';
import logoWhite from '../../assets/images/logo-image-white.png';

import { userActions } from "../../actions";
import { connect } from 'react-redux';


class ResetPage extends Component {

    constructor(props) {
        super(props)
        this.state = { email: '', token: '', pwd: '', pwd_bis: '', error: false };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAskReset = this.handleAskReset.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.generateContent = this.generateContent.bind(this);
    }

    componentDidMount() {
        this.setState({ token : this.props.location.search !== '' ? this.props.location.search.split('=')[1] : '' });
    }

    handleInputChange(e) {
        this.setState({ [e.target.name] : e.target.value })
    }

    handleAskReset(e) {
        e.preventDefault();
        this.props.askReset(this.state.email);
    }

    handleVerify(e) {
        e.preventDefault();
        return this.props.verify(this.state.token, this.state.email);
    }

    handleLeave(e) {
        e.preventDefault();
        this.props.leave();
    }

    handleReset(e) {
        e.preventDefault();
        this.handleVerify(e).then(() => {
            this.state.pwd === this.state.pwd_bis ? this.props.reset(this.state.pwd, this.props.infos.id, this.state.token ) : this.setState({ error: true })
        }).catch((e) => {

        })
    }

    generateContent() {
        switch(this.props.match.params.type) {
            case 'ask':
                return (!this.props.success) ? (
                    <Fragment>
                        <header>
                            <h1>Mot de passe oublié&nbsp;?</h1>
                        </header>

                        <div>
                            <p>
                                <label htmlFor="email">Courriel :</label>
                                <input type="text"
                                        name="email"
                                        id="email"
                                        value={ this.state.email }
                                        onChange={ this.handleInputChange } />
                            </p>

                            { (this.props.error) && <b className="error">Ce courriel n'est associé à aucun utilisateur</b> }

                            <div className="buttons">
                                <a className="button ghost slim" onClick={ this.handleLeave }>Annuler</a>
                                <button onClick={ this.handleAskReset }>Suivant</button>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <header>
                            <h1>Mot de passe oublié&nbsp;?</h1>
                        </header>

                        <div>
                            <p className="text-conf">
                                Votre demande a bien été prise en compte. Un courriel a été envoyé à : <b>{this.state.email}</b>.
                                <br /><br />
                                Consulter vos messages reçus pour obtenir la procédure à suivre.
                            </p>
                        </div>
                    </Fragment>
                )

            case 'edit':
                return (
                    <Fragment>
                        <header>
                            <h1>Nouveau de mot de passe</h1>
                        </header>

                        <div>
                            <p>
                                <label htmlFor="email">Courriel :</label>
                                <input type="text"
                                        name="email"
                                        id="email"
                                        value={ this.state.email }
                                        onChange={ this.handleInputChange } />
                            </p>
                            <p>
                                <label htmlFor="pwd">Nouveau mot de passe :</label>
                                <input type="password"
                                        name="pwd"
                                        id="pwd"
                                        value={ this.state.pwd }
                                        onChange={ this.handleInputChange } />
                            </p>
                            <p>
                                <label htmlFor="pwd_bis">Confirmation du mot de passe :</label>
                                <input type="password"
                                        name="pwd_bis"
                                        id="pwd_bis"
                                        value={ this.state.pwd_bis }
                                        onChange={ this.handleInputChange } />
                            </p>

                            { (!this.props.success) && <b className="warning">Assurez-vous d'entrer le courriel approprié</b> }
                            { (this.state.error) && <b className="error">Les mots de passe doivent être identiques</b> }

                            <div className="buttons">
                                <a className="button ghost slim" onClick={ this.handleLeave }>Annuler</a>
                                <button onClick={ this.handleReset }>Confirmer</button>
                            </div>
                        </div>
                    </Fragment>
                )

            case 'end':
                return (!this.props.success) ? (
                    <Fragment>
                        <header>
                            <h1>Erreur</h1>
                        </header>

                        <div>
                            <p className="text-conf">
                                Une erreur s'est produite lors du changement de mot de passe.
                            </p>

                            <div className="buttons">
                                <button onClick={ this.handleLeave }>Retouner à la page de connexion</button>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <header>
                            <h1>Succès</h1>
                        </header>

                        <div>
                            <p className="text-conf">
                                Le mot de passe a été changé avec succès.
                            </p>

                            <div className="buttons">
                                <button onClick={ this.handleLeave }>Retouner à la page de connexion</button>
                            </div>
                        </div>
                    </Fragment>
                )

            default:
                return null;
        }
    }

    render() {
        const content = this.generateContent();

        return (
            <Fragment>
                <Helmet>
                    { title('Connexion') }
                </Helmet>

                <div className="login-page reset">
                    <div className="left-block">
                        <form className="login-form">

                            {content}

                            <img src={ logo } alt="Centraide QCA Logo" />
                        </form>
                    </div>

                    <div className="right-block">
                        <img src={ logoWhite } alt="Centraide QCA Logo" />
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        error: state.user.resetError,
        success: state.user.reset,
        infos: state.user.reset_infos
    };
};

const mapDispatchToProps = dispatch => {
    return {
        askReset: (email) => dispatch(userActions.askReset(email)),
        verify: (token, email) => dispatch(userActions.verify(token, email)),
        reset: (id, pwd, token) => dispatch(userActions.reset(id, pwd, token)),
        leave: () => dispatch(userActions.leaveReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPage);
