import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { formatLabel, fetchOptionIds, displayCheckedAnswers, formatId, isChecked, parseArrayString, formatYear } from '../../../utils';
import { Answer } from '../../../models';
import Details from './_details';

import { formActions } from "../../../actions";
import { connect } from 'react-redux';
import { isArray } from 'util';


var isCustom = (s) => (s.custom === true || s.custom == 'true' || s.custom == '1');

class CheckboxList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            error: false,
            section_opened: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleColumnAddition = this.handleColumnAddition.bind(this);
        this.handleColumnDelete = this.handleColumnDelete.bind(this);
    }

    handleChange(e) {
        const optionId = fetchOptionIds(this.props.options);
        const fieldName = e.target.type === "text" ? e.target.name.split('_')[0] : e.target.value.split('_')[0];
        const optionIndex = e.target.type === "text" ? e.target.name.split('_')[1] : e.target.value.split('_')[1];
        const answerSelected = e.target.type === "text" ? e.target.name.split('_')[2] : e.target.value.split('_')[2];

        var ans = { [optionId] : {
            value: parseArrayString((this.props.answers[optionId] || {}).value),
            detail: (this.props.answers[optionId] || {}).detail
        }};

        if (!ans || !ans[optionId] || !ans[optionId].value || ans[optionId].value.length < this.props.fields.length ) {
            ans = Answer(optionId, new Array(0));
            this.props.fields.forEach(function (field) {
                ans[optionId].value.push(
                    {
                        name: field.categorie_name,
                        value: (new Array(field.options.length)).fill("0")
                    }
                )
            });
        }

        var index = -1;

        ans[optionId].value.forEach(function (el, i) {
            if(el && el.name === fieldName) {
                index = i;
            }
        });

        const field = this.props.fields.filter(function (field) {
            return field.categorie_name === fieldName;
        })[0];

        if (isCustom(field)) {
            if (e.target.type === "text") {
                ans[optionId].value[index].value[optionIndex][0] = e.target.value;
            }
            else if (e.target.type === "checkbox") {
                ans[optionId].value[index].value[optionIndex][1] = ans[optionId].value[index].value[optionIndex][1] !== answerSelected ? answerSelected : "0";
            }
        }
        else {
            ans[optionId].value[index].value[optionIndex] = ans[optionId].value[index].value[optionIndex] !== answerSelected ? answerSelected : "0";
        }

        this.props.saveAnswer(ans);
    }

    handleColumnAddition() {
        const optionId = fetchOptionIds(this.props.options);
        var value = parseArrayString((this.props.answers[optionId] || {}).value);

        if (!value) {
            value = []
            this.props.fields.forEach(function (field) {
                value.push(
                    {
                        name: field.categorie_name,
                        value: (new Array(field.options.length)).fill("0")
                    }
                )
            });
        }

        var c = this.props.fields.find((s) => isCustom(s));
        var index = -1;

        value.forEach(function (el, i) {
            if(el && el.name === c.categorie_name) {
                index = i;
            }
        });

        if (value[index].value.length > 0) {
            if (value[index].value.length % 2 === 0) {
                if(value[index].value[value[index].value.length - 1][0].trim() === ''){
                    return;
                }
            }
            else {
                if(value[index].value[Math.floor(value[index].value.length / 2)][0].trim() === ''){
                    return;
                }
            }
        }

        if (value[index].value.length % 2 === 0) {
            value[index].value.splice(value[index].value.length / 2, 0,['', '0'])
        }
        else {
            value[index].value.push(['', '0']);
        }

        this.props.saveAnswer(Answer(optionId, value))
    }

    handleColumnDelete(e) {

        var fieldName = e.fieldName, answerI = e.index;
        const optionId = fetchOptionIds(this.props.options);
        const value = parseArrayString((this.props.answers[optionId] || {}).value);

        var ans = { [optionId] : {
            value: value,
            detail: (this.props.answers[optionId] || {}).detail
        }};

        var index = -1;
        ans[optionId].value.forEach(function (el, i) {
            if(el && el.name === fieldName) {
                index = i;
            }
        });

        ans[optionId].value[index].value.splice(answerI, 1);
        this.props.saveAnswer(ans);
    }

    collapse(fieldName) {
        var state = Object.assign({}, this.state)
        state.section_opened.push(fieldName);
        this.setState(state);
    }

    shrink(fieldName) {
        var state = Object.assign({}, this.state)
        const section_opened = state.section_opened.filter(function (el) {
            return el !== fieldName;
        });
        state.section_opened = section_opened;
        this.setState(state);
    }

    whoIsChecked(fieldName, optionIndex, isCustom) {
        const optionId = fetchOptionIds(this.props.options);
        const value = parseArrayString((this.props.answers[optionId] || {}).value);

        if (value) {
            const answers = value.filter(function (el) {
                return el.name === fieldName;
            })

            if (isCustom) {
                return answers[0].value.map(function (el) {
                    return el[1];
                })[optionIndex];
            }
            else {
                return answers[0].value[optionIndex];
            }
        }

        return false;
    }

    getOptionCustom(fieldName) {
        const optionId = fetchOptionIds(this.props.options);
        const value = parseArrayString((this.props.answers[optionId] || {}).value);

        if (value) {
            const answers = value.filter(function (el) {
                return el.name === fieldName;
            });

            return answers[0].value.map(function (el) {
                return el[0];
            });
        }

        return [];
    }

    generateRow(fieldName, options, isCustom = false) {
        if (isCustom) {
            options = this.getOptionCustom(fieldName);
        }
        const size = options.length;
        var trs = [];

        for (let i = 0; i < Math.round(size/2); i++) {
            trs.push(
                <tr key={i}>
                    {(isCustom && !this.props.readonly) && <td className="delete-btn" onClick={ () => this.handleColumnDelete({fieldName: fieldName, index: i}) }><Icon>delete</Icon></td>}
                    <td colSpan={(isCustom || this.props.readonly) ? 1 : 2} className="line-title">{(!isCustom || this.props.readonly) ? formatYear(options[i], this.props.financialYear) : <input name={fieldName + '_' + i + '_0'} type="text" onChange={(e) => this.handleChange(e)} value={ options[i]|| "" }></input>}</td>
                    <td><input value={fieldName + '_' + i + '_1'} type="checkbox" onChange={(e) => this.handleChange(e)} checked={this.whoIsChecked(fieldName, i, isCustom) === "1"} disabled={this.props.readonly}/></td>
                    <td><input value={fieldName + '_' + i + '_2'} type="checkbox" onChange={(e) => this.handleChange(e)} checked={this.whoIsChecked(fieldName, i, isCustom) === "2"} disabled={this.props.readonly}/></td>

                    {
                        (Math.round(size/2) + i < size) ?
                            <Fragment>
                                {(isCustom && !this.props.readonly) && <td className="delete-btn" onClick={ () => this.handleColumnDelete({fieldName: fieldName, index: (Math.round(size/2) + i)}) }><Icon>delete</Icon></td>}
                                <td colSpan={(isCustom || this.props.readonly) ? 1 : 2} className="line-title">{(!isCustom || this.props.readonly) ? formatYear(options[Math.round(size/2) + i], this.props.financialYear) : <input name={fieldName + '_' + (Math.round(size/2) + i) + '_0'} type="text" onChange={(e) => this.handleChange(e)} value={options[Math.round(size/2) + i]|| ""}></input>}</td>
                                <td><input value={fieldName + '_' + (Math.round(size/2) + i) + '_1'} type="checkbox" onChange={(e) => this.handleChange(e)} checked={this.whoIsChecked(fieldName, (Math.round(size/2) + i), isCustom) === "1"} disabled={this.props.readonly}/></td>
                                <td><input value={fieldName + '_' + (Math.round(size/2) + i) + '_2'} type="checkbox" onChange={(e) => this.handleChange(e)} checked={this.whoIsChecked(fieldName, (Math.round(size/2) + i), isCustom) === "2"} disabled={this.props.readonly}/></td>
                            </Fragment>
                        :
                            <Fragment>
                                {(isCustom && !this.props.readonly) && <td></td>}
                                <td colSpan={(isCustom || this.props.readonly) ? 1 : 2}></td>
                                <td></td>
                                <td></td>
                            </Fragment>
                    }
                </tr>
            )
        }

        return trs;
    }

    generateList() {
        var table = [];
        this.props.fields.forEach(function(field, i) {
            table.push(
                <Fragment key={ i }>
                    <tr className="categorie-name">
                        <th colSpan={ 8 } onClick={ () => { this.state.section_opened.indexOf(field.categorie_name) === -1 ? this.collapse(field.categorie_name) : this.shrink(field.categorie_name)} } style={ { cursor: 'pointer' } }>
                            {formatYear(field.categorie_name, this.props.financialYear)}
                            {!this.props.readonly && (
                                this.state.section_opened.indexOf(field.categorie_name) === -1 ? <span style={ { float: 'right' } }><Icon>add</Icon></span> : <span style={ { float: 'right' } }><Icon>remove</Icon></span>
                            )}
                        </th>
                    </tr>

                    { (this.state.section_opened.indexOf(field.categorie_name) !== -1 || this.props.readonly) &&
                        <Fragment>
                            <tr className="option-name">
                                {(isCustom(field) &&!this.props.readonly)&& <th></th>}
                                <th colSpan={(isCustom(field) || this.props.readonly) ? 1 : 2}></th>
                                <th>1</th>
                                <th>2</th>
                                {(isCustom(field) && !this.props.readonly) && <th></th>}
                                <th colSpan={(isCustom(field) || this.props.readonly) ? 1 : 2}></th>
                                <th>1</th>
                                <th>2</th>
                            </tr>

                            { this.generateRow(field.categorie_name, field.options, isCustom(field)) }
                            { (isCustom(field) && !this.props.readonly) && <tr key={ -1 } className="plus-btn"><td colSpan={ 8 } align="center" onClick={ this.handleColumnAddition }><Icon>add</Icon></td></tr> }
                        </Fragment>
                    }
                    </Fragment>

            );
        }, this);

        return (
            <table>
                <thead>

                </thead>
                <tbody>
                    { table }
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <div className="ctn checkbox-list">
                { (this.props.readonly) ? (
                    formatLabel(this.props.label, null, null, null, this.props.question_number, this.props.financialYear)
                ) : (
                    formatLabel(this.props.label, null, this.props.required, this.props.help, this.props.question_number, this.props.financialYear)
                ) }
                <div className="table-wrap">
                    { this.generateList() }
                </div>

                { (this.state.error) && <small className="error">{ this.state.error }</small> }
            </div>
        )
    }
}

CheckboxList.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    answers: PropTypes.object,
    saveAnswer: PropTypes.func,
    required: PropTypes.bool,
    options: PropTypes.array,
    fields: PropTypes.array,
    readonly: PropTypes.bool,
    special: PropTypes.string,
    multiple: PropTypes.bool
}

const mapStateToProps = (state, props) => {
    var optionIds = (fetchOptionIds(props.options) || []),
        answers = {};
    optionIds.map(oid => answers[oid] = state.form.answers[oid] || {})

    return {
        financialYear: state.form.base.financialYear,
        answers: answers
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxList);
