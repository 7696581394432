import { parseArrayString, isChecked } from '../utils';


export const CHECKBOX = 'checkbox';
export const RADIO = 'radio';
export const SELECT = 'select';
export const TABLE = 'table';
export const FORMULA = 'formule';

// Fonction qui vérifie si une question est
// bel et bien répondue en fonction de l'objet
// Question, des Answers et du type de la question.
export function answered(q, answers, type) {
    // Pour un radio/checkbox/select, on doit vérifier toutes
    // les options pour regarder si au moins une est sélectionnée.
    if (type === RADIO || type === CHECKBOX || type.indexOf(SELECT) > -1) {
        var allFalse = true;

        q.options.map(o => {
            if (!answers.hasOwnProperty(o.id)) return;
            if (isChecked(answers, o)) allFalse = false;
        })

        return (!allFalse);
    }

    // Pour un table, on doit vérifier toutes les lignes et toutes
    // les colonnes (attention, la première peut être un titre).
    if (type.indexOf(TABLE) > -1) {
        var oneCellCompleted = false;
        var options = q.options.slice(0);
        if (q.hasOwnProperty('children_question') && q.children_question) options.push(...q.children_question.options)

        // On crée un array de booléen indiquant quels
        // index sont les titres.
        var fields = parseArrayString(q.fields);
        var titles = fields.map(f => f.type === 'title');

        options.map(o => {
            if (oneCellCompleted) {
                return;
            }
            if (!answers.hasOwnProperty(o.id)) {
                oneCellCompleted = false;
                return;
            }

            var val = parseArrayString(answers[o.id].value);
            if (Array.isArray(val)) {
                val.map((v, i) => {
                    // Vérification supplémentaire pour les customs options
                    if (o.custom === true || o.custom == 'true' || o.custom == '1') {
                        if (!Array.isArray(v)) oneCellCompleted = false;
                        else v.map((cust) => { if (cust && cust !== null && cust != '') oneCellCompleted = true })
                    } else {
                        if ((v !== null && v != '') && !(titles[i])) oneCellCompleted = true
                    }

                })
            } else {
                oneCellCompleted = false;
            }
        })
        return (oneCellCompleted)
    }

    // Pour une formule, on regarde si les 3 composantes
    // sont remplis.
    if (type === FORMULA) {
        var allFilled = true;
        var o = q.options[0];
        if (!o || !answers.hasOwnProperty(o.id)) return;

        var val = parseArrayString(answers[o.id].value);
        if (!val || !Array.isArray(val)) return;

        val.map(v => {
            if (typeof v === 'undefined' || v === null || v === "") allFilled = false;
        })

        return (allFilled);
    }

    // Pour les champs génériques (text/date/number/file/...),
    // on regarde que la réponse n'est pas vide.
    var o = q.options[0];
    if (!o || !answers.hasOwnProperty(o.id)) return;

    var val = answers[o.id].value;
    return (val && val != '')
}

// Fonction utilitaire pour get le progrès d'un
// formulaire en fonction de ses sections et de
// ses réponses.
// Retourne : { percentage: 0-100, valid: [... id des sections valides ...], progress: [... id des sections en progrès ...] }
export function progress(sections, answers) {
    var globalQuestionsCount = 0,
        globalAnswersCount = 0;

    var valid = [],
        progress = [];

    (sections || []).map(s => {
        var localQuestionsCount = 0,
            localAnswersCount = 0;

        // Parcours les questions dans la section.
        s.questions.map(q => {
            // Si la question n'est pas required, on skip.
            var type = q.question_type.label;
            const computed = q.computed ? JSON.parse(q.computed) : {};

            if (!q.required || computed.readOnly === "true" || type === 'title') return;
            localQuestionsCount++;

            if (answered(q, answers, type)) localAnswersCount++;
        })

        // Si autant (ou plus) de Answers que de Options,
        // la section est "valid".
        if (localAnswersCount >= localQuestionsCount) valid.push(s.id)

        // Si on a plus d'une Answer et plus d'une Option,
        // la section est "progress".
        else if (localAnswersCount > 0 && localQuestionsCount > 0) progress.push(s.id)

        // On incrémente les Answers et les Options globales
        // pour calculer le pourcentage.
        globalQuestionsCount += localQuestionsCount
        globalAnswersCount += localAnswersCount
    })

    if (globalQuestionsCount === 0) globalQuestionsCount = 1;
    return { percentage: ((globalAnswersCount / globalQuestionsCount) * 100), valid: valid, progress: progress }
}
