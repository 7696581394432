import {
    LOGIN_USER,
    LOGIN_ERROR,
    LOGIN_DISABLED,
    LOGOUT_USER,
    PWD_RESET,
    PWD_RESET_ERROR,
    PWD_ASK_RESET,
    PWD_ASK_RESET_ERROR,
    PWD_RESET_VERIFY,
    PWD_RESET_VERIFY_ERROR,
    PWD_RESET_LEAVE,
    UPDATE_USER_PROFILE,
    UPDATE_PROFILE_ERROR,
    UPDATE_USER_LOGO
} from "../constants";

import { userService } from "../services";
import { history, logoForUser } from '../utils';


export const userActions = {
    login,
    logout,
    askReset,
    verify,
    leaveReset,
    reset,
    edit,
}

function login(email, password) {
    return function (dispatch) {
        userService.login(email, password)
            .then(payload => {
                dispatch({ type: LOGIN_USER, payload });
                history.replace('/');
            })
            .catch(error => {
                if (error === 'Disabled')
                    dispatch({ type: LOGIN_DISABLED, payload: { } });
                else
                    dispatch({ type: LOGIN_ERROR, payload: { } })
            });
    }
};

function logout() {
    return function (dispatch) {
        dispatch({ type: LOGOUT_USER, payload: userService.logout() })
        history.replace('/login');
    }
};

function askReset(email) {
    return function (dispatch) {
        userService.askReset(email)
            .then(payload => {
                dispatch({ type: PWD_ASK_RESET, payload });
            })
            .catch(error => {
                dispatch({ type: PWD_ASK_RESET_ERROR, payload: {} })
                console.log('ERROR ASK RESET PASSWORD :', error);
            });
    }
};

function verify(token, email) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            userService.verify(token, email)
                .then(payload => {
                    dispatch({ type: PWD_RESET_VERIFY, payload });
                    resolve('OK');
                })
                .catch(error => {
                    dispatch({ type: PWD_RESET_VERIFY_ERROR, payload: {} })
                    console.log('ERROR VERIFY RESET PASSWORD :', error);
                    reject('ERROR VERIFY RESET PASSWORD :', error);
                });
        })
    }
};

function reset(pwd, id, token) {
    return function (dispatch) {
        userService.reset(pwd, id, token)
            .then(payload => {
                dispatch({ type: PWD_RESET, payload });
                history.replace('/password/end');
            })
            .catch(error => {
                dispatch({ type: PWD_RESET_ERROR, payload: {} })
                console.log('ERROR RESET PASSWORD :', error);
                history.replace('/password/end');
            });
    }
}

function leaveReset() {
    return function (dispatch) {
        dispatch({ type: PWD_RESET_LEAVE, payload: {} });
        history.replace('/login');
    }
};

function edit(id, new_user) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            userService.edit(id, new_user)
                .then(payload => {
                    dispatch({ type: UPDATE_USER_PROFILE, payload });
                    resolve('FORM SAVED');
                })
                .catch(error => {
                    console.log('ERROR UPDATE PROFILE :', error);
                    reject('ERROR SAVE FORM :', error);
                })
        })

    }
}
