import React from 'react';
import { formatId, formatYear } from '../../../utils/formHelpers';
import { Answer } from '../../../models';

import 'antd/es/input/style/index.css';
import { Input } from 'antd';

import { formActions } from '../../../actions';
import { connect } from 'react-redux';

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.handleDetail = this.handleDetail.bind(this);
    }

    handleDetail(e) {
        var optionId = e.target.getAttribute('data-for');
        this.props.saveAnswer(Answer(optionId, true, e.target.value));
    }

    render() {
        const { detail_text } = this.props;
        const { TextArea } = Input;
        return (
            <span className="detail-box">
                <label htmlFor={formatId(this.props.id) + '-detail'}>
                    {detail_text
                        ? formatYear(detail_text, this.props.financialYear)
                        : 'Justifier : '}
                </label>
                <TextArea
                    name={formatId(this.props.id) + '-detail'}
                    id={formatId(this.props.id) + '-detail'}
                    data-for={this.props.detail}
                    onChange={(e) => this.handleDetail(e)}
                    required={this.props.required}
                    value={this.props.answer.detail}
                    autoSize={{ minRows: 3, maxRows: 20 }}
                />
            </span>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        financialYear: state.form.base.financialYear,
        answer: state.form.answers[props.detail] || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveAnswer: (answer) => dispatch(formActions.saveAnswer(answer)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
