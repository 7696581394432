import React from 'react';
import {
    HomePage,

    OrganisationPage,
    OrganisationListPage,
    OrganisationFinancialPage,
    OrganisationFinancialSummaryPage,

    NotePage,
    NoteListPage,

    RequestPage,
    RequestListPage,
    RequestFormPage,
    RequestSummaryPage,

    AnalyzeListPage,
    AnalyzeFormPage,

    UserPage,
    UserListPage,

    FAQPage,
    DashboardPage
} from '../pages';
import { page } from '../utils';

const pages = {
    HomePage: (props) => <HomePage { ...props } />,

    OrganisationPage: (props) => <OrganisationPage { ...props } />,
    OrganisationEditPage: (props) => <OrganisationPage edit={ true } { ...props } />,
    OrganisationCreatePage: (props) => <OrganisationPage create={ true } { ...props } />,
    OrganisationListPage: (props) => <OrganisationListPage { ...props } />,
    OrganisationFinancialEditPage: (props) => <OrganisationFinancialPage { ...props } />,
    OrganisationFinancialPage: (props) => <OrganisationFinancialPage readOnly={ true } { ...props } />,
    OrganisationFinancialSummaryPage: (props) => <OrganisationFinancialSummaryPage { ...props } />,
    OrganisationOldFinancialSummaryPage: (props) => <OrganisationFinancialSummaryPage old={ true } { ...props } />,

    NotePage: (props) => <NotePage { ...props } />,
    NoteEditPage: (props) => <NotePage edit={ true } { ...props } />,
    NoteCreatePage: (props) => <NotePage create={ true } { ...props } />,
    NoteListPage: (props) => <NoteListPage { ...props } />,

    RequestPage: (props) => <RequestPage { ...props } />,
    RequestListPage: (props) => <RequestListPage { ...props } />,
    RequestFormPage: (props) => <RequestFormPage { ...props } />,
    RequestFormPagePrint: (props) => <RequestFormPage print={ true } { ...props } />,
    RequestSummaryPage: (props) => <RequestSummaryPage { ...props } />,
    RequestSummaryPagePrint: (props) => <RequestSummaryPage print={ true } { ...props } />,

    AnalyzeListPage: (props) => <AnalyzeListPage { ...props } />,
    AnalyzeFormPage: (props) => <AnalyzeFormPage { ...props } />,
    AnalyzeFormPagePrint: (props) => <AnalyzeFormPage print={ true } { ...props } />,

    UserPage: (props) => <UserPage { ...props } />,
    UserEditPage: (props) => <UserPage edit={ true } { ...props } />,
    UserCreatePage: (props) => <UserPage create={ true } { ...props } />,
    UserListPage: (props) => <UserListPage { ...props } />,

    FAQPage: (props) => <FAQPage { ...props } />,

    DashboardPage: (props) => <DashboardPage mode="stats" { ...props } />,
    ReportingPage: (props) => <DashboardPage mode="reporting" { ...props } />,
    StatusPage: (props) => <DashboardPage mode="status" { ...props } />,
}

export const routes = [
    {
        path: "/",
        exact: true,
        component: pages.HomePage,
        allowed: [1, 2, 3, 4, 5]
    },

    // Organisations
    {
        path: "/organisations",
        exact: true,
        component: pages.OrganisationListPage,
        allowed: [1, 2, 3],

        main: true,
        display: 'Organismes',
        icon: 'store',
        class: 'organisations-color'
    },
    {
        path: "/organisations/new",
        exact: true,
        component: pages.OrganisationCreatePage,
        allowed: [1]
    },
    {
        path: "/organisations/:id",
        exact: true,
        component: pages.OrganisationPage,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/organisations/:id/edit",
        exact: true,
        component: pages.OrganisationEditPage,
        allowed: [1, 5]
    },
    {
        path: "/organisations/:id/financial/:year",
        exact: true,
        component: pages.OrganisationFinancialEditPage,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/organisations/:id/financial/:year/show",
        exact: true,
        component: pages.OrganisationFinancialPage,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/organisations/:id/financial/:year/summary",
        exact: true,
        component: pages.OrganisationFinancialSummaryPage,
        allowed: [1, 2, 3, 4]
    },
    {
        path: "/organisations/:id/financial/:year/summary/old",
        exact: true,
        component: pages.OrganisationOldFinancialSummaryPage,
        allowed: [1, 2, 3, 4]
    },

    // Requests
    {
        path: "/requests",
        exact: true,
        component: pages.RequestListPage,
        allowed: [1, 2, 3, 4, 5],

        main: true,
        display: 'Demandes',
        icon: 'today',
        class: 'requests-color',

        // children: [
        //     {
        //         path: "/requests/types/1",
        //         display: "FOA renouvellement"
        //     },
        //     {
        //         path: "/requests/types/2",
        //         display: "FOA reconduction"
        //     },
        //     {
        //         path: "/requests/types/3",
        //         display: "FIDC"
        //     },
        //     {
        //         path: "/requests/types/4",
        //         display: "FMN"
        //     }
        // ]
    },
    {
        path: "/requests/:id",
        exact: true,
        component: pages.RequestPage,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/requests/:id/form",
        exact: true,
        component: pages.RequestFormPage,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/requests/:id/form/print",
        exact: true,
        component: pages.RequestFormPagePrint,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/requests/:id/summary",
        exact: true,
        component: pages.RequestSummaryPage,
        allowed: [1, 2, 3, 4]
    },
    {
        path: "/requests/:id/summary/print",
        exact: true,
        component: pages.RequestSummaryPagePrint,
        allowed: [1, 2, 3, 4]
    },

    // Analysis
    {
        path: "/analysis",
        exact: true,
        component: pages.AnalyzeListPage,
        allowed: [1, 2, 3, 4],

        main: true,
        display: 'Analyses',
        icon: 'gavel',
        class: 'requests-color'
    },
    {
        path: "/requests/:requestId/analysis/:id",
        exact: true,
        component: pages.AnalyzeFormPage,
        allowed: [1, 2, 3, 4]
    },
    {
        path: "/requests/:requestId/analysis/:id/print",
        exact: true,
        component: pages.AnalyzeFormPagePrint,
        allowed: [1, 2, 3, 4]
    },

    // Notes
    {
        path: "/notes",
        exact: true,
        component: pages.NoteListPage,
        allowed: [1, 2],

        main: true,
        display: 'Notes',
        icon: 'notes',
        class: 'organisations-color'
    },
    {
        path: "/notes/new",
        exact: true,
        component: pages.NoteCreatePage,
        allowed: [1, 2]
    },
    {
        path: "/organisations/:id_org/notes/new",
        exact: true,
        component: pages.NoteCreatePage,
        allowed: [1, 2]
    },
    {
        path: "/notes/:id_note",
        exact: true,
        component: pages.NotePage,
        allowed: [1, 2, 3, 4]
    },
    {
        path: "/notes/:id_note/edit",
        exact: true,
        component: pages.NoteEditPage,
        allowed: [1, 2]
    },

    //Dashboard
    {
        path: "/dashboard",
        exact: true,
        component: pages.DashboardPage,
        allowed: [1, 2],

        main: true,
        display: 'Tableau de bord',
        icon: 'pie_chart',
        class: 'organisations-color'
    },
    {
        path: "/dashboard/reporting",
        exact: true,
        component: pages.ReportingPage,
        allowed: [1, 2],
    },
    {
        path: "/dashboard/status",
        exact: true,
        component: pages.StatusPage,
        allowed: [1, 2],
    },

    // Users
    {
        path: "/users",
        exact: true,
        component: pages.UserListPage,
        allowed: [1],

        main: true,
        display: 'Utilisateurs',
        icon: 'person',
        class: 'users-color'
    },
    {
        path: "/users/new",
        exact: true,
        component: pages.UserCreatePage,
        allowed: [1]
    },
    {
        path: "/users/:id",
        exact: true,
        component: pages.UserPage,
        allowed: [1, 2, 3, 4, 5]
    },
    {
        path: "/users/:id/edit",
        exact: true,
        component: pages.UserEditPage,
        allowed: [1, 2, 3, 4, 5]
    },


    // FAQ
    {
        path: '/faq',
        exact: true,
        component: pages.FAQPage,
        allowed: [1, 2, 3, 4, 5]
    },
];
