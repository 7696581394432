import { MTableBody } from 'material-table';
import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { isUndefined } from 'util';

export function numberFormatProps() {
    return {
        isAllowed: (values) => {
            const { formattedValue, floatValue } = values;
            return formattedValue === '' || floatValue <= 9999999999999;
        },
        decimalSeparator: ',',
        thousandSeparator: ' ',
    };
}

export function loadDefaultFilter(tableKey, column) {
    const table_filters =
        JSON.parse(localStorage.getItem('table_filters')) || {};

    if (table_filters && table_filters[tableKey]) {
        column.defaultFilter = table_filters[tableKey][column.field];
    }

    const column_filters =
        JSON.parse(localStorage.getItem('column_filters')) || {};
    if (
        column_filters &&
        column_filters[tableKey] &&
        !isUndefined(column_filters[tableKey][column.field])
    ) {
        column.hidden = column_filters[tableKey][column.field];
    }

    return column;
}

export function materialTableProps(
    tableKey = null,
    exportFilename = 'export',
    isHome = false
) {
    return {
        components: {
            Body: (props) => (
                <MTableBody
                    {...props}
                    onFilterChanged={(columnId, value) => {
                        props.onFilterChanged(columnId, value);
                        if (tableKey) {
                            const currentFilters = JSON.parse(
                                localStorage.getItem('table_filters')
                            );
                            var newFilters = { ...currentFilters };
                            if (!newFilters[tableKey])
                                newFilters[tableKey] = {};
                            newFilters[tableKey][
                                props.columns[columnId].field
                            ] = value;
                            localStorage.setItem(
                                'table_filters',
                                JSON.stringify(newFilters)
                            );
                        }
                    }}
                />
            ),
        },
        onChangeColumnHidden: (column, hidden) => {
            if (tableKey) {
                const currentFilters = JSON.parse(
                    localStorage.getItem('column_filters')
                );
                var newFilters = { ...currentFilters };
                if (!newFilters[tableKey]) newFilters[tableKey] = {};
                newFilters[tableKey][column.field] = hidden;
                localStorage.setItem(
                    'column_filters',
                    JSON.stringify(newFilters)
                );
            }
        },
        localization: {
            body: {
                emptyDataSourceMessage: 'Aucune donnée',
                filterRow: {
                    filterTooltip: 'Filtre',
                },
            },
            pagination: {
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsSelect: 'ligne(s)',
                firstTooltip: 'Première page',
                previousTooltip: 'Page précédente',
                nextTooltip: 'Page suivante',
                lastTooltip: 'Dernière page',
            },
            toolbar: {
                exportTitle: 'Exporter',
                exportName: 'Exporter en fichier Excel',
                addRemoveColumns: 'Colonne(s) à afficher',
                showColumnsTitle: 'Colonne(s) à afficher',
            },
            header: {
                actions: 'Actions',
            },
        },
        options: {
            toolbar: !isHome,
            emptyRowsWhenPaging: false,
            sorting: true,
            draggable: false,
            filtering: true,
            exportButton: true,
            showTitle: false,
            search: false,
            columnsButton: true,
            pageSize: 50,
            actionsColumnIndex: -1,
            pageSizeOptions: [5, 10, 15, 20, 25, 50, 100, 150, 200, 500],
            headerStyle: {},
            rowStyle: (rowData) => ({
                backgroundColor:
                    rowData.disabled === true || rowData.disabled == 1
                        ? 'rgba(219, 55, 57, 0.1)'
                        : '',
            }),
            exportCsv: (columns, data) => {
                const col = columns.filter(
                    (col) => col.hidden === false || isUndefined(col.hidden)
                );

                const d = data.map((el) => {
                    var d = {};
                    col.forEach((c) => {
                        var val = '';
                        if (c.render) {
                            val = c.render(el);
                        } else if (c.lookup) {
                            val = c.lookup[c.field];
                        } else if (c.type === 'boolean') {
                            if (el[c.field] === true) val = 'Non';
                            else if (el[c.field] === false) val = 'Oui';
                            else val = '';
                        } else {
                            val = el[c.field];
                        }

                        try {
                            d[c.title] = val ? val.trim() : val;
                        } catch {
                            d[c.title] = String(val);
                        }
                    });
                    return d;
                });

                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(d);
                XLSX.utils.book_append_sheet(wb, ws, '');

                const wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'binary',
                });

                const buf = new ArrayBuffer(wbout.length);

                const view = new Uint8Array(buf);

                for (let i = 0; i !== wbout.length; ++i)
                    view[i] = wbout.charCodeAt(i) & 0xff;

                saveAs(
                    new Blob([buf], { type: 'application/octet-stream' }),
                    `${exportFilename}.xlsx`
                );
            },
        },
    };
}
