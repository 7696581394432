import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';

import { requestService } from '../../../services';
import {
    numberFormatProps,
    formatFileName,
    formatDownloadFile,
} from '../../../utils';

import { Input, Select, Popconfirm, notification, Modal } from 'antd';

import {
    Can,
    REQ_STATE_READY_FOR_APPROBATION,
    REQ_STATE_COMPLETED,
} from '../../../constants';

export const showModal = (_) => {
    _.setState({ recModal: true });
};
export const hideModal = (_) => {
    _.setState({ recModal: false });
};

export function fetch(_, req = _.state.req) {
    requestService
        .getRecommendation(req.id)
        .then((r) => {
            if (r) _.setState({ rec: r });
            else if (req.hasPreformulatedRecommendations())
                preformulate(_, req);
            else _.setState({ rec: {} });
        })
        .catch((e) => {
            _.setState(() => {
                throw new Error(e);
            });
        });
}

export function create(_, reqId) {
    requestService
        .createRecommendation({ request_id: reqId, done: false })
        .then((r) => {
            _.setState({ rec: r });
        })
        .catch((e) => {
            _.setState(() => {
                throw new Error(e);
            });
        });
}

export function preformulate(_, req) {
    requestService.preformulateRecommendations(req).then((rec) => {
        if (!rec) {
            _.setState({ rec: {} });
            return;
        }

        const updateState = rec.updateState;
        delete rec.updateState;

        if (!updateState) {
            _.setState({ rec: rec });
            return;
        }

        requestService
            .update({
                id: req.id,
                state_id: REQ_STATE_READY_FOR_APPROBATION,
            })
            .then((r) => {
                r = _.formatRequest(r);
                _.setState({ req: { ..._.state.req, ...r }, rec: rec });
            });
    });
}

export function fetchColors(_) {
    requestService
        .getColors()
        .then((c) => {
            _.setState({ colors: c });
        })
        .catch((e) => {
            _.setState(() => {
                throw new Error(e);
            });
        });
}

export function validRec(_) {
    const { rec } = _.state;
    if (!rec) return [];

    var errors = [];

    if (!rec.ammount_recommended)
        errors.push('Entrer un montant recommandé valide');
    if (!rec.color_id) errors.push('Choisir une couleur');

    return errors.length === 0 ? true : errors;
}

function handleRecInputChange(_, key, value) {
    const { rec, recChange } = _.state;
    var change = !rec[key] || rec[key] !== value;
    if (key === 'ammount_final' || key === 'color_id_final') change = false;
    if (recChange === true) change = true;
    _.setState({ rec: { ...rec, [key]: value }, recChange: change });
}

function updateRec(_, done = false, notify = true) {
    const { req, rec } = _.state;

    requestService
        .updateRecommendation({
            id: rec.id,
            request_id: req.id,
            color_id: done ? rec.color_id_final : rec.color_id,
            ammount_recommended: rec.ammount_recommended,
            ammount_final: done ? rec.ammount_final : null,
            comment: rec.comment,
            url: rec.url,
            done: done ? 1 : 0,
        })
        .then((r) => {
            _.setState({ rec: { ...rec, ...r }, recChange: false });

            if (notify)
                notification.open({
                    message: 'Recommandations mises à jour',
                    description:
                        'Les recommandations ont été mises à jour avec succès.',
                    placement: 'bottomRight',
                });
        })
        .catch((error) => {
            notification.open({
                message: "Une erreur s'est produite",
                description:
                    'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        });
}

export function submitRec(_) {
    const { req, recChange } = _.state;

    if (validRec(_) !== true) return;

    requestService
        .update({
            id: req.id,
            state_id: _.getNextState(),
        })
        .then((r) => {
            r = _.formatRequest(r);
            _.setState({ req: { ...req, ...r } });

            notification.open({
                message: 'Recommandations soumises',
                description:
                    'Les recommandations ont été soumises avec succès.',
                placement: 'bottomRight',
            });

            if (recChange) updateRec(_, false, false);
        })
        .catch((e) => {
            notification.open({
                message: "Une erreur s'est produite",
                description:
                    'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        });
}

export function closeRequest(_) {
    const { req, rec } = _.state;

    if (validRec(_) !== true) return;
    if (!rec.ammount_final) return;

    var newReq = {
        id: req.id,
        state_id: REQ_STATE_COMPLETED,
        color_id: rec.color_id_final,
    };

    requestService
        .update(newReq)
        .then((r) => {
            r = _.formatRequest(r);
            _.setState({ req: { ...req, ...r } });

            notification.open({
                message: 'Recommandations approuvées',
                description:
                    'Les recommandations ont été approuvées avec succès. Cette demande sera archivée sous peu.',
                placement: 'bottomRight',
            });

            updateRec(_, true, false);
            hideModal(_);
        })
        .catch((e) => {
            notification.open({
                message: "Une erreur s'est produite",
                description:
                    'Veuillez réessayer ou contacter un administrateur.',
                placement: 'bottomRight',
            });
        });
}

export function render(_) {
    const { req, colors, recModal, editConclusion } = _.state;
    let done = req.isCompleted();

    var { rec } = _.state;
    rec = rec || {};

    var colorsOptions = (colors || []).map((c) => (
        <Select.Option key={c.id} value={c.id}>
            {c.label}
        </Select.Option>
    ));

    var linkToFile = (url = {}, html = 'Consulter le fichier courant') => {
        if (!('url' in url)) return;
        url = url.url;
        return url ? (
            <a href={url} target="_blank">
                {html}
            </a>
        ) : null;
    };

    var file = linkToFile(rec.url);

    return (
        <Can do="see_backstage" on={req}>
            <Can do="read_conclusion" on={req}>
                <Can do="edit_conclusion" on={req} passThrough>
                    {(can) => (
                        <article
                            className={
                                'box recommendations-box' +
                                ((!can || done) && !editConclusion
                                    ? ' read-only'
                                    : '')
                            }
                        >
                            <h3>{done ? 'Conclusion' : 'Recommandations'}</h3>
                            <ul>
                                <li>
                                    <label>Montant recommandé :</label>
                                    {can && !done ? (
                                        <NumberFormat
                                            onChange={(e) =>
                                                handleRecInputChange(
                                                    _,
                                                    'ammount_recommended',
                                                    e.target.value
                                                )
                                            }
                                            value={rec.ammount_recommended}
                                            {...numberFormatProps()}
                                        />
                                    ) : (
                                        <b>{rec.ammount_recommended}$</b>
                                    )}
                                </li>
                                {done && (
                                    <li>
                                        <label>Montant accordé :</label>
                                        <b>{rec.ammount_final}$</b>
                                    </li>
                                )}
                                <li>
                                    <label>
                                        {done
                                            ? 'Couleur accordée :'
                                            : 'Couleur recommandée : '}
                                    </label>
                                    {can && !done ? (
                                        <Select
                                            showSearch
                                            onChange={(value) =>
                                                handleRecInputChange(
                                                    _,
                                                    'color_id',
                                                    value
                                                )
                                            }
                                            value={rec.color_id}
                                            filterOption={(input, option) =>
                                                option.props.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {colorsOptions}
                                        </Select>
                                    ) : (
                                        <b>{(rec.color || {}).label}</b>
                                    )}
                                </li>
                                <li>
                                    <label>Commentaires :</label>
                                    {can && !done ? (
                                        <Input.TextArea
                                            onChange={(e) =>
                                                handleRecInputChange(
                                                    _,
                                                    'comment',
                                                    e.target.value
                                                )
                                            }
                                            value={rec.comment}
                                        />
                                    ) : rec.comment ? (
                                        <b>{rec.comment}</b>
                                    ) : (
                                        <i>Aucun commentaire</i>
                                    )}
                                </li>
                                <li className="doc">
                                    <label>Rapport d'analyse final :</label>
                                    {can && (!done || editConclusion) ? (
                                        <Fragment>
                                            {file ? (
                                                <span>
                                                    <b>{file}</b>
                                                </span>
                                            ) : null}
                                            <span>
                                                Importer{file && ' à nouveau'}{' '}
                                                (max. 20 mo) :
                                                <input
                                                    type="file"
                                                    onChange={(e) =>
                                                        handleRecInputChange(
                                                            _,
                                                            'url',
                                                            e.target.files[0]
                                                        )
                                                    }
                                                />
                                            </span>
                                        </Fragment>
                                    ) : file ? (
                                        <b>{file}</b>
                                    ) : (
                                        <i>Aucun fichier</i>
                                    )}
                                </li>
                            </ul>

                            <div className="buttons">
                                {can &&
                                !done &&
                                _.state.recChange &&
                                !recModal ? (
                                    <a
                                        className={
                                            'button' +
                                            (!_.state.recChange
                                                ? ' disabled'
                                                : '')
                                        }
                                        onClick={(e) => updateRec(_)}
                                    >
                                        Enregistrer les changements
                                    </a>
                                ) : can &&
                                  done &&
                                  editConclusion &&
                                  _.state.recChange &&
                                  !recModal ? (
                                    <a
                                        className={
                                            'button' +
                                            (!_.state.recChange
                                                ? ' disabled'
                                                : '')
                                        }
                                        onClick={(e) => {
                                            _.setState({
                                                ..._.state,
                                                editConclusion: false,
                                            });
                                            updateRec(_);
                                        }}
                                    >
                                        Enregistrer les changements
                                    </a>
                                ) : can &&
                                  done &&
                                  !editConclusion &&
                                  !recModal ? (
                                    <a
                                        className={'button'}
                                        onClick={(e) =>
                                            _.setState({
                                                ..._.state,
                                                editConclusion: true,
                                            })
                                        }
                                    >
                                        Charger un nouveau rapport final
                                    </a>
                                ) : (
                                    can &&
                                    editConclusion &&
                                    !recModal && (
                                        <a
                                            className={'button'}
                                            onClick={(e) =>
                                                _.setState({
                                                    ..._.state,
                                                    editConclusion: false,
                                                })
                                            }
                                        >
                                            Annuler
                                        </a>
                                    )
                                )}
                            </div>
                        </article>
                    )}
                </Can>
            </Can>
        </Can>
    );
}

export function renderModal(_) {
    const { req, recModal, colors } = _.state;
    var { rec } = _.state;

    if (!rec) return;

    var colorsOptions = (colors || []).map((c) => (
        <Select.Option key={c.id} value={c.id}>
            {c.label}
        </Select.Option>
    ));

    return (
        <Modal
            visible={recModal}
            className="request-approbation-modal"
            centered
            okText="Approuver les recommandations"
            okButtonProps={{
                className:
                    'button' +
                    (!validRec(_) || !rec.ammount_final ? ' disabled' : ''),
            }}
            onOk={() => closeRequest(_)}
            cancelText="Annuler"
            cancelButtonProps={{ className: 'button ghost' }}
            onCancel={() => hideModal(_)}
        >
            <h3>Approbation finale de la demande</h3>
            <p>
                Voulez-vous vraiment enregistrer cette décision ? Cette action
                est irréversible. La décision demeurera disponible pour
                consultation ultérieure.
            </p>
            <br />
            <div className="ctn">
                <label>Montant accordé :</label>
                <NumberFormat
                    onChange={(e) =>
                        handleRecInputChange(_, 'ammount_final', e.target.value)
                    }
                    value={rec.ammount_final}
                    placeholder={rec.ammount_recommended}
                    {...numberFormatProps()}
                />
            </div>
            <div className="ctn">
                <label>Couleur accordée :</label>
                <Select
                    showSearch
                    onChange={(value) =>
                        handleRecInputChange(_, 'color_id_final', value)
                    }
                    defaultValue={rec.color_id}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {colorsOptions}
                </Select>
            </div>
        </Modal>
    );
}
